
import { ContentItem, Elements } from '@kentico/kontent-delivery';
import { EmployerNextStep } from './employer_next_step';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Employer extends ContentItem {
    public postIntakeContent!: Elements.LinkedItemsElement<EmployerNextStep>;
    public identifier!: Elements.TextElement;
    public customTasks!: Elements.LinkedItemsElement<ContentItem>;
    public fitForDutyForm!: Elements.AssetsElement;
    public intermittentBondingHasRestrictions!: Elements.MultipleChoiceElement;
    public rtwRequiresRelease!: Elements.MultipleChoiceElement;
    public customIntermittentBondingText!: Elements.TextElement;
    public intermittentBondingAllowed!: Elements.MultipleChoiceElement;
    public specialBenefits!: Elements.LinkedItemsElement<ContentItem>;
    public identifierDev1!: Elements.TextElement;
    public leaveLogicPolicyMapping!: Elements.LinkedItemsElement<ContentItem>;
    public identifierUat!: Elements.TextElement;
    public rtwCustomEmployeeMessaging!: Elements.CustomElement;
    public educationalContent!: Elements.LinkedItemsElement<ContentItem>;
    public identifierItest1!: Elements.TextElement;
    public rtwFitForDutyFormDestination!: Elements.MultipleChoiceElement;
    public corporateProtections!: Elements.LinkedItemsElement<ContentItem>;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'post_intake_content') {
                    return 'postIntakeContent';
                }
                if (elementName === 'custom_tasks') {
                    return 'customTasks';
                }
                if (elementName === 'fit_for_duty_form') {
                    return 'fitForDutyForm';
                }
                if (elementName === 'intermittent_bonding_has_restrictions') {
                    return 'intermittentBondingHasRestrictions';
                }
                if (elementName === 'custom_tasks') {
                    return 'customTasks';
                }
                if (elementName === 'rtw___requires_release') {
                    return 'rtwRequiresRelease';
                }
                if (elementName === 'custom_intermittent_bonding_text') {
                    return 'customIntermittentBondingText';
                }
                if (elementName === 'intermittent_bonding_allowed') {
                    return 'intermittentBondingAllowed';
                }
                if (elementName === 'special_benefits') {
                    return 'specialBenefits';
                }
                if (elementName === 'identifier_dev1') {
                    return 'identifierDev1';
                }
                if (elementName === 'leave_logic_policy_mapping') {
                    return 'leaveLogicPolicyMapping';
                }
                if (elementName === 'identifier_uat') {
                    return 'identifierUat';
                }
                if (elementName === 'rtw___custom_employee_messaging') {
                    return 'rtwCustomEmployeeMessaging';
                }
                if (elementName === 'educational_content') {
                    return 'educationalContent';
                }
                if (elementName === 'identifier_itest1') {
                    return 'identifierItest1';
                }
                if (elementName === 'rtw___fit_for_duty_form_destination') {
                    return 'rtwFitForDutyFormDestination';
                }
                if (elementName === 'corporate_protections') {
                    return 'corporateProtections';
                }
                if (elementName === 'excluded_leave_plans') {
                    return 'excludedLeavePlans';
                }
                return elementName;
            })
        });
    }
}
