interface ErrorsMap {
  [key: string]: number;
}

const errors: ErrorsMap = {};

const buildKey = (url: string, method: string) => `${url}_${method}`;

export const addError = (url: string, method: string): void => {
  const key = buildKey(url, method);

  if (!errors[key]) {
    errors[key] = 1;
  }

  errors[key]++;
};

export const atMaxErrors = (url: string, method: string): boolean => {
  const key = buildKey(url, method);

  return errors[key] ? errors[key] > 4 : false;
};
