import React from 'react';
import styled from 'styled-components';
import { getHtmlFromMarkdown } from '../../../../utils';
import {
  ActionLink,
  LoadingCover,
} from '../../../base/simple-styled-components';
import { FadeLoader } from 'react-spinners';
import LeftArrow from '../../../../images/left-arrow-blue.svg';
import { TaskType } from '../../index';
import { retrieveTask } from '../../utils';
import { useParams } from 'react-router';
import { colors, fonts } from '../../../../style';

export interface TaskLLDescriptionParamsProps {
  taskKey?: string;
}

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 32px;
`;

const Title = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 36px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  margin-bottom: 24px;
`;

const BackButton = styled.div`
  margin-bottom: 24px;
`;

const ActionLinkMod = styled(ActionLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: fit-content;
  margin: 0;
`;

const llManipulation = () => {
  const hideDisplay = 'display:none;';

  const hideElements = document.querySelectorAll(
    '.tinymce-template-collapsing-button',
  );

  for (const element of hideElements) {
    element.setAttribute('style', hideDisplay);
  }
};

const TaskLlDescription: () => React.ReactElement = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [task, setTask] = React.useState<TaskType>();
  const [isLlTask, setIsLlTask] = React.useState<boolean>(false);
  const params = useParams<TaskLLDescriptionParamsProps>();

  React.useEffect(() => {
    retrieveTask(setTask, setIsLlTask, params.taskKey, setLoading);
  }, [params.taskKey]);

  React.useEffect(() => {
    llManipulation();
  }, [task]);

  return (
    <>
      <BackButton>
        <ActionLinkMod
          className="d-flex"
          to={`/app/task/${task?.taskId}/details`}
        >
          <img src={LeftArrow} alt="Back" />
          <span style={{ marginLeft: '8px' }}>Task Details</span>
        </ActionLinkMod>
      </BackButton>
      <Title role="title">{task?.taskTitle}</Title>
      <Container>
        {loading ||
          (!task && (
            <LoadingCover>
              {' '}
              <FadeLoader
                height={14}
                width={5}
                radius={2}
                margin={0}
                color={colors.blue}
              />
            </LoadingCover>
          ))}
        {task && isLlTask && task.leaveLogicDescription && (
          <div
            dangerouslySetInnerHTML={getHtmlFromMarkdown(
              task?.leaveLogicDescription,
            )}
          />
        )}
      </Container>
    </>
  );
};

export default TaskLlDescription;
