import React, { useState } from 'react';
import styled from 'styled-components';
import {
  LoadingCover,
  OpenSansSemiBoldLabel2,
  SectionSeparator,
  OpenSansRegularValue,
  ActionLink,
  SubmitButton,
  CancelButton,
  StyledNotification,
  ToastNotificationGroupStyle,
} from '../../../base/simple-styled-components';
import { FadeLoader } from 'react-spinners';
import { colors, fonts } from '../../../../style';
import { TaskType } from '../../index';
import dayjs from 'dayjs';
import { PersonDataContext } from '../../../../contexts/person-data-context';
import {
  Leave,
  PersonData,
  TaskHistoryResponse,
} from '../../../../data-models';
import { taskStatusConfigMap } from '../../utils';
import { getTaskHistory, postTask } from '../../../../utils/web-apis-client';
import { getHtmlFromMarkdown } from '../../../../utils';
import CloseX from '../../../../images/close-x-white.svg';
import {
  StatusFlag,
  ImageContainer,
  CloseIcon,
  HelpCircleIcon,
  StatusHelpTextBox,
  StatusHelpTextHeader,
  StatusHelpText,
} from '../../task-list-item';
import TaskNotes from '../task-notes';

const noButtonsStatuses = [5, 7, 8, 9];

interface TaskDescriptionProps {
  task: TaskType;
  refresh: Function;
  isLlTask: boolean;
}

const Title = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 28px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
`;

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 16px;
  padding-top: 32px;
`;

const RowContainer = styled.div`
  background: #ffffff;
  padding: 0 24px 32px;
`;

const NotesContainer = styled.div`
  background: #ffffff;
  padding: 0 24px;
  padding-bottom: 32px;
`;

const Link = styled(ActionLink)`
  margin-top: 0px;
  line-break: 16px;
`;

const CancelButtonMod = styled(CancelButton)`
  margin-left: auto;
  border-radius: 40px !important;
  width: auto !important;
`;

const SubmitButtonMod = styled(SubmitButton)`
  border-radius: 40px !important;
  width: auto !important;
`;

const DetailRow = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
`;

const DataRow = styled.div`
  display: flex;
  align-items: baseline;
  margin-right: 16px;
  flex-wrap: wrap;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 24px 24px;
  background: #fcfcfc;
`;

const DetailLabel = styled.div`
  color: ${colors.boulder1};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

const StatusFlagMod = styled(StatusFlag)`
  margin-left: auto;

  @media (max-width: 420px) {
    margin-left: unset;
    margin-bottom: 8px;
  }
`;

const checkCaseIsIntermittent = (
  personData: PersonData,
  task: TaskType,
  setCaseIsIntermittent: Function,
) => {
  const foundLeave = personData.cases?.leaves?.find((c: Leave) =>
    task.caseInfo.find((taskCase) => c.caseId === taskCase.caseId),
  );

  if (foundLeave && foundLeave.absenceType === 'Intermittent') {
    setCaseIsIntermittent(true);
  }
};

const getTaskNotes = async (
  taskId: string,
  setNotes: Function,
  setLoading: Function,
) => {
  const response: TaskHistoryResponse = await getTaskHistory(taskId);

  if (response) {
    setNotes(response);
  }
  setLoading(false);
};

const changeTaskStatus = async (
  taskId: string,
  taskStatus: string,
  refreshTask: Function,
  setDisplayToast: Function,
  setLoading: Function,
) => {
  try {
    setLoading(true);
    await postTask(taskId, {
      taskStatus,
    });
    refreshTask();
  } catch {
    setDisplayToast(true);
  }
};

const createCaseLink = (task: TaskType, isCaseIntermittent: boolean) => {
  if (!task.caseInfo?.length) {
    return <DetailLabel>{task.notificationCaseId}</DetailLabel>;
  } else {
    return (
      <Link
        id="task-case-link"
        to={
          isCaseIntermittent
            ? `/app/absence-history/${task.notificationCaseId}`
            : `/app/leave-plan/${task.notificationCaseId}`
        }
      >
        {' '}
        {task.caseInfo[0].caseId.toUpperCase()}
      </Link>
    );
  }
};

const TaskDescription: (p: TaskDescriptionProps) => React.ReactElement = (
  p,
) => {
  const { refresh, task, isLlTask } = p;
  const [loading, setLoading] = React.useState<boolean>(true);
  const [displayToast, setDisplayToast] = React.useState<boolean>(false);
  const [caseIsIntermittent, setCaseIsIntermittent] =
    React.useState<boolean>(false);
  const statusConfig = taskStatusConfigMap?.[task.taskStatusId];
  const [notes, setNotes] = React.useState<TaskHistoryResponse>();

  //if task due date was null, it displays as 1/1/1970.  Blank out if this is the case
  if (p.task.taskDueDate === '1970-01-01T00:00:00') {
    p.task.taskDueDate = '';
  }

  const { personData } = React.useContext(PersonDataContext);

  const [help, setHelp] = useState(false);

  React.useEffect(() => {
    if (personData) {
      setLoading(true);
      checkCaseIsIntermittent(personData, task, setCaseIsIntermittent);
      getTaskNotes(task.taskId.toString(), setNotes, setLoading);
    }
  }, [personData, task]);

  setTimeout(() => {
    if (displayToast) {
      setDisplayToast(false);
    }
  }, 6000);

  return (
    <>
      <ToastNotificationGroupStyle className="position-fixed">
        {displayToast && (
          <StyledNotification
            key="error"
            type={{
              style: 'error',
              icon: true,
            }}
            closable={true}
            onClose={() => setDisplayToast(false)}
          >
            Sorry, there was a problem updating your task. Please try again
            later.
          </StyledNotification>
        )}
      </ToastNotificationGroupStyle>
      <Container>
        {loading && (
          <LoadingCover>
            {' '}
            <FadeLoader
              height={14}
              width={5}
              radius={2}
              margin={0}
              color={colors.blue}
            />
          </LoadingCover>
        )}
        <RowContainer>
          <TitleRow>
            {' '}
            <Title>Task details</Title>
            <StatusFlagMod
              data-testid={`task-${task.taskId}-status-flag`}
              background={statusConfig.background}
              color={statusConfig.color}
            >
              {statusConfig.text}
              {task.taskStatusHelp && (
                <ImageContainer angle="0deg">
                  <HelpCircleIcon
                    onMouseEnter={() => setHelp(true)}
                    fill={statusConfig.color}
                    stroke={statusConfig.color}
                  ></HelpCircleIcon>
                  {help && (
                    <StatusHelpTextBox>
                      <CloseIcon onClick={() => setHelp(false)}>
                        <img
                          src={CloseX}
                          height="60%"
                          width="60%"
                          alt="Close"
                        />
                      </CloseIcon>
                      <StatusHelpTextHeader>HELP</StatusHelpTextHeader>
                      <StatusHelpText>{task.taskStatusHelp}</StatusHelpText>
                    </StatusHelpTextBox>
                  )}
                </ImageContainer>
              )}
            </StatusFlagMod>
          </TitleRow>
          <DetailRow>
            <DataRow>
              <OpenSansSemiBoldLabel2>TYPE: &nbsp;</OpenSansSemiBoldLabel2>
              <DetailLabel>{task.category.codename.toUpperCase()}</DetailLabel>
            </DataRow>
            <DataRow>
              <OpenSansSemiBoldLabel2>
                RELATED TO: &nbsp;
              </OpenSansSemiBoldLabel2>
              {createCaseLink(task, caseIsIntermittent)}
            </DataRow>
            <DataRow>
              <OpenSansSemiBoldLabel2>Due: &nbsp;</OpenSansSemiBoldLabel2>
              {task.taskDueDate && (
                <DetailLabel>
                  {dayjs(task.taskDueDate).format('MMM DD, YYYY').toUpperCase()}
                </DetailLabel>
              )}
            </DataRow>
          </DetailRow>
        </RowContainer>

        <SectionSeparator />

        <RowContainer>
          <OpenSansRegularValue
            dangerouslySetInnerHTML={getHtmlFromMarkdown(task.description)}
          />
        </RowContainer>
        {isLlTask && (
          <RowContainer>
            <Link
              id="ll-task-details-link"
              to={{
                pathname: `/app/task/${task.taskId}/ll-description`,
              }}
            >
              Learn more about this task
            </Link>
          </RowContainer>
        )}
        {notes && notes.taskNoteHistory.length > 0 && (
          <NotesContainer>
            <TaskNotes {...notes} />
          </NotesContainer>
        )}
        {task.category.codename.toLowerCase() === 'recommended' &&
          (!task.formCodename || task.taskCodeName === 'll_task') &&
          !noButtonsStatuses.includes(task.taskStatusId) && (
            <ButtonRow>
              <CancelButtonMod
                id="task-ignore"
                onClick={() =>
                  changeTaskStatus(
                    task.taskId.toString(),
                    'Ignored',
                    refresh,
                    setDisplayToast,
                    setLoading,
                  )
                }
              >
                Ignore
              </CancelButtonMod>
              <SubmitButtonMod
                id="task-mark-as-done"
                onClick={() =>
                  changeTaskStatus(
                    task.taskId.toString(),
                    'Completed',
                    refresh,
                    setDisplayToast,
                    setLoading,
                  )
                }
              >
                Mark As Done
              </SubmitButtonMod>
            </ButtonRow>
          )}
      </Container>
    </>
  );
};

export default TaskDescription;
