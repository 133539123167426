/* eslint-disable */
type opType = {
  operation: string;
  path: string;
  value: any;
};

type joinType = {
  operation: string;
  params: opType[];
};

export type overlapRuleType = {
  keys: string[];
  operation: string;
  params: string[];
};

export type FilterRuleType = {
  option: string;
  fieldType: string;
  rules: joinType[];
};

export type styleRuleType = {
  name: string | string[];
  rules: joinType[];
};

type ruleListType = {
  styleRules: styleRuleType[];
  overlapRules: overlapRuleType[];
  periodRules: styleRuleType[];
  benefitRules?: styleRuleType[];
};

const metaIsJobProtected = 'meta.isJobProtected';
const metaDecisionStatus = 'meta.decisionStatus';
const metaPeriodStatus = 'meta.periodStatus';
const metaStatus = 'meta.status';
const metaIsPaid = 'meta.isPaid';
const timeDecisionStatus = 'meta.timeDecisionStatus';
const metaAdminERPaid = 'meta.selectedLeavePlan.category';
const metaAdminERPaidapplicability = 'meta.selectedLeavePlan.applicability';

export const draftClaimRules: ruleListType = {
  styleRules: [
    {
      name: 'potentialJobProtection',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'JobProtectedBenefits',
            },
          ],
        },
      ],
    },
    {
      name: 'potentialPaidBenefits',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'PotentialPaidBenefits',
            },
          ],
        },
      ],
    },
    {
      name: 'milestone',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'Milestone',
            },
          ],
        },
      ],
    },
    {
      name: 'paidBenefits',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'PaidBenefits',
            },
          ],
        },
      ],
    },
  ],
  overlapRules: [
    {
      keys: ['milestone'],
      operation: 'rollup',
      params: ['legendKey', 'milestone'],
    },
    {
      keys: ['potentialJobProtection'],
      operation: 'rollupRange',
      params: ['legendKey', 'potentialJobProtection'],
    },
    {
      keys: ['potentialPaidBenefits'],
      operation: 'greatestValue',
      params: ['meta.percentage', 'meta.percentage'],
    },
    {
      keys: ['paidBenefits'],
      operation: 'none',
      params: [],
    },
  ],
  periodRules: [
    {
      name: 'absencePeriod',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'DraftClaimPeriod',
            },
          ],
        },
      ],
    },
  ],
  benefitRules: [
    {
      name: 'jobProtectionBenefits',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'JobProtectedBenefits',
            },
          ],
        },
      ],
    },
    {
      name: 'paidBenefits',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'PaidBenefits',
            },
          ],
        },
      ],
    },
  ],
};

const certs = [
  {
    name: 'approvedPLContinuous',
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'CertificationPeriod',
          },
          {
            path: 'meta.periodType',
            operation: 'eq',
            value: 'Fully Certified',
          },
          {
            path: metaPeriodStatus,
            operation: 'eq',
            value: 'Approved',
          },
        ],
      },
    ],
  },
  {
    name: 'pendingPLContinuous',
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'CertificationPeriod',
          },
          {
            path: metaPeriodStatus,
            operation: 'eq',
            value: 'Pending',
          },
        ],
      },
    ],
  },
  {
    name: 'deniedPLContinuous',
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'CertificationPeriod',
          },
          {
            path: metaPeriodStatus,
            operation: 'eq',
            value: 'Denied',
          },
        ],
      },
    ],
  },
];

const pending = [
  {
    name: ['pendingJPContinuous', 'pendingPLContinuous'],
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'AbsencePeriodDecision',
          },
          {
            path: metaIsJobProtected,
            operation: 'eq',
            value: true,
          },
          {
            path: metaIsPaid,
            operation: 'eq',
            value: true,
          },
          {
            path: metaDecisionStatus,
            operation: 'arrayIncludes',
            value: ['Pending', 'Partially Adjudicated'],
          },
          {
            path: 'meta.adjudicationStatus',
            operation: 'arrayIncludes',
            value: ['Undecided', 'Pending Evidence'],
          },
        ],
      },
    ],
  },
  {
    name: ['AdminERPaidPendingJPContinuous', 'AdminERPaidPendingPLContinuous'],
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'Admin ER-Paid',
          },
          {
            path: metaAdminERPaid,
            operation: 'eq',
            value: 'Admin ER-Paid',
          },
          {
            path: metaDecisionStatus,
            operation: 'arrayIncludes',
            value: ['Pending', 'Partially Adjudicated'],
          },
          {
            path: 'meta.adjudicationStatus',
            operation: 'arrayIncludes',
            value: ['Undecided', 'Pending Evidence'],
          },
          {
            path: metaAdminERPaidapplicability,
            operation: 'neq',
            value: 'Not Applicable',
          },
        ],
      },
    ],
  },
  {
    name: ['pendingJPContinuous'],
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'AbsencePeriodDecision',
          },
          {
            path: metaIsJobProtected,
            operation: 'eq',
            value: true,
          },
          {
            path: metaDecisionStatus,
            operation: 'arrayIncludes',
            value: ['Pending', 'Partially Adjudicated'],
          },
          {
            path: 'meta.adjudicationStatus',
            operation: 'arrayIncludes',
            value: ['Undecided', 'Pending Evidence'],
          },
        ],
      },
    ],
  },
  {
    name: ['pendingPLContinuous'],
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'AbsencePeriodDecision',
          },
          {
            path: metaIsPaid,
            operation: 'eq',
            value: true,
          },
          {
            path: metaDecisionStatus,
            operation: 'arrayIncludes',
            value: ['Pending', 'Partially Adjudicated'],
          },
          {
            path: 'meta.adjudicationStatus',
            operation: 'arrayIncludes',
            value: ['Undecided', 'Pending Evidence'],
          },
        ],
      },
    ],
  },
];

const deniedStatusArray = [
  'Partially Adjudicated',
  'Fully Adjudicated - Approved',
  'Fully Adjudicated - Mixed Plan Decisions',
  'Fully Adjudicated - Denied',
];

const denied = [
  {
    name: ['deniedJPContinuous', 'deniedPLContinuous'],
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'AbsencePeriodDecision',
          },
          {
            path: metaIsJobProtected,
            operation: 'eq',
            value: true,
          },
          {
            path: metaIsPaid,
            operation: 'eq',
            value: true,
          },
          {
            path: metaDecisionStatus,
            operation: 'arrayIncludes',
            value: deniedStatusArray,
          },
        ],
      },
      {
        operation: 'any',
        params: [
          {
            path: timeDecisionStatus,
            operation: 'eq',
            value: 'Denied',
          },
          {
            path: 'meta.adjudicationStatus',
            operation: 'arrayIncludes',
            value: ['Denied', 'Rejected'],
          },
        ],
      },
    ],
  },
  {
    name: ['AdminERPaidDeniedJPContinuous', 'AdminERPaidDeniedPLContinuous'],
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'AbsencePeriodDecision',
          },
          {
            path: metaAdminERPaid,
            operation: 'eq',
            value: 'Admin ER-Paid',
          },
          {
            path: metaDecisionStatus,
            operation: 'arrayIncludes',
            value: deniedStatusArray,
          },
          {
            path: metaAdminERPaidapplicability,
            operation: 'neq',
            value: 'Not Applicable',
          },
        ],
      },
      {
        operation: 'any',
        params: [
          {
            path: timeDecisionStatus,
            operation: 'eq',
            value: 'Denied',
          },
          {
            path: 'meta.adjudicationStatus',
            operation: 'arrayIncludes',
            value: ['Denied', 'Rejected'],
          },
        ],
      },
    ],
  },
  {
    name: ['deniedJPContinuous'],
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'AbsencePeriodDecision',
          },
          {
            path: metaIsJobProtected,
            operation: 'eq',
            value: true,
          },
          {
            path: metaDecisionStatus,
            operation: 'arrayIncludes',
            value: deniedStatusArray,
          },
        ],
      },
      {
        operation: 'any',
        params: [
          {
            path: timeDecisionStatus,
            operation: 'eq',
            value: 'Denied',
          },
          {
            path: 'meta.adjudicationStatus',
            operation: 'arrayIncludes',
            value: ['Denied', 'Rejected'],
          },
        ],
      },
    ],
  },
  {
    name: ['deniedPLContinuous'],
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'AbsencePeriodDecision',
          },
          {
            path: metaIsPaid,
            operation: 'eq',
            value: true,
          },
          {
            path: metaDecisionStatus,
            operation: 'arrayIncludes',
            value: deniedStatusArray,
          },
        ],
      },
      {
        operation: 'any',
        params: [
          {
            path: timeDecisionStatus,
            operation: 'eq',
            value: 'Denied',
          },
          {
            path: 'meta.adjudicationStatus',
            operation: 'arrayIncludes',
            value: ['Denied', 'Rejected'],
          },
        ],
      },
    ],
  },
];

const approvedStatusArray = [
  'Partially Adjudicated',
  'Fully Adjudicated - Approved',
  'Fully Adjudicated - Mixed Plan Decisions',
];

const approved = [
  {
    name: ['approvedJPContinuous', 'approvedPLContinuous'],
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'AbsencePeriodDecision',
          },
          {
            path: metaIsJobProtected,
            operation: 'eq',
            value: true,
          },
          {
            path: metaIsPaid,
            operation: 'eq',
            value: true,
          },
          {
            path: metaDecisionStatus,
            operation: 'arrayIncludes',
            value: approvedStatusArray,
          },
          {
            path: timeDecisionStatus,
            operation: 'eq',
            value: 'Approved',
          },
        ],
      },
    ],
  },

  {
    name: [
      'AdminERPaidApprovedJPContinuous',
      'AdminERPaidApprovedPLContinuous',
    ],
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'Admin ER-Paid',
          },
          {
            path: metaAdminERPaid,
            operation: 'eq',
            value: 'Admin ER-Paid',
          },
          {
            path: metaDecisionStatus,
            operation: 'arrayIncludes',
            value: approvedStatusArray,
          },
          {
            path: timeDecisionStatus,
            operation: 'eq',
            value: 'Approved',
          },
        ],
      },
    ],
  },
  {
    name: ['approvedJPContinuous'],
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'AbsencePeriodDecision',
          },
          {
            path: metaIsJobProtected,
            operation: 'eq',
            value: true,
          },
          {
            path: metaDecisionStatus,
            operation: 'arrayIncludes',
            value: approvedStatusArray,
          },
          {
            path: timeDecisionStatus,
            operation: 'eq',
            value: 'Approved',
          },
        ],
      },
    ],
  },
  {
    name: ['approvedPLContinuous'],
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'tags',
            operation: 'includes',
            value: 'AbsencePeriodDecision',
          },
          {
            path: metaIsPaid,
            operation: 'eq',
            value: true,
          },
          {
            path: metaDecisionStatus,
            operation: 'arrayIncludes',
            value: approvedStatusArray,
          },
          {
            path: timeDecisionStatus,
            operation: 'eq',
            value: 'Approved',
          },
        ],
      },
    ],
  },
];

export const continuousRules: ruleListType = {
  styleRules: [...pending, ...denied, ...approved, ...certs],
  overlapRules: [
    {
      keys: ['absencePeriod'],
      operation: 'rollupRange',
      params: ['legendKey', 'absencePeriod'],
    },
    {
      keys: [
        'approvedJPContinuous',
        'pendingJPContinuous',
        'deniedJPContinuous',
        'approvedPLContinuous',
        'pendingPLContinuous',
        'deniedPLContinuous',
        'AdminERPaidPendingJPContinuous',
        'AdminERPaidPendingPLContinuous',
        'AdminERPaidDeniedJPContinuous',
        'AdminERPaidDeniedPLContinuous',
        'AdminERPaidApprovedJPContinuous',
        'AdminERPaidApprovedPLContinuous',
      ],
      operation: 'rollupByGroup',
      params: ['legendKey'],
    },
  ],
  periodRules: [
    {
      name: 'absencePeriod',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'AbsencePeriod',
            },
            {
              path: 'tags',
              operation: 'includes',
              value: 'Continuous',
            },
          ],
        },
      ],
    },
  ],
  benefitRules: [
    {
      name: 'jobProtectionBenefits',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'AbsencePeriodDecision',
            },
          ],
        },
      ],
    },
    {
      name: 'paidBenefits',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'GDC',
            },
            {
              path: 'tags',
              operation: 'includes',
              value: 'Benefit',
            },
          ],
        },
      ],
    },
  ],
};

export const intermittentRules: ruleListType = {
  styleRules: [
    {
      name: 'approvedIntermittent',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'ActualAbsencePeriod',
            },
            {
              path: 'meta.isPartiallyApproved',
              operation: 'eq',
              value: false,
            },
            {
              path: 'meta.hasDecisionError',
              operation: 'eq',
              value: false,
            },
            {
              path: metaStatus,
              operation: 'eq',
              value: 'Approved',
            },
          ],
        },
      ],
    },
    {
      name: 'pendingIntermittent',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'ActualAbsencePeriod',
            },
            {
              path: metaStatus,
              operation: 'eq',
              value: 'Pending',
            },
          ],
        },
      ],
    },
    {
      name: 'partialIntermittent',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'ActualAbsencePeriod',
            },
            {
              path: 'meta.isPartiallyApproved',
              operation: 'eq',
              value: true,
            },
            {
              path: 'meta.hasDecisionError',
              operation: 'eq',
              value: false,
            },
            {
              path: metaStatus,
              operation: 'eq',
              value: 'Approved',
            },
          ],
        },
      ],
    },
    {
      name: 'deniedIntermittent',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'ActualAbsencePeriod',
            },
            {
              path: metaStatus,
              operation: 'eq',
              value: 'Denied',
            },
          ],
        },
      ],
    },
  ],
  overlapRules: [
    {
      keys: ['absencePeriod'],
      operation: 'rollupRange',
      params: ['legendKey', 'absencePeriod'],
    },
    {
      keys: [
        'approvedIntermittent',
        'pendingIntermittent',
        'partialIntermittent',
        'deniedIntermittent',
      ],
      operation: 'rollup',
      params: ['legendKey', 'partialIntermittent'],
    },
  ],
  periodRules: [
    {
      name: 'absencePeriod',
      rules: [
        {
          operation: 'all',
          params: [
            {
              path: 'tags',
              operation: 'includes',
              value: 'AbsencePeriod',
            },
            {
              path: 'tags',
              operation: 'includes',
              value: 'Intermittent',
            },
          ],
        },
      ],
    },
  ],
};

export const intermittentFilterRules: FilterRuleType[] = [
  {
    option: 'View All',
    fieldType: 'Select',
    rules: [],
  },
  {
    option: 'Approved',
    fieldType: 'Status',
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'legendKey',
            operation: 'eq',
            value: 'approvedIntermittent',
          },
        ],
      },
    ],
  },
  {
    option: 'Pending',
    fieldType: 'Status',
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'legendKey',
            operation: 'eq',
            value: 'pendingIntermittent',
          },
        ],
      },
    ],
  },
  {
    option: 'Partial',
    fieldType: 'Status',
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'legendKey',
            operation: 'eq',
            value: 'partialIntermittent',
          },
        ],
      },
    ],
  },
  {
    option: 'Denied',
    fieldType: 'Status',
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'legendKey',
            operation: 'eq',
            value: 'deniedIntermittent',
          },
        ],
      },
    ],
  },
  {
    option: 'Treatment',
    fieldType: 'Type',
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'meta.type',
            operation: 'eq',
            value: 'Office Visit',
          },
        ],
      },
    ],
  },
  {
    option: 'Episode',
    fieldType: 'Type',
    rules: [
      {
        operation: 'all',
        params: [
          {
            path: 'meta.type',
            operation: 'neq',
            value: 'Office Visit',
          },
        ],
      },
    ],
  },
];
