import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../style';
import { enums, prefixObjectValues } from '../../utils';

interface FileProps {
  keyId?: string;
}

const domIdsStatic = {
  rootNode: 'file',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  background-color: ${colors.coolNeutralL1};
`;

const File: (p: FileProps) => React.ReactElement = (p) => {
  const domIds = domIdsUnique(p.keyId);

  return (
    <Container id={domIds.rootNode}>
      <Switch>
        <Route path={enums.AppPaths.file} exact>
          File Landing
        </Route>
        <Route path={enums.AppPaths.filePregnancy}>
          <Redirect to={`${enums.AppPaths.form}/pregnancy`} />
        </Route>
        <Route path={enums.AppPaths.filePersonalMedical}>
          <Redirect to={`${enums.AppPaths.form}/personal-medical`} />
        </Route>
      </Switch>
    </Container>
  );
};

export default File;
