import React, { useState } from 'react';
import { useTimer } from '../../hooks/user-timer';
import { VBSurvey } from './survey/vb';
import { Dialog } from '../base/simple-styled-components';

type Props = {
  timer: number;
};

export const Feedback: React.FC<Props> = ({ timer }) => {
  const [visible, setVisible] = useState(true);
  const time = useTimer(timer);

  if (!time) {
    return <></>;
  }
  return (
    <>
      {visible && (
        <Dialog>
          <VBSurvey showDialog={setVisible} />
        </Dialog>
      )}
    </>
  );
};
