import { useContext, useState } from 'react';
import {
  ButtonContainer,
  FileSize,
  LinkButtonStyled,
  toastMessages,
  UploadButton,
  UploadButtonContainer,
  UploadContainerStyled,
  UploadImageStyled,
} from '../index.sc';
import {
  allowedFileTypes,
  convertExtensionToLowercase,
  CustomUploadFileInfo,
} from '../../../utils/upload-helpers';
import UploadImage from '../../../images/cloud-upload-new.svg';
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from '@progress/kendo-react-upload';
import {
  analyticsTrackEvent,
  enums,
  formatBytes,
  prefixObjectValues,
} from '../../../utils';
import { PersonDataContext } from '../../../contexts/person-data-context';
import {
  Sortable,
  SortableItemUIProps,
  SortableOnDragOverEvent,
  SortableOnNavigateEvent,
} from '@progress/kendo-react-sortable';
import fileIcon from '../../../images/document.svg';
import { useMutation } from '@tanstack/react-query';
import { uploadAction2 } from '../api';
import {
  CircularGauge,
  CircularGaugeProps,
} from '@progress/kendo-react-gauges';
import NotificationSymbol from '../../../images/x-icon-red.svg';
import styled from 'styled-components';
import { fonts } from '../../../style';

const MAX_FILE_SIZE = 21000000;

interface UploadProps {
  keyId?: string;
  files?: CustomUploadFileInfo[];
  uploadTried?: boolean;
  selectedCase: string;
  docType: string;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDropDowns: React.Dispatch<React.SetStateAction<boolean>>;
}

interface AnalyticsData {
  name?: string;
  extension?: string;
  eid?: string;
  size?: string;
  errors?: string[];
  id?: number;
}

const domIdsStatic = {
  rootNode: 'upload',
  dropzone: 'dropzone',
};

const NotificationBanner = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #faeaea;
  border-radius: 8px;
  padding: 12px 12px 12px 18px;
  margin-top: 18px;
  margin-bottom: 18px;
  width: 100%;
`;

const NotificationBannerText = styled.p`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding-left: 1em;
  margin: 0;
  font-family: ${fonts.openSans.regular.fontFamily};
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

const DropzoneContentHint = (
  <>
    <UploadImageStyled>
      <img src={UploadImage} alt="upload panel" />
    </UploadImageStyled>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <LinkButtonStyled
        className="btn btn-link"
        style={{
          lineHeight: '1.2',
          marginLeft: '2px',
          verticalAlign: 'baseline',
          backgroundColor: '#037CB7',
          color: '#fff',
          display: 'flex',
          padding: '8px, 12px',
        }}
      >
        Choose Files
      </LinkButtonStyled>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        color: '#037CB7',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '1.55',
        paddingTop: '10px',
        paddingBottom: '24px',
      }}
    >
      Or drag and drop
    </div>
  </>
);

const DropzoneContentNote = (
  <>
    <div
      style={{
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '1.5',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '24px',
      }}
    >
      Allowed file types : pdf, jpg, jpeg, doc, docx, png, gif, rtf, txt, bmp
    </div>
    <div
      style={{
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '1.5',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      Files cannot exceed {Math.floor(MAX_FILE_SIZE / 1000 / 1000)} MB
    </div>
  </>
);

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const onAddFiles = (
  e: UploadOnAddEvent,
  files: any,
  setFiles: Function,
  handleDisplayToast: Function,
  setInsideDropZone: Function,
  eid: string | undefined,
) => {
  setInsideDropZone(false);
  const newList = [...files];

  let errors = false;
  const fileData: AnalyticsData = {};

  e.affectedFiles.forEach((f: UploadFileInfo) => {
    f.extension = f.extension?.toLowerCase();
    f.name = convertExtensionToLowercase(f.name); //convert ext and fileName ext to lowercase due to S3 upload errors
    const size = f.size ? f.size : 0;
    if ((f.validationErrors && f.validationErrors.length > 0) || size <= 0) {
      errors = true;
      fileData.name = f.name;
      fileData.size = formatBytes(f.size, 2);
      fileData.errors = f.validationErrors;
      fileData.extension = f.extension;
      fileData.eid = eid;
    } else {
      newList.push(f);
    }
  });

  setFiles(newList);
  if (errors) {
    analyticsTrackEvent(enums.AnalyticsEvents.failedUploadValidation, fileData);
    handleDisplayToast(toastMessages.validationError, 'error');
  }
};

//sets the style of each sortable item
const getBaseItemStyle = (isActive: boolean) => ({
  height: 70,
  lineHeight: '68px',
  fontSize: '16px',
  textAlign: 'center',
  margin: '10px',
  fontWeight: 'bold',
  color: '#037CB7',
  outline: 'none',
  border: '1px solid',
  cursor: 'move',
  display: 'flex',
  shadow: '0 4px 8px 0 rgb(0 0 0 / 20%)',
  background: '#F6F8FA',
  borderColor: isActive ? '#037CB7' : '#fff',
  borderRadius: '1.5rem',
});

const subbmittingMessage = (
  currentStep: any,
  sortableFiles: any,
  currentStepIndex: any,
) => {
  const arcOptions: CircularGaugeProps = {
    value: currentStepIndex,
    color: '#037CB7',
    scale: {
      max: sortableFiles.length + 1,
    },
  };
  const centerRenderer = () => {
    return (
      <span className="tw-font-bold tw-text-base">
        {currentStepIndex} of {sortableFiles.length + 1}
      </span>
    );
  };
  return (
    <>
      <div
        style={{
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '48px',
        }}
      >
        <CircularGauge
          style={{ width: '100px', height: '100px' }}
          {...arcOptions}
          centerRender={centerRenderer}
        />
      </div>
      <div
        style={{
          fontFamily: 'Ubuntu',
          fontWeight: 400,
          fontSize: '26px',
          alignContent: 'center',
          textAlign: 'center',
        }}
      >
        <div>Submiting documents. </div>
        {currentStep && (
          <div className="text-clip overflow-hidden ...">
            {currentStep.toString()}
          </div>
        )}
      </div>
    </>
  );
};

const FileSelection: (p: UploadProps) => React.ReactElement = (p) => {
  const domIds = domIdsUnique(p.keyId);
  const [files, setFiles] = useState<Array<UploadFileInfo>>([]);
  const [insideDropZone, setInsideDropZone] = useState(false);
  const [displayToast, setDisplayToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const { personData } = useContext(PersonDataContext);
  const [submittingFiles, setSubmittingFiles] = useState(false);
  const [currentStep, setCurrentStep] = useState<string | null>(null);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  //Maps the files to an array of objects with an id property to allow for sorting
  const sortableFiles = files.map((file, index) => {
    return {
      id: index,
      ...file,
    };
  });

  //UI for each sortable item
  const SortableItemUI = (props: SortableItemUIProps) => {
    const { isActive, style, attributes, dataItem, forwardRef } = props;
    const classNames = ['grid-cols-1'];
    const name = dataItem.name;

    return (
      <div
        ref={forwardRef}
        {...attributes}
        style={{
          ...getBaseItemStyle(isActive),
          ...style,
        }}
        className={classNames.join(' ')}
      >
        <img alt="file" className="pl-3 pt-3 pb-3" src={fileIcon} />
        <div id={dataItem.name} className="ml-2 text-clip overflow-hidden ...">
          {dataItem.name}
        </div>
        <FileSize color="#525252">{formatBytes(dataItem.size)}</FileSize>
        <ButtonContainer>
          <LinkButtonStyled
            onClick={() => removeFile(name)}
            id={'deleteButton'}
            className="btn btn-link"
            style={{
              fontSize: '14px',
              verticalAlign: 'baseline',
            }}
          >
            Remove
          </LinkButtonStyled>
        </ButtonContainer>
      </div>
    );
  };

  const removeFile = (name: string) => {
    const fileIndex = files.findIndex((file) => file.name === name);
    const newFiles = [...files];
    newFiles.splice(fileIndex, 1);
    setFiles(newFiles);
    //update the id of each file after removing one to prevent duplicate ids
    for (let i = 0; i < sortableFiles.length; i++) {
      sortableFiles[i].id = i;
    }
  };

  const closeToast = () => {
    setDisplayToast(false);
  };

  setTimeout(() => {
    if (displayToast) {
      closeToast();
    }
  }, 8000);

  const handleDisplayToast = (message: string) => {
    setToastMessage(message);
    setDisplayToast(true);
  };

  const onRemoveFiles = (e: UploadOnRemoveEvent) => {
    setFiles(e.newState);
  };

  const onDragOver = (event: SortableOnDragOverEvent) => {
    setFiles(event.newState as CustomUploadFileInfo[]);
  };

  const onNavigate = (event: SortableOnNavigateEvent) => {
    setFiles(event.newState as CustomUploadFileInfo[]);
  };

  //kicks off the merge/upload process
  const mutateUpload = useMutation({
    mutationFn: () =>
      uploadAction2(
        p.selectedCase,
        p.docType,
        sortableFiles,
        setCurrentStep,
        setCurrentStepIndex,
      ),
    onSuccess: (data) => {
      p.setSuccess(true);
      console.log('Mutation success', data);
    },
    onError: (data) => {
      p.setError(true);
      console.log('Mutation error', data);
    },
  });

  if (submittingFiles === false) {
    return (
      <div id={'fileSelection'}>
        <UploadContainerStyled
          className={insideDropZone ? 'dropzone-entered' : ''}
          onDragOver={() => {
            setInsideDropZone(true);
          }}
          onDragLeave={() => {
            setInsideDropZone(false);
          }}
        >
          <Upload
            showActionButtons={false}
            id={domIds.dropzone}
            autoUpload={false}
            files={files}
            onAdd={(e) =>
              onAddFiles(
                e,
                files,
                setFiles,
                handleDisplayToast,
                setInsideDropZone,
                personData?.person?.eid,
              )
            }
            onRemove={onRemoveFiles}
            multiple={true}
            batch={false}
            withCredentials={false}
            restrictions={{
              maxFileSize: MAX_FILE_SIZE,
              allowedExtensions: allowedFileTypes,
            }}
          />
          {DropzoneContentHint}
          {DropzoneContentNote}
        </UploadContainerStyled>
        {displayToast && (
          <div>
            <NotificationBanner>
              <span>
                <img alt="Info circle" src={NotificationSymbol}></img>
              </span>
              <span style={{ alignSelf: 'center' }}>
                <NotificationBannerText key="success">
                  {toastMessage}
                </NotificationBannerText>
              </span>
            </NotificationBanner>
          </div>
        )}
        {sortableFiles.length > 0 ? (
          <>
            <Sortable
              idField={'id'}
              disabledField={'disabled'}
              data={sortableFiles}
              itemUI={SortableItemUI}
              onDragOver={onDragOver}
              onNavigate={onNavigate}
            />
            <UploadButtonContainer className="d-flex justify-content-end align-items-center mr-5">
              <UploadButton
                onClick={() => {
                  mutateUpload.mutate();
                  setSubmittingFiles(true);
                  p.setShowDropDowns(false);
                }}
                id={domIds.uploadButton}
              >
                Upload documents
              </UploadButton>
            </UploadButtonContainer>
          </>
        ) : null}
      </div>
    );
  } else {
    return subbmittingMessage(currentStep, sortableFiles, currentStepIndex);
  }
};
export default FileSelection;
