import { useEffect, useState } from 'react';

/**
 * Hook to set a timer.
 * Use case:
 * Delay rendering a component. Used by Feedback functionality.
 * e.g. when user interacts with a functionality, wait n time before asking feedback.
 * @param time ms number to delay render
 * @returns
 */
export const useTimer = (time: number = 5000) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, time);

    return () => clearTimeout(timer);
  }, [time]);

  return show;
};
