import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../style';
import { get, analyticsTrackEvent, enums } from '../../../../utils';
import { KenticoDataContext } from '../../../../contexts/kentico-data-context';
import { CustomLink, CardButton } from '../../simple-styled-components';

const CardFooter = styled.div`
  align-items: baseline;
  background: ${colors.warmNeutralL1};
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding: 1.5em;
  width: 100%;
`;

const TasksButton = styled(CardButton)`
  background: ${colors.alabaster1};
  color: ${colors.skyD2};
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.skyD2};
`;

const CardFooterSection = (p: any) => {
  const { featureFlags } = React.useContext(KenticoDataContext);
  const leaveId = get(p, 'leaves[0].caseId', '');
  const showTasksButton = get(p, 'areTasksDue', false);

  // Don't show footer for ACM only cases. Must have a leave or claim.
  if (get(p, 'leaves', []).length === 0 && get(p, 'claims', []).length === 0) {
    return null;
  }

  return (
    <>
      {featureFlags.absence_history &&
      get(p, 'leaves[0].absenceType', null) === 'Intermittent' ? (
        <CardFooter>
          {showTasksButton && (
            <CustomLink to={`${enums.AppPaths.tasks}/${p.NTNId}`}>
              <TasksButton>Go To Tasks</TasksButton>
            </CustomLink>
          )}
          <CustomLink
            to={`/app/absence-history/${p.NTNId}`}
            onClick={() =>
              analyticsTrackEvent(enums.AnalyticsEvents.summaryAbsences, {
                ntn: leaveId,
              })
            }
          >
            <CardButton>View details</CardButton>
          </CustomLink>
        </CardFooter>
      ) : (
        // hasDraftClaim && (
        <CardFooter>
          {showTasksButton && (
            <CustomLink to={`${enums.AppPaths.tasks}/${p.NTNId}`}>
              <TasksButton>Go To Tasks</TasksButton>
            </CustomLink>
          )}
          <CustomLink
            to={`/app/leave-plan/${p.NTNId}`}
            onClick={() =>
              analyticsTrackEvent(enums.AnalyticsEvents.summaryLeavePlan, {
                ntn: p.NTNId,
              })
            }
          >
            <CardButton>View details</CardButton>
          </CustomLink>
        </CardFooter>
        // )
      )}
    </>
  );
};

export default CardFooterSection;
