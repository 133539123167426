
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Event extends ContentItem {
    public eventToHook!: Elements.CustomElement;
    public eventFilterS!: Elements.CustomElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'event_to_hook') {
                    return 'eventToHook';
                }
                if (elementName === 'event_filter_s_') {
                    return 'eventFilterS';
                }
                return elementName;
            })
        });
    }
}
