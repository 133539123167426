import React from 'react';
import { get, enums, parseIntOrDefault } from '../../utils';
import { postRefresh, getRefresh } from '../../utils/web-apis-client';
import { getSimpleRemoteConfig } from '../../utils/remote-config-manager';
import { RefreshContext } from '../../contexts/refresh-context';
import { log } from '../../utils/log';

// const log = new Logger\('hooks/use-refresh-handler');

export const startRefresh = async (
  triggerLoginRefresh: boolean,
  triggerFullRefresh: boolean,
) => {
  try {
    await postRefresh(triggerLoginRefresh, triggerFullRefresh);
  } catch (e) {
    log.error('POST refresh failure:', e);
  }
};

export const checkIsRefreshing = (
  immediateData: string[],
  caseDetail: string[],
  statusDetail: any,
  refreshing: boolean,
) => {
  return (
    refreshing &&
    immediateData?.some((key: string) => {
      if (key === enums.RefreshDetailTypes.caseDetails) {
        if (caseDetail.length > 0) {
          return caseDetail.some(
            (c) =>
              statusDetail[enums.RefreshDetailTypes.caseDetails].find(
                (rc: any) => rc.sourceKey === c,
              )?.status === enums.RefreshStatus.started,
          );
        }

        //all check
        return statusDetail[enums.RefreshDetailTypes.caseDetails].some(
          (ntn: any) => ntn.status === enums.RefreshStatus.started,
        );
      }
      return statusDetail[key].status === enums.RefreshStatus.started;
    })
  );
};

export const allSuccess = (
  immediateData: string[],
  caseDetail: string[],
  statusDetail: any,
) => {
  return immediateData?.every((key: string) => {
    if (key === enums.RefreshDetailTypes.caseDetails) {
      //specific check
      if (caseDetail.length > 0) {
        return caseDetail.every(
          (c) =>
            statusDetail[enums.RefreshDetailTypes.caseDetails].find(
              (rc: any) => rc.sourceKey === c,
            )?.status === enums.RefreshStatus.refreshed,
        );
      }
      //all check
      return statusDetail[enums.RefreshDetailTypes.caseDetails].every(
        (ntn: any) => ntn?.status === enums.RefreshStatus.refreshed,
      );
    }
    return statusDetail[key]?.status === enums.RefreshStatus.refreshed;
  });
};

export const checkMinDataRefreshed = (
  immediateData: string[],
  caseDetail: string[],
  statusDetail: any,
  refreshing: boolean,
) => {
  if (checkIsRefreshing(immediateData, caseDetail, statusDetail, refreshing)) {
    return enums.RefreshStatus.started;
  }

  return allSuccess(immediateData, caseDetail, statusDetail)
    ? enums.RefreshStatus.refreshed
    : enums.RefreshStatus.failed;
};

// For login only
export const handleRefresh: (
  triggerLoginRefresh: boolean,
  triggerFullRefresh: boolean,
) => any = async (triggerLoginRefresh, triggerFullRefresh) => {
  return new Promise(async (resolve, reject) => {
    const remoteConfig = await getSimpleRemoteConfig();
    const refreshCheckInterval = parseIntOrDefault(
      remoteConfig[enums.RemoteConfigKeys.refreshCheckIntervalMs],
      2000,
    );
    const refreshCheckCount = parseIntOrDefault(
      remoteConfig[enums.RemoteConfigKeys.refreshCheckCount],
      60,
    );

    await startRefresh(triggerLoginRefresh, triggerFullRefresh);

    let currentCheckCount = 0;
    const refreshInterval = setInterval(async () => {
      try {
        const result = await getRefresh();

        // check result status, only return if not "started"
        const rs = get(result, 'loginRefreshStatus.status', '');

        if (rs.length > 0 && rs !== enums.RefreshStatus.started) {
          log.debug('Login refresh complete. Status:', rs);
          resolve(result);
          clearInterval(refreshInterval);
        }

        currentCheckCount++;
        log.debug('Login refresh count:', currentCheckCount);

        if (currentCheckCount >= refreshCheckCount) {
          reject('Timeout');
          clearInterval(refreshInterval);
        }
      } catch (e) {
        log.error('Full refresh GET error:', e);
        reject(e);
        clearInterval(refreshInterval);
      }
    }, refreshCheckInterval);
  });
};

/**
 * A hook to assist with Handlebars
 */
const useRefreshHandler = (
  immediateData: string[],
  caseDetail: string[] = [],
) => {
  const refreshState = React.useContext(RefreshContext);
  const [status, setStatus] = React.useState(enums.RefreshStatus.started);
  const checkStatus = () => {
    const result = checkMinDataRefreshed(
      immediateData,
      caseDetail,
      refreshState.refreshDetails,
      refreshState.isRefreshing,
    );

    setStatus(result);
  };

  React.useEffect(() => {
    if (status === enums.RefreshStatus.started) {
      checkStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshState.isRefreshing]);

  console.debug(`Refresh completed with status of ${status}`);

  return {
    status,
    details: refreshState.refreshDetails,
    isRefreshing: refreshState.isRefreshing,
    pollForUpdates: refreshState.pollForUpdates,
  };
};

export default useRefreshHandler;
