import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../style';
import { get, prefixObjectValues } from '../../utils';

interface MessagesProps {
  keyId?: string;
}

const domIdsStatic = {
  rootNode: 'messages',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  background-color: ${colors.coolNeutralL1};
`;

const Messages: (p: MessagesProps) => React.ReactElement = (p) => {
  const path = get(p, 'location.pathname', '/');
  const domIds = domIdsUnique(p.keyId);

  return (
    <Container id={domIds.rootNode}>
      <Switch>
        <Route path={`${path}/`} exact>
          Messages Landing
        </Route>
        {/* child routes here */}
      </Switch>
    </Container>
  );
};

export default Messages;
