import { log } from '../log';
import * as dm from '../../data-models';
import { models } from '../cms-client';
import * as wac from '../web-apis-client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { IFormContext } from '../../contexts/form-context';
import {
  getCaseId,
  getFieldTypeByName,
  getPhoneNumber,
  getTimeRange,
  removeBlankOrNullProperties,
  generateDraftAnswersFromForm,
  escapeNewLineCharacters,
} from './utils';
import Handlebars from 'handlebars';
import * as hbh from '../../hooks/use-handlebars/helpers';
import { get } from '..';

dayjs.extend(utc);
dayjs.extend(timezone);
const commonDateFormat = 'YYYY-MM-DD';

// const log = new Logger\('form-submitter');
export interface ISubmitOptions {
  form: models.Form;
  formResult: { [name: string]: any };
  formContext: IFormContext;
}

export interface IFormSubmitSequence {
  operationName: keyof typeof wac;
  operationParams: any[];
  operationOptions?: { [key: string]: any };
}

type SubmitFunction = (
  form: models.Form,
  formResult: { [name: string]: any },
  formContext: IFormContext,
) => Promise<any>;

const generateAnswersFromDraftFields: (
  optionsTemplate: string,
  sa: IFormSubmitSequence,
  cc: IFormContext,
) => IFormSubmitSequence = (optionsTemplate, sa, cc) => {
  const sequence = { ...sa };

  const activatedOptions = Handlebars.compile(optionsTemplate)(cc);
  log.debug({ activatedOptions });

  // build an answer object based on the formResult and the options.draftAnswerMap
  const finalOptions = JSON.parse(activatedOptions);
  log.debug({ finalOptions });

  const finalAnswers = removeBlankOrNullProperties(finalOptions.draftAnswerMap);

  // convert to array of answers
  const answersArray = generateDraftAnswersFromForm(
    finalAnswers,
    finalOptions.draftQuestionMap,
  );
  log.info({ answersArray });

  //Assume first element is the body
  sequence.operationParams[0].answers = answersArray;

  return sequence;
};

const generateAnswersFromFormResults: (
  optionsTemplate: string,
  sa: IFormSubmitSequence,
  cc: IFormContext,
) => IFormSubmitSequence = (optionsTemplate, sa, cc) => {
  const sequence = { ...sa };

  const activatedOptions = Handlebars.compile(optionsTemplate)(cc);
  log.debug({ activatedOptions });

  // build an answer object based on the formResult and the options.draftAnswerMap
  const finalOptions = JSON.parse(activatedOptions);
  log.debug({ finalOptions });

  const formResult = { ...cc.formResult };

  if (finalOptions.formFieldAnswerOverrideMap) {
    Object.keys(finalOptions.formFieldAnswerOverrideMap).forEach((key) => {
      formResult[key] = finalOptions.formFieldAnswerOverrideMap[key];
    });
  }

  const answersCleaned = removeBlankOrNullProperties(formResult);

  const answersArray = Object.keys(answersCleaned).map((key) => {
    return {
      fieldName: key,
      answer: answersCleaned[key],
    };
  });

  log.info({ answersArray });

  //Assume first element is the body
  sequence.operationParams[0].answers = answersArray;

  return sequence;
};

const optionsMap: {
  [key: string]: any;
} = {
  draftFieldsAsAnswers: generateAnswersFromDraftFields,
  formFieldsAsAnswers: generateAnswersFromFormResults,
};

export const submitAsWebMessage: SubmitFunction = async (
  form,
  formResult,
  formContext,
) => {
  // new form
  const nf: dm.Message = { attributes: [] };

  nf.description = form.system.name;

  Object.keys(formResult).forEach((key) => {
    const v = formResult[key];
    const ft = getFieldTypeByName(form, key);
    nf.attributes?.push({
      attributeName: key,
      attributeValue: v,
      attributeValueType: ft,
    });
  });

  return wac.postWebMessage(nf, getCaseId(formResult, formContext));
};

export const submitAsScheduleCallback: SubmitFunction = async (
  _form,
  formResult,
) => {
  // new form
  const nf: dm.ScheduleCallBackRequest = {};

  try {
    nf.dateToContact = dayjs(formResult.scheduledDate).format(commonDateFormat);
    nf.phoneNumber = getPhoneNumber(formResult.contactNumber);
    nf.reasonForCall = formResult.callTopic;
    nf.timeToContact = getTimeRange(formResult.scheduledTime.startTime);
    nf.interpreter = formResult.needInterpreter;
    nf.multilingualLanguage = formResult.multilingualLanguage;
    nf.otherLanguage = formResult.otherLanguage;

    return await wac.postCallback(nf);
  } catch (e) {
    log.error(e);
    return false;
  }
};

export const configuredInOptions: SubmitFunction = async (
  form,
  fr,
  formContext,
) => {
  hbh.registerSelf(Handlebars, () => '');
  // combined context
  const cc: IFormContext = {
    ...formContext,
    formResult: escapeNewLineCharacters(fr),
  };
  let submissionResult: unknown;
  log.debug({ combinedContext: cc });

  const sequences: IFormSubmitSequence[] = get(
    JSON.parse(form.optionsTemplate?.value),
    'submitSequence',
    [],
  );
  log.debug({ sequences });

  for (const s of sequences) {
    try {
      // sequence template
      const st = JSON.stringify(s);
      // sequence activated
      let sa: IFormSubmitSequence = JSON.parse(Handlebars.compile(st)(cc));
      log.debug({ sequenceActivated: sa });

      //Apply options if configured
      if (sa.operationOptions) {
        sa.operationOptions.forEach(
          (optionKey: string) =>
            (sa = optionsMap[optionKey](form.optionsTemplate?.value, sa, cc)),
        );
      }

      // find operation by function name
      const op = wac[sa.operationName] as (...args: any[]) => Promise<any>;

      // execute operation
      const opResult = await op.apply(null, sa.operationParams);

      // set result on context
      submissionResult = opResult ? opResult : true;
    } catch (e) {
      log.error(`Unable to submit for operation name ${s.operationName}`, e);
      return false;
    }
  }

  return submissionResult;
};

export const submitEndpointMap: { [name: string]: SubmitFunction } = {
  callback: submitAsScheduleCallback,
  configuredInOptions,
  webmessage: submitAsWebMessage,
};

export const submit = async (
  form: models.Form,
  formResult: { [name: string]: any },
  formContext: { [name: string]: any },
) => {
  const s =
    submitEndpointMap[form.submissionEndpoint.value] ||
    submitEndpointMap['webmessage'];

  return s(form, formResult, formContext);
};
