import React from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  getSimpleRemoteConfig,
  IEmployerContent,
} from '../../../utils/remote-config-manager';
import { logout } from '../../../utils/auth-manager';
import {
  Drawer,
  DrawerItemProps,
  DrawerItem,
} from '@progress/kendo-react-layout';
import { colors } from '../../../style';
import {
  prefixObjectValues,
  showPaymentPageBasedOnEmployerId,
  enums,
} from '../../../utils';
import { isDueTask } from '../../tasks/utils';
import {
  DashboardMenuItem,
  OpenSansSemiBoldLabel,
  OpenSansRegularValue,
  StatusBubble,
} from '../simple-styled-components/index';
import {
  getContent,
  getHtmlFromMarkdown,
  getReminderData,
} from '../../../utils/index';
import { convertAnchorTagsToRouterLinks } from '../../../utils/html-parsing-helpers';
import Button from '../button';
import { ButtonTypes, AppPaths } from '../../../utils/enums';
import {
  AccountMenu,
  AccountMenuProps,
} from '../app-header/account-menu/index';
import { LogoArea } from '../app-header/logo-area/index';
import MobileMenuIcon from '../../../images/mobile-menu.svg';
import Handlebars from 'handlebars';
import { registerSelf } from '../../../hooks/use-handlebars/helpers';
import { PersonDataContext } from '../../../contexts/person-data-context';
import { PersonData } from '../../../data-models';
import {
  IKenticoData,
  KenticoDataContext,
} from '../../../contexts/kentico-data-context';
import { PreferencesContext } from '../../../contexts/preferences-context';
import { EmployerEducationalContent } from '../../../kontent-models';
import { getTasks } from '../../../utils/web-apis-client';
import { useQuery } from '@tanstack/react-query';
import {
  MobileReminderMenu,
  MobileReminderMenuComponent,
  ReminderMenuItem,
} from '../app-header/reminder-centre';

export interface DashboardMenuEntry {
  text: string;
  id: string;
  active?: boolean;
  action: () => void;
}
export interface DashboardMenuProps {
  keyId?: string;
}

export interface MenuItem {
  text?: string;
  id?: string;
  mobileonly?: string;
  route: string;
  bold?: string;
  separator?: boolean;
}

export interface MenuItemProps {
  active?: number;
}

export interface AppHeaderProps {
  keyId?: string;
  accountMenuItems?: AccountMenuProps['menuItems'];
  firstName?: string;
  lastName?: string;
  leftalign?: boolean;
}

const paymentPrefs = 'Payment Preferences';

const DrawerLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;

const CustomDrawerItem = (props: DrawerItemProps) => {
  const external = props.id === 'account-settings';
  const { route, ...others } = props;
  return (
    <DrawerItem {...others}>
      {props.bold === 'Y' ? (
        <DrawerLink
          to={route}
          onClick={props.id === 'log-out' ? () => logout() : undefined} //runs logout function instead of href for the logout button
        >
          <OpenSansSemiBoldLabel>{props.text}</OpenSansSemiBoldLabel>
        </DrawerLink>
      ) : (
        <DrawerLink
          to={!external ? route : ''}
          onClick={external ? route : undefined}
        >
          <OpenSansRegularValue>{props.text}</OpenSansRegularValue>
        </DrawerLink>
      )}
    </DrawerItem>
  );
};

const getMenuItems = (
  accountSettingsLink: string,
  showPaymentPrefs: boolean,
  hasDueTasks: number,
  showPaymentInfo: boolean,
  showEducationTab: boolean,
  educationTabName: string,
) => {
  let menuItems = [
    {
      text: 'Start a leave or claim',
      id: 'startClaim',
      bold: 'Y',
      mobileonly: 'Y',
      route: AppPaths.startClaim,
    },
    {
      separator: true,
      mobileonly: 'Y',
      route: '/',
    },
    {
      text: 'Summary',
      id: 'summary',
      mobileonly: 'N',
      route: AppPaths.appRoot,
    },
    {
      text: 'Absences',
      id: 'absences',
      mobileonly: 'N',
      route: AppPaths.absenceHistoryAll,
    },
    {
      text: 'Tasks',
      id: 'tasks',
      mobileonly: 'N',
      route: AppPaths.tasks,
      statusbubble: hasDueTasks,
    },
    {
      text: 'Payments',
      id: 'payments',
      mobileonly: 'N',
      route: AppPaths.payments,
    },
    {
      text: 'Documents',
      id: 'documents',
      mobileonly: 'N',
      route: AppPaths.documents,
    },
    {
      text: 'Upload',
      id: 'new-upload',
      mobileonly: 'N',
      route: AppPaths.newUpload,
    },
    {
      text: 'Available Time',
      id: 'time',
      mobileonly: 'N',
      route: AppPaths.time,
    },
    {
      text: educationTabName,
      id: 'learning-hub-tab',
      mobileonly: 'N',
      route: AppPaths.learninghub,
    },
    {
      separator: true,
      mobileonly: 'Y',
      route: '/',
    },
    {
      text: 'Account Settings',
      id: 'account-settings',
      mobileonly: 'Y',
      route: () => window.location.assign(accountSettingsLink),
    },
    {
      text: 'Notification Preferences',
      id: 'notification-preferences',
      mobileonly: 'Y',
      route: AppPaths.profile,
    },
    {
      text: paymentPrefs,
      id: 'payment-preferences',
      mobileonly: 'Y',
      route: AppPaths.profile,
    },
    {
      separator: true,
      mobileonly: 'Y',
      route: '/',
    },
    {
      text: 'Log out',
      id: 'log-out',
      bold: 'Y',
      mobileonly: 'Y',
      route: '',
    },
  ];

  if (!showPaymentPrefs) {
    menuItems = menuItems.filter((m) => m.id !== 'payment-preferences');
  }

  if (!showPaymentInfo) {
    menuItems = menuItems.filter((m) => m.id !== 'payments');
  }

  if (!showEducationTab) {
    menuItems = menuItems.filter((m) => m.id !== 'learning-hub-tab');
  }

  return menuItems;
};

/*******************************************************************************
 * Adds a unique prefix to the domIds to create a unique id
 * @param {string} prefix A unique prefix to add to the domIds
 * @returns {domIdsStatic} If a prefix is provided, then a copy of domIdsStatic
 * is returned with the property values modified to include the prefix. If a
 * prefix is not provided a copy of domIdsStatic is returned.
 ******************************************************************************/
const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  rootNode: 'menu-container',
  newClaimBtn: 'new-claim-button',
  menuItem: 'menu-item-',
};

/*******************************************************************************
 * styles / styled components
 ******************************************************************************/

const Container = styled.div`
  padding: 2rem 5rem;
  width: 100%;

  @media (max-width: 1050px) {
    padding: 2rem;
  }
`;

const DashboardMenuItemMod = styled(DashboardMenuItem)`
  margin-top: 37px;
  text-decoration: none;
`;

const ItemText = styled.span<MenuItemProps>`
  text-decoration: none;
  padding-bottom: 8px;
  border-bottom: ${(props) =>
    props.active === 1 ? '3px solid #0199A6' : 'none'};

  &:hover {
    border-bottom: 3px solid ${colors.poolD1};
    color: ${colors.warmNeutralL3};
    text-decoration: none;
  }

  &:visited {
    color: ${colors.warmNeutralL3};
  }
`;

const MobileMenuButton = styled.button`
  background-color: ${colors.coolNeutralL1};
  border: 0;
  cursor: pointer;
  display: none;
  outline: none;
  padding: 5px;

  @media (max-width: 1050px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  border: 0;
  cursor: pointer;
  display: none;
  outline: none;
  padding: 5px;
  margin-left: auto;

  @media (max-width: 1050px) {
    display: flex;
  }
`;

const MenuRow = styled.div`
  width: 100%;
  margin-top: 37px;
  @media (max-width: 1050px) {
    display: none;
  }
`;

const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomDrawer = styled(Drawer)`
  max-width: 1280px;
`;

export const getAccountSettingsLink = async (setOdyessiUrl: Function) => {
  const remoteConfig = await getSimpleRemoteConfig();
  setOdyessiUrl(remoteConfig['odyssei_preferences_url']);
};

const getDynamicHeaderContent = async (
  setContent: Function,
  personData: any,
  assets: IKenticoData['assets'],
  callCenter: IKenticoData['callCenter'],
) => {
  const result = await getContent(window.location.pathname, 'header');

  registerSelf(Handlebars, () => '', assets);
  setContent(Handlebars.compile(result)({ ...personData, callCenter }));
};

const getShowPaymentInfo = async (
  ERContent: any,
  setShowPaymentInfo: Function,
) => {
  const showInfo = await showPaymentPageBasedOnEmployerId(ERContent);
  setShowPaymentInfo(showInfo);
};

const getEducationalContent = async (
  ERContent: IEmployerContent | undefined,
  setShowEducationTab: Function,
  setEducationTabName: Function,
) => {
  if (
    ERContent?.educationalContent &&
    ERContent.educationalContent?.length > 0
  ) {
    setShowEducationTab(true);
    setEducationTabName(
      (ERContent?.educationalContent[0] as EmployerEducationalContent).tabName
        ?.value || 'Employer Benefits',
    );
  }
};

export const userHasDueTasks = async (
  personData: PersonData | undefined,
  setHasDueTasks: Function,
) => {
  if (personData?.person?.customerNumber) {
    try {
      const queryParams = {
        customerNumber: parseInt(personData?.person?.customerNumber),
      };
      const result = await getTasks(queryParams);
      const openTasks = result.tasks.some(
        (task) => isDueTask(task.taskStatusId) && task.assigneeId === 2,
      );

      setHasDueTasks(openTasks ? 1 : 0);
    } catch (err) {
      console.log(err);
    }
  }
};

const DashboardMenu: (p: DashboardMenuProps) => React.ReactElement = (
  props,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const domIds = domIdsUnique(props.keyId);
  const [expanded, setExpanded] = React.useState(false);
  const [odyessiUrl, setOdyessiUrl] = React.useState<string>('');
  const [content, setContent] = React.useState<any>();
  const [showPaymentPrefs, setShowPaymentPrefs] = React.useState<boolean>(true);
  const [showPaymentInfo, setShowPaymentInfo] = React.useState<boolean>(true);
  const { assets, callCenter } = React.useContext(KenticoDataContext);
  const [hasDueTasks, setHasDueTasks] = React.useState<number>(0);
  const [showEducationTab, setShowEducationTab] =
    React.useState<boolean>(false);
  const [educationTabName, setEducationTabName] = React.useState<string>('');
  const { ERContent } = React.useContext(PreferencesContext);
  const [reminderMenuItems, setReminderMenuItems] = React.useState<
    ReminderMenuItem[]
  >([]);
  const [isMobileReminderMenuOpen, setIsMobileReminderMenuOpen] =
    React.useState<boolean>(false);
  const history = useHistory();

  const accountMenuRoute = React.useCallback(
    (route) => history.push(route),
    [history],
  );

  const { personData } = React.useContext(PersonDataContext);
  const location = useLocation();
  let menuItems: any = [];
  let accountMenuItems: any = [];

  React.useEffect(() => {
    history.listen(() => {
      if (!content) {
        getDynamicHeaderContent(setContent, personData, assets, callCenter);
      }
    });
  }, [history, personData, content, assets, callCenter]);

  React.useEffect(() => {
    getAccountSettingsLink(setOdyessiUrl);
    if (!content) {
      getDynamicHeaderContent(setContent, personData, assets, callCenter);
    }
  }, [assets, callCenter, content, personData]);

  React.useEffect(() => {
    userHasDueTasks(personData, setHasDueTasks);
    setShowPaymentPrefs(personData?.eeCentric?.checkCutting ?? false);
  }, [personData]);

  React.useEffect(() => {
    getEducationalContent(ERContent, setShowEducationTab, setEducationTabName);
  }, [ERContent]);

  React.useEffect(() => {
    getShowPaymentInfo(ERContent, setShowPaymentInfo);
  }, [ERContent]);

  const { data: reminderData, status: reminderQueryStatus } = useQuery({
    queryKey: ['reminders'],
    queryFn: () => getReminderData(),
    enabled: !!personData?.person?.eid,
  });

  React.useEffect(() => {
    if (reminderData && reminderData.length > 0) {
      setReminderMenuItems(reminderData);
    }
  }, [personData, reminderData]);

  const getAccountMenuItems = () => {
    const accountItems = [
      {
        displayText: 'Account Settings',
        action: () => window.location.assign(odyessiUrl),
      },
      {
        displayText: 'Notification Preferences',
        action: () => accountMenuRoute(AppPaths.profile),
      },
      {
        displayText: paymentPrefs,
        action: () => accountMenuRoute(AppPaths.profile),
      },
      {
        displayText: 'Log out',
        action: () => logout(),
      },
    ];

    if (!showPaymentPrefs) {
      return accountItems.filter((m) => m.displayText !== paymentPrefs);
    }

    return accountItems;
  };

  const onSelect = () => {
    setExpanded(!expanded);
  };

  function GetRoute() {
    if (location.pathname.endsWith('/')) {
      return location.pathname.substring(0, location.pathname.length - 1); //remove trailing forward slash if exists
    }

    return location.pathname;
  }

  if (odyessiUrl) {
    menuItems = getMenuItems(
      odyessiUrl,
      showPaymentPrefs,
      hasDueTasks,
      showPaymentInfo,
      showEducationTab,
      educationTabName,
    );
    accountMenuItems = getAccountMenuItems();
  }

  return (
    <>
      <CustomDrawer
        expanded={expanded}
        position={'start'}
        mode={'overlay'}
        animation={{
          duration: 400,
        }}
        items={menuItems.map((item: any) => ({
          ...item,
        }))}
        onOverlayClick={() => setExpanded(!expanded)}
        onSelect={onSelect}
        item={CustomDrawerItem}
      >
        <Container id={domIds.rootNode}>
          <MobileReminderMenuComponent
            menuItems={reminderMenuItems}
            queryStatus={reminderQueryStatus}
            setOpenMenu={setIsMobileReminderMenuOpen}
            isOpen={isMobileReminderMenuOpen}
          ></MobileReminderMenuComponent>
          <HeaderBar className="row mr-0">
            <LogoArea />
            <ButtonContainer>
              <MobileReminderMenu
                menuItems={reminderMenuItems}
                mobileMenuIsOpen={isMobileReminderMenuOpen}
                mobileMenuFunction={setIsMobileReminderMenuOpen}
              ></MobileReminderMenu>
              <MobileMenuButton
                className="col-sm-1"
                onClick={() => setExpanded(!expanded)}
              >
                <img src={MobileMenuIcon} alt="" />
              </MobileMenuButton>
            </ButtonContainer>
            <AccountMenu
              firstName={personData?.person?.firstName}
              lastName={personData?.person?.lastName}
              menuItems={accountMenuItems}
              reminderMenuItems={reminderMenuItems}
              reminderQueryStatus={reminderQueryStatus}
            />
          </HeaderBar>
          <div
            className="p-0 col-sm-auto tw-hidden lg:tw-block"
            style={{ flexGrow: 1 }}
          >
            <Link to={enums.AppPaths.startClaim}>
              <Button
                style={{ float: 'right' }}
                buttonStyle={ButtonTypes.secondary}
                id={domIds.newClaimBtn}
              >
                Start a leave or claim{' '}
              </Button>
            </Link>
          </div>
          <MenuRow className="row" style={{ alignItems: 'baseline' }}>
            {menuItems
              ?.filter((m: any) => m.mobileonly === 'N')
              .map((m: any) => (
                <DashboardMenuItemMod
                  id={domIds.menuItem + m.id}
                  key={domIds.menuItem + m.id + m.route}
                  to={m.route}
                  className="col-sm-auto"
                >
                  <ItemText
                    // because styled components won't allow booleans for non standard attributes
                    active={GetRoute() === m.route ? 1 : 0}
                  >
                    {m.text}
                    {m.statusbubble === 1 && <StatusBubble />}
                  </ItemText>
                </DashboardMenuItemMod>
              ))}
          </MenuRow>
        </Container>
      </CustomDrawer>
      {content
        ? convertAnchorTagsToRouterLinks(getHtmlFromMarkdown(content).__html)
        : null}
    </>
  );
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { DashboardMenu, domIdsUnique };
