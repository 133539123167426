import axios from 'axios';
import { postDocument } from '../web-apis-client';
import { getSimpleRemoteConfig } from '../remote-config-manager';
import { enums } from '../.';
import { UploadFileInfo } from '@progress/kendo-react-upload';
import {
  IPostDocumentArgsWithCaseId,
  IPostDocumentArgsWithPlanId,
} from '../../data-models/web-apis';

export const convertExtensionToLowercase = (filename: string) => {
  const extension = filename.split('.').pop();
  const lower = extension?.toLowerCase();
  const noExt = filename.replace(/\.[^/.]+$/, '');
  return `${noExt}.${lower}`;
};

export const sendPercentValid = (p: number | null | undefined) => {
  return p && p > 0;
};

export const uploadFile = async (
  file: any,
  index: number,
  setFileAttributes: Function,
  setUploadTried: Function,
) => {
  if (file.sent) {
    return 'success';
  }
  setFileAttributes(file, index, true);
  try {
    if ((file.caseId || file.planId) && file.extension) {
      const pdArgs: IPostDocumentArgsWithCaseId | IPostDocumentArgsWithPlanId =
        {
          planId: 'test',
          fileName: file.name,
          contentType: documentContentTypeMap[file.extension.substring(1)],
          actionType: 'init',
        };
      if (file.caseId) {
        pdArgs.caseId = file.caseId;
      } else {
        pdArgs.planId = file.planId;
      }

      const response = await postDocument(pdArgs);

      const options = {
        headers: {
          'Content-Type': documentContentTypeMap[file.extension.substring(1)],
        },
        onUploadProgress: (e: ProgressEvent) => {
          const percentComplete = Math.round((e.loaded * 100) / e.total);

          setFileAttributes(
            file,
            index,
            true,
            false,
            false,
            '',
            percentComplete,
          );
        },
      };

      const putResponse = await axios.put(
        response.s3Url,
        file.getRawFile?.(),
        options,
      );

      if (putResponse.status === 200) {
        setFileAttributes(file, index, false, true, false, '', 100);
        return 'success';
      } else {
        throw Error('Upload Failed');
      }
    } else {
      throw new Error('Upload failed');
    }
  } catch (e) {
    setFileAttributes(
      file,
      index,
      false,
      false,
      true,
      'File failed to upload',
      null,
    );
    return 'failure';
  } finally {
    setUploadTried(true);
  }
};

const documentContentTypeMap: any = {
  bmp: 'image/bmp',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  gif: 'image/gif',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  rtf: 'application/rtf',
  png: 'image/png',
  txt: 'text/plain',
};

export const allowedFileTypes = Object.keys(documentContentTypeMap);

export const getFileSizeLimit = async (setFileSizeLimit: Function) => {
  const remoteConfig = await getSimpleRemoteConfig();
  setFileSizeLimit(remoteConfig[enums.RemoteConfigKeys.uploadLimit]);
};

export interface CustomUploadFileInfo extends UploadFileInfo {
  caseId?: string;
  planId?: string;
  sending?: boolean;
  sent?: boolean;
  sendPercent?: number | null;
  error?: boolean;
  errorMessage?: string;
}
