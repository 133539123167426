import React from 'react';
import styled from 'styled-components';
import {
  getHtmlFromMarkdown,
  parseJSONOrDefault,
  prefixObjectValues,
} from '../../../utils';
import { convertAnchorTagsToRouterLinks } from '../../../utils/html-parsing-helpers';
import { models } from '../../../utils/cms-client';
import * as options from '../options';
import { defaults, getFont } from '../simple-styled-components';
import { rem } from '../../../style';
import { FormContext } from '../../../contexts/form-context';
import useHandlebars from '../../../hooks/use-handlebars';
import { RefreshContext } from '../../../contexts/refresh-context';

interface ComponentProps {
  keyId?: string;
  text: models.FormText;
  formValueGetter: (name: string) => any;
}

const domIdsStatic = {
  rootNode: 'form-text',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  margin: 1.5rem 0rem;
  font-family: ${getFont(defaults.fontName, defaults.fontType).fontFamily};
  font-weight: ${getFont(defaults.fontName, defaults.fontType).fontWeight};
  font-size: ${rem(defaults.fontSize)};
  color: ${defaults.color};
`;

const FormText: (p: ComponentProps) => React.ReactElement | null = ({
  keyId,
  text,
  formValueGetter,
}) => {
  const domIds = domIdsUnique(keyId);
  const { formContext } = React.useContext(FormContext);
  const { refreshDetails } = React.useContext(RefreshContext);
  const markdown = useHandlebars(
    text?.markdownText?.value,
    {
      ...formContext,
    },
    formValueGetter,
  );
  const [optionsTemplate, setOptionsTemplate] = React.useState({});

  React.useEffect(() => {
    setOptionsTemplate(parseJSONOrDefault(text?.optionsTemplate?.value));
  }, [text]);

  return options.shouldShow(
    optionsTemplate,
    formValueGetter,
    formContext,
    refreshDetails,
  ) ? (
    <Container id={domIds.rootNode}>
      {convertAnchorTagsToRouterLinks(getHtmlFromMarkdown(markdown).__html)}
    </Container>
  ) : null;
};

export default FormText;
