import styled from 'styled-components';
import { colors, rem } from '../../../../style';
import { FormContext } from '../../../../contexts/form-context';
import { RadioButton } from '@progress/kendo-react-inputs';
import React from 'react';
import {
  DropDownList,
  Error,
  Input,
  Text,
  InfoText,
} from '../../simple-styled-components';
import { enums, get } from '../../../../utils';
import dayjs from 'dayjs';
import Button from '../../../base/button';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { Reveal } from '@progress/kendo-react-animation';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import PenIcon from '../../../../images/pen-clip-solid.svg';
import InfoIcon from '../../../../images/info-icon.svg';

export interface SignerResult {
  insuredsName: string;
  onBehalfSelected: boolean;
  signed: boolean;
  signedDate: string;
  signersName?: string;
  signersRelationship?: string;
}

export const DEFAULT_VALUE_RELATIONSHIP = 'Please select relationship';

const Container = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
`;

const Grid = styled.div`
  align-items: center;
  display: grid;
  gap: ${rem(10)};
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
`;

const SignatureContainer = styled(Text)`
  background-color: ${colors.alabaster};
  border: 1px solid ${colors.geyser2};
  border-radius: 10px;
  padding: ${rem(5)};
  text-align: center;
`;

const ItalicText = styled(Text)`
  font-style: italic;
  margin: 10px 0;
`;

export interface ISignatureProps {
  insuredsName?: string;
  signersName?: string;
  signersRelationship?: string;
  signed: boolean;
  setSigned: React.Dispatch<React.SetStateAction<boolean>>;
  setValidationError?: React.Dispatch<React.SetStateAction<string>>;
}

export type SignatureComponent = (p: ISignatureProps) => React.ReactElement;

const Signature: SignatureComponent = ({
  insuredsName,
  signersName,
  signersRelationship,
  signed,
  setSigned,
  setValidationError,
}) => (
  <SignatureContainer>
    {signed ? (
      <div id="form-signer-signature">{insuredsName || signersName}</div>
    ) : (
      <Button
        id="form-signer-button"
        buttonStyle={enums.ButtonTypes.link}
        onClick={(e) => {
          e.preventDefault();

          if (
            insuredsName ||
            ((signersName || '').trim().length > 0 &&
              (signersRelationship || '').trim().length > 0 &&
              (signersRelationship || '') !== DEFAULT_VALUE_RELATIONSHIP)
          ) {
            setSigned(true);
            setValidationError && setValidationError('');
          } else {
            setValidationError &&
              setValidationError('Please check required values to sign');
          }
        }}
      >
        <img src={PenIcon} alt="" className="mr-2" />
        Click/tap to sign here
      </Button>
    )}
  </SignatureContainer>
);

export interface ISignatureWithDateProps extends ISignatureProps {
  signedDate?: string;
}

const SignatureWithDate = ({
  signedDate,
  ...others
}: ISignatureWithDateProps) => (
  <Grid className="mt-2">
    <div>
      <Text fontSize={14} fontType={enums.FontTypes.semibold}>
        {others.insuredsName ? 'Insured' : 'Signer'}’s Signature
      </Text>
      <Signature {...others} />
    </div>
    <div>
      <Text fontSize={14} fontType={enums.FontTypes.semibold}>
        Date
      </Text>
      <Text fontType={enums.FontTypes.semibold}>{signedDate}</Text>
    </div>
  </Grid>
);

export const handleRelationshipChange = (
  e: DropDownListChangeEvent,
  setSignersRelationship: React.Dispatch<React.SetStateAction<string>>,
) => {
  const v = e.target.value?.toString();
  if (v !== DEFAULT_VALUE_RELATIONSHIP) {
    setSignersRelationship(v);
  }
};

const updateSignerState = (
  v: any,
  setSigned: React.Dispatch<React.SetStateAction<boolean>>,
  setSignersName: React.Dispatch<React.SetStateAction<string>>,
  setSignersRelationship: React.Dispatch<React.SetStateAction<string>>,
  setOnBehalfSelected: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setSigned(v?.signed || false);
  setSignersName(v?.signersName);
  setSignersRelationship(v?.signersRelationship);
  setOnBehalfSelected(v?.onBehalfSelected || false);
};

const legalDisclaimer = `By clicking/tapping “Sign Here” below, I understand, acknowledge, and
agree that this is an electronic signature, and that this document may
be electronically signed. My electronic signature on this document has
the same legal effect as if handwritten for the purposes of validity,
enforceability, and admissibility.`;

const signerInstruction = `In order to sign on behalf of the insured, you must click the sign
here button and provide your name and relationship`;

const Signer: (p: FieldRenderProps) => React.ReactElement = ({
  value,
  onChange,
  ...p
}) => {
  const { formContext } = React.useContext(FormContext);
  const [signed, setSigned] = React.useState(value?.signed || false);
  const [signedDate, setSignedDate] = React.useState(value?.signedDate);
  const [insuredsName, setInsuredsName] = React.useState(value?.insuredsName);
  const [signersName, setSignersName] = React.useState(value?.signersName);
  const [signersRelationship, setSignersRelationship] = React.useState(
    value?.signersRelationship,
  );
  const [onBehalfSelected, setOnBehalfSelected] = React.useState(
    value?.onBehalfSelected || false,
  );
  const [validationError, setValidationError] = React.useState(
    p.validationError || '',
  );

  React.useEffect(() => {
    const insName = `${get(formContext, 'person.firstName', '')} ${get(
      formContext,
      'person.lastName',
      '',
    )}`;

    setSignedDate(dayjs().format('MM/DD/YYYY'));
    setInsuredsName(insName);
  }, [formContext]);

  React.useEffect(() => {
    updateSignerState(
      value,
      setSigned,
      setSignersName,
      setSignersRelationship,
      setOnBehalfSelected,
    );
  }, [value]);

  React.useEffect(() => {
    if (signed) {
      onChange({
        value: {
          insuredsName,
          onBehalfSelected,
          signed,
          signedDate,
          signersName,
          signersRelationship:
            signersRelationship === DEFAULT_VALUE_RELATIONSHIP
              ? undefined
              : signersRelationship,
        },
      });
      p.onBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    insuredsName,
    onBehalfSelected,
    signed,
    signedDate,
    signersName,
    signersRelationship,
  ]);

  const handleOnBehalfChange = React.useCallback(() => {
    const newValue = {
      insuredsName,
      onBehalfSelected: !onBehalfSelected,
      signed: false,
      signedDate,
      signersName: '',
      signersRelationship: DEFAULT_VALUE_RELATIONSHIP,
    };
    onChange({ value: newValue });
    updateSignerState(
      newValue,
      setSigned,
      setSignersName,
      setSignersRelationship,
      setOnBehalfSelected,
    );
  }, [onBehalfSelected, onChange, insuredsName, signedDate]);

  return (
    <Container className="merge-card-bottom mb-2r" id="form-signer">
      <InfoText fontSize={13}>
        <img
          src={InfoIcon}
          alt=""
          className="mr-2"
          style={{ marginLeft: -25 }}
        />
        {legalDisclaimer}
      </InfoText>
      <div className="d-flex justify-content mt-5">
        <RadioButton
          id={'form-signer-asinsured-button'}
          disabled={false}
          name={'form-signer-asinsured-button'}
          value={1}
          checked={!onBehalfSelected}
          label={'Sign as insured'}
          onChange={handleOnBehalfChange}
        />
      </div>
      <Reveal>
        {!onBehalfSelected ? (
          <SignatureWithDate
            {...{
              insuredsName,
              signed,
              setSigned,
              signedDate,
            }}
          />
        ) : null}
      </Reveal>
      <Button
        id="form-signer-onbehalf-button"
        buttonStyle={enums.ButtonTypes.flat}
        onClick={handleOnBehalfChange}
        style={{ textAlign: 'start' }}
      >
        Sign on behalf of the insured
      </Button>
      <Reveal style={{ zIndex: 99, position: 'relative' }}>
        {onBehalfSelected ? (
          <>
            <ItalicText fontSize={14} fontType={enums.FontTypes.semibold}>
              {signerInstruction}
            </ItalicText>
            <div>
              <Text fontSize={14} fontType={enums.FontTypes.semibold}>
                Insured's Name
              </Text>
              <Input
                id="form-signer-onbehalf-insureds-name"
                disabled={true}
                value={insuredsName}
                valid={true}
              />
            </div>
            <Grid className="mt-2">
              <div>
                <Text fontSize={14} fontType={enums.FontTypes.semibold}>
                  Signer's Name
                </Text>
                <Input
                  id="form-signer-onbehalf-signers-name"
                  disabled={signed}
                  value={signersName}
                  onChange={(e) => setSignersName(e.target.value?.toString())}
                  valid={
                    (!p.touched || p.valid) && validationError.length === 0
                  }
                />
              </div>
              <div>
                <Text fontSize={14} fontType={enums.FontTypes.semibold}>
                  Relationship of Signer to Insured
                </Text>
                <DropDownList
                  style={{ width: '100%' }}
                  id="form-signer-signers-relationship"
                  disabled={signed}
                  value={signersRelationship}
                  onChange={(e) =>
                    handleRelationshipChange(e, setSignersRelationship)
                  }
                  valid={
                    (!p.touched || p.valid) && validationError.length === 0
                  }
                  defaultItem={DEFAULT_VALUE_RELATIONSHIP}
                  data={[
                    'Power of Attorney Designee',
                    'Personal Representative',
                    'Guardian',
                    'Conservator',
                  ]}
                />
              </div>
            </Grid>
            <SignatureWithDate
              {...{
                signersName,
                signersRelationship,
                signed,
                setSigned,
                signedDate,
                setValidationError,
              }}
            />
            {validationError.length > 0 && <Error>{validationError}</Error>}
            <ItalicText fontSize={14}>
              After completion, please upload a copy of the document granting
              authority.
            </ItalicText>
          </>
        ) : null}
      </Reveal>
      <hr></hr>
    </Container>
  );
};

export default Signer;
