import React from 'react';
import { get, getRelatedTask, getStatusIcon } from '../../../../utils';
import { Claim, Task } from '../../../../data-models';
import dayjs from 'dayjs';
import {
  Items,
  Item,
  CardItemBody,
  dateFormat,
  CardItemTitle,
  CardText,
  StatusFlag,
} from './shared-styles';
import ClaimStatusInfo from '../../../../images/claimStatusInfoIcon.svg';
import { Popover } from '@progress/kendo-react-tooltip';
import styled from 'styled-components';
import { getClaimStatusConfig } from '../../../../utils/remote-config-manager';
import { EmployerTaskInfo } from '../../../tasks/employer';
import { isDueTask } from '../../../tasks/utils';
import { AwaitingTasksBanner } from '../tasks-banner';

interface IClaimCardItem {
  claim: Claim;
  ERTasks: Array<Task>;
  EETasks: Array<Task>;
}

const ClaimsCardItems: (props: {
  claims: Array<Claim>;
  ERTasks: Array<Task>;
  EETasks: Array<Task>;
}) => React.ReactElement = (props) => (
  <Items>
    {get(props, 'claims', []).map((claim: Claim, i: number) => (
      <ClaimCardItem
        claim={claim}
        key={`${claim.claimId}-item-${i}`}
        ERTasks={props.ERTasks}
        EETasks={props.EETasks}
      />
    ))}
  </Items>
);

const getStatusText = async (props: any, setStatusMessage: Function) => {
  const statusConfigs = await getClaimStatusConfig();
  const foundStatus = statusConfigs.find(
    (s) => s.status === get(props.claim, 'status', 'N/A'),
  );
  if (foundStatus && foundStatus.message) {
    setStatusMessage(foundStatus.message);
  }
};

const ClaimStatusInfoIcon = styled.button`
  background: #fff;
  border: 0px;
  margin-left: 6px;
  bottom: 1px;
`;

export const ClaimCardItem: (props: IClaimCardItem) => React.ReactElement = (
  props,
) => {
  const createdDate = props.claim?.createdDate || null;
  const anchor = React.useRef<HTMLDivElement | null>(null);
  const ERTask = props.ERTasks
    ? getRelatedTask(props.claim?.claimId, props.ERTasks)
    : null;

  const [statusMessage, setStatusMessage] = React.useState();
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    getStatusText(props, setStatusMessage);
  }, [props]);

  const dueClaimTasks = props.EETasks.filter(
    (t) =>
      isDueTask(t.taskStatusId) &&
      t.caseInfo.some((c) => c.caseId === props.claim.caseId),
  );

  return (
    <Item>
      <CardItemBody>
        <div style={{ textAlign: 'left', flexWrap: 'wrap', width: '35%' }}>
          <CardItemTitle>{props.claim.claimType}</CardItemTitle>
        </div>
        {dayjs(createdDate).isValid() ? (
          <div
            className="d-flex"
            style={{ textAlign: 'left', flexWrap: 'wrap', width: '35%' }}
          >
            <CardText color="#525252">CREATED:&nbsp;</CardText>{' '}
            <CardText color="#757575">
              {dayjs(createdDate).format(dateFormat)}
            </CardText>
          </div>
        ) : null}
        <div
          id={'claim-status-id-' + props.claim.caseId}
          style={{ textAlign: 'left', width: '25%', whiteSpace: 'nowrap' }}
          ref={anchor}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <StatusFlag
            color={get(props.claim, 'status', 'None')
              .replace(/ /g, '')
              .toLowerCase()}
          >
            <img
              style={{ paddingRight: '5px' }}
              src={getStatusIcon(props)}
              alt=""
            />
            {get(props.claim, 'status', 'N/A')}
            <ClaimStatusInfoIcon>
              <img alt="" src={ClaimStatusInfo} />
            </ClaimStatusInfoIcon>
            <Popover
              id={'popover-id-' + props.claim.caseId}
              show={show}
              anchor={anchor.current}
              position={'top'}
            >
              <div
                style={{
                  width: 220,
                  backgroundColor: '#015294',
                  padding: '5px',
                  borderRadius: '2px',
                  color: '#FFF',
                  fontWeight: 400,
                  textAlign: 'left',
                }}
              >
                {statusMessage}
              </div>
            </Popover>
          </StatusFlag>
        </div>
        {dueClaimTasks.length > 0 && (
          <AwaitingTasksBanner ntn={props.claim.caseId!} />
        )}
        <EmployerTaskInfo
          show={ERTask && isDueTask(ERTask?.taskStatusId) ? true : false}
        />
      </CardItemBody>
    </Item>
  );
};

export default ClaimsCardItems;
