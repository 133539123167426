
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class EmployerLinkSection extends ContentItem {
    public employerCustomLinks!: Elements.LinkedItemsElement<ContentItem>;
    public linksSectionHeader!: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'employer_custom_links') {
                    return 'employerCustomLinks';
                }
                if (elementName === 'links_section_header') {
                    return 'linksSectionHeader';
                }
                return elementName;
            })
        });
    }
}
