import dayjs from 'dayjs';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { colors } from '../../style';
import { prefixObjectValues } from '../../utils';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import { defaultTheme } from './defaultTheme';
import { ChevronLeft, ChevronRight } from './assets/assets';
import { generateCalendarRows } from './generators';
import DayButton from './day-button';
import Overlay from './overlay';
import DetailModal from './detail-modal';
import RangeElement from './overlay/elements/range-element';
import PageFold from './overlay/elements/page-fold';
import Legend from './legend';

dayjs.extend(localeData);
dayjs.extend(updateLocale);
dayjs.locale('en');
dayjs.updateLocale('en', {
  weekdaysMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
});

export interface DateProps {
  date: string;
  data?: { [key: string]: any };
  disabled?: boolean;
}

export interface ILegend {
  [key: string]: {
    style?: React.CSSProperties;
    content?: string;
    elementType?: React.ElementType;
    [key: string]: any;
  };
}

export interface IElement {
  startDate: string;
  endDate: string;
  legendKey: string;
  [key: string]: any;
}

interface CalendarProps {
  keyId?: string;
  defaultSelectedDate?: string; //defaults to today
  selectedDateBackground?: string;
  dayDateFormat?: string; // defaults to 'D'
  dates?: DateProps[];
  detailModal?: React.Component;
  onDateSelected?: Function;
  customDetailModal?: React.ElementType;
  theme?: { [key: string]: string | number };
  legend?: ILegend;
  showLegend?: boolean;
  elements?: IElement[];
  periods?: IElement[];
  hideUnusedStyles?: boolean;
  showSelectedDate?: boolean;
}

const domIdsStatic = {
  rootNode: 'generic-calendar',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  position: relative;
`;

const VisualContainer = styled.div`
  display: flex;
  gap: 0 16px;

  @media (max-width: 767px) {
    #cv-right-calendar-container {
      display: none;
    }
    width: 100%;
  }
`;

const NavigationBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background-color: ${(props) => props.theme.navigationBar.background};
  border: ${(props) => props.theme.navigationBar.border};
  border-radius: ${(props) => props.theme.navigationBar.radius};
  color: ${(props) => props.theme.navigationBar.color};
  font-family: ${(props) => props.theme.navigationBar.font};
  font-size: ${(props) => props.theme.navigationBar.size};
  font-weight: ${(props) => props.theme.navigationBar.weight};
`;

const ButtonStyled = styled.div`
  background-color: ${(props) => props.theme.navigationBar.button.background};
  border: 0;
  cursor: pointer;
  line-height: 16px;
  padding: 10px 15px;

  svg {
    height: ${(props) => props.theme.navigationBar.button.height};
    width: ${(props) => props.theme.navigationBar.button.width};
  }

  path {
    fill: ${(props) => props.theme.navigationBar.button.fill};
  }

  @media (min-width: 768px) {
    &#cv-left-next-month-button,
    &#cv-right-prev-month-button {
      visibility: hidden;
    }
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  border-bottom: ${(props) => props.theme.border};
  border-left: ${(props) => props.theme.border};
`;

const DayOfWeekRow = styled(Row)`
  background-color: ${(props) => props.theme.dayOfWeek.background};
  border: ${(props) => props.theme.dayOfWeek.rowBorder};
`;

const DaysOfMonthContainer = styled.div`
  background-color: ${colors.coolNeutralL1};
  position: relative;
`;

const WeekDayHeading = styled.div`
  font-family: ${(props) => props.theme.dayOfWeek.font};
  font-size: ${(props) => props.theme.dayOfWeek.size};
  font-weight: ${(props) => props.theme.dayOfWeek.weight};
  color: ${(props) => props.theme.dayOfWeek.color};
  border-right: ${(props) => props.theme.dayOfWeek.colBorder};
  text-align: ${(props) => props.theme.dayOfWeek.alignment};

  &:last-child {
    border-right: 0;
  }
`;

const WeekRow = styled(Row)`
  border-right: ${(props) => props.theme.border};
`;

const Calendar: (p: CalendarProps) => React.ReactElement = (p) => {
  const [calendarRows, setCalendarRows] = React.useState<Array<dayjs.Dayjs[]>>(
    [],
  );
  const [nmCalendarRows, setNmCalendarRows] = React.useState<
    Array<dayjs.Dayjs[]>
  >([]);
  const [date, setDate] = React.useState(dayjs(p.defaultSelectedDate));
  const [selectedDate, setSelectedDate] = React.useState(
    dayjs(p.defaultSelectedDate),
  );
  const [dateDetails, setDateDetails] = React.useState({});
  const [displayDetailModal, setDisplayDetailModal] = React.useState(false);
  const domIds = domIdsUnique(p.keyId);

  React.useEffect(() => {
    const rows = generateCalendarRows(date);
    const nextMonth = date.clone().add(1, 'month');
    const nmRows = generateCalendarRows(nextMonth);
    setCalendarRows(rows);
    setNmCalendarRows(nmRows);
  }, [date]);

  const changeMonth = (increment: number) => {
    const newDate = date.clone().add(increment, 'month');
    setDate(newDate);
  };

  const toggleDetailModal = (toggle: boolean) => {
    setDisplayDetailModal(toggle);
  };

  const handleDateSelection = (
    e: React.MouseEvent<HTMLElement>,
    d: any,
    details: any,
  ) => {
    e.preventDefault();
    setDateDetails(details);
    toggleDetailModal(true);
    setSelectedDate(d);

    p?.onDateSelected?.(d);
  };

  const sharedVisualProps = {
    changeMonth,
    handleDateSelection,
    dayDateFormat: p.dayDateFormat,
    periods: p.periods,
    elements: p.elements,
    dates: p.dates,
    legend: p.legend,
    selectedDate,
    showSelectedDate: p.showSelectedDate,
  };

  return (
    <ThemeProvider theme={p.theme ?? defaultTheme}>
      <Container id={domIds.rootNode}>
        <VisualContainer>
          <CalendarVisual
            prefix={'cv-left'}
            date={date}
            calendarRows={calendarRows}
            {...sharedVisualProps}
          />
          <CalendarVisual
            prefix={'cv-right'}
            date={date.clone().add(1, 'month')}
            calendarRows={nmCalendarRows}
            {...sharedVisualProps}
          />
        </VisualContainer>
        {p.showLegend && (
          <Legend
            expandable={true}
            styles={p.legend ?? {}}
            hideUnusedStyles={p.hideUnusedStyles}
            stylesVisibleOnCalendar={[
              ...(p.elements ?? []),
              ...(p.periods ?? []),
            ]}
          />
        )}
        {displayDetailModal && (
          <DetailModal
            details={dateDetails}
            customElement={p.customDetailModal}
            modalCallback={() => toggleDetailModal(false)}
          />
        )}
      </Container>
    </ThemeProvider>
  );
};

interface ICalendarVisual {
  prefix: string;
  changeMonth: (m: number) => void;
  date: dayjs.Dayjs;
  calendarRows: Array<dayjs.Dayjs[]>;
  handleDateSelection: (
    e: React.MouseEvent<HTMLElement>,
    d: any,
    details: any,
  ) => void;
  dates?: DateProps[];
  elements?: IElement[];
  periods?: IElement[];
  legend?: ILegend;
  dayDateFormat?: string;
  selectedDate: dayjs.Dayjs;
  showSelectedDate?: boolean;
}

const CalendarVisual = (p: ICalendarVisual) => {
  const {
    prefix,
    changeMonth,
    date,
    calendarRows,
    handleDateSelection,
    dayDateFormat,
    periods,
    elements,
    dates,
    legend,
    selectedDate,
    showSelectedDate,
  } = p;

  return (
    <div id={`${prefix}-calendar-container`} className="w-100">
      <NavigationBar>
        <ButtonStyled
          id={`${prefix}-prev-month-button`}
          onClick={() => changeMonth(-1)}
        >
          <ChevronLeft />
        </ButtonStyled>
        <div id={`${prefix}-month-name`}>
          {date.format('MMMM, YYYY').toUpperCase()}
        </div>
        <ButtonStyled
          id={`${prefix}-next-month-button`}
          onClick={() => changeMonth(1)}
        >
          <ChevronRight />
        </ButtonStyled>
      </NavigationBar>
      <DayOfWeekRow>
        {dayjs.weekdaysMin().map((weekday) => (
          <WeekDayHeading>{weekday}</WeekDayHeading>
        ))}
      </DayOfWeekRow>
      <DaysOfMonthContainer>
        {calendarRows.map((row, weekIndex) => (
          <WeekRow key={`${prefix}-week-${weekIndex}`}>
            {row.map((day, dayIndex) => (
              <DayButton
                key={`${prefix}-day-${dayIndex}`}
                date={day}
                format={dayDateFormat ?? 'DD'}
                calendarDate={date}
                details={dates ?? []}
                handleDateSelection={handleDateSelection}
                periods={periods ?? []}
                selectedDate={selectedDate}
                showSelectedDate={showSelectedDate}
              />
            ))}
          </WeekRow>
        ))}
        {elements && (
          <Overlay
            keyId={`${prefix}-`}
            elements={elements}
            date={date}
            legend={legend}
          />
        )}
      </DaysOfMonthContainer>
    </div>
  );
};

export default Calendar;
export { RangeElement, PageFold, Legend };
