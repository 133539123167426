import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import OptionsModal from '../options-modal';

export function Prompt(props: any) {
  const {
    when,
    onOK,
    onCancel,
    title,
    okText,
    cancelText,
    description,
    options,
    useTextBox,
    mandatory,
  } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(
    async (...args) => {
      if (onOK) {
        const canRoute = await Promise.resolve(onOK(args));
        if (canRoute) {
          history.block(() => {});
          history.push(currentPath);
        }
      }
    },
    [currentPath, history, onOK],
  );

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <OptionsModal
      title={title}
      description={description}
      markdown={false}
      confirmButtonText={okText}
      modalCallback={handleCancel}
      cancelButtonText={cancelText}
      handleConfirm={handleOK}
      confirmButtonCustomId="confirm-dialog-navigate-from-form"
      options={options}
      useTextBox={useTextBox}
      mandatory={mandatory}
    />
  ) : null;
}
