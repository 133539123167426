import * as dm from '../../data-models';
import { get } from '../';
import {
  styleRuleType,
  FilterRuleType,
  intermittentRules,
  continuousRules,
  draftClaimRules,
} from './rules';
import dayjs from 'dayjs';
import {
  mergeOverlappingDateRanges,
  createDaysFromRange,
} from './overlap-utils';
import { getCalendarData } from '../web-apis-client';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(minMax);

export interface ProcessedCalendarItem extends dm.Calendar {
  legendKey: string;
}

type appendStyleByRulesType = (
  rules: styleRuleType[],
  data: dm.Calendar[],
) => ProcessedCalendarItem[];

export interface DateRange {
  startDate?: string;
  endDate?: string;
  [key: string]: any;
}

type matcherType = (
  obj: { [key: string]: any },
  key: string,
  operation: string,
  value: any,
) => boolean;

type filterElementsType = (
  elements: any[],
  periods: any[],
  visualType: 'intake' | 'continuous' | 'intermittent',
  filterRule?: FilterRuleType,
) => { filteredElements: DateRange[]; filteredPeriods: DateRange[] };

interface IBenefits {
  jobProtectionBenefits: ProcessedCalendarItem[];
  incomeProtectionBenefits: ProcessedCalendarItem[];
}

type getLeavePlanDataType = (
  calendarData: dm.Calendar[],
  visualType: 'intake' | 'continuous' | 'intermittent',
  systemId?: string,
) => {
  elements: DateRange[];
  rawElements: ProcessedCalendarItem[];
  nonOverlappingPeriods: DateRange[];
  periods: ProcessedCalendarItem[];
  benefits: IBenefits;
  dayDetails: any;
};

type getCalendarDetailsType = (
  startDate?: string,
  endDate?: string,
  leaves?: dm.Leave[],
  systemId?: string,
  isPlanId?: boolean,
) => Promise<dm.Calendar[]>;

type getBenefitDetailsType = (
  calendarData: ProcessedCalendarItem[],
) => IBenefits;

// Decide how to evaluate the rules based on the operation type
const evaluateRules = (
  elem: dm.Calendar,
  filterRule: FilterRuleType | styleRuleType,
) =>
  filterRule.rules.every((rule) => {
    if (rule.operation === 'any') {
      return rule.params.some((param) =>
        matcher(elem, param.path, param.operation, param.value),
      );
    } else {
      return rule.params.every((param) =>
        matcher(elem, param.path, param.operation, param.value),
      );
    }
  });

/**
 * Function that appends a legendKey to calendar objects based on specified rules.
 * @param rules styleRuleType[]
 * @param data dm.Calendar[]
 * @returns ProcessedCalendarItem[]
 */
export const appendStyleByRules: appendStyleByRulesType = (rules, data) => {
  const styledItems: ProcessedCalendarItem[] = [];
  data.forEach((d) => {
    const matchedStyle = rules.find((sr) => evaluateRules(d, sr));

    const styleName = matchedStyle?.name;
    if (Array.isArray(styleName)) {
      styleName.forEach((name: string) =>
        styledItems.push({
          ...d,
          legendKey: name,
        }),
      );
    } else {
      styledItems.push({ ...d, legendKey: styleName ?? '' });
    }
  });

  return styledItems.filter((sa) => sa.legendKey.length > 0);
};

/**
 * Evaluates a property in an object against a passed value according to a specified operation.
 * @param obj
 * @param key - the path to the object property
 * @param operation - includes, eq, neq
 * @param value - value to match against
 * @returns - boolean
 */
export const matcher: matcherType = (obj, key, operation, value) => {
  const result = get(obj, key);

  if (result === undefined) {
    return false;
  }

  switch (operation) {
    case 'includes':
      return result?.includes?.(value);
    case 'eq':
      return result === value;
    case 'neq':
      return result !== value;
    case 'arrayIncludes':
      return value?.includes?.(result);
    default:
      return result === value;
  }
};

/**
 * Finds the associated subcases based on a parent ID.
 * @param leaves - leaves to search
 * @param systemId - specified parentId
 * @returns - array of related ids
 */
const getRelatedCasesFromParent = (leaves?: dm.Leave[], systemId?: string) => {
  const relatedIds: Set<string> = new Set();

  if (systemId) {
    relatedIds.add(systemId);
  }

  if (leaves && leaves.length > 0) {
    leaves.forEach(
      (l) => l?.parentId === systemId && l.leaveId && relatedIds.add(l.leaveId),
    );
  }

  return [...relatedIds];
};

/**
 * Filters calendar items by specified systemIds.
 * @param calendarData
 * @param systemId
 * @returns filtered calendar array
 */
export const filterItemsById = (
  calendarData: dm.Calendar[] | ProcessedCalendarItem[],
  systemIds?: string[],
) => {
  if (!systemIds || (systemIds && systemIds.length === 0)) {
    return calendarData;
  }

  return calendarData.filter(
    (cd) => cd?.systemId && systemIds.includes(cd.systemId),
  );
};

/**
 * Groups the benefit details into job protection and income protection. In the future, the types that determine the grouping should be more abstracted
 * to allow for easier configuration.
 * @param calendarData
 * @returns grouped benefits
 */
export const getBenefitDetails: getBenefitDetailsType = (calendarData) => {
  const jobProtectionBenefits = calendarData.filter((item) =>
    [
      'potentialJobProtection',
      'approvedJPContinuous',
      'pendingJPContinuous',
      'deniedJPContinuous',
    ].includes(item.legendKey),
  );
  const incomeProtectionBenefits = calendarData.filter((item) =>
    [
      'potentialPaidBenefits',
      'paidBenefits',
      'approvedPLContinuous',
      'pendingPLContinuous',
      'deniedPLContinuous',
    ].includes(item.legendKey),
  );

  return { jobProtectionBenefits, incomeProtectionBenefits };
};

//Determines the rules for each of the calendar types
export const styleMap: { [key: string]: any } = {
  intake: draftClaimRules,
  continuous: continuousRules,
  intermittent: intermittentRules,
};

/**
 * Filter calendar elements and periods by a specified filter rule. Currently only used in Absence History to allow users to filter by status and
 * absence type.
 * @param elements
 * @param periods
 * @param visualType
 * @param filterRule
 * @returns filtered elements and periods
 */
export const filterElements: filterElementsType = (
  elements,
  periods,
  visualType,
  filterRule,
) => {
  const overlapRules = styleMap[visualType].overlapRules;

  const filtered = filterRule
    ? elements.filter((elem) => evaluateRules(elem, filterRule))
    : elements;

  return {
    filteredElements: mergeOverlappingDateRanges(filtered, overlapRules),
    filteredPeriods: mergeOverlappingDateRanges(periods, overlapRules),
  };
};

/**
 * Calls the calendar api with the start and end date if passed. If the start and end date are undefined, will attempt to call the api using the earliest
 * start and latest end date from the leave array. If unable to determine those dates, will default to 2 month prior and 2 months in the future of today.
 * Will then filter the result by the systemID if passed.
 * @param startDate
 * @param endDate
 * @param leaves
 * @param systemId
 * @param isPlanId - will pass the systemID as a header to the api if true
 * @returns calendar items filtered by date and systemID
 */
export const getCalendarDetails: getCalendarDetailsType = async (
  startDate,
  endDate,
  leaves,
  systemId,
  isPlanId,
) => {
  let result;
  if (startDate && endDate) {
    result = await getCalendarData(
      dayjs(startDate).add(-1, 'day').toISOString(),
      endDate,
      isPlanId ? systemId : undefined,
    );
  } else {
    const earliestStart =
      leaves && leaves.length > 0
        ? dayjs
            .min(leaves.map((l) => dayjs(l.startDate)))
            ?.add?.(-1, 'day')
            ?.toISOString?.()
        : dayjs().add(-2, 'month').toISOString();
    const latestEnd =
      leaves && leaves.length > 0
        ? dayjs.max(leaves.map((l) => dayjs(l.endDate)))?.toISOString?.()
        : dayjs().add(2, 'month').toISOString();

    result = await getCalendarData(earliestStart, latestEnd);
  }

  const relatedIds = getRelatedCasesFromParent(leaves, systemId);

  return filterItemsById(result as any, relatedIds);
};

const addDatesToAllDayActual = (elements: ProcessedCalendarItem[]) => {
  return elements.map((e) => {
    const hasValidStart = e?.startDate && dayjs(e.startDate).isValid();
    const isActual =
      e?.meta?.actualDate && dayjs(e?.meta?.actualDate).isValid();

    if (!hasValidStart && isActual) {
      return {
        ...e,
        startDate: e?.meta?.actualDate,
        endDate: e?.meta?.actualDate,
      };
    }

    return e;
  });
};

/**
 * Function that processes calendar data into elements, periods, and benefits to be consumed by the Generic Calendar component. Determines
 * what styles to append and rules to apply based on the calendar type.
 * @param calendarData
 * @param visualType
 * @param systemId
 * @returns
 */
export const getLeavePlanData: getLeavePlanDataType = (
  calendarData,
  visualType,
) => {
  const rules = styleMap[visualType];
  const styledElements = appendStyleByRules(rules.styleRules, calendarData);
  const periods = appendStyleByRules(rules.periodRules, calendarData);

  // Actuals recorded in Fineos set to 'all day' do not come through with a valid start and end date. Change these properties to the actual
  // date in order to handle all elements consistently in the filter and overlap rules application.
  const elements = addDatesToAllDayActual(styledElements);

  const { filteredElements, filteredPeriods } = filterElements(
    elements,
    periods,
    visualType,
    undefined,
  );

  const benefits = getBenefitDetails(elements);

  const dates: any = [];
  elements.forEach((item: any) =>
    dates.push(...createDaysFromRange(item, visualType !== 'intermittent')),
  );

  const grouped = groupByDate([...dates]);

  return {
    elements: filteredElements,
    rawElements: elements,
    nonOverlappingPeriods: filteredPeriods,
    periods,
    benefits,
    dayDetails: grouped,
  };
};

/**
 * Groups elements in an array of dates by the date.
 * @param dateArr
 * @returns
 */
export const groupByDate = (dateArr: any) => {
  return dateArr.reduce((results: any, item: any) => {
    const current = results.find((i: any) =>
      dayjs(i.date).isSame(dayjs(item.date), 'd'),
    );
    if (current) {
      current.data.push(...item.data);
    } else {
      results.push({ ...item });
    }
    return results;
  }, []);
};
