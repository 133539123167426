import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import dayjs from 'dayjs';
import Chevron from '../../../images/chevron-down-lg.png';
import { ReactComponent as HelpCircle } from '../../../images/help-circle.svg';
import CloseX from '../../../images/close-x-white.svg';
import { useHistory } from 'react-router-dom';
import { taskStatusConfigMap } from '../utils';
import { TaskType } from '..';
import { useState } from 'react';

const Container = styled.div`
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 24px 32px;
  margin-bottom: 16px;
  gap: 32px;

  @media (max-width: 676px) {
    gap: 16px;
    padding: 16px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 16px);

  @media (max-width: 676px) {
    flex-direction: column;
    align-items: start;
    gap: 8px;
  }
`;

const DueDate = styled.p`
  color: ${colors.sanmarino};
  font-family: 'Ubuntu';
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  padding: 0;
  margin: 0;

  @media (max-width: 676px) {
    font-size: 12px;
  }
`;

const Header = styled.p`
  color: ${colors.prussianblue};
  font-family: 'Ubuntu';
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin: 4px 0;

  @media (max-width: 676px) {
    font-size: 16px;
    margin-bottom: 0;
  }
`;

const SubText = styled.p`
  color: ${colors.boulder1};
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;

  @media (max-width: 676px) {
    font-size: 10px;
  }
`;

const DueContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const CategoryFlag = styled.div<{ required: boolean }>`
  background-color: ${(p) => (p.required ? '#4277A1' : '#FFFFFF')};
  border-color: #4277a1;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  font-family: ${fonts.ubuntu.bold.fontFamily};
  font-size: 10px;
  font-weight: ${fonts.ubuntu.bold.fontWeight};
  line-height: 15px;
  color: ${(p) => (p.required ? '#FFFFFF' : '#4277A1')};
  padding: 0px 2px;
  width: 32px;
  text-align: center;
`;

export const StatusFlag = styled.div<{
  background: string;
  color: string;
}>`
  background-color: ${(p) => p.background};
  border-radius: 4px;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 10px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  color: ${(p) => p.color};
  padding: 4px 0px;
  width: 120px;
  text-align: center;
`;

export const ImageContainer = styled.span<{ angle: string }>`
  img {
    transform: rotate(${(p) => p.angle});
  }
  cursor: pointer;
  position: relative;
  paddingleft: 5px;
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: 8px;
  right: 6px;
  cursor: pointer;
`;

export const StatusHelpTextBox = styled.div`
  background-color: #01344b;
  opacity: 95%;
  color: #ffffff;
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 16px 10px 10px 10px;
  cursor: default;

  position: absolute;
  width: 196px;
  height: auto;
  right: -92px;
  bottom: 30px;

  flex-direction: column;
  align-items: start;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 17px solid #01344b;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    z-index: -1;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
  }
`;

export const StatusHelpTextHeader = styled.span`
  position: absolute;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-size: 12px;
  line-height: 0px;
`;

export const StatusHelpText = styled.p`
  flex-direction: column;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  margin-top: 12px;
  margin-bottom: 0px;
  font-family: ${fonts.openSans.regular.fontFamily};
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

export const HelpCircleIcon = styled(HelpCircle)`
  margin-left: 6px;
  bottom: 1px;
`;

const TaskListItem: (p: TaskType) => React.ReactElement = (p) => {
  const statusConfig = taskStatusConfigMap?.[p.taskStatusId];

  //if task due date was null, it displays as 1/1/1970.  Blank out if this is the case

  const history = useHistory();

  const navigateToDetails = () => {
    history.push(`/app/task/${p.taskId}/details`);
  };

  const caseId =
    p.caseInfo && p.caseInfo.length
      ? p.caseInfo[0].caseId
      : p.notificationCaseId;

  const leaveReason = () => {
    // In most cases there will be only 1 subcase
    const reason = p.caseInfo[0].leaveReason;

    if (p.caseInfo.length > 1) {
      // ABS cases have a better leave reason usually than GDC
      const abs = p.caseInfo.find((t) =>
        t.caseId.toLocaleLowerCase().includes('abs'),
      );

      if (abs) {
        return abs.leaveReason;
      }
    }

    return reason;
  };

  const [help, setHelp] = useState(false);

  return (
    <Container role="list-item">
      <ContentWrapper>
        <div>
          <DueContainer>
            <CategoryFlag required={p.category.codename === 'required'}>
              {p.category.name.substring(0, 3).toUpperCase()}
            </CategoryFlag>
            {/* if task due date was null, it displays as 1/1/1970. Blank out if
            this is the case */}
            {p.taskDueDate && p.taskDueDate !== '1970-01-01T00:00:00' && (
              <DueDate>
                DUE {dayjs(p.taskDueDate).format('MMM DD, YYYY').toUpperCase()}
              </DueDate>
            )}
          </DueContainer>
          <Header>{p.taskTitle}</Header>
          <SubText>{`${leaveReason()} (${caseId})`}</SubText>
        </div>
        {statusConfig && (
          <StatusFlag
            data-testid={`task-${p.taskId}-status-flag`}
            background={statusConfig.background}
            color={statusConfig.color}
          >
            {statusConfig.text}
            {p.taskStatusHelp && (
              <ImageContainer angle="0deg">
                <HelpCircleIcon
                  id={`task-${p.taskId}-help-circle`}
                  onMouseEnter={() => setHelp(true)}
                  fill={statusConfig.color}
                  stroke={statusConfig.color}
                ></HelpCircleIcon>
                {help && (
                  <StatusHelpTextBox>
                    <CloseIcon
                      onClick={() => setHelp(false)}
                      data-testid={`task-${p.taskId}-close-icon`}
                    >
                      <img src={CloseX} height="60%" width="60%" alt="Close" />
                    </CloseIcon>
                    <StatusHelpTextHeader>HELP</StatusHelpTextHeader>
                    <StatusHelpText>{p.taskStatusHelp}</StatusHelpText>
                  </StatusHelpTextBox>
                )}
              </ImageContainer>
            )}
          </StatusFlag>
        )}
      </ContentWrapper>
      <ImageContainer angle="270deg" onClick={navigateToDetails}>
        <img
          id={`task-details-${p.taskId}-chevron`}
          src={Chevron}
          alt="Go to details"
        />
      </ImageContainer>
    </Container>
  );
};

export default TaskListItem;
