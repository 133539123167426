import React from 'react';
import { FormRenderProps } from '@progress/kendo-react-form';
import { enums, scrollApp, scrollToElementByName } from '../../../../utils';
import Button from '../../../base/button';
import { rem } from '../../../../style';
import styled from 'styled-components';
import { FormContext } from '../../../../contexts/form-context';
import ConfirmDialogModal from '../../../base/confirm-dialog-modal';
import { findFormStepIndex, onPrevClick } from '../handlers';
import { CustomStepProps, processNavigationObject } from '../utils';
import { useAutosaveDelete } from '../../../../hooks/use-data';
import { useLocation } from 'react-router-dom';

export interface IButtonsProps {
  currentStepIndex: number;
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>;
  formRenderProps: FormRenderProps;
  form: any;
  formSteps: any;
  currentStepHasLeavePlanGraph: boolean;
  submitting: boolean;
  displayConfirmationModal: boolean;
  setDisplayConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  stepGoBack: number;
  setStepGoBack: React.Dispatch<React.SetStateAction<number>>;
  steps: CustomStepProps[];
  setFormStepIndex: React.Dispatch<React.SetStateAction<number>>;
  scrollToElement: string;
  setScrollToElement: Function;
}

const ButtonContainer = styled.div`
  margin-top: ${rem(80)};
`;

const Buttons = ({
  currentStepIndex,
  setCurrentStepIndex,
  formRenderProps,
  form,
  formSteps,
  currentStepHasLeavePlanGraph,
  submitting,
  displayConfirmationModal,
  setDisplayConfirmationModal,
  stepGoBack,
  setStepGoBack,
  steps,
  setFormStepIndex,
  scrollToElement,
  setScrollToElement,
}: IButtonsProps) => {
  const { formContext, allowNavBack, allowNavForward } =
    React.useContext(FormContext);

  const isLastStep = steps.length - 1 === currentStepIndex;
  const location = useLocation();
  const { lastPath } = processNavigationObject({
    location,
    history: undefined as any,
    match: undefined as any,
  });
  const { mutate: mutateAutosaveDelete, isSuccess: autosaveDeleteSuccessful } =
    useAutosaveDelete(lastPath);

  const handleDelete = React.useCallback(
    (event) => {
      event.preventDefault();
      mutateAutosaveDelete();
    },
    [mutateAutosaveDelete],
  );

  React.useEffect(() => {
    if (autosaveDeleteSuccessful) {
      window.location.reload();
    }
  }, [autosaveDeleteSuccessful]);

  const handleConfirm = async () => {
    if (stepGoBack > -1) {
      setCurrentStepIndex(() => Math.max(stepGoBack, 0));
      setFormStepIndex(findFormStepIndex(steps[stepGoBack], formSteps));
      setStepGoBack(-1);
    } else {
      // current step index
      const csi = currentStepIndex - 1;
      setCurrentStepIndex(csi);
      setFormStepIndex(findFormStepIndex(steps[csi], formSteps));
    }

    if (scrollToElement.length > 0) {
      scrollToElementByName(scrollToElement);
      setScrollToElement('');
    } else {
      scrollApp();
    }
  };

  return (
    (form && formSteps && (
      <ButtonContainer
        id="form-buttons"
        className="d-flex justify-content-between mb-5"
      >
        <div className="tw-flex tw-gap-3">
          {currentStepIndex !== 0 ? (
            <Button
              id="form-buttons-back"
              keyId="previous"
              onClick={(e: React.SyntheticEvent<any>) =>
                onPrevClick({
                  event: e,
                  setCurrentStepIndex,
                  formContext,
                  setDisplayConfirmationModal,
                  currentStepHasLeavePlanGraph,
                  steps,
                  formSteps,
                  currentStepIndex,
                  setFormStepIndex,
                })
              }
              buttonStyle={enums.ButtonTypes.secondary}
              disabled={submitting || !allowNavBack}
            >
              Back
            </Button>
          ) : undefined}
          <Button
            id="form-buttons-reset"
            className="tw-flex tw-justify-self-start"
            onClick={handleDelete}
          >
            Start Over
          </Button>
        </div>
        <div />
        <Button
          id="form-buttons-next"
          keyId="next"
          onClick={formRenderProps.onSubmit}
          disabled={submitting || !allowNavForward}
        >
          {isLastStep ? 'Submit' : 'Next'}
        </Button>
        {displayConfirmationModal && (
          <ConfirmDialogModal
            title="You are about to reset your estimated leave plan"
            description="By returning to a previous step your estimated leave plan could be reset.  Are you sure you want to continue?"
            confirmButtonText="Yes"
            cancelButtonText="No"
            handleConfirm={handleConfirm}
            modalCallback={() => setDisplayConfirmationModal(false)}
          />
        )}
      </ButtonContainer>
    )) ||
    null
  );
};

export default Buttons;
