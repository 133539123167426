
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Formelement extends ContentItem {
    public inputlabel!: Elements.TextElement;
    public inputname!: Elements.TextElement;
    public inputtype!: Elements.LinkedItemsElement<ContentItem>;
    public tooltip!: Elements.LinkedItemsElement<ContentItem>;
}
