import { useContext } from 'react';
import { PersonDataContext } from '../../contexts/person-data-context';
import styled from 'styled-components';
import { colors, fonts } from '../../style';
import { ItemRender } from './item-renderer';
import shield from '../../images/grey-shield.svg';
import { NoDocumentsContainer } from '../base/simple-styled-components';
import ClockGraphic from '../../images/clock.svg';
import useMobileView from '../../hooks/use-mobile-view';
import { MobileItemRenderer } from './mobile-item-renderer';
import ErrorBoundary from '../../utils/error-boundary';
import { useQuery } from '@tanstack/react-query';
import { getAvailableTime } from './api';
import { Spinner } from '../base/spinner';

const Title = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 36px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  margin-bottom: 8px;
`;

const Container = styled.div`
  max-width: 1110px;
`;

type Props = {
  showMobile?: boolean;
};

const FallBack: React.FC = () => {
  return (
    <NoDocumentsContainer>
      <img src={ClockGraphic} alt="" width="179" height="148" />
      <div className="tw-mt-4 tw-italic" id="time-empty">
        We are unable to provide information regarding the amount of leave used
        or available. Please review your most recent notice(s) for details on
        your leave(s).
      </div>
    </NoDocumentsContainer>
  );
};

const AvailableTime: React.FC<Props> = ({ showMobile = false }) => {
  const isMobileView = useMobileView(1050, showMobile);
  const { personData } = useContext(PersonDataContext);

  const {
    data: entitlementPlans,
    error,
    isLoading,
  } = useQuery(['leaveAvailibility', personData], () =>
    getAvailableTime(personData),
  );

  if (error) {
    return <FallBack />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Title className="available-time-header">Available Time</Title>
      <Container id="available-time">
        {entitlementPlans && entitlementPlans.length > 0 ? (
          <ErrorBoundary fallback={<FallBack />}>
            <>
              {entitlementPlans.map((plan) => {
                return isMobileView ? (
                  <MobileItemRenderer
                    data={plan}
                    key={plan.mainEntitlement.id}
                  />
                ) : (
                  <ItemRender data={plan} key={plan.mainEntitlement.id} />
                );
              })}
              <div className="tw-flex tw-justify-center tw-my-5 tw-p-5 tw-bg-[#EAEAEA4D] tw-w-[100%] border border-[#CFCFCF] tw-rounded-md">
                {!isMobileView && (
                  <div id="shield-icon">
                    <img
                      src={shield}
                      alt="shield"
                      className="tw-mt-[14px] tw-pr-4"
                    />
                  </div>
                )}
                The leave amounts displayed are approximate. They may be
                impacted by other leave requests and may not include time
                reported in the last two business days. Please review your most
                recent notice(s) for details on your leave(s). Although time may
                be available, you must be eligible and on leave for a qualifying
                reason to use such time.
              </div>
            </>
          </ErrorBoundary>
        ) : (
          <FallBack />
        )}
      </Container>
    </>
  );
};

export default AvailableTime;
