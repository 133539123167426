import { TaskType } from '..';
import {
  getTasksConfig,
  getTaskStatusConfig,
} from '../../../utils/remote-config-manager';
import { getTasks } from '../../../utils/web-apis-client';

// TODO Move to more abstracted location
export type ApiError = {
  status: number;
  message: string;
};

export const getTasksWithKontent = async (customerNumber: string) => {
  const queryParams = {
    customerNumber: Number(customerNumber),
  };
  const totalLeaveTasks = await getTasks(queryParams);
  const tasksConfig = await getTasksConfig();
  const statusText = await getTaskStatusConfig();

  const tasksWithKontent: TaskType[] = [];
  for (const task of totalLeaveTasks.tasks) {
    const taskKontent = tasksConfig.find(
      (tc) => tc.taskCodename === task.taskCodeName,
    );

    if (taskKontent && taskKontent.taskCodename === 'll_task') {
      tasksWithKontent.push({
        ...task,
        ...taskKontent,
        taskTitle: task.taskTitle,
      });
    } else if (taskKontent) {
      tasksWithKontent.push({
        ...task,
        ...taskKontent,
      });
    }

    if (statusText) {
      const matchingText = statusText.find(
        (s) => s.taskStatusId === task.taskStatusId,
      );
      task.taskStatusHelp = matchingText?.description;
    }
  }

  return tasksWithKontent;
};
