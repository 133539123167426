import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Container,
  TitleBar,
  StyledNotification,
  ToastNotificationGroupStyle,
} from '../base/simple-styled-components';
import { colors, fonts } from '../../style';
import TaskListWrapper from './task-list-wrapper';
import * as dm from '../../data-models';
import { ITasksConfig } from '../../utils/remote-config-manager';
import ClipboardWarning from '../../images/clipboard-warning-icon.png';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import CaseDropDownListWithParentNTN from '../base/case-drop-down-list-with-parent-ntn';
import { ApiError } from './container/api';
import { useHistory } from 'react-router-dom';
import { enums } from '../../utils';

export type TaskType = dm.Task & ITasksConfig;

const DEFAULT_OPTION = 'View all leaves & claims';

const Title = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 36px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  margin-bottom: 8px;
`;

const Instructions = styled.div`
  color: ${colors.mineshaft};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin-bottom: 15px;
`;

const TitleBarMod = styled(TitleBar)`
  background-color: ${colors.alabaster2};
  border-bottom: 0;
  padding: 32px;
  @media (max-width: 576px) {
    padding: 16px;
  }
`;

const Body = styled.div`
  padding: 0 32px 48px 34px;

  @media (max-width: 576px) {
    padding: 16px;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 32px;
  margin-top: 114px;
  text-align: center;

  @media (max-width: 576px) {
    margin-top: 70px;
  }
`;

const SmallText = styled.div`
  color: ${colors.sanmarino2};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin: 0 auto;
  max-width: 532px;
  text-align: center;
`;

type Props = {
  data: TaskType[];
  tasksError: ApiError | null;
  routedViaNtn: string | undefined;
};

const Tasks = ({ data, tasksError, routedViaNtn }: Props) => {
  const history = useHistory();
  const [tasks, setTasks] = useState(
    Boolean(routedViaNtn)
      ? data.filter(
          (t) =>
            t.notificationCaseId === routedViaNtn ||
            t.caseInfo.some((c) => c.caseId === routedViaNtn),
        )
      : data,
  );
  const [displayToast, setDisplayToast] = React.useState(Boolean(tasksError));
  const toastMessage =
    'There was a problem getting your tasks. Please try again later.';
  const toastType = 'error';

  const closeToast = () => {
    setDisplayToast(false);
  };

  setTimeout(() => {
    if (displayToast) {
      closeToast();
    }
  }, 8000);

  const handleFilterByClaim = (e: DropDownListChangeEvent) => {
    const selection = e.target.value.caseId;

    if (selection === DEFAULT_OPTION) {
      history.replace(enums.AppPaths.tasks);
      setTasks(data);
    } else {
      setTasks(
        data.filter(
          (t) =>
            t.notificationCaseId === selection ||
            t.caseInfo.some((cI) => cI.caseId === selection),
        ),
      );
      history.replace(`${enums.AppPaths.tasks}/${selection}`);
    }
  };

  return (
    <div id="tasks-container">
      <ToastNotificationGroupStyle
        style={{
          top: 0,
          right: 0,
          alignItems: 'flex-end',
          position: 'fixed',
        }}
      >
        {displayToast && (
          <StyledNotification
            key="success"
            type={{ style: toastType, icon: true }}
            closable={true}
            onClose={closeToast}
          >
            {toastMessage}
          </StyledNotification>
        )}
      </ToastNotificationGroupStyle>
      <div className="col-12 col-lg-9 p-0">
        <Title className="tasks-header">Tasks</Title>
        <Instructions style={{ marginBottom: 24, maxWidth: 904 }}>
          <span>
            Your tasks list contains all the items we need from you to process
            your leave or claim.
          </span>
        </Instructions>
        <Container id={'task-container'}>
          <TitleBarMod>
            <CaseDropDownListWithParentNTN
              defaultOption={DEFAULT_OPTION}
              onChange={handleFilterByClaim}
              viewAll
              maxWidth="100%"
              routedNTNId={routedViaNtn}
            />
          </TitleBarMod>
          <Body className="position-relative">
            {tasks.length > 0 ? (
              <TaskListWrapper taskList={tasks} />
            ) : (
              <div
                id="no-due-tasks-view"
                className="d-flex flex-column items-center"
              >
                <ImageContainer>
                  <img src={ClipboardWarning} alt="" />
                </ImageContainer>
                <SmallText>
                  <i>
                    You have no tasks. Please reference the most recent letter
                    in the documents tab for the most up-to-date information.
                  </i>
                </SmallText>
              </div>
            )}
          </Body>
        </Container>
      </div>
    </div>
  );
};

export default Tasks;
