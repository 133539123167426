import styled from 'styled-components';
import { colors, fonts, rem } from '../../../../style';
import { get, prefixObjectValues, enums } from '../../../../utils';
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { FeedbackLink } from '../../simple-styled-components';

import accountIcon from '../../../../images/account-icon.svg';
import downArrow from '../../../../images/down-arrow.svg';
import verticalSeparator from '../../../../images/vertical-separator.svg';
import { ReminderMenu, ReminderMenuItem } from '../reminder-centre';

export interface AccountMenuItem {
  displayText: string;
  action: () => void;
}
export interface AccountMenuProps {
  keyId?: string;
  firstName?: string;
  lastName?: string;
  menuItems?: AccountMenuItem[];
  reminderMenuItems?: ReminderMenuItem[];
  reminderQueryStatus: 'error' | 'loading' | 'success';
}

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  clearCacheMenuItem: 'account-menu-clear-cache',
  rootNode: 'account-menu-root-node',
  signOutMenuItem: 'account-menu-sign-out',
  userNameMenuItem: 'account-menu-user-name',
};

/*******************************************************************************
 * Adds a unique prefix to the domIds to create a unique id
 * @param {string} prefix A unique prefix to add to the domIds
 * @returns {domIdsStatic} If a prefix is provided, then a copy of domIdsStatic
 * is returned with the property values modified to include the prefix. If a
 * prefix is not provided a copy of domIdsStatic is returned.
 ******************************************************************************/
const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

/*******************************************************************************
 * styles / styled components
 ******************************************************************************/

const Container = styled.div`
  width: fit-content;

  @media (max-width: 1050px) {
    display: none;
  }
`;

const itemStyle = {
  color: `${colors.warmNeutralL3}`,
  fontFamily: `${fonts.openSans.regular.fontFamily}`,
  fontWeight: fonts.openSans.regular.fontWeight,
  fontSize: `${rem(14)}`,
  marginBottom: '0',
  paddingBottom: '5px',
  paddingTop: '5px',
};

const FeedbackLinkMod = styled(FeedbackLink)`
  color: ${colors.blue};
  text-decoration: none;
`;

const Separator = styled.img`
  margin-left: 14px;
  margin-right: 14px;
`;

const MenuContainer = styled.div`
  align-items: center;
  display: flex;
`;

const MenuMod = styled(Menu)`
  .k-menu:not(.k-context-menu) {
    background-color: ${colors.coolNeutralL1};

    .k-item:focus {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
`;

/*******************************************************************************
 * Determines which action to execute when a menu item has been selected
 * @param {import('@progress/kendo-react-layout').MenuSelectEvent} e
 * @param {array} menuItems
 ******************************************************************************/
const onMenuItemSelected = (
  e: MenuSelectEvent,
  menuItems: AccountMenuProps['menuItems'],
) => {
  const displayText = get(e, 'item.text');
  const selectedItem = menuItems?.find((m) => m.displayText === displayText);
  const selectedItemAction = get(selectedItem, 'action', () => {});
  selectedItemAction();
};

/*******************************************************************************
 * Custom rendering rules for the account menu item
 * @param {*} e event data
 ******************************************************************************/
const renderAccountMenuRoot = (e: any) => {
  const { firstName, lastName, id } = get(e, 'item.data', {});

  return (
    <>
      <img
        src={accountIcon}
        alt=""
        className="d-block d-sm-none"
        width={30}
        height={30}
      />
      <img src={accountIcon} alt="" className="d-none d-sm-block" width={16} />
      <span id={id} className="mx-2 d-none d-sm-block">
        {firstName} {lastName}
      </span>
      <img src={downArrow} alt="" className="d-none d-sm-block" width={8} />
    </>
  );
};

/*******************************************************************************
 * AccountMenu component
 * @param {AccountMenu.propTypes} props AccountMenu propTypes
 ******************************************************************************/
const AccountMenu: (p: AccountMenuProps) => React.ReactElement = (props) => {
  const domIds = domIdsUnique(props.keyId);
  const {
    firstName,
    lastName,
    menuItems,
    reminderMenuItems,
    reminderQueryStatus,
  } = props;

  return (
    <Container id={domIds.rootNode}>
      <MenuContainer>
        <FeedbackLinkMod to={enums.AppPaths.feedback}>Feedback</FeedbackLinkMod>
        <Separator src={verticalSeparator} alt="" />
        <ReminderMenu
          menuItems={reminderMenuItems}
          queryStatus={reminderQueryStatus}
        ></ReminderMenu>
        <MenuMod onSelect={(e: any) => onMenuItemSelected(e, menuItems)}>
          <MenuItem
            cssStyle={{ ...itemStyle, boxShadow: 'none' }}
            data={{ firstName, lastName, id: domIds.userNameMenuItem }}
            linkRender={(p: any) => renderAccountMenuRoot(p)}
          >
            {menuItems?.map((m, i) => (
              <MenuItem cssStyle={itemStyle} text={m.displayText} key={i} />
            ))}
          </MenuItem>
        </MenuMod>
      </MenuContainer>
    </Container>
  );
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { AccountMenu, domIdsUnique };
