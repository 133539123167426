
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class EmployerHeroImage extends ContentItem {
    public tablet!: Elements.AssetsElement;
    public mobile!: Elements.AssetsElement;
    public desktop!: Elements.AssetsElement;
}
