import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import CloseIcon from '../../images/live-chat-close-icon.svg';
import MonitorIcon from '../../images/desktop-solid.svg';
import MinimizeIcon from '../../images/minimize-bar-blue.svg';
import ConfirmDialogModal from '../base/confirm-dialog-modal';
import {
  ChatMessage,
  ChatMessageProps,
} from '@progress/kendo-react-conversational-ui';
import MessageTemplate from './message-template';
import CustomMessageBox from './custom-message-box';
import {
  ChatMod,
  MainContainer,
  ButtonContainer,
  ReconnectContainer,
  CoBrowseButtonContainer,
} from './index.sc';
import {
  processMessage,
  handleOnMessage,
  getChatDetails,
  startSession,
  bot,
} from './helpers';
import { handleInitialConnectionStatusEvents } from './helpers/message-handlers';
import SecondaryButton from '../base/button';
import { ButtonTypes } from '../../utils/enums';
import { log } from '../../utils/log';
import { autoScroll } from '../../utils';
import { KenticoDataContext } from '../../contexts/kentico-data-context';

// const log = new Logger\('live-chat');
let checkIntervalInstance: NodeJS.Timeout | undefined;
let unseenAgentMessages = 0;
let workingTitle = document.title;

const CustomChatMessage = (props: ChatMessageProps) => (
  <ChatMessage {...props} dateFormat={'""'} />
);

interface LiveChatProps {
  hideShowLiveChat: Function;
  minimizeChat: Function;
  minimized: boolean;
}

const initialMessagesArray = (
  setPreChatContext: Function,
  preChatContext: { [key: string]: any },
) => {
  return [
    {
      author: { id: -1 },
      type: 'header',
    },
    {
      author: { id: -1 },
      type: 'preForm',
      setPreChatContext,
      preChatContext,
    },
  ];
};

export const onTypingCallback = (typingEvent: any, addNewMessage: Function) => {
  if (typingEvent.data.ParticipantRole === 'AGENT') {
    const typingMessage = {
      author: bot,
      typing: true,
    };
    addNewMessage({ message: typingMessage });
  }
};

export const handleNewSessionEffect = ({
  newSession,
  setShowMessageBox,
  addNewMessage,
  messages,
  setMessages,
  setShowReconnect,
  setPreChatContext,
  preChatContext,
}: any) => {
  if (newSession && newSession.controller) {
    newSession.onConnectionEstablished(() => {
      log.debug('Connection established!');
    });

    newSession.onTyping((typingEvent: any) =>
      onTypingCallback(typingEvent, addNewMessage),
    );

    newSession.onConnectionBroken(() => {
      log.debug('Connection broken');
    });

    newSession.onMessage((m: any) =>
      handleOnMessage({
        message: m,
        addNewMessage,
        messages,
        setMessages,
        setShowReconnect,
        setShowMessageBox,
        preChatContext,
      }),
    );

    //Handle existing session (Load from transcript)
    if (newSession.existingSession) {
      log.debug('Loading prior messages from transcript...');
      setMessages(initialMessagesArray(setPreChatContext, preChatContext));
      newSession
        .getTranscript({})
        .then((result: any) => {
          result.data.Transcript.forEach((t: any) =>
            processMessage(
              { data: t },
              bot,
              addNewMessage,
              setShowReconnect,
              setShowMessageBox,
              preChatContext,
              true,
            ),
          );
        })
        .catch((err: any) => {
          log.error(err);
        });

      newSession.existingSession = false;
      handleInitialConnectionStatusEvents(messages, setMessages);
    }
  }
};

type handleAddNewMessageType = (
  event: any,
  setMessages: React.Dispatch<React.SetStateAction<any>>,
  newSession: any,
  atIndex?: number,
) => void;

export const handleAddNewMessage: handleAddNewMessageType = (
  event,
  setMessages,
  newSession,
  atIndex,
) => {
  setMessages((prevState: any) => {
    if (atIndex && prevState.length > atIndex) {
      prevState[atIndex] = event.message;
      return prevState;
    }

    return [...prevState, event.message];
  });

  // only scroll to the bottom when a new message comes through
  if (!event.message.typing) {
    const messageList = document.querySelector('.k-message-list');
    autoScroll(100, messageList);
  }

  if (
    newSession &&
    !event.loadedFromTranscript &&
    event.message.author.id === 1
  ) {
    newSession.sendMessage({
      message: event.message.text,
      contentType: 'text/plain',
    });
  }
};

const closeConfirm = async (
  newSession: any,
  p: any,
  setDisplayConfirmationModal: any,
) => {
  if (newSession && newSession.disconnectParticipant) {
    newSession.disconnectParticipant();
  }

  if (checkIntervalInstance) {
    clearInterval(checkIntervalInstance);
    checkIntervalInstance = undefined;
    resetDocumentTitle();
  }

  unseenAgentMessages = 0;

  p.hideShowLiveChat();
  setDisplayConfirmationModal(false);
};

const setDocumentTitle = (newTitle: string) => {
  document.title = newTitle;
};

const resetDocumentTitle = () => {
  if (document.title.includes('New Messages')) {
    setDocumentTitle(workingTitle);
  }
};

const checkBlink = () => {
  if (document.hidden) {
    if (document.title.includes('New Messages')) {
      setDocumentTitle(workingTitle);
    } else {
      workingTitle = document.title;
      if (unseenAgentMessages) {
        setDocumentTitle(`New Messages! (${unseenAgentMessages})`);
      }
    }
  } else {
    unseenAgentMessages = 0;
    resetDocumentTitle();
  }
};

const handleBlinkingTitle = (agentMessagesLength: any) => {
  if (agentMessagesLength > 0 && document.hidden) {
    unseenAgentMessages++;
  }

  if (!checkIntervalInstance) {
    checkIntervalInstance = setInterval(() => {
      checkBlink();
    }, 1000);
  }
};

const getAgentMessages = (
  messages: any,
  setAgentMessages: React.Dispatch<React.SetStateAction<any>>,
) => {
  setAgentMessages(
    messages.filter((msg: any) => msg.author.id === 0 && !msg.typing),
  );
};

const LiveChat: (p: LiveChatProps) => React.ReactElement = (p) => {
  const user = { id: 1 };
  const objType: any = {};

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    React.useState(false);
  const [chatDetails, setChatDetails] = React.useState(objType);
  const [newSession, setNewSession] = React.useState(objType);
  const [showReconnect, setShowReconnect] = React.useState(false);
  const [preChatContext, setPreChatContext] = React.useState<{
    [key: string]: any;
  }>({});
  const [showMessageBox, setShowMessageBox] = React.useState(false);
  const { callCenter } = React.useContext(KenticoDataContext);
  const [messages, setMessages] = React.useState<any>(
    initialMessagesArray(setPreChatContext, preChatContext),
  );
  const [agentMessages, setAgentMessages] = React.useState<any[]>([]);

  React.useEffect(() => {
    getAgentMessages(messages, setAgentMessages);
  }, [messages]);

  React.useEffect(() => {
    handleBlinkingTitle(agentMessages.length);
  }, [agentMessages.length]);

  //Set the chat details for the Connect session
  React.useEffect(() => {
    getChatDetails(setChatDetails, chatDetails, preChatContext, callCenter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatDetails, callCenter.open, preChatContext]);

  //Once we have the chat details, start the Connect session
  React.useEffect(() => {
    startSession(
      chatDetails,
      setNewSession,
      messages,
      setMessages,
      handleReconnect,
      preChatContext,
      callCenter,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatDetails, callCenter.open]);

  //Once the connection is created, subscribe to the Connect events
  React.useEffect(() => {
    if (callCenter.open && preChatContext.connect) {
      handleNewSessionEffect({
        newSession,
        setShowMessageBox,
        addNewMessage,
        messages,
        setMessages,
        setShowReconnect,
        setPreChatContext,
        preChatContext,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSession, callCenter.open]);

  //Live chat handler functions
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleConfirm = async () => {
    closeConfirm(newSession, p, setDisplayConfirmationModal);
  };

  const handleChatClose = () => {
    if (callCenter.open) {
      setDisplayConfirmationModal(true);
    } else {
      handleConfirm();
    }
  };

  const handleReconnect = async (_e: any, fromFailed = false) => {
    setChatDetails({});

    if (!fromFailed) {
      const initialContext = { answers: {}, connect: false };
      setMessages(initialMessagesArray(setPreChatContext, initialContext));
      setPreChatContext(initialContext);
      setShowReconnect(false);
    } else {
      setMessages(initialMessagesArray(setPreChatContext, preChatContext));
    }
  };

  const addNewMessage = (event: any, atIndex?: number) => {
    handleAddNewMessage(event, setMessages, newSession, atIndex);
  };

  return (
    <>
      <MainContainer
        id="live-chat-container"
        className={p.minimized ? 'chat-minimized' : 'chat-visible'}
      >
        <CoBrowseButtonContainer>
          <Button
            id="live-chat-cobrowse"
            imageUrl={MonitorIcon}
            onClick={() => window.Cobrowse.modal.openModal()}
            look="flat"
            style={{ zIndex: 1 }}
          />
        </CoBrowseButtonContainer>
        <ButtonContainer>
          <Button
            id="live-chat-minimize"
            imageUrl={MinimizeIcon}
            onClick={() => p.minimizeChat()}
            look="flat"
            style={{ zIndex: 1, marginRight: 8 }}
          />
          <Button
            id="live-chat-close"
            imageUrl={CloseIcon}
            onClick={handleChatClose}
            look="flat"
            style={{ zIndex: 1 }}
          />
        </ButtonContainer>
        <ChatMod
          user={user}
          hideMessageBox={!showMessageBox}
          messages={messages}
          onMessageSend={addNewMessage}
          placeholder={'Type or ask me something'}
          messageTemplate={MessageTemplate}
          message={CustomChatMessage}
          messageBox={CustomMessageBox}
        ></ChatMod>

        {showReconnect && callCenter.open && (
          <ReconnectContainer>
            <SecondaryButton
              id="live-chat-reconnect"
              buttonStyle={ButtonTypes.secondary}
              onClick={handleReconnect}
            >
              Reconnect
            </SecondaryButton>
          </ReconnectContainer>
        )}
      </MainContainer>

      {displayConfirmationModal && (
        <ConfirmDialogModal
          title="Exit Live Chat"
          description="Closing this will disconnect your live chat session. Are you sure you want to continue?"
          confirmButtonText="Yes"
          cancelButtonText="No"
          handleConfirm={handleConfirm}
          modalCallback={hideConfirmationModal}
        />
      )}
    </>
  );
};

export default LiveChat;
