import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../style';
import { PreferencesContext } from '../../contexts/preferences-context';
import { KenticoDataContext } from '../../contexts/kentico-data-context';
import useHandlebars from '../../hooks/use-handlebars';
import { getHtmlFromMarkdown, parseJSONOrDefault, get } from '../../utils';
import AccordionCard from '../base/accordion-card';
import * as models from '../../kontent-models';

const DEFAULT_SUBHEADER =
  'The information below has been provided to Unum by your employer.* ' +
  'Questions about the content should be directed toward your Human Resources ' +
  'department.';

const DEFAULT_DISCLAIMER =
  '*For your convenience, Unum is providing you with access to certain ' +
  'policies provided to Unum by your employer.<br/><br/> Please note that the documents ' +
  'and policies provided here are not owned, created by, or managed by Unum. ' +
  'Unum makes no representations regarding the content and information ' +
  'contained in the attached policies, including whether such policies comply ' +
  'with any relevant, applicable laws or regulatory provisions. Unum does not ' +
  'endorse such policies or the content provided therein, and Unum is not ' +
  'liable or responsible in any way for damages relating to the content of ' +
  'these policies. The policies accessible here are provided for you and your ' +
  "employer's convenience only. If you have any questions or concerns " +
  'relating to these specific policies, please contact your employer ' +
  'directly. <br/><br/>By clicking on the attached policies, you acknowledge the above ' +
  'statement and will be taken to the relevant policy provided by your ' +
  'employer.';

type PriorityMessageBoxType = (p: {
  content: models.EmployerEducationalContent;
}) => React.ReactElement | null;

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1008px;
  padding: 20px 32px;

  @media (max-width: 576px) {
    padding: 25px;
  }
`;

const Title = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 28px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
`;

const PageTitle = styled(Title)`
  font-size: 36px;
  margin-bottom: 20px;
  line-height: 43px;

  @media (max-width: 360px) {
    font-size: 30px;
    margin-bottom: 18px;
    line-height: 34px;
  }
`;

const MainTitle = styled(Title)`
  font-size: 28px;
  margin-bottom: 40px;
  line-height: 32px;

  @media (max-width: 360px) {
    font-size: 24px;
    margin-bottom: 28px;
  }
`;

const PriorityMessageTitle = styled(Title)`
  font-size: 20px;
  margin-bottom: 12px;
`;

const LinkTitle = styled(Title)`
  color: ${colors.warmNeutralL3};
  font-weight: ${fonts.ubuntu.medium.fontWeight};
  line-height: 32px;
  margin-bottom: 10px;
`;

const Text = styled.div`
  color: ${colors.dovegray1};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.regular.fontWeight};

  @media (max-width: 360px) {
    font-size: 14px;
  }
`;

const Subheader = styled(Text)`
  color: ${colors.warmNeutralL3};
  margin-bottom: 28px;
  line-height: 22px;
  max-width: 71%;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  @media (max-width: 360px) {
    margin-bottom: 18px;
  }
`;

const PriorityMessage = styled.div`
  background-color: ${colors.catskillwhite};
  border-radius: 8px;
  display: flex;
  line-height: 24px;
  margin-bottom: 29px;
  margin-top: 5px;
  min-width: 280px;
  padding: 27px 33px 16px 32px;

  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 350px;
    min-width: 0;
    padding: 27px 33px 36px 32px;
    width: 100%;
  }
`;

const LinksSection = styled.div`
  background-color: ${colors.catskillwhite};
  border-radius: 8px;
  padding: 26px 24px 16px 24px;

  @media (max-width: 768px) {
    max-width: 350px;
    min-width: 0;
    width: 100%;
  }
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
  margin-top: 32px;

  @media (min-width: 769px) {
    margin-left: 32px;
    margin-top: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
`;

interface IHeroImage {
  desktop: string;
  tablet: string;
  mobile: string;
}

const HeroImage = styled.div<IHeroImage>`
  width: 100%;
  height: 400px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('${(p) => p.desktop}');
  border-radius: 8px 8px 0 0;
  background-size: cover;
  margin-bottom: 23px;

  @media (max-width: 768px) {
    background-image: url('${(p) => p.tablet}');
    height: 250px;
  }

  @media (max-width: 360px) {
    background-image: url('${(p) => p.mobile}');
    height: 200px;
    margin-bottom: 3px;
  }
`;

const ButtonAnchor = styled.a`
  align-self: center;
  padding-left: 15px;
  text-decoration: none;

  @media (max-width: 768px) {
    padding-left: 0;
    width: 100%;
  }
`;

const Button = styled.div`
  align-self: center;
  background-color: ${colors.denim};
  border: none;
  border-radius: 16px;
  padding: 5px;
  text-align: center;
  width: 100%;
  color: ${colors.white};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  min-width: 160px;

  @media (max-width: 768px) {
    min-width: 0;
  }
`;

const CustomLink = styled.a`
  color: ${colors.denim};
  display: block;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  margin: 6px 0;
  text-decoration: none;
`;

const ErrorText = styled.div`
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgb(112 112 112 / 50%);
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 18px;
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LearningHub: () => React.ReactElement = () => {
  const { ERContent } = React.useContext(PreferencesContext);
  const { localizedStrings } = React.useContext(KenticoDataContext);

  const content: models.EmployerEducationalContent = parseJSONOrDefault(
    useHandlebars(JSON.stringify(ERContent?.educationalContent), {}),
    false,
  )[0];

  return (
    <>
      {content ? (
        <>
          <PageTitle>
            {get(content, 'tabName.value', 'Employer Benefits')}
          </PageTitle>
          <Subheader
            dangerouslySetInnerHTML={getHtmlFromMarkdown(
              get(
                localizedStrings,
                'employer_benefits_subheader.translatedText',
                DEFAULT_SUBHEADER,
              ),
            )}
          />
          <PageContainer>
            <Container id="learning-hub-container">
              {get(content, 'heroImage.value.0.desktop.value.0.url', false) && (
                <HeroImage
                  id="learning-hub-image"
                  desktop={get(
                    content,
                    'heroImage.value.0.desktop.value.0.url',
                    '',
                  )}
                  tablet={get(
                    content,
                    'heroImage.value.0.tablet.value.0.url',
                    '',
                  )}
                  mobile={get(
                    content,
                    'heroImage.value.0.mobile.value.0.url',
                    '',
                  )}
                />
              )}

              <ContentContainer
                id="learning-hub-content"
                className="d-flex flex-column flex-md-row"
              >
                <div>
                  <MainTitle>
                    {get(content, 'title.value', 'Overview')}
                  </MainTitle>
                  <Text
                    style={{ marginBottom: 29 }}
                    dangerouslySetInnerHTML={getHtmlFromMarkdown(
                      get(content, 'generalMessage.value', ''),
                    )}
                  />
                  <PriorityMessageBox content={content} />

                  <MainTitle>
                    {get(content, 'cardSectionTitle.value', 'Special Benefits')}
                  </MainTitle>
                  {get(content, 'contentCards.value', []).map(
                    (card: models.EmployerContent, i: number) => (
                      <AccordionCard
                        key={`lhc-${i}`}
                        idPrefix="learning-hub"
                        titleString={get(card, 'headline.value', '')}
                        expandedMarkdown={get(card, 'content.value', '')}
                        index={i}
                      />
                    ),
                  )}
                  <Text
                    style={{
                      fontStyle: 'italic',
                      fontSize: '14px',
                      margin: '25px 0 15px 0',
                    }}
                    dangerouslySetInnerHTML={getHtmlFromMarkdown(
                      get(
                        localizedStrings,
                        'employer_benefits_disclaimer.translatedText',
                        DEFAULT_DISCLAIMER,
                      ),
                    )}
                  />
                </div>
              </ContentContainer>
            </Container>
            <Sidebar>
              {content?.linksSection?.value?.length > 0 && (
                <LinksSection id="learning-hub-links-section">
                  <LinkTitle>
                    {get(
                      content,
                      'linksSection.value.0.linksSectionHeader.value',
                      '',
                    )}
                  </LinkTitle>
                  {get(
                    content,
                    'linksSection.value.0.employerCustomLinks.value',
                    [],
                  ).map((link: models.EmployerCustomLink, i: number) => (
                    <CustomLink
                      key={`ecl-${i}`}
                      href={link.url.value || link.asset.value[0].url}
                      target="_blank"
                      rel="noreferrer"
                      data-link-type={
                        get(link, 'asset.value.0.url', false) ? 'external' : ''
                      }
                    >
                      {link.linkName.value}
                    </CustomLink>
                  ))}
                </LinksSection>
              )}
            </Sidebar>
          </PageContainer>
        </>
      ) : (
        <ErrorText id="learning-hub-error" className="p-3">
          {localizedStrings?.learning_hub_error?.translatedText ||
            "Your employer doesn't have any educational content for you to view at the moment"}
        </ErrorText>
      )}
    </>
  );
};

const PriorityMessageBox: PriorityMessageBoxType = (p) => {
  const { content } = p;

  const buttonLink = get(
    content,
    'callToActionSection.value[0].buttonLink.value',
    '',
  );
  const downloadAsset = get(
    content,
    'callToActionSection.value[0].downloadableLink.value[0].url',
    '',
  );

  const showButton = buttonLink || downloadAsset;

  if (content?.callToActionSection?.value?.length > 0) {
    return (
      <PriorityMessage id="learning-hub-priority-message">
        <div className="d-flex flex-column">
          <PriorityMessageTitle>
            {get(content, 'callToActionSection.value.0.header.value', '')}
          </PriorityMessageTitle>
          <Text
            dangerouslySetInnerHTML={getHtmlFromMarkdown(
              get(content, 'callToActionSection.value.0.description.value', ''),
            )}
          />
        </div>

        {showButton && (
          <ButtonAnchor
            id="learning-hub-priority-message-button"
            href={buttonLink || downloadAsset}
            target="_blank"
            rel="noreferrer"
            data-link-type={downloadAsset ? 'external' : ''}
          >
            <Button>
              {get(
                content,
                'callToActionSection.value.0.buttonText.value',
                'Learn more',
              )}
            </Button>
          </ButtonAnchor>
        )}
      </PriorityMessage>
    );
  } else {
    return null;
  }
};

export default LearningHub;
