import React from 'react';
import { Link } from 'react-router-dom';
import HtmlToReact from 'html-to-react';
import Documents from '../../components/documents';
import Payments from '../../components/payments';
import ClaimsCard from '../../components/base/claim-card';
import { parseJSONOrDefault, analyticsTrackEvent } from '..';
const dataAttrib = 'data-event';
//Instructions for the convertAnchorTagsToRouterLinks function. Exported for testing.
export const aTagInstruction = {
  replaceChildren: false,
  shouldProcessNode: (node: any) => {
    if (node.name === 'a') {
      const linkType = node.attribs['data-link-type'];

      return linkType && linkType === 'internal';
    }
    return false;
  },
  processNode: (node: any, children: any) => {
    const { href } = node.attribs;

    delete node.attribs.href;

    return React.createElement(Link, { to: href, ...node.attribs }, children);
  },
};

export const trackEventTag = {
  replaceChildren: false,
  shouldProcessNode: (node: any) => {
    return node.name === 'a' && dataAttrib in node.attribs;
  },
  processNode: (node: any, children: any) => {
    const props = {
      ...node.attribs,
      className: node.attribs?.class || '',
    };
    delete props.class;
    props.onClick = () =>
      analyticsTrackEvent(node.attribs[dataAttrib] || 'Link Clicked');

    return React.createElement('a', { ...props }, children);
  },
};

const actionMap: { [key: string]: () => void } = {
  chat: () => (window as any).displayLiveChat(true),
  reload: () => window.location.reload(),
};

export const convertJSTag = {
  replaceChildren: false,
  shouldProcessNode: (node: any) => {
    return node.name === 'button' && 'data-action' in node.attribs;
  },
  processNode: (node: any, children: any) => {
    const props: { [key: string]: any } = {
      className: node.attribs?.class || '',
    };

    const action = node.attribs['data-action'];

    props.onClick = () => {
      if ('data-event' in node.attribs) {
        analyticsTrackEvent(node.attribs[dataAttrib]);
      }
      actionMap[action]();
    };

    return React.createElement('button', props, children);
  },
};

export const componentMap: {
  [key: string]: any;
} = {
  Documents,
  Payments,
  ClaimsCard,
};

export const customComponentInstruction = {
  replaceChildren: false,
  shouldProcessNode: (node: any) => {
    if (node.name === 'div') {
      return node.attribs['data-custom-component'];
    }
    return false;
  },
  processNode: (node: any, children: any) => {
    const componentName = node.attribs['data-custom-component'];
    const Component = componentMap[componentName];

    const propsMap = node.attribs['data-props-map'];
    const parsedPropsMap = parseJSONOrDefault(propsMap, {});

    let parsedProps: any = {};
    if (children.length && children.length > 0) {
      parsedProps = parseJSONOrDefault(children[0], {});

      Object.keys(parsedPropsMap).forEach((key: string) => {
        const action = parsedPropsMap[key];

        switch (action) {
          case 'obj':
            parsedProps[key] = parseJSONOrDefault(parsedProps[key], {});
            break;
          case 'cases':
            const caseList = parseJSONOrDefault(parsedProps[key], {});
            parsedProps[key] = [
              ...(caseList.claims || []),
              ...(caseList.leaves || []),
            ];
            break;
        }
      });
    }

    return React.createElement(Component, parsedProps, null);
  },
};

/**
 * Parses an html string to convert all anchor tags with the data-link-type attribute equal to 'internal' to Router links to prevent browser refresh.
 * Converts html to React DOM structure.
 *
 * @param content : string;
 */
export const convertAnchorTagsToRouterLinks = (content: string) => {
  const HtmlToReactParser = HtmlToReact.Parser;

  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

  const defaultInstruction = {
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode,
  };

  const processingInstructions = [
    aTagInstruction,
    trackEventTag,
    convertJSTag,
    customComponentInstruction,
    defaultInstruction,
  ];

  const htmlToReactParser = new HtmlToReactParser();

  return htmlToReactParser.parseWithInstructions(
    content,
    () => true,
    processingInstructions,
  );
};
