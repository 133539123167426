/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { CustomSharedEntitlement } from '../../../data-models';
import ExpandIcon from '../../../images/expand-icon.svg';
import CollapseIcon from '../../../images/collapse-icon.svg';
import GreenEclipse from '../../../images/green-eclipse.svg';
import YellowEclipse from '../../../images/yellow-eclipse.svg';
import GrayEclipse from '../../../images/gray-eclipse.svg';
import ShieldIcon from '../../../images/small-shield.svg';
import ApprovedIcon from '../../../images/small-green-circle-check.svg';
import PendingIcon from '../../../images/small-yellow-circle-white-time.svg';
import { NY_PFL_PLANS, unavailableTime } from '../util';

type Props = {
  data: CustomSharedEntitlement;
};

export const MobileItemRenderer: React.FC<Props> = ({ data }) => {
  const [isExpanded, setExpanded] = useState(false);
  const { mainEntitlement, entitlements } = data;
  const approved = Math.round(
    (mainEntitlement.approvedTime / mainEntitlement.timeEntitlement) * 100,
  );
  const pending = Math.round(
    (mainEntitlement.pendingTime / mainEntitlement.timeEntitlement) * 100,
  );

  const getIcon = () => {
    return !isExpanded ? ExpandIcon : CollapseIcon;
  };

  const TW_ROUNDED_MD = 'tw-rounded-md';
  const TW_ROUNDED_L_MD = 'tw-rounded-l-md';
  const getApprovedRoundess = () => {
    return mainEntitlement.approvedTime === mainEntitlement.timeEntitlement
      ? TW_ROUNDED_MD
      : TW_ROUNDED_L_MD;
  };

  const ApprovedLine: React.FC = () => {
    if (approved) {
      return (
        <div
          className={`tw-bg-unm-approved ${getApprovedRoundess()}`}
          id="approved-bar"
          style={{
            width: `${approved}%`,
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  const PendingLine: React.FC = () => {
    if (pending) {
      return (
        <div
          id="pending-bar"
          className={`tw-bg-unm-pending ${
            mainEntitlement.approvedTime > 0 &&
            mainEntitlement.pendingTime + mainEntitlement.approvedTime ===
              mainEntitlement.timeEntitlement
              ? 'tw-rounded-r-md'
              : mainEntitlement.pendingTime === mainEntitlement.timeEntitlement
              ? TW_ROUNDED_MD
              : approved === 0
              ? TW_ROUNDED_L_MD
              : ''
          }`}
          style={{
            width: `${pending}%`,
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <div
      id={`item-container-${mainEntitlement.id}`}
      className="tw-mt-4 tw-pt-3 tw-px-3 tw-pb-3 tw-shadow-basic tw-rounded-md  tw-text-sm tw-text-unm-primary02-700"
      style={{
        borderBottom: entitlements.length > 0 ? '2px solid #4FA3CD' : '',
      }}
    >
      <div
        className="tw-h-[140px] tw-font-[600]"
        id="expand-container"
        onClick={() => setExpanded(!isExpanded)}
      >
        {unavailableTime(mainEntitlement) ? (
          <div id="unavailable-time-tooltip" className="tw-flex">
            <span className="tw-italic tw-pr-2 tw-text-center tw-text-xl tw-font-[600]">
              <div className="tw-text-sm tw-text-unm-primary02-700">
                {mainEntitlement.name}
              </div>
              <div>Not available</div>
            </span>
          </div>
        ) : (
          <>
            <div className="tw-flex">
              <div className="tw-flex tw-w-full">
                <div className="tw-w-11/12">
                  <div>{mainEntitlement.name}</div>
                  <div>
                    <img src={GrayEclipse} alt="gray eclilpse" />{' '}
                    {mainEntitlement.timeEntitlement}{' '}
                    {mainEntitlement.timeBasis} in{' '}
                    {mainEntitlement.timeWithinPeriod}{' '}
                    {mainEntitlement.timeWithinPeriodBasis} Maximum
                  </div>
                </div>
                <div className="tw-w-1/12">
                  {entitlements.length > 0 && (
                    <img src={getIcon()} alt="collapse icon" />
                  )}
                </div>
              </div>
            </div>
            <div className="tw-mt-2 tw-w-full tw-bg-unm-adhoc4 tw-h-3 border tw-rounded-md tw-flex">
              <ApprovedLine />
              <PendingLine />
            </div>
            <div className="tw-flex tw-w-full tw-mt-2 tw-text-xs">
              <div className="tw-w-1/2">
                <div className="tw-flex tw-w-full">
                  <div className="tw-w-1/2">
                    <img src={GreenEclipse} alt="green eclilpse" /> Approved:
                  </div>
                  <div className="tw-w-1/2">
                    {mainEntitlement.approvedTime} {mainEntitlement.timeBasis}
                  </div>
                </div>
                <div className="tw-flex tw-w-full tw-mt-2">
                  <div className="tw-w-1/2">
                    <img src={YellowEclipse} alt="yellow eclilpse" /> Pending:
                  </div>
                  <div className="tw-w-1/2">
                    {mainEntitlement.pendingTime} {mainEntitlement.timeBasis}
                  </div>
                </div>
              </div>
              <div className="tw-w-1/2 tw-text-[10px] tw-font-normal">
                <div>Time available as of {mainEntitlement.periodEndDate}</div>
                <div>{mainEntitlement.calculationPeriod}</div>
              </div>
            </div>
          </>
        )}
      </div>
      {NY_PFL_PLANS.find((e) => e === mainEntitlement.name) && (
        <div
          id="ny-plans-info"
          className="tw-text-[10px] tw-leading-tight tw-italic tw-w-80 border tw-rounded-md tw-p-2 tw-mb-2"
        >
          The leave amounts displayed are approximate. They may be impacted by
          other leave requests and may not include time reported in the last two
          business days. Although time may be available, an employee must be
          eligible and on leave for a qualifying reason to use such time. In
          addition, under NY DBL/PFL, employees cannot take more than a combined
          total of 26 weeks of family and disability leave in a benefit year.
        </div>
      )}
      {entitlements.length > 0 && isExpanded && (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-bg-[#F5F5F566]">
          <div
            id="disclaimer"
            className="tw-text-[10px] tw-leading-tight tw-italic tw-w-80 border tw-rounded-md tw-p-2"
          >
            This is a shared plan. The plans below each have an individual plan
            entitlement, however an eligible employee cannot take more than the
            maximum combined plan entitlement as indicated above.
          </div>
          <div className="tw-flex tw-flex-col tw-justify-center tw-w-[90%] tw-pb-4">
            {entitlements.map((entitlement) => (
              <div
                id={`item-container-${entitlement.id}`}
                className="tw-w-full tw-p-4"
                key={entitlement.id}
              >
                <div id="entitlement-name" className="tw-font-[600]">
                  {entitlement.name}
                </div>
                <div id="entitlement-information" className="tw-text-xs">
                  <div id="item-maximum" className="tw-flex tw-mt-1">
                    <div className="tw-text-center">
                      <img src={ShieldIcon} alt="shield" />
                    </div>
                    <div className="tw-mt-[2px] tw-ml-2">
                      {entitlement.timeEntitlement} {entitlement.timeBasis} Plan
                      Entitlement
                    </div>
                  </div>
                  <div id="item-approved" className="tw-flex tw-mt-1">
                    <div className="">
                      <img src={ApprovedIcon} alt="approved" />
                    </div>
                    <div className="tw-ml-2">
                      {entitlement.approvedTime} {entitlement.timeBasis}{' '}
                      Approved
                    </div>
                  </div>
                  <div id="item-pending" className="tw-flex tw-mt-1">
                    <div className="">
                      <img src={PendingIcon} alt="pending" />
                    </div>
                    <div className="tw-ml-2">
                      {entitlement.pendingTime} {entitlement.timeBasis} Pending
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
