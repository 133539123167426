
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class PersonaBasedText extends ContentItem {
    public persona!: Elements.TaxonomyElement;
    public text!: Elements.CustomElement;
}
