import styled from 'styled-components';
import { get, prefixObjectValues } from '../../../../utils';
import {
  OpenSansRegularValueMod,
  OpenSansSemiBoldLabelMod,
} from '../../index.sc';
import { colors } from '../../../../style';

const templateIds = {
  address: 'check-preference-address',
  somethingNotRightButton: 'check-preference-something-not-right-button',
  infoMessage: 'check-preference-info-message',
};

const MailingAddressContainer = styled.div`
  background-color: #dae4e9;
  padding: 16px;
`;

const AddressDisplay = styled(OpenSansSemiBoldLabelMod)`
  margin-left: 34px;
  width: fit-content;
`;

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, templateIds);

const CheckPreferenceOptions = (props: any) => {
  const automationIds = domIdsUnique(props.keyId);

  return (
    <MailingAddressContainer>
      <OpenSansRegularValueMod className="mb-2" color={colors.warmNeutralL3}>
        The mailing address we have on file is:
      </OpenSansRegularValueMod>
      <AddressDisplay className="mt-1 mb-3 " id={automationIds.address}>
        {get(props, 'address.addressLine1', '') + ', '}
        {get(props, 'address.addressLine2', '')}
        {get(props, 'address.addressLine2', '') && (
          <>
            , <br />
          </>
        )}
        {get(props, 'address.addressLine3', '')}
        {get(props, 'address.addressLine3', '') && (
          <>
            , <br />
          </>
        )}
        {get(props, 'address.city', '')}
        {get(props, 'address.city', '') && <>, </>}
        {get(props, 'address.state', '')}

        {get(props, 'address.state', '') && (
          <>
            , <br />
          </>
        )}
        {get(props, 'address.zip', '')}
        <br />
      </AddressDisplay>
      <OpenSansRegularValueMod
        className="mb-3 mt-2"
        color={colors.warmNeutralL3}
        id={automationIds.infoMessage}
      >
        Your address is provided by your employer. Please contact them to
        request a change of address. If you need to provide a temporary address,
        reach out to a representative to let them know.
      </OpenSansRegularValueMod>
    </MailingAddressContainer>
  );
};

export { CheckPreferenceOptions };
