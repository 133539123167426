import { ContentItem, Elements } from '@kentico/kontent-delivery';
import { FormField } from './form_field';
import { FormText } from './form_text';
import { FormButton } from './form_button';
import { NextStep } from './next_step';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class FormStep extends ContentItem {
  public label?: Elements.TextElement;
  public icon?: Elements.AssetsElement;
  public flags!: Elements.MultipleChoiceElement;
  public optionsTemplate?: Elements.TextElement;
  public sectionFieldNamePrefix?: Elements.TextElement;
  public sections!: Elements.LinkedItemsElement<
    FormField | FormText | FormButton | NextStep
  >;
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'options_template') {
          return 'optionsTemplate';
        }
        if (elementName === 'section_field_name_prefix') {
          return 'sectionFieldNamePrefix';
        }
        return elementName;
      },
    });
  }
}
