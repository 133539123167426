import styled from 'styled-components';
import { colors, fonts, rem } from '../../../style';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import closeIcon from '../../../images/modal-x-lg.svg';
import downloadIcon from '../../../images/download-icon.svg';
import marked from 'marked';

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  titlebar: 'information-dialog-titlebar',
  downloadIcon: 'modal-download-icon',
  title: 'modal_title',
  image: 'modal_image',
  markdown: 'modal_markdown',
  button: 'common-modal-confirm-button',
};

/*******************************************************************************
 * styles / styled components
 ******************************************************************************/

interface StyleProps {
  small?: boolean;
}

const DialogMod = styled(Dialog)`
  .k-window {
    border: 'none';
    border-radius: 0;
  }
  .k-dialog {
    height: 100%;
    max-width: 1240px;
  }
  .k-window-content {
    background-color: ${colors.background};
  }
  .k-window-titlebar {
    background-color: ${colors.background};
    height: 45px;
  }
  .k-dialog-close .k-icon.k-i-x::before {
    content: url(${closeIcon});
  }
  &.no-border .k-window-titlebar {
    border: 'none';
    padding: 10px 10px 0;
  }
  &.rounded .k-dialog-content {
    border-radius: 0 0 5px 5px;
  }
  &.rounded .k-dialog {
    border-radius: 5px;
  }
`;

const CustomScrollBar = styled.div`
  /* width */
  ::-webkit-scrollbar {
    width: 16px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #dfe7ec;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #5fbde3;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #5fbde3;
  }
`;

const InfoDialogMod = styled(DialogMod)<StyleProps>`
  left: 0;
  margin: auto;
  margin-top: ${(props) => (props.small ? '90px' : '0')};
  max-height: ${(props) => (props.small ? '320px' : '90%')};
  max-width: ${(props) => (props.small ? '557px' : '1000px')};
  padding: '0px';
  pointer-events: 'all';
  right: 0;
  width: 90%;

  @media (max-width: 500px) {
    max-height: ${(props) => (props.small ? '370px' : '90%')};
  }

  @media (max-width: 375px) {
    max-height: ${(props) => (props.small ? '410px' : '90%')};
  }

  .k-window {
    border-radius: 5px;

    @media (max-width: 600px) {
      max-width: 450px;
    }
  }
  .k-window-content {
    background-color: ${colors.white};
    border-radius: 0 0 5px 5px;
  }
  .k-window-titlebar {
    height: 48px;
  }
`;

const ContainerStyled = styled.div<StyleProps>`
  height: 100%;
  padding: ${(props) => (props.small ? '1rem 0 0 1rem' : '1rem 0 1rem 1rem')};
`;

const TitleStyled = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.ubuntu.semibold.fontFamily};
  font-size: ${rem(18)};
  font-weight: bold;
  margin-bottom: 20px;
`;

const BodyStyled = styled.div`
  color: ${colors.boulder};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(15)};
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

const InnerContainer = styled(CustomScrollBar)<StyleProps>`
  height: 100%;
  max-height: 650px;
  overflow-y: auto;
  padding: 10px 10px 10px 0;
  padding: ${(props) => (props.small ? '10px 5px 0 0' : '10px 10px 10px 0')};

  @media (min-width: 768px) {
    padding: ${(props) => (props.small ? '10px 5px 0 0' : '14px 50px;')};
  }

  /* width */
  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
`;

const DefaultButtonStyled = styled(Button)`
  background: ${colors.primaryBlue};
  border: 'none';
  border-radius: 8px;
  color: ${colors.white};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  min-width: 191px;
  padding: 10px;
  pointer-events: all;
  text-align: center;

  &:hover {
    background: ${colors.skyD2};
    border-color: ${colors.skyD2};
    color: #fff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

/*******************************************************************************
 * CommonModal component
 * @param {CommonModalProps} props CommonModal propTypes
 ******************************************************************************/
const CommonModal: (p: CommonModalProps) => React.ReactElement = (props) => {
  const { modalCallback, markdown, modalDownloadURL } = props;

  const modalId = props.modalId ? props.modalId : 'common-modal';

  const parser = marked.setOptions({});
  const parsedMessage = parser.parse(markdown);
  const htmlToinsert = { __html: parsedMessage };

  return (
    <InfoDialogMod
      id={modalId}
      title={
        <div id={domIdsStatic.titlebar}>
          {modalDownloadURL !== 'false' && modalDownloadURL !== '' && (
            <a
              id={domIdsStatic.downloadIcon}
              href={modalDownloadURL}
              target="_blank"
              rel="noopener noreferrer"
              download={props.title}
            >
              <img src={downloadIcon} alt="Download icon" />
            </a>
          )}
        </div>
      }
      small={props.small}
      onClose={modalCallback}
    >
      <ContainerStyled>
        <InnerContainer small={props.small}>
          {props.title && props.title !== '' && (
            <TitleStyled id={domIdsStatic.title}>{props.title}</TitleStyled>
          )}
          <BodyStyled
            id={domIdsStatic.markdown}
            dangerouslySetInnerHTML={htmlToinsert}
          />
          {props.confirmButtonText && (
            <ButtonContainer>
              <DefaultButtonStyled
                id={domIdsStatic.button}
                onClick={modalCallback}
                style={{ textAlign: 'center' }}
              >
                {props.confirmButtonText}
              </DefaultButtonStyled>
            </ButtonContainer>
          )}
        </InnerContainer>
      </ContainerStyled>
    </InfoDialogMod>
  );
};

/*******************************************************************************
 * prop types
 ******************************************************************************/
interface CommonModalProps {
  modalCallback: any;
  title?: string;
  markdown: string;
  confirmButtonText?: string;
  small?: boolean;
  modalId?: string;

  //Include if content to download
  modalDownloadURL?: string;
}

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { CommonModal };
