import styled from 'styled-components';
import { fonts } from '../../../style';
import RedX from '../../../images/red-x-icon.png';
import GreenCheck from '../../../images/green-checkmark.png';
import { formatBytes, enums, get } from '../../../utils';
import { Dialog, Text, Container, Button, UploadListItem } from '../index.sc';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import React from 'react';
import { IUploadText } from '..';

interface StatusProps {
  keyId?: string;
  files?: any;
  modalCallback: (result?: enums.UploadStatus) => void;
  text?: IUploadText;
}

const Header = styled.div`
  color: ${(p) => p.color};
  font-family: ${fonts.ubuntu.bold.fontFamily};
  font-size: 32px;
  font-weight: ${fonts.ubuntu.bold.fontWeight};
  margin-bottom: 25px;

  @media (max-width: 576px) {
    font-size: 26px;
  }

  span {
    font-size: 32px;

    @media (max-width: 576px) {
      font-size: 26px;
    }
  }
`;

const NoteText = styled.div`
  color: ${(p) => p.color};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 18px;
  font-weight: ${fonts.openSans.regular.fontWeight};

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const StatusContainer = styled.div`
  margin-bottom: 60px;

  @media (max-width: 576px) {
    margin-bottom: 36px;
  }
`;

const Status: (p: StatusProps) => React.ReactElement = (p) => {
  const { files } = p;

  const calculateUploadStatus = React.useCallback(() => {
    let status = enums.UploadStatus.allSuccessful;

    const errorCount = files.filter((f: any) => f.error).length;

    if (errorCount > 0) {
      status =
        errorCount === files.length
          ? enums.UploadStatus.allErrors
          : enums.UploadStatus.someErrors;
    }

    return status;
  }, [files]);

  return (
    <Dialog
      title={'Upload Your Document'}
      onClose={() => p.modalCallback(calculateUploadStatus())}
    >
      <Container className="mb-3">
        {getStatusText(calculateUploadStatus())}

        {p.files.map((file: any, i: number) => (
          <UploadListItem
            className="col-12 flex-column flex-md-row"
            style={{ padding: '10px 0 19px', margin: 0 }}
            key={`status-item-${i}`}
          >
            <div className="d-flex col-12 col-md-8 p-0">
              <Text className="col-6 col-md-6 p-0 noOverflow">{file.name}</Text>
              <Text className="col-6 col-md-6 p-0 text-right text-md-left align-self-center">
                {formatBytes(file.size)}
              </Text>
            </div>
            <div className="d-flex col-12 col-md-4 p-0 mt-4 mt-md-0">
              <Text className="col-6 col-md-8 p-0 text-left text-md-right">
                {file.sent ? 'Complete' : 'Failed'}
              </Text>
              <div className="col-6 col-md-4 p-0 text-right">
                <img src={file.sent ? GreenCheck : RedX} alt="" />
              </div>
            </div>
          </UploadListItem>
        ))}
      </Container>
      <DialogActionsBar>
        <div className="d-flex justify-content-end">
          <Button
            outline
            onClick={() => {
              p.modalCallback(calculateUploadStatus());
            }}
          >
            {get(p, 'text.modalCloseButton', 'Cancel')}
          </Button>
          <Button disabled>Upload</Button>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
};

const getStatusText = (status: any, text?: IUploadText) => {
  switch (status) {
    case enums.UploadStatus.allSuccessful:
      return (
        <StatusContainer>
          <Header color="#04A874">
            <span className="k-icon k-i-check-circle" />
            &nbsp;Upload successful, thank you
          </Header>
          <NoteText color="#525252">
            {text?.modalSuccessDesc || (
              <>
                Our team will review your document and send you a response{' '}
                <strong>within the next 1-5 business days.</strong>
                <br />
                <br />
                <strong>
                  There is no need to contact us regarding this upload
                </strong>{' '}
                until you receive our response.
              </>
            )}
          </NoteText>
        </StatusContainer>
      );
    case enums.UploadStatus.someErrors:
      return (
        <StatusContainer>
          <Header color="#EA8705">
            <span className="k-icon k-i-warning" />
            &nbsp;Some documents did not upload
          </Header>
          <NoteText color="#525252">
            {text?.modalSomeErrorDesc || (
              <>
                Unfortunately not all documents uploaded successfully. Please
                review the documents below and re-upload those which were
                unsuccessful.
                <br />
                <br />
                If this problem persists please contact our help center at the
                bottom right of your screen.
              </>
            )}
          </NoteText>
        </StatusContainer>
      );
    case enums.UploadStatus.allErrors:
      return (
        <StatusContainer>
          <Header color="#D10000">
            <span className="k-icon k-i-warning" />
            &nbsp;Your documents did not upload
          </Header>
          <NoteText color="#525252">
            {text?.modalAllErrorDesc || (
              <>
                Unfortunately your document(s) did not upload successfully.{' '}
                <strong>Please try uploading again.</strong>
                <br />
                <br />
                If this problem persists please contact our help center at the
                bottom right of your screen.
              </>
            )}
          </NoteText>
        </StatusContainer>
      );
    default:
      return <></>;
  }
};

export default Status;
