import styled from 'styled-components';
import { getHtmlFromMarkdown, prefixObjectValues } from '../../../../utils';
import { IFormContext } from '../../../../contexts/form-context';
import { ISpecialBenefit } from '../../../../utils/remote-config-manager';
import { colors, fonts } from '../../../../style';
import useHandlebars from '../../../../hooks/use-handlebars';

interface IERExpandedSectionItem {
  keyId?: string;
  item: ISpecialBenefit;
  formContext: IFormContext;
  formValueGetter: (name: string) => any;
}

const EmployerCardContainer = styled.div`
  background-color: white;
  width: 100%;
  max-width: 900px;
  border-radius: 8px;
  border: 1px solid ${colors.alto2};
  display: flex;
  margin-bottom: 16px;

  @media only screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

const EmployerCardTitle = styled.div`
  display: block;
  color: ${colors.prussianblue};
  font-family: ${fonts.openSans.bold.fontFamily};
  font-size: 18px;
  font-weight: ${fonts.openSans.bold.fontWeight};
  line-height: 24px;
  margin-bottom: 0.4rem;

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`;

const EmployerCardImage = styled.div`
  width: 28%;
  border-radius: 8px 0px 0 8px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 650px) {
    width: 100%;
    height: 150px;
    border-radius: 8px 8px 0 0;
  }
`;

const EmployerCardContent = styled.div`
  padding: 24px;
  max-width: 72%;

  p:last-child {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 650px) {
    max-width: 100%;
    padding: 19px 24px 24px;

    p {
      font-size: 14px;
    }
  }
`;

const EmployerCardText = styled.div`
  margin-top: 6px;
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  line-height: 24px;
  overflow-wrap: break-word;
  word-break: break-word;

  &:last-child {
    margin-bottom: 0;
  }
`;

const domIdsStatic = {
  benefit: 'er-benefit',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const EmployerExpandedSectionItem: (
  p: IERExpandedSectionItem,
) => React.ReactElement = (p) => {
  const domIds = domIdsUnique(p.keyId);
  const { item, formContext, formValueGetter } = p;

  const markdown = useHandlebars(item.content, formContext, formValueGetter);

  //Employer Cards

  return (
    <EmployerCardContainer id={domIds.benefit}>
      <EmployerCardImage
        style={{ backgroundImage: `url('${item?.thumbnail?.url}')` }}
      />
      <EmployerCardContent>
        <EmployerCardTitle>{item.headline}</EmployerCardTitle>
        <EmployerCardText
          dangerouslySetInnerHTML={getHtmlFromMarkdown(markdown)}
        />
      </EmployerCardContent>
    </EmployerCardContainer>
  );
};

export default EmployerExpandedSectionItem;
