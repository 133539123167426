import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import CloseX from '../../../images/close-x-white.svg';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import LeaveImage from '../../../images/leave-calendar/leave-visual-icon.svg';
import { Legend } from '../../generic-calendar';
import { enums } from '../../../utils';

const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid white;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;

  @media (max-width: 1279px) {
    justify-content: start;
  }
`;

const ModalWindow = styled.div`
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${colors.alto2};
  width: 100%;
  min-height: 458px;
  display: flex;
  max-width: 568px;
  flex-direction: column;
`;

const GuideBody = styled.div`
  padding: 16px;
`;

const TitleBar = styled.div`
  background-color: ${colors.denim};
  border-radius: 4px 4px 0 0;
  display: flex;
  position: relative;
  justify-content: center;
  color: white;
  width: 100%;
  padding: 5px;
  min-height: 48px;
  align-items: center;
`;

const RegularText = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.medium.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.medium.fontWeight};
  max-width: 264px;
  line-height: 22px;
  text-align: center;
  margin-top: 11px;
`;

const InstructionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 42px 0;
  width: 100%;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
`;

const GuideModal = (p: {
  legend: any;
  hideUnusedStyles?: boolean;
  stylesVisibleOnCalendar?: any[];
}) => {
  const [displayGuide, setDisplayGuide] = React.useState(false);

  React.useEffect(() => {
    const hasDimissedGuide = localStorage.getItem(
      enums.CacheKeys.dimissedCalendarGuide,
    );
    setDisplayGuide(!hasDimissedGuide);
  }, []);

  const toggleGuide = () => {
    setDisplayGuide(!displayGuide);
    localStorage.setItem(enums.CacheKeys.dimissedCalendarGuide, 'true');
  };

  return displayGuide ? (
    <Container id="calendar-guide-modal">
      <ModalWindow>
        <TitleBar>
          LEAVE CALENDAR
          <ButtonContainer>
            <KendoButton
              id="calendar-guide-close-x"
              imageUrl={CloseX}
              onClick={toggleGuide}
              look="flat"
            />
          </ButtonContainer>
        </TitleBar>
        <GuideBody>
          <InstructionContainer>
            <img src={LeaveImage} alt="" width="130" height="71" />
            <RegularText>
              Interact with the calendar and key dates to view more details.
            </RegularText>
          </InstructionContainer>
          <Legend
            styles={p.legend}
            expandable={false}
            hideUnusedStyles={p.hideUnusedStyles}
            stylesVisibleOnCalendar={p.stylesVisibleOnCalendar}
          />
        </GuideBody>
      </ModalWindow>
    </Container>
  ) : null;
};

export default GuideModal;
