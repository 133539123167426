import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import TaskListItem from '../task-list-item';
import { PageChangeEvent } from '@progress/kendo-react-data-tools';
import PagerSection from '../../base/pager-section';
import FilterDropDown, { OptionType } from '../../base/filter-drop-down';
import { TaskType } from '..';
import * as handlers from '../utils';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import ClipboardWarning from '../../../images/clipboard-warning-icon.png';

interface TaskListProps {
  keyId?: string;
  taskList: TaskType[];
  categoryFilter: string;
}

const DEFAULT_FILTER = 'View all';

const DEFAULT_OPTION = {
  option: 'View all',
  fieldType: 'Group 0',
  value: 'viewAll',
};

const FilterDropDownContainer = styled.div`
  margin: 32px 0;

  @media (max-width: 576px) {
    margin: 24px 0;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 32px;
  margin-top: 114px;
  text-align: center;

  @media (max-width: 576px) {
    margin-top: 70px;
  }
`;

const SmallText = styled.div`
  color: ${colors.sanmarino2};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin: 0 auto;
  max-width: 532px;
  text-align: center;
`;

const TaskList: (p: TaskListProps) => React.ReactElement = (p) => {
  const { taskList, categoryFilter } = p;
  const [filter, setFilter] = React.useState<OptionType>(DEFAULT_OPTION);
  const [filteredTasks, setFilteredTasks] = React.useState<TaskType[]>([]);
  const [page, setPage] = React.useState({
    skip: 0,
    take: 5,
  });

  const filterItems = (filterAction: string) => {
    setFilteredTasks(handlers.actionMap[filterAction](taskList));
    setPage({ skip: 0, take: 5 });
  };

  const handleFilterByProperty = (e: ComboBoxChangeEvent) => {
    setFilter(e?.value);
    filterItems(e?.value?.value);
  };

  const handlePageChange = (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
  };

  React.useEffect(() => {
    setFilter(DEFAULT_OPTION);
    filterItems('viewAll');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskList]);

  const { skip, take } = page; //Used for Pagination

  return (
    <div>
      <FilterDropDownContainer>
        <FilterDropDown
          keyId="tasks-"
          options={handlers.filterOptionMap[categoryFilter]}
          onChange={handleFilterByProperty}
          value={filter}
          defaultValue={DEFAULT_FILTER}
        />
      </FilterDropDownContainer>
      {filteredTasks.length === 0 && (
        <div className="d-flex flex-column items-center">
          <ImageContainer>
            <img src={ClipboardWarning} alt="" />
          </ImageContainer>
          <SmallText>
            <i>You have no tasks matching the applied filter</i>
          </SmallText>
        </div>
      )}
      <div role="list">
        {filteredTasks.slice(skip, skip + take).map((task) => (
          <TaskListItem key={`task-item-${task.taskId}`} {...task} />
        ))}
      </div>
      <PagerSection
        skip={skip}
        take={take}
        handlePageChange={handlePageChange}
        elements={filteredTasks}
      />
    </div>
  );
};

export default TaskList;
