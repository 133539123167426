import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import { parseJSONOrDefault } from '../../../utils';
import * as dm from '../../../data-models';
import * as enums from '../../../utils/enums';
import WarningIcon from '../../../images/warning-icon.png';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';

interface AbsenceCardProps {
  index: number;
  legendKey: string;
  legend: any;
  [key: string]: any;
}

type CategoryMapType = {
  [category: string]: {
    name: string;
    order: number;
  };
};

const DEFAULT_STATUS = 'Unknown';

const TimeText = styled.div`
  color: ${colors.boulder1};
  display: inline;
  margin-left: 5px;
  line-height: 22px;
`;

const PlanContainer = styled.div`
  align-items: center;
  border-bottom: 1px dashed ${colors.alto2};
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-bottom: 20px;

  :last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  > div {
    flex: 1 1 25%;
    line-height: 19px;

    @media (max-width: 700px) {
      flex: 1 1 100%;
      margin-top: 5px;
      text-align: left !important;
    }
  }

  > div:first-child {
    flex: 1 1 50%;
  }
`;

const LeaveCategory = styled.div`
  color: ${colors.sanmarino};
  font-family: ${fonts.ubuntu.medium.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.ubuntu.medium.fontWeight};
  line-height: 22px;
  margin-bottom: 13px;
`;

const CategoryContainer = styled.div`
  border-bottom: 1px solid ${colors.alto2};
  margin-bottom: 22px;
`;

const defaultCategoryMap: CategoryMapType = {
  'Non-Paid': { name: 'JOB PROTECTION', order: 1 },
  Paid: { name: 'INCOME PROTECTION', order: 2 },
  default: { name: 'NOT JOB/INCOME PROTECTION', order: 3 },
};

const getCategoryDisplayName = (
  category: string | undefined,
  map: CategoryMapType,
) => {
  if (category && category in map) {
    return map[category];
  }

  return map['default'];
};

const groupDecisions = (
  decisions: dm.AbsenceDecision[],
  map: CategoryMapType,
) =>
  decisions.reduce((arr: any, decision: dm.AbsenceDecision) => {
    const { name, order } = getCategoryDisplayName(decision.leaveCategory, map);

    if (
      decision.adjudicationStatus === enums.AbsenceStatuses.accepted ||
      decision.adjudicationStatus === enums.AbsenceStatuses.approved
    ) {
      arr[name] = arr[name] || { order, decisions: [] };
      arr[name].decisions.push(decision);
    }

    return arr;
  }, {});

const LeavePlanList: (p: AbsenceCardProps) => React.ReactElement | null = (
  p,
) => {
  const { config } = React.useContext(KenticoDataContext);
  const [showList, setShowList] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (p.status && p.decisions) {
      setShowList(
        p.legendKey in p.legend &&
          p.legend[p.legendKey].showDecisions &&
          p.decisions.length > 0,
      );
    }
  }, [p]);

  if (showList) {
    const categoryKenticoMap: CategoryMapType = parseJSONOrDefault(
      config['absence_plan_category_map'],
      defaultCategoryMap,
    );

    const groupedDecisions = groupDecisions(
      p.decisions || [],
      categoryKenticoMap,
    );

    return (
      <div className="decision-list">
        {!p.hasDecisionError ? (
          <div>
            {Object.entries(groupedDecisions)
              ?.sort(([, a]: any, [, b]: any) => a.order - b.order)
              .map((category: any, categoryIndex: number) => (
                <CategoryContainer key={`${p.index}_${categoryIndex}_category`}>
                  <LeaveCategory>{category[0]}</LeaveCategory>
                  {category[1]?.decisions.map(
                    (d: dm.AbsenceDecision, planIndex: number) => (
                      <PlanContainer key={`${p.index}_${planIndex}_plan`}>
                        <div>
                          PLAN: <TimeText>{d.leavePlanName}</TimeText>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                          TIME: <TimeText>{d.timeRequested} Hours</TimeText>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                          STATUS:{' '}
                          <TimeText>
                            {d?.timeDecisionStatus || DEFAULT_STATUS}
                          </TimeText>
                        </div>
                      </PlanContainer>
                    ),
                  )}
                </CategoryContainer>
              ))}
          </div>
        ) : (
          <div>
            <img src={WarningIcon} alt="" style={{ marginRight: 5 }} />
            There was an issue getting the details of your absence decision.
            Please try again later.
            <hr />
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default LeavePlanList;
