import styled, { css, keyframes } from 'styled-components';
import { getHtmlFromMarkdown, prefixObjectValues } from '../../../../utils';
import { convertAnchorTagsToRouterLinks } from '../../../../utils/html-parsing-helpers';
import { Label as KendoLabel } from '@progress/kendo-react-labels';
import unumAvatar from '../../../../images/unum-avatar.svg';
import questionCircle from '../../../../images/question-circle.svg';
import chevronCircle from '../../../../images/chevron-right-circle.svg';
import { colors, rem } from '../../../../style';
import React from 'react';

export interface ComponentProps {
  editorId?: string;
  formFieldLabel: string;
  formFieldLabelDetails?: string;
  keyId?: string;
}

interface LabelProps {
  open?: boolean;
  position?: 'left' | 'right' | 'none';
}

interface LabelButtonProps {
  open?: boolean;
}

const domIdsStatic = {
  rootNode: 'label',
  kendoLabel: 'k-label',
  kendoDetailsLabel: 'k-details-label',
  detailsButton: 'details-button',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  background-color: ${colors.coolNeutralL1};
`;

// label vars
const durationSeconds = 1;
const detailIconWidth = 33;
const minHeight = 25;
const maxWidth = 876;
const maxHeight = 500;

// label positions
const lPositionMargin = 40;
const lPostitionLeft = `0px ${lPositionMargin}px 0px 0px`;
const lPostitionRight = `0px 0px 0px ${lPositionMargin}px`;
const getMarginForPosition = (position: LabelProps['position']) => {
  if (position === 'none') {
    return '0px';
  }

  return position === 'left' ? lPostitionLeft : lPostitionRight;
};

/**
 * Key Frames
 */

const labelAnimationShow = (position: LabelProps['position']) => keyframes`
  0% {
    opacity: 0%;
    max-width: 0px;
    max-height: ${minHeight}px;
    margin: 0px 0px;
  }

  10% {
    opacity: 0%;
    max-width: 0px;
    max-height: ${minHeight}px;
    margin: 0px 0px;
  }

  20% {
    opacity: 0%;
    max-width: 0px;
    max-height: ${minHeight}px;
    margin: 0px 0px;
  }

  40% {
    margin: ${getMarginForPosition(position)};
  }

  80% {
    opacity: 0%;
    max-width: ${maxWidth}px;
    max-height: ${minHeight}px;
    margin: ${getMarginForPosition(position)};
  }

  90% {
    opacity: 0%;
    max-width: ${maxWidth}px;
    max-height: ${maxHeight}px;
    margin: ${getMarginForPosition(position)};
  }

  100% {
    opacity: 100%;
    max-width: ${maxWidth}px;
    max-height: ${maxHeight}px;
    margin: ${getMarginForPosition(position)};
  }
`;

const labelAnimationHide = (position: LabelProps['position']) => keyframes`
  0% {
    opacity: 100%;
    max-width: ${maxWidth}px;
    max-height: ${maxHeight}px;
    margin: ${getMarginForPosition(position)};
  }

  10% {
    opacity: 0%;
    max-width: ${maxWidth}px;
    max-height: ${maxHeight}px;
    margin: ${getMarginForPosition(position)};
  }

  20% {
    opacity: 0%;
    max-width: ${maxWidth}px;
    max-height: ${minHeight}px;
    margin: ${getMarginForPosition(position)};
  }

  80% {
    opacity: 0%;
    max-width: 0px;
    max-height: ${minHeight}px;
    margin: 0px 0px;
  }

  90% {
    opacity: 0%;
    max-width: 0px;
    max-height: ${minHeight}px;
    margin: 0px 0px;
  }

  100% {
    opacity: 0%;
    max-width: 0px;
    max-height: ${minHeight}px;
    margin: 0px 0px;
  }
`;

const buttonTransitionOpen = keyframes`
  0% {
    border-radius: 0px 30px 30px 0px;
  }

  20% {
    border-radius: 0px 30px 30px 0px;
  }

  30% {
    border-radius: 0px;
  }

  70% {
    border-radius: 0px;
  }

  80% {
    border-radius: 0px 0px 0px 30px;
  }

  100% {
    border-radius: 0px 0px 0px 30px;
  }
`;

const buttonTransitionClose = keyframes`
  0% {
    border-radius: 0px 0px 0px 30px;
  }

  20% {
    border-radius: 0px 0px 0px 30px;
  }

  30% {
    border-radius: 0px;
  }

  70% {
    border-radius: 0px;
  }

  80% {
    border-radius: 0px 30px 30px 0px;
  }

  100% {
    border-radius: 0px 30px 30px 0px;
  }
`;

const detailIconShow = keyframes`
  0% {
    transform: scale(0%);
    max-width: 0%;
  }

  80% {
    transform: scale(0%);
    max-width: 0%;
  }

  90% {
    transform: scale(0%);
    max-width: 100%;
  }

  100% {
    transform: scale(100%);
    max-width: 100%;
  }
`;

const detailIconHide = keyframes`
  0% {
    transform: scale(100%);
    max-width: 100%;
  }
  
  10% {
    transform: scale(0%);
    max-width: 100%;
  }
  
  80% {
    transform: scale(0%);
    max-width: 100%;
  }

  90% {
    transform: scale(0%);
    max-width: 0%;
  }

  100% {
    transform: scale(0%);
    max-width: 0%;
  }
`;

const LabelWrapper = styled.div`
  margin-left: ${rem(-66)};

  @media (max-width: 1200px) {
    margin-left: 0px;
  }
`;

const UnumAvatar = styled.img.attrs({ src: unumAvatar })``;

const generateAnimation = (p: LabelProps) => {
  if (p.open === undefined) {
    return css`
      animation: none;
    `;
  }
  return css`
    animation: ${p.open
        ? labelAnimationShow(p.position)
        : labelAnimationHide(p.position)}
      ${durationSeconds}s ease-in-out;
  `;
};

const KLabel = styled(KendoLabel)<LabelProps>`
  display: ${(p) => (p.open === undefined ? 'none !important' : 'flex')};
  margin-bottom: 0px !important;
  ${generateAnimation}
  animation-fill-mode: both;
  margin: ${(p) => (p.open && getMarginForPosition(p.position)) || 0};
  pointer-events: all;
  flex-direction: column !important;
  gap: 16px;

  p {
    margin: 0px !important;
  }
`;

const LabelContainer = styled.div`
  background-color: ${colors.white};
  border: none;
  box-shadow: 0 -3px 12px #e0e7ed;
  border-radius: 0px 30px 30px 30px;
  padding: ${rem(20)};
`;

const LabelButton = styled.button<LabelButtonProps>`
  background-color: ${colors.coolNeutralL1};
  border: none;
  border-radius: ${(p) => (p.open ? '0px 0px 0px 30px' : '0px 30px 30px 0px')};
  margin: ${rem(-20)};
  width: ${rem(52)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  animation: ${(p) => (p.open ? buttonTransitionOpen : buttonTransitionClose)}
    ${durationSeconds}s ease-in-out;
  animation-fill-mode: both;
  min-width: ${rem(52)};
`;

const LabelIcon = styled.img<LabelButtonProps>`
  font-size: ${rem(detailIconWidth)};
  color: ${colors.skyD2};
  animation: ${(p) => (p.open ? detailIconShow : detailIconHide)}
    ${durationSeconds}s ease-in-out;
  animation-fill-mode: both;
`;

const Label: (p: ComponentProps) => React.ReactElement = (p) => {
  const domIds = domIdsUnique(p.keyId);
  const [open, setOpen] = React.useState<boolean>();

  return (
    <Container id={domIds.rootNode}>
      <LabelWrapper className="d-flex align-items-start flex-grow mb-3">
        <UnumAvatar alt="Unum Avatar" />
        <LabelContainer className="d-flex">
          <KLabel
            id={`${p.editorId}_${domIds.kendoLabel}`}
            editorId={p.editorId}
            open={!open}
            position={p.formFieldLabelDetails ? 'left' : 'none'}
          >
            {convertAnchorTagsToRouterLinks(
              getHtmlFromMarkdown(p.formFieldLabel).__html,
            )}
          </KLabel>
          {p.formFieldLabelDetails && (
            <>
              <LabelButton
                id={domIds.detailsButton}
                open={open}
                onClick={(e) => {
                  e.preventDefault();
                  setOpen((c) => !c);
                }}
              >
                <LabelIcon src={questionCircle} open={!open} />
                <LabelIcon src={chevronCircle} open={open} />
              </LabelButton>
              <KLabel
                open={open}
                editorId={domIds.kendoDetailsLabel}
                position="right"
              >
                {convertAnchorTagsToRouterLinks(
                  getHtmlFromMarkdown(p.formFieldLabelDetails).__html,
                )}
              </KLabel>
            </>
          )}
        </LabelContainer>
      </LabelWrapper>
    </Container>
  );
};

export default Label;
