import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import Chevron from '../../../images/chevron-down-lg.png';
import { ChevronContainer, ChevronIcon } from '../../base/accordion-card';
import { prefixObjectValues } from '../../../utils';

const Container = styled.div`
  margin-top: 20px;
  background-color: white;
  border: 1px solid ${colors.alto2};
  border-radius: 4px;
  padding: 10px 15px;
`;

const Header = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.bold.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.ubuntu.bold.fontWeight};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LegendText = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 10px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin-left: 5px;
`;

const ItemsContainer = styled.div`
  margin-top: 10px;
  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    grid-gap: 15px 10px;
    padding-left: 0;
  }
`;

const LegendItem = styled.li`
  list-style: none;
  display: flex;
`;

interface ILegendStyle {
  [key: string]: any;
  legendItem?: boolean;
  legendIcon?: string;
  legendText?: string;
}

interface ILegendStyles {
  [key: string]: ILegendStyle;
}

interface ILegend {
  keyId?: string;
  expandable?: boolean;
  styles: ILegendStyles;
  stylesVisibleOnCalendar?: any[];
  hideUnusedStyles?: boolean;
}

const domIdsStatic = {
  rootNode: 'legend-container',
  expandedSection: 'legend-expanded-section',
  expandBtn: 'legend-expand-btn',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Legend: (p: ILegend) => React.ReactElement | null = (p) => {
  const [expanded, setExpanded] = React.useState(true);
  const domIds = domIdsUnique(p.keyId);

  const expandable = p.expandable ?? true;

  const expandLegend = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const getLegendItems = React.useCallback(() => {
    const visibleStyles = p?.stylesVisibleOnCalendar?.map?.(
      (elems: any) => elems?.legendKey,
    );
    const styleKeys = Object.keys(p.styles);
    const legendItems: ILegendStyle[] = [];
    styleKeys.forEach((sk) => {
      const includeStyle =
        !p.hideUnusedStyles ||
        (p.hideUnusedStyles && visibleStyles?.includes?.(sk));
      if (includeStyle && p.styles[sk].legendItem) {
        legendItems.push(p.styles[sk]);
      }
    });

    return legendItems;
  }, [p.styles, p.stylesVisibleOnCalendar, p.hideUnusedStyles]);

  const legendItems = getLegendItems();

  return legendItems.length > 0 ? (
    <Container id={domIds.rootNode}>
      <Header>
        <span>Legend</span>
        {expandable && (
          <ChevronContainer>
            <ChevronIcon
              id={domIds.expandBtn}
              imageUrl={Chevron}
              onClick={expandLegend}
              look="flat"
              $expanded={expanded}
            />
          </ChevronContainer>
        )}
      </Header>
      {expanded && (
        <ItemsContainer id={domIds.expandedSection}>
          <ul>
            {legendItems.map(
              (lgi) =>
                lgi.legendItem && (
                  <LegendItem>
                    <img
                      src={lgi.legendIcon}
                      alt=""
                      height="16px"
                      width="24px"
                    />
                    <LegendText>{lgi.legendText}</LegendText>
                  </LegendItem>
                ),
            )}
          </ul>
        </ItemsContainer>
      )}
    </Container>
  ) : null;
};

export default Legend;
