import styled from 'styled-components';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import isBetween from 'dayjs/plugin/isBetween';
import { prefixObjectValues } from '../../../utils';
import { IElement, ILegend } from '../';
dayjs.extend(weekday);
dayjs.extend(isBetween);

const RangeContainer = styled.div<{
  startH?: number;
  startV?: number;
  endH?: number;
  endV?: number;
}>`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
`;

const domIdsStatic = {
  rootNode: 'calendar-overlay-container',
  element: '-calendar-element',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

interface IOverlay {
  keyId: string;
  elements: IElement[];
  legend?: ILegend;
  date: dayjs.Dayjs;
}

interface IOverlayItem {
  keyId: string;
  item: IElement;
  legend: { [key: string]: any };
  date: dayjs.Dayjs;
}

const Overlay = (p: IOverlay) => {
  const domIds = domIdsUnique(p.keyId);
  return (
    <RangeContainer id={domIds.rootNode}>
      {p.elements.map((item: any, index: number) => (
        <>
          {p?.legend?.[item?.legendKey] && (
            <OverlayItem
              keyId={p.keyId + index}
              item={item}
              legend={p.legend[item.legendKey]}
              date={p.date}
            />
          )}
        </>
      ))}
    </RangeContainer>
  );
};

const OverlayItem = (p: IOverlayItem) => {
  const domIds = domIdsUnique(p.keyId);
  const { item, legend } = p;

  const Element = legend.elementType;

  if (
    !dayjs(p.date).isBetween(
      dayjs(item.startDate),
      dayjs(item.endDate),
      'month',
      '[]',
    )
  ) {
    return null;
  }

  const elementStartDate =
    dayjs(item.startDate).month() === p.date.month()
      ? dayjs(item.startDate)
      : dayjs(p.date).startOf('month');

  const dayInMonth = elementStartDate.date();
  const startOfMonth = elementStartDate.startOf('month').weekday();

  const verticalPosition = Math.floor((dayInMonth + startOfMonth) / 7);
  const horizontalPosition = elementStartDate.weekday();

  const elementEndDate =
    dayjs(item.endDate).month() === p.date.month()
      ? dayjs(item.endDate)
      : dayjs(p.date).endOf('month');

  const endDayInMonth = elementEndDate.date();
  const verticalEnd = Math.ceil((endDayInMonth + startOfMonth) / 7);
  const horizontalEnd =
    elementEndDate.weekday() - elementStartDate.weekday() + 1;

  const totalRanges = verticalEnd - verticalPosition + 1;

  const position = {
    top: verticalPosition,
    left: horizontalPosition,
    totalRangeWidth: horizontalEnd - horizontalPosition,
    startWidth: 7 - horizontalPosition,
    endWidth: 7 - horizontalEnd,
    spansMultipleWeeks: totalRanges > 1,
  };

  return (
    <Element
      id={domIds.element}
      position={position}
      item={item}
      legend={legend}
      style={legend.style}
      order={legend.order}
      totalRanges={2}
      date={p.date}
      content={legend.content}
    />
  );
};

export default Overlay;
