import React from 'react';
import styled from 'styled-components';
import {
  SectionSeparator,
  OpenSansRegularValue,
} from '../../../base/simple-styled-components';
import { colors, fonts } from '../../../../style';
import { TaskType } from '../../index';
import arrow from '../../../../images/down_arrow.svg';
import { ITasksConfig } from '../../../../utils/remote-config-manager';
import { getHtmlFromMarkdown } from '../../../../utils';

const Arrow = styled.img.attrs({
  alt: 'Expand',
  src: arrow,
})`
  cursor: pointer;
  margin-right: 0.5em;
  transform: ${(props: { rotate: string }) =>
    props.rotate === 'true' ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: all 0.5s;
  user-select: none;
  width: 2.75em;
  margin-left: auto;
`;

const Title = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 28px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
`;

const QuestionTitle = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 22px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  margin-bottom: 8px;
`;

const TitleContainer = styled.div`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  padding: 32px;
`;

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;

const QuestionsContainer = styled.div`
  background: #fcfcfc;
  margin-top: 1px;
  padding: 32px;
`;

const getFAQs = (questions: ITasksConfig['frequentlyAskedQuestions']) => {
  return (
    <QuestionsContainer>
      {questions.map((q, index) => {
        return (
          <div key={`question-${index}`}>
            <QuestionTitle>{q.question}</QuestionTitle>
            <OpenSansRegularValue
              dangerouslySetInnerHTML={getHtmlFromMarkdown(q.response)}
            ></OpenSansRegularValue>
            {questions.length > 1 && index < questions.length - 1 && (
              <SectionSeparator />
            )}
          </div>
        );
      })}
    </QuestionsContainer>
  );
};

const TaskHelp: (p: TaskType) => React.ReactElement = (p) => {
  const [expand, setExpand] = React.useState<boolean>(false);

  return (
    <Container id="help-root">
      <TitleContainer onClick={() => setExpand(!expand)}>
        {' '}
        <Title>Help</Title>
        <Arrow id="expand-help-arrow" rotate={`${expand}`} />
      </TitleContainer>
      {expand &&
        p.frequentlyAskedQuestions.length > 0 &&
        getFAQs(p.frequentlyAskedQuestions)}
    </Container>
  );
};

export default TaskHelp;
