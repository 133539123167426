
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class EmployerEducationalContent extends ContentItem {
    public tabName!: Elements.TextElement;
    public heroImage!: Elements.LinkedItemsElement<ContentItem>;
    public cardSectionTitle!: Elements.TextElement;
    public linksSection!: Elements.LinkedItemsElement<ContentItem>;
    public contentCards!: Elements.LinkedItemsElement<ContentItem>;
    public callToActionSection!: Elements.LinkedItemsElement<ContentItem>;
    public title!: Elements.TextElement;
    public generalMessage!: Elements.CustomElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'tab_name') {
                    return 'tabName';
                }
                if (elementName === 'hero_image') {
                    return 'heroImage';
                }
                if (elementName === 'card_section_title') {
                    return 'cardSectionTitle';
                }
                if (elementName === 'links_section') {
                    return 'linksSection';
                }
                if (elementName === 'content_cards') {
                    return 'contentCards';
                }
                if (elementName === 'call_to_action_section') {
                    return 'callToActionSection';
                }
                if (elementName === 'general_message') {
                    return 'generalMessage';
                }
                return elementName;
            })
        });
    }
}
