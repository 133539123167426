import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../style';
import { FormContext, IFormContext } from '../../../../contexts/form-context';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { generatePlan } from '../../../../utils/form-submitter/draft-claim';
import LeavePlan from '../../../leave-plan';
import LoadingIndicatorCard from '../../../leave-plan/loading-indicator-card';
import {
  StyledNotification,
  ToastNotificationGroupStyle,
} from '../../../base/simple-styled-components/index';
import { ToastTypes } from '../../../../utils/enums';
import { log } from '../../../../utils/log';

interface ILeavePlanGraphProps extends FieldRenderProps {
  moveToStep: Function;
  editConfig: any;
}

const Container = styled.div`
  background-color: ${colors.coolNeutralL1};
`;

export interface IGenerateLeavePlanParams {
  formContext: IFormContext;
  formOptionsTemplate: string;
  formValueGetter: (s: string) => any;
  onChange: (event: { target?: any; value?: any }) => void;
  setAllowNavBack: React.Dispatch<React.SetStateAction<boolean>>;
  setAllowNavForward: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplayToast: React.Dispatch<React.SetStateAction<boolean>>;
  setFormContext: React.Dispatch<any>;
  setIsBusy: React.Dispatch<React.SetStateAction<boolean>>;
  setToastMessage: React.Dispatch<React.SetStateAction<string>>;
  setToastType: React.Dispatch<
    React.SetStateAction<'error' | 'none' | 'success'>
  >;
}

export const generateLeavePlan = async (o: IGenerateLeavePlanParams) => {
  try {
    o.setAllowNavBack(false);
    o.setAllowNavForward(false);
    o.setIsBusy(true);
    // this prevents them from going forward if the API fails
    o.onChange({
      value: undefined,
    });
    const { draftClaim, calendarData } = await generatePlan(
      o.formValueGetter,
      o.formOptionsTemplate,
    );
    if (draftClaim) {
      o.onChange({
        value: { planId: draftClaim.planId },
      });
      o.setFormContext({
        ...o.formContext,
        currentLeavePlan: draftClaim,
        calendarData,
      });
      o.setAllowNavForward(true);
    }
  } catch (e) {
    log.error(e);
    o.setToastType('error');
    o.setToastMessage(
      'There was an error submitting your leave plan. Please try again later.',
    );
    o.setDisplayToast(true);
    o.setFormContext({
      ...o.formContext,
      leavePlanSuccessfullyGenerated: 'No',
    });
    o.setAllowNavForward(false);
  } finally {
    o.setAllowNavBack(true);
    o.setIsBusy(false);
  }
};

const setOverrideRules = (
  formContext: IFormContext,
  setOverrideShow: Function,
) => {
  if (
    formContext.formName === 'Pregnancy' &&
    (formContext.eeCentric?.override[9280003] === '3' ||
      formContext.eeCentric?.override[9280003] === '4')
  ) {
    setOverrideShow(false);
  }

  if (
    formContext.formName === 'Personal Medical' &&
    (formContext.eeCentric?.override[9280001] === '3' ||
      formContext.eeCentric?.override[9280001] === '4' ||
      formContext.eeCentric?.override[9280002] === '3' ||
      formContext.eeCentric?.override[9280002] === '4')
  ) {
    setOverrideShow(false);
  }
};

const LeavePlanGraph: (p: ILeavePlanGraphProps) => React.ReactElement = (p) => {
  const {
    onChange,
    formValueGetter,
    formOptionsTemplate,
    showGraph,
    editConfig,
    moveToStep,
  } = p;
  const { formContext, setFormContext, setAllowNavBack, setAllowNavForward } =
    React.useContext(FormContext);
  const [isBusy, setIsBusy] = React.useState(false);
  const [overrideShow, setOverrideShow] = React.useState(true);
  const [displayToast, setDisplayToast] = React.useState<boolean>(false);
  const [toastMessage, setToastMessage] = React.useState<string>('');
  const [toastType, setToastType] =
    React.useState<ToastTypes['style']>('success');

  setTimeout(() => {
    if (displayToast) {
      closeToast();
    }
  }, 6000);

  const closeToast = () => {
    setDisplayToast(false);
  };

  //set rules on whether to show graph based on the override values
  React.useEffect(() => {
    setOverrideRules(formContext, setOverrideShow);
  }, [formContext]);

  React.useEffect(() => {
    if (!isBusy && !formContext?.currentLeavePlan) {
      generateLeavePlan({
        formContext,
        formOptionsTemplate,
        formValueGetter,
        onChange,
        setAllowNavBack,
        setAllowNavForward,
        setDisplayToast,
        setFormContext,
        setIsBusy,
        setToastMessage,
        setToastType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      id="form-leave-plan"
      className="d-flex justify-content-center align-items-center"
    >
      <ToastNotificationGroupStyle
        style={{
          top: 0,
          right: 0,
          alignItems: 'flex-end',
          position: 'fixed',
        }}
      >
        {displayToast && (
          <StyledNotification
            key="success"
            type={{ style: toastType, icon: true }}
            closable={true}
            onClose={closeToast}
          >
            {toastMessage}
          </StyledNotification>
        )}
      </ToastNotificationGroupStyle>
      {!isBusy && formContext?.calendarData && formContext?.currentLeavePlan ? (
        showGraph === 'true' &&
        overrideShow && (
          <div id="form-leave-plan-graph-container" className="w-100">
            <LeavePlan
              systemId={formContext.currentLeavePlan.planId}
              visualType="intake"
              moveToStep={moveToStep}
              editConfig={editConfig}
              calendarData={formContext.calendarData}
            />
          </div>
        )
      ) : (
        <LoadingIndicatorCard />
      )}
    </Container>
  );
};

export default LeavePlanGraph;
