import { FormRenderProps } from '@progress/kendo-react-form';
import FormField from '../form-field';
import { enums, getHtmlFromMarkdown, parseJSONOrDefault } from '../../../utils';
import { models } from '../../../utils/cms-client';
import FormText from '../form-text';
import { PersonData, EmployerPreferences } from '../../../data-models';
import { determineIntegrated } from './utils';
import { convertAnchorTagsToRouterLinks } from '../../../utils/html-parsing-helpers';
import NextStep from '../../next-step';
import { shouldShow } from '../options/index';
import { IFormContext } from '../../../contexts/form-context';
import FormAccordionCard from '../form-accordion-card';

type FormSectionModelType =
  | models.FormButton
  | models.FormField
  | models.FormText
  | models.NextStep
  | models.AccordionCard;

export const updatedClaimList = (pd: PersonData) => {
  const ipd = determineIntegrated(pd);
  const cases = [
    ...(ipd.cases?.claims || []),
    ...(ipd.cases?.leaves || []),
    ...(ipd.cases?.accommodations || []),
  ];

  const modifiedCases = cases.map((c: any) => {
    const isAccommodation = 'accommodationId' in c;
    if (c.caseId === undefined || c.caseId.length < 0) {
      c.caseId = c?.claimId ?? c?.leaveId ?? c?.accommodationId;
    }
    return { ...c, isAccommodation };
  });

  const claimList = modifiedCases.filter((c) => c.caseId !== undefined);

  return { claimList, ipd };
};

export const extractConfigUrls = (config: { [name: string]: string }) => {
  const configUrls: { [name: string]: string } = {};

  if (!config) {
    return configUrls;
  }

  for (const key of Object.keys(config)) {
    if (key.includes('url')) {
      configUrls[key] = config[key];
    }
  }

  return configUrls;
};

export const generateFormContext = (
  pd: PersonData,
  employerPreferences: EmployerPreferences,
  task: any,
  config: { [name: string]: string },
  defaults?: any,
) => {
  const { claimList, ipd } = updatedClaimList(pd);

  let taskCase: any = 'Select';
  if (task?.caseId) {
    taskCase = claimList.filter((c) => c.caseId === task.caseId)[0];
  }

  const dates = {
    now: new Date().toISOString(),
  };

  return {
    ...ipd,
    employerPreferences,
    claimList,
    dates,
    taskCase,
    defaults,
    configUrls: extractConfigUrls(config),
  };
};

export const generateSection = (
  f: FormSectionModelType,
  i: number,
  currentStepIndex: number,
  formRenderProps: FormRenderProps,
  formOptionsTemplate: string,
  moveToStep: Function,
  sectionFieldNamePrefix: string,
) => {
  let R;

  switch (f.system.type) {
    case enums.FormSectionTypes.FormButton:
      R = <div>form button here</div>;
      break;
    case enums.FormSectionTypes.FormField:
      R = (
        <FormField
          key={i}
          keyId={`${currentStepIndex}_${i}_`}
          currentStepIndex={currentStepIndex}
          moveToStep={moveToStep}
          field={f as models.FormField}
          formValueGetter={formRenderProps.valueGetter}
          formOnChange={formRenderProps.onChange}
          formOptionsTemplate={formOptionsTemplate}
          sectionFieldNamePrefix={sectionFieldNamePrefix}
        />
      );
      break;
    case enums.FormSectionTypes.FormText:
      R = (
        <FormText
          key={i}
          keyId={`${currentStepIndex}_${i}_`}
          text={f as models.FormText}
          formValueGetter={formRenderProps.valueGetter}
        />
      );
      break;
    case 'accordion_card':
      R = (
        <FormAccordionCard
          key={i}
          keyId={`${currentStepIndex}_${i}_`}
          content={f as models.AccordionCard}
          formValueGetter={formRenderProps.valueGetter}
          index={i}
        />
      );
      break;
    default:
      R = <div></div>;
  }

  return (
    <div key={i} data-form-section-type={f.system.type}>
      {R}
    </div>
  );
};

export const sortArray = (arr: any) => {
  arr.sort(function (a: any | undefined, b: any | undefined) {
    if (a.order && b.order) {
      return a.order.value - b.order.value;
    }
    return 0;
  });
  return arr;
};

const renderNextStep = (n: any) => {
  return (
    <NextStep
      keyId={n.title.value}
      title={n.title.value}
      description={n.description.value}
      thumbnail={n.thumbnail.value.length > 0 ? n.thumbnail.value[0].url : ''}
      link={n.link}
      type={n.type.value.name}
    />
  );
};

const removeUnshownNextSteps = (stepArray: any, formContext: IFormContext) =>
  stepArray.filter((ns: any) =>
    shouldShow(
      parseJSONOrDefault(ns.optionsTemplate.value),
      () => '',
      formContext,
    ),
  );

export interface SubmissionObject {
  noNextSteps?: boolean;
  markdown?: any;
}

export const generateSubmittedComponent = ({
  form,
  labelMarkdown,
  formContext,
}: {
  form: models.Form | undefined;
  labelMarkdown: string;
  formContext: IFormContext;
}) => {
  const returnObj: SubmissionObject = { noNextSteps: false };

  const nextStepArray: any = sortArray(
    form?.submissionText.value[0].nextSteps.value,
  );
  const additionalResourcesArray: any = sortArray(
    form?.submissionText.value[0].additionalResources.value,
  );
  //filter by options template so that unused titles can correctly shown or not shown
  const filteredNextStepsArray = removeUnshownNextSteps(
    nextStepArray,
    formContext,
  );

  const customNextStepsForForm = formContext?.ERContent?.postIntakeContent
    ? formContext.ERContent.postIntakeContent.filter((pic: any) =>
        pic?.intakes?.value.find((i: any) => i?.name === formContext.formName),
      )
    : [];

  const filteredCustomNextStepsArray = removeUnshownNextSteps(
    customNextStepsForForm,
    formContext,
  );

  const filteredAdditionalResourcesArray = removeUnshownNextSteps(
    additionalResourcesArray,
    formContext,
  );

  if (
    filteredNextStepsArray.length <= 0 &&
    filteredCustomNextStepsArray.length <= 0 &&
    filteredAdditionalResourcesArray.length <= 0
  ) {
    returnObj.noNextSteps = true;
  }
  returnObj.markdown = (
    <>
      <div className="mb-3">
        {convertAnchorTagsToRouterLinks(
          getHtmlFromMarkdown(labelMarkdown).__html,
        )}
      </div>
      <FormText
        key={'submitted-text'}
        keyId={'submitted-text'}
        text={form?.submissionText.value[0] as any}
        formValueGetter={() => ''}
      />
      {filteredNextStepsArray.length > 0 ? (
        <h2 className="mt-3r mb-2r">Next Steps</h2>
      ) : (
        ''
      )}
      {filteredNextStepsArray.map(
        (n: models.NextStep): React.ReactElement => renderNextStep(n),
      )}
      {filteredCustomNextStepsArray.length > 0 ? (
        <h2 className="mt-3r mb-2r">Recommended From Your Employer</h2>
      ) : (
        ''
      )}
      {filteredCustomNextStepsArray.length > 0 &&
        filteredCustomNextStepsArray.map(
          (n: models.NextStep): React.ReactElement => renderNextStep(n),
        )}
      {filteredAdditionalResourcesArray.length > 0 ? (
        <h2 className="mt-3r mb-2r">Additional Resources and Support</h2>
      ) : (
        ''
      )}
      {filteredAdditionalResourcesArray.map(
        (n: models.NextStep): React.ReactElement => renderNextStep(n),
      )}
    </>
  );
  return returnObj;
};
