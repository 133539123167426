import { parseJSONOrDefault, prefixAndReCamelCase } from '../../../../utils';
import Handlebars from 'handlebars';
import * as hbh from '../../../../hooks/use-handlebars/helpers';
import * as options from '../../options';

export const process = (
  propsValue: string,
  formContext: { [key: string]: any } | undefined,
  formValueGetter: (name: string) => any,
  namePrefix?: string,
) => {
  // apply handlebars to props string first
  hbh.registerSelf(Handlebars, formValueGetter);
  const propsActivated = Handlebars.compile(propsValue)(formContext);

  const pc = parseJSONOrDefault(propsActivated);
  for (const key in pc) {
    const value = pc[key];

    if (typeof value === 'string' && value.charAt(0) === '$') {
      pc[key] = options.getOptionValue(value, formValueGetter, formContext);
    }

    if (key === 'name' && namePrefix) {
      pc[key] = prefixAndReCamelCase(namePrefix, value);
    }
  }

  // as an indicator for custom fields like WeeklySchedule
  pc.processedProps = true;

  return pc;
};
