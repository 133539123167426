import React from 'react';
import { useVBCoverage } from '../../hooks/use-data';
import { Skeleton } from '@progress/kendo-react-indicators';
import { benefitList } from './config';

const ID = 'product-selector';

export interface IProductSelectorProps {
  className?: string;
  onChange: (value: string) => void;
  selectedProduct?: string;
}

export default function ProductSelector(
  props: Readonly<IProductSelectorProps>,
) {
  const { data: vbCoverage } = useVBCoverage();

  const getProductList = React.useCallback(() => {
    if (vbCoverage) {
      return benefitList.map((v) => {
        if (
          vbCoverage.coverages.some((c) => c.productDescription === v.value)
        ) {
          v.disabled = false;
        }

        return v;
      });
    }

    return benefitList;
  }, [vbCoverage]);

  const isButtonSelected = React.useCallback(
    (value: string) => {
      if (value?.toString() === props.selectedProduct) {
        return 'tw-bg-unm-primary02-400 tw-text-white tw-cursor-default';
      }

      return 'tw-bg-unm-adhoc1';
    },
    [props.selectedProduct],
  );

  const getButtonBgColor = React.useCallback(
    (value: { value: string; disabled: boolean }) => {
      if (value.disabled) {
        return 'tw-bg-gray-200 tw-text-gray-400 tw-cursor-not-allowed';
      } else {
        return isButtonSelected(value.value);
      }
    },
    [isButtonSelected],
  );

  return (
    <div className={props.className}>
      {vbCoverage ? (
        <div
          id={`${ID}-group`}
          className="tw-flex max-lg:tw-justify-center tw-gap-7"
        >
          {getProductList().map((value, index: number) => {
            return value.disabled ? null : (
              <button
                id={`${ID}-${index}`}
                key={`key-${index.toString()}`}
                onClick={(_e: any) => {
                  props.onChange(value.value);
                }}
                className={`tw-text-nowrap tw-px-2 tw-h-[32px] sm:tw-h-[72px] sm:tw-w-[177px] tw-border-none tw-rounded-full sm:tw-rounded tw-shadow-sm tw-flex tw-justify-center tw-items-center tw-gap-3 ${getButtonBgColor(
                  value,
                )}`}
              >
                <div className="tw-hidden sm:tw-flex">
                  <img
                    src={value.icon}
                    alt={value.value}
                    className="tw-w-12 tw-h-12"
                  />
                </div>
                {value.value}
              </button>
            );
          })}
        </div>
      ) : (
        <div className="tw-flex tw-justify-between tw-gap-5">
          {Array.from({ length: 3 }, (_, index) => (
            <Skeleton
              key={index}
              className="tw-h-[26px] tw-w-[74px] sm:tw-h-[72px] sm:tw-w-[177px]"
            />
          ))}
        </div>
      )}
    </div>
  );
}
