import { useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import styled from 'styled-components';
import { Field } from '@progress/kendo-react-form';
import { prefixObjectValues } from '../../../../utils';
import { CommonModal } from '../../../base/common-modal';
import { FormInput } from '../form-component';
import { OpenSansRegularValueMod } from '../../index.sc';
import { fonts, colors } from '../../../../style';
import blankCheck from '../../../../images/blank-check.png';
import infoCircle from '../../../../images/info-circle.svg';

const templateIds = {
  accountNumberInfoButton: 'eft-preference-accountNumber-info-button',
  accountNumberInput: 'eft-preference-accountNumber-input',
  bankNameInput: 'eft-preference-bankName-input',
  infoModal: 'eft-preference-info-modal',
  routingNumberInfoButton: 'eft-preference-routingNumber-info-button',
  routingNumberInput: 'eft-preference-routingNumber-input',
};

const CheckImage = styled.img.attrs({
  className: 'img-fluid mx-auto d-block',
  src: blankCheck,
  alt: 'routing and acccount number locations on a personal check',
})`
  margin-bottom: 24px;
`;

const EftPreferenceContainer = styled.div`
  background-color: ${colors.backgroundAlt};
  margin-bottom: 16px;
  padding-bottom: 16px;
  padding-left: 75px;
  padding-top: 16px;
`;

const InfoIconButton = styled.button.attrs({
  className: 'k-button k-button-icon',
})`
  background-color: transparent;
  border: unset;
  margin-left: 0;
  margin-top: 25px;

  :hover {
    background-color: transparent;
  }
`;

const InfoModal = styled(CommonModal)`
  && {
    .k-dialog {
      height: auto;
      max-width: 557px;
    }
  }

  .k-window-titlebar {
    background-color: ${colors.background};
    height: 48px;
    padding: 12px !important;
  }

  .k-window-content {
    background-color: #fff;
  }
`;

const FieldRow = styled.div`
  align-items: center;
  display: flex;
`;

const ModalContent = styled.div`
  max-width: 557px;
`;

const ModalTitle = styled(OpenSansRegularValueMod)`
  font-family: ${fonts.ubuntu.bold.fontFamily};
  font-weight: ${fonts.ubuntu.bold.fontWeight};
  margin-bottom: 26px;
`;

const ModalParagraph = styled(OpenSansRegularValueMod)`
  color: #657676;
`;

const ModalBoldedText = styled.span`
  color: ${colors.strengthTextColor};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
`;

const getModalContent = () => {
  return (
    <ModalContent>
      <ModalTitle as="p">
        Where can I find my routing and account numbers?
      </ModalTitle>
      <CheckImage />
      <ModalParagraph>
        Your <ModalBoldedText>bank routing number</ModalBoldedText> is a
        nine-digit code that’s based on the location where your account was
        opened. It’s the first set of numbers printed on the bottom of your
        checks, on the left side.{' '}
      </ModalParagraph>
      <ModalParagraph>
        Your <ModalBoldedText>account number</ModalBoldedText> (usually 10-12
        digits) is specific to your personal account. It’s the second set of
        numbers printed on the bottom of your checks, just to the right of the
        bank routing number.{' '}
      </ModalParagraph>
    </ModalContent>
  );
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, templateIds);

const renderRow = (inputComponent: any, infoButton?: any) => (
  <FieldRow>
    <div>{inputComponent}</div>
    <div>{infoButton}</div>
  </FieldRow>
);

const requiredValidator = (value: any) =>
  value ? '' : 'This field is required.';

const EftPreferenceOptions = (props: any) => {
  const automationIds = domIdsUnique(props.keyId);
  const [showInfo, setShowInfo] = useState(false);

  return (
    <EftPreferenceContainer>
      {renderRow(
        <Field
          key={automationIds.bankNameInput}
          id={automationIds.bankNameInput}
          name={automationIds.bankNameInput}
          label={props.bankNameLabel}
          component={FormInput}
          validator={requiredValidator}
          data-cb-mask="true"
          className="fs-exclude"
        />,
      )}
      {renderRow(
        <Field
          key={automationIds.routingNumberInput}
          id={automationIds.routingNumberInput}
          name={automationIds.routingNumberInput}
          label={props.routingNumberLabel}
          component={FormInput}
          validator={requiredValidator}
          data-cb-mask="true"
          className="fs-exclude"
        />,
        <InfoIconButton
          id={automationIds.routingNumberInfoButton}
          onClick={() => setShowInfo(true)}
        >
          <img src={infoCircle} alt="Info" className="k-image" />
        </InfoIconButton>,
      )}
      {renderRow(
        <Field
          key={automationIds.accountNumberInput}
          id={automationIds.accountNumberInput}
          name={automationIds.accountNumberInput}
          label={props.accountNumberLabel}
          component={FormInput}
          validator={requiredValidator}
          data-cb-mask="true"
          className="fs-exclude"
        />,
        <InfoIconButton
          id={automationIds.accountNumberInfoButton}
          onClick={() => setShowInfo(true)}
        >
          <img src={infoCircle} alt="Info" className="k-image" />
        </InfoIconButton>,
      )}
      {showInfo && (
        <InfoModal
          modalId={automationIds.infoModal}
          modalCallback={() => setShowInfo(false)}
          title="&nbsp;"
          markdown={renderToStaticMarkup(getModalContent())}
          modalDownloadURL="false"
        />
      )}
    </EftPreferenceContainer>
  );
};

export { EftPreferenceOptions, requiredValidator, templateIds };
