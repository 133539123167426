import styled from 'styled-components';
import { colors } from '../../../style';
import { FadeLoader } from 'react-spinners';
import { Text } from '../../base/simple-styled-components';

const LoadingContainer = styled.div`
  background-color: ${colors.white};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 113px 0;
  width: 100%;
`;

const LoadingIndicatorCard = () => {
  return (
    <LoadingContainer id="loading-indicator-card">
      <FadeLoader
        color={colors.denim}
        css={
          'display: inline-block; vertical-align: middle; zoom: 1.25; margin: 20px 0px;'
        }
      />
      <Text
        color={colors.denim}
        style={{
          textAlign: 'center',
          maxWidth: 248,
          fontWeight: 600,
          fontSize: 16,
        }}
      >
        Loading details and calendar
      </Text>
    </LoadingContainer>
  );
};

export default LoadingIndicatorCard;
