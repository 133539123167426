import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../style/index';
import {
  enums,
  getHtmlFromMarkdown,
  getLocalizedOrDefault,
  getFormattedHoursOfOperation,
} from '../../../utils';
import { models } from '../../../utils/cms-client';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';
import LiveChatIcon from '../../../images/live-chat-graphic.svg';
import useHandlebars from '../../../hooks/use-handlebars';

const ContactCardContainer = styled.div`
  background-color: #eff3f8;
  height: max-content;
  margin-left: 32px;
  padding: 30px;
  max-width: 280px;

  @media only screen and (max-width: 1024px) {
    margin-left: 0;
    max-width: none;
  }
`;

const Title = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 20px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  margin-bottom: 10px;
`;

const Text = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

const FormContactCard = (p: { step: models.FormStep }) => {
  const { callCenter, localizedStrings } = React.useContext(KenticoDataContext);

  const text = getLocalizedOrDefault(
    localizedStrings,
    'contact_card_text',
    'You can chat, in real-time, with one of our specialists, Monday through Friday, {{hoost}} - {{hooet}}, ET.',
  );

  const compiledText = useHandlebars(
    text,
    getFormattedHoursOfOperation(callCenter),
  );

  return (p?.step?.flags?.value || []).filter(
    (f: any) => f.codename === enums.FormStepFlags.hideStepper,
  ).length > 0 ? (
    <ContactCardContainer id="form-contact-card">
      <Title>
        <img src={LiveChatIcon} alt="" className="mr-2" />
        <span>
          {getLocalizedOrDefault(
            localizedStrings,
            'contact_card_title',
            'Need assistance?',
          )}
        </span>
      </Title>
      <Text dangerouslySetInnerHTML={getHtmlFromMarkdown(compiledText)} />
    </ContactCardContainer>
  ) : null;
};

export default FormContactCard;
