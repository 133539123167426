
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Frequentlyusedtasksbycasetype extends ContentItem {
    public caseType!: Elements.TaxonomyElement;
    public frequentlyUsedTasks!: Elements.LinkedItemsElement<ContentItem>;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'case_type') {
                    return 'caseType';
                }
                if (elementName === 'frequently_used_tasks') {
                    return 'frequentlyUsedTasks';
                }
                return elementName;
            })
        });
    }
}
