import React from 'react';
import { colors } from '../../style';
import { DocumentLink } from '../base/simple-styled-components';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import WarningIcon from '../../images/warning-icon.png';
import { ClipLoader } from 'react-spinners';
import {
  Dialog,
  Container,
  SemiboldText,
  Text,
  Button,
  Image,
} from './index.sc';
import FileSelectListItem from './file-select-list-item';
import Status from './status';
import { CustomUploadFileInfo, uploadFile } from '../../utils/upload-helpers';
import { enums, get } from '../../utils';

export interface IUploadText {
  modalTitle?: string;
  modalInitialDesc?: string;
  modalSuccessDesc?: string;
  modalSomeErrorDesc?: string;
  modalAllErrorDesc?: string;
  modalCloseButton?: string;
}

interface UploadModalProps {
  modalCallback: (result?: enums.UploadStatus) => void;
  caseId?: string;
  displayConfirmation?: boolean;
  files?: any;
  onUpload?: any;
  planId?: string;
  text?: IUploadText;
  uploadTried?: boolean;
}

const UploadModal: (p: UploadModalProps) => React.ReactElement = (p) => {
  const [files, setFiles] = React.useState<Array<CustomUploadFileInfo>>(
    p.files || [],
  );
  const [filesAllowed, setFilesAllowed] = React.useState(1);
  const [uploadTried, setUploadTried] = React.useState(p.uploadTried || false);
  const [showConfirmation, setShowConfirmation] = React.useState(
    p.displayConfirmation || false,
  );
  const [disableUpload, setDisableUpload] = React.useState(true);
  const [isUploading, setIsUploading] = React.useState(false);

  const toggleConfirmation = (toggle: boolean) => {
    setShowConfirmation(toggle);
  };

  const handleUpload = async () => {
    toggleConfirmation(false);
    setDisableUpload(true);
    setIsUploading(true);

    await uploadAction();
  };

  const uploadAction = async () => {
    const promises = files.map((file: CustomUploadFileInfo, index: number) => {
      return uploadFile(file, index, setFileAttributes, setUploadTried);
    });
    const response = await Promise.all(promises);

    if (!response.some((r) => r === 'failure') && p.onUpload) {
      p.onUpload();
    }
    setIsUploading(false);
  };

  const setFileAttributes = (
    file: CustomUploadFileInfo,
    index: number,
    sending = false,
    sent = false,
    error = false,
    errorMessage = '',
    sendPercent = null,
  ) => {
    file.sending = sending;
    file.sent = sent;
    file.error = error;
    file.errorMessage = errorMessage;
    file.sendPercent = sendPercent;
    const newFiles = [...files];
    newFiles[index] = file;
    setFiles(newFiles);
  };

  const addFile = () => {
    setFilesAllowed(filesAllowed + 1);
  };

  if (uploadTried) {
    return (
      <Status files={files} modalCallback={p.modalCallback} text={p.text} />
    );
  }

  return (
    <>
      <Dialog
        id="upload-modal"
        title={get(p, 'text.modalTitle', 'Upload Your Document')}
        onClose={() => p.modalCallback()}
      >
        <Container>
          <Text>
            {get(
              p,
              'text.modalInitialDesc',
              'Please select one or more files to upload to your leave.',
            )}
          </Text>
          <div className="d-flex" style={{ marginBottom: 36 }}>
            <div>
              <Image src={WarningIcon} alt="" />
            </div>
            <Text>
              A file cannot exceed 21 MB, accepted formats include pdf, jpg,
              doc, docx, png, tif, txt, rtf.
            </Text>
          </div>

          <SemiboldText style={{ marginBottom: 18 }}>
            Select Your Uploads:
          </SemiboldText>

          {[...Array(filesAllowed)].map((_, i) => (
            <FileSelectListItem
              key={`file-select-${i}`}
              index={i}
              files={files}
              setFiles={setFiles}
              caseId={p.caseId}
              planId={p.planId}
              setDisableUpload={setDisableUpload}
            />
          ))}
          <DocumentLink
            id="add-another-button"
            type="button"
            onClick={addFile}
            style={{ marginTop: 16 }}
          >
            Add another file
          </DocumentLink>
        </Container>
        <DialogActionsBar>
          <div className="d-flex justify-content-center justify-content-md-end">
            <Button
              id={'upload-cancel-button'}
              type="button"
              outline
              onClick={() => p.modalCallback()}
            >
              Cancel
            </Button>
            <Button
              id={'upload-button'}
              type="button"
              onClick={() => toggleConfirmation(true)}
              disabled={disableUpload}
            >
              <ClipLoader
                size={15}
                loading={isUploading}
                color={colors.white}
                css="margin-right:5px; margin-left: -12px;"
              />
              {isUploading ? 'Uploading' : 'Upload'}
            </Button>
          </div>
        </DialogActionsBar>
      </Dialog>

      {showConfirmation && (
        <Dialog
          id="upload-confirmation-modal"
          title={'Finished adding files?'}
          onClose={() => toggleConfirmation(false)}
          height={232}
        >
          <div>Have you finished adding all your files?</div>
          <DialogActionsBar>
            <div className="d-flex justify-content-center justify-content-md-end">
              <Button
                id={'upload-confirmation-cancel-button'}
                type="button"
                outline
                onClick={() => toggleConfirmation(false)}
              >
                Add another file
              </Button>
              <Button
                id={'upload-confirmation-button'}
                type="button"
                onClick={handleUpload}
              >
                Finished
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default UploadModal;
