import React from 'react';
import { formatBytes } from '../../../utils';
import { DocumentLink } from '../../base/simple-styled-components';
import {
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from '@progress/kendo-react-upload';
import { Upload, Text, UploadListItem, ErrorText } from '../index.sc';
import {
  CustomUploadFileInfo,
  convertExtensionToLowercase,
  allowedFileTypes,
} from '../../../utils/upload-helpers';

const MAX_FILE_SIZE = 21000000;

const filterFilesById = (
  fileToFilter: CustomUploadFileInfo,
  files: CustomUploadFileInfo[],
) => {
  return files.filter(
    (file: CustomUploadFileInfo) => file.uid && file.uid !== fileToFilter.uid,
  );
};

const ListItem = (props: any) => {
  const handleRemoval = (fileToRemove: any) => {
    props.onRemove(fileToRemove.uid);
  };

  return (
    <>
      {props.files.map((file: any, i: number) => (
        <div
          className="d-flex flex-column flex-md-row col-12 p-0"
          key={`file-info-${i}`}
        >
          <div className="d-flex flex-row col-sm-12 col-md-9 p-0">
            <Text className="col-7 col-md-6 p-0 noOverflow">{file.name}</Text>
            <Text className="col-5 col-md-6 text-right text-md-left pr-0">
              {formatBytes(file.size)}
            </Text>
          </div>
          <div className="col-sm-12 col-md-3 p-0 mt-4 mt-md-0">
            <DocumentLink
              id={`remove-button-${i}`}
              className="col-3 text-left"
              onClick={() => handleRemoval(file)}
            >
              Remove
            </DocumentLink>
          </div>
        </div>
      ))}
    </>
  );
};

const FileSelectListItem = (p: any) => {
  const index = p.index;
  const [file, setFile] = React.useState<Array<CustomUploadFileInfo>>([]);
  const [showError, setShowError] = React.useState(false);

  const onAddFiles = (e: UploadOnAddEvent) => {
    setShowError(false);
    let errors = false;

    e.affectedFiles.forEach((newFile: CustomUploadFileInfo) => {
      newFile.extension = newFile.extension?.toLowerCase();
      newFile.name = convertExtensionToLowercase(newFile.name); //convert ext and fileName ext to lowercase due to S3 upload errors
      // conditionally assign caseId or planId for later processing
      Object.assign(
        newFile,
        p.caseId && { caseId: p.caseId },
        p.planId && { planId: p.planId },
      );
      if (newFile.validationErrors && newFile.validationErrors.length > 0) {
        errors = true;
      } else {
        const filteredFiles =
          file.length > 0 ? filterFilesById(file[0], p.files) : p.files;

        filteredFiles.push(newFile);
        p.setFiles(filteredFiles);
        p.setDisableUpload(false);

        setFile([newFile]);
      }
    });

    if (errors) {
      setShowError(true);
    }
  };

  const onRemoveFiles = (e: UploadOnRemoveEvent) => {
    const filteredFiles = filterFilesById(e.affectedFiles[0], p.files);
    p.setFiles(filteredFiles);

    if (filteredFiles.length === 0) {
      p.setDisableUpload(true);
    }

    setFile(e.newState);
  };

  return (
    <UploadListItem
      id={`file-list-item-${index}`}
      className="upload-list-item flex-column"
    >
      <div className="d-flex col-12 p-0">
        {file.length === 0 && (
          <Text
            className="w-100 d-flex align-items-center"
            style={{ marginBottom: 14 }}
          >
            <i>Select Upload </i>
          </Text>
        )}
        <Upload
          id={`file-select-button-${index}`}
          autoUpload={false}
          files={file}
          onAdd={onAddFiles}
          onRemove={onRemoveFiles}
          batch={false}
          withCredentials={false}
          selectMessageUI={SelectButton}
          restrictions={{
            maxFileSize: MAX_FILE_SIZE,
            allowedExtensions: allowedFileTypes,
          }}
          showActionButtons={false}
          listItemUI={ListItem}
        />
      </div>
      {showError && (
        <ErrorText id={`file-select-error-${index}`}>
          Please select a valid upload
        </ErrorText>
      )}
    </UploadListItem>
  );
};

const SelectButton = () => {
  return <div>Select</div>;
};

export default FileSelectListItem;
