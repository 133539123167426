import { logout } from '../auth-manager';
import { log } from '../log';
import { addError, atMaxErrors } from './api-stats';

const APP_JSON = 'application/json';

export const callApi = async <T = any>(
  url: string,
  method: string,
  headers?: { [name: string]: any } | undefined,
  body: any = undefined,
  queryParams: { [name: string]: any } = {},
): Promise<T> => {
  try {
    const r = await window.fetch(
      `/api${url}${compileQueryParams(queryParams)}`,
      {
        method,
        credentials: 'include',
        headers,
        body: body ? JSON.stringify(body) : undefined,
        cache: 'no-store',
      },
    );

    if (r.ok) {
      const data = await r
        .clone()
        .json()
        .catch(() => r.text());
      return data as T;
    }

    if (r.status === 401 || r.status === 403 || r.status === 404) {
      log.error(JSON.stringify(r));
      // if we get a 40X, logout
      await logout();
    }

    if (r.status >= 500 && r.status <= 599) {
      addError(url, method);
      if (atMaxErrors(url, method)) {
        await logout();
      }
    }

    throw Error(`Failed ${method}Api: ${url}, ${r.status}, ${r.statusText}`);
  } catch (e) {
    log.error(`callApi error: ${e}`);
    throw e;
  }
};

export const getApi = async <T>(
  url: string,
  headers?: { [name: string]: any },
  queryParams: { [name: string]: any } = {},
): Promise<T> => callApi(url, 'get', headers, undefined, queryParams);

export const postApi = async <T>(
  url: string,
  headers?: { [name: string]: any } | undefined,
  body?: any,
  queryParams: { [name: string]: any } = {},
): Promise<T> => {
  if (body) {
    headers = {
      ...headers,
      'Content-Type': APP_JSON,
    };
  }

  return callApi(url, 'post', headers, body, queryParams);
};

export const patchApi = async <T>(
  url: string,
  headers?: { [name: string]: any } | undefined,
  body?: any,
): Promise<T> => {
  if (body) {
    headers = {
      ...headers,
      'Content-Type': APP_JSON,
    };
  }

  return callApi(url, 'patch', headers, body);
};

export const putApi = async <T>(
  url: string,
  headers?: { [name: string]: any } | undefined,
  body?: any,
): Promise<T> => {
  if (body) {
    headers = {
      ...headers,
      'Content-Type': APP_JSON,
    };
  }

  return callApi(url, 'put', headers, body);
};

export const deleteApi = async <T>(
  url: string,
  headers?: { [name: string]: any } | undefined,
  body?: any,
): Promise<T> => callApi(url, 'delete', headers, body);

function compileQueryParams(queryParams: { [name: string]: any }) {
  return Object.keys(queryParams).reduce((acc, key, index) => {
    return `${acc}${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(
      queryParams[key],
    )}`;
  }, '');
}
