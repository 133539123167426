import { useEffect } from 'react';
import styled from 'styled-components';
import { ChatMessageBoxProps } from '@progress/kendo-react-conversational-ui';
import dayjs from 'dayjs';

// will be added to the current time to test lastTypingIndicator
const typingIntervalSeconds = -5;

// the last time we sent a typing indicator event through connect
let lastTypingIndicator = dayjs(new Date(0));

const SendButtonStyled = styled.div`
  height: 23px;
  width: 23px;
`;

const MessageBoxStyled = styled.div`
  align-items: center;
  border-top: 2px solid #d0dce3;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 4px;
  padding-right: 4px;
  scroll-behavior: smooth;
  z-index: 1;
`;

/**
 * Will only send if enough time has passed from lastTypingIndicator
 */
const attemptTypingIndicator = async () => {
  if (
    global.connectSession &&
    dayjs().add(typingIntervalSeconds, 'second').isAfter(lastTypingIndicator)
  ) {
    lastTypingIndicator = dayjs();
    await global.connectSession.sendEvent({
      contentType: 'application/vnd.amazonaws.connect.event.typing',
    });
  }
};

function resetInputHeight() {
  const textarea = document.querySelector('.custom-message-box-input');
  if (textarea) {
    textarea.setAttribute('style', 'height:auto');
  }
}

const MessageBoxInput = (props: any) => {
  useEffect(() => {
    const textarea = document.querySelector('.custom-message-box-input');
    if (textarea) {
      textarea.addEventListener('input', autoResize, false);
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener('input', autoResize);
      }
    };
  }, []);

  function autoResize(this: any) {
    this.style.height = 'auto';
    this.style.height = this.scrollHeight + 'px';
  }

  return (
    <textarea
      id="custom-textarea"
      rows={1}
      cols={10}
      wrap="soft"
      ref={props.messageInput.ref}
      className={
        props.messageInput.props.className + ' custom-message-box-input'
      }
      placeholder={props.messageInput.props.placeholder}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          props.messageInput.props.onKeyDown(e);
          resetInputHeight();
          e.preventDefault();
        }
      }}
      onChange={attemptTypingIndicator}
    />
  );
};

const SendButtonCustom = (props: any) => {
  return (
    <button
      id="custom-send-button"
      className={props.className}
      onClick={(e) => {
        props.onClick(e);
        resetInputHeight();
      }}
    >
      <SendButtonStyled />
    </button>
  );
};

function CustomMessageBox(props: ChatMessageBoxProps) {
  return (
    <>
      <MessageBoxStyled id="message-input-container">
        <MessageBoxInput {...props} />
        <SendButtonCustom {...props.sendButton.props} />
      </MessageBoxStyled>
    </>
  );
}

export default CustomMessageBox;
