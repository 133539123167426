
import { ContentItem, Elements } from '@kentico/kontent-delivery';
import { IntakeSection } from './intake_section';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class IntakeSelection extends ContentItem {
    public nextButtonText!: Elements.TextElement;
    public header!: Elements.TextElement;
    public sections!: Elements.LinkedItemsElement<IntakeSection>;
    public backButtonText!: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'next_button_text') {
                    return 'nextButtonText';
                }
                if (elementName === 'back_button_text') {
                    return 'backButtonText';
                }
                return elementName;
            })
        });
    }
}
