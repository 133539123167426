import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import {
  ComboBoxChangeEvent,
  ComboBox,
  ComboBoxProps,
} from '@progress/kendo-react-dropdowns';
import { prefixObjectValues } from '../../../utils';

export type OptionType = {
  option: string;
  fieldType: string;
  value?: string;
};

interface FilterDropDownProps extends ComboBoxProps {
  keyId?: string;
  options: Array<OptionType>;
  onChange: (e: ComboBoxChangeEvent) => void;
  defaultValue: string;
}

const domIdsStatic = {
  rootNode: 'filter-drop-down',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const ComboBoxMod = styled(ComboBox)`
  height: 32px;
  margin-right: 16px;
  width: 204px;

  .k-select {
    background-color: ${colors.white};
    border: none;
  }

  .k-i-close {
    height: 32px;
  }

  .k-input {
    height: 32px;
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (max-width: 720px) {
    margin-right: 0;
    width: 100%;
    max-width: 276px;
  }
`;

const ListItem = styled.span`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin: 0;
  padding-top: 7px;
  padding-bottom: 6px;
`;

/**
 * Render the item list with custom li styling and a new classname to match special
 * root styling selector.
 * @param li React.ReactElement<HTMLLIElement>
 * @returns new React.ReactElement<HTMLLIElement>
 */
const itemRender = (li: React.ReactElement<HTMLLIElement>) => {
  const newProps = {
    ...li.props,
    className: li.props.className + ' filter-option',
  };
  const itemChildren = <ListItem>{li.props.children[0]}</ListItem>;

  return React.cloneElement(li, newProps, itemChildren);
};

/**
 * A custom styled dropdown list to filter a list of items.
 * @param p FilterDropDownProps
 * @returns React.ReactElement
 */
const FilterDropDown: (p: FilterDropDownProps) => React.ReactElement = (p) => {
  const domIds = domIdsUnique(p.keyId);
  const { options, onChange, defaultValue } = p;

  //Disable combobox typing ability
  React.useEffect(() => {
    const comboboxInput = document.getElementById(domIds.rootNode);
    if (comboboxInput) {
      (comboboxInput as HTMLInputElement).readOnly = true;
    }
  }, [domIds.rootNode]);

  return (
    <ComboBoxMod
      {...p}
      id={domIds.rootNode}
      defaultValue={{ option: defaultValue }}
      data={options}
      textField="option"
      groupField="fieldType"
      itemRender={itemRender}
      onChange={onChange}
      clearButton={false}
    />
  );
};

export default FilterDropDown;
