/* eslint-disable sonarjs/no-duplicate-string */
import { RangeElement } from '../../generic-calendar';
import { colors } from '../../../style';
import ApprovedIcon from '../../../images/leave-calendar/approved-absence-icon.svg';
import DeniedIcon from '../../../images/leave-calendar/denied-absence-icon.svg';
import PendingIcon from '../../../images/leave-calendar/pending-absence-icon.svg';
import LeaveIcon from '../../../images/leave-calendar/leave-period-icon.svg';

const CONTENT = '{{data.length}} ABS';

const overlayStyle = {
  backgroundColor: 'red',
  content: `''`,
  position: 'absolute',
  height: '100%',
  width: '100%',
  borderRadius: '18px',
  background:
    'transparent linear-gradient(180deg, #FFFFFF 0%, #000000 100%) 0% 0% no-repeat padding-box',
  opacity: 0.2,
  mixBlendMode: 'overlay',
  marginLeft: '-2px',
};

const fontStyle = {
  fontSize: '9px',
  fontFamily: 'Roboto',
  fontWeight: 700,
  paddingLeft: 0,
};

const borderStyle = '1px dashed ';

export const calendarLegend = {
  approvedIntermittent: {
    style: {
      backgroundColor: colors.greenHaze1,
      justifyContent: 'center',
      borderRadius: '18px',
      '&::before': overlayStyle,
      ...fontStyle,
    },
    content: CONTENT,
    order: 0,
    elementType: RangeElement,
    filterable: true,
    cardStyle: {
      backgroundColor: '#ffffff',
      color: '#000000',
    },
    statusText: 'APPROVED',
    legendItem: true,
    legendIcon: ApprovedIcon,
    legendText: 'ALL ABSENCES ARE APPROVED',
    showDecisions: true,
  },
  pendingIntermittent: {
    style: {
      backgroundColor: colors.bridesmaid,
      border: borderStyle + colors.christine,
      color: colors.christine,
      justifyContent: 'center',
      borderRadius: '18px',
      '&::before': overlayStyle,
      ...fontStyle,
    },
    content: CONTENT,
    order: 0,
    elementType: RangeElement,
    filterable: true,
    cardStyle: {
      backgroundColor: '#ffffff',
      color: '#000000',
    },
    statusText: 'PENDING',
    legendItem: true,
    legendIcon: PendingIcon,
    legendText: 'SOME ABSENCES ARE PENDING OR DENIED',
  },
  partialIntermittent: {
    style: {
      backgroundColor: colors.bridesmaid,
      border: borderStyle + colors.christine,
      color: colors.christine,
      justifyContent: 'center',
      borderRadius: '18px',
      '&::before': overlayStyle,
      ...fontStyle,
    },
    content: CONTENT,
    order: 0,
    elementType: RangeElement,
    filterable: true,
    cardStyle: {
      backgroundColor: '#ffffff',
      color: '#000000',
    },
    statusText: 'PARTIAL',
    showDecisions: true,
  },
  deniedIntermittent: {
    style: {
      backgroundColor: colors.cardinal,
      justifyContent: 'center',
      borderRadius: '18px',
      '&::before': overlayStyle,
      ...fontStyle,
    },
    content: CONTENT,
    order: 0,
    elementType: RangeElement,
    filterable: true,
    cardStyle: {
      backgroundColor: '#ffffff',
      color: '#000000',
    },
    statusText: 'DENIED',
    legendItem: true,
    legendIcon: DeniedIcon,
    legendText: 'ALL ABSENCES ARE DENIED',
    showDecisions: true,
  },
  leaveRequest: {
    legendItem: true,
    legendIcon: LeaveIcon,
    legendText: 'LEAVE REQUEST',
  },
};
