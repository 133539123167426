import styled from 'styled-components';
import { colors, rem } from '../../../../style';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { MaskedTextBox, Text } from '../../simple-styled-components';
import { enums, timeDurationIsValid } from '../../../../utils';
import React from 'react';
import { Button } from '@progress/kendo-react-buttons';

const MAX_WEEKS = 4;
export const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const defaults = {
  weekCount: 4,
  dayHourCount: 24,
  initialWeekHours: [0, 0, 0, 0, 0, 0, 0],
};

export interface WeeklyScheduleProps extends FieldRenderProps {
  weekCount?: number;
  dayHourCount?: number;
  initialWeekHours?: number[];
  processedProps?: boolean;
}

export interface WeeklyScheduleResult {
  weeks: string[][];
}

const Container = styled.div`
  background-color: ${colors.coolNeutralL1};

  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;

export interface GridProps {
  weekCount: number;
}

const getHeaderTemplate = (weekCount: number) =>
  `'${'header '.repeat(weekCount + 1).trim()}'`;

const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${rem(50)} repeat(${(p) => p.weekCount}, ${rem(80)});
  grid-template-rows: auto;
  align-items: center;
  gap: ${rem(5)};
  grid-template-areas: ${(p) => getHeaderTemplate(p.weekCount)};
  place-items: center;
`;

export const calcFromMinutes = (m: number) => {
  if (m < 60) {
    return [0, m];
  }

  return [1, m - 60];
};

export const addTimes = (pv: string, cv: string) => {
  // ignore while they are editing values
  if (cv.includes('_') && !pv.includes('_')) {
    return pv;
  } else if (!cv.includes('_') && pv.includes('_')) {
    return cv;
  } else if (cv.includes('_') && pv.includes('_')) {
    return '00:00';
  }

  // previous value parts
  const pp = pv.split(':');
  // current value parts
  const cp = cv.split(':');

  const hours = parseInt(pp[0]) + parseInt(cp[0]);
  const minutes = parseInt(pp[1]) + parseInt(cp[1]);
  const [addHours, remainderMinutes] = calcFromMinutes(minutes);

  return `${String(hours + addHours).padStart(2, '0')}:${String(
    remainderMinutes,
  ).padStart(2, '0')}`;
};

const WeekHoursTotal = ({
  schedule,
  weekIndex,
}: {
  schedule: string[][];
  weekIndex: number;
}) => {
  const [total, setTotal] = React.useState('00:00');

  React.useEffect(() => {
    setTotal(schedule[weekIndex].reduce(addTimes));
  }, [schedule, weekIndex]);

  return (
    <Text fontType={enums.FontTypes.semibold} color="#037CB7">
      {total}
    </Text>
  );
};

export interface IHandleUseEffectProcessedProps {
  p: any;
  value: any;
  onChange: (v: any) => void;
  processedProps: boolean;
  weekCount: number;
  setWeekCount: React.Dispatch<React.SetStateAction<number>>;
  setSchedule: React.Dispatch<React.SetStateAction<string[][]>>;
}

export const convertHourNumberToTimeString = (n: number) => {
  if (typeof n === 'string') {
    return n as unknown as string;
  }

  const h = Math.trunc(n);
  const m = (n - h) * 60;
  const hs = String(h).padStart(2, '0');
  const ms = String(m).padStart(2, '0');
  return `${hs}:${ms}`;
};

export const convertNumberScheduleToStringSchedule = (schedule: number[][]) =>
  schedule.map((w) => w.map((d) => convertHourNumberToTimeString(d)));

export const handleUseEffectProcessedProps = ({
  p,
  value,
  onChange,
  processedProps,
  weekCount,
  setWeekCount,
  setSchedule,
}: IHandleUseEffectProcessedProps) => {
  if (processedProps) {
    const newWeekCount = value?.weeks?.length || p.weekCount || weekCount;
    const newSchedule = convertNumberScheduleToStringSchedule([
      ...(value?.weeks ||
        (p.initialWeekHours
          ? [p.initialWeekHours]
          : [defaults.initialWeekHours])),
      ...Array.from(
        { length: MAX_WEEKS - (value?.weeks ? value.weeks.length : 1) },
        () => p.initialWeekHours || defaults.initialWeekHours,
      ),
    ]);

    setWeekCount(newWeekCount);
    setSchedule(newSchedule);
    if (value === undefined) {
      onChange({
        value: { weeks: newSchedule.slice(0, newWeekCount) },
      });
    }
  }
};

const WeeklySchedule: (p: WeeklyScheduleProps) => React.ReactElement = ({
  onChange,
  processedProps,
  value,
  ...p
}) => {
  const [weekCount, setWeekCount] = React.useState<number>(
    value?.weeks?.length || p?.weekCount || defaults.weekCount,
  );
  const [schedule, setSchedule] = React.useState<string[][]>(
    convertNumberScheduleToStringSchedule([
      ...(value?.weeks ||
        (p?.initialWeekHours
          ? [p.initialWeekHours]
          : [defaults.initialWeekHours])),
      ...Array.from(
        { length: MAX_WEEKS - (value?.weeks ? value.weeks.length : 1) },
        () => p?.initialWeekHours || defaults.initialWeekHours,
      ),
    ]),
  );

  React.useEffect(() => {
    handleUseEffectProcessedProps({
      p,
      value,
      onChange,
      processedProps: processedProps === true,
      weekCount,
      setWeekCount,
      setSchedule,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedProps, value]);

  const handleOnChange = React.useCallback(
    (selectedHours: string, weekIndex: number, dayIndex: number) => {
      // new list
      const nl: any[] = JSON.parse(JSON.stringify(schedule));
      nl[weekIndex][dayIndex] = selectedHours;

      onChange({
        value: { weeks: nl.slice(0, weekCount) },
      });
    },
    [onChange, schedule, weekCount],
  );

  const handleWeekCountChange = (increment: boolean) => {
    // new count
    const nc = weekCount + (increment ? 1 : -1);
    setWeekCount(nc);
    onChange({
      value: { weeks: schedule.slice(0, nc) },
    });
  };

  const getScheduleCell = React.useCallback(
    (wci: number, wdi: number) =>
      schedule?.length > 0 && schedule[0] !== undefined
        ? schedule[wci][wdi]
        : '00:00',
    [schedule],
  );

  const isCellValid = React.useCallback(
    (wci, wdi) => {
      // cell value
      const cv = getScheduleCell(wci, wdi);

      return timeDurationIsValid(cv);
    },
    [getScheduleCell],
  );

  const handleCellBlur = React.useCallback(
    (wci, wdi) => {
      // handle when the cell is "_8:00" and make it "08:00"
      const cv = getScheduleCell(wci, wdi);
      if (cv[4] === '_' && cv[3] !== '_' && cv[0] !== '0') {
        handleOnChange(`0${cv[0]}:${cv[1]}${cv[3]}`, wci, wdi);
        p.onBlur();
      }
    },
    [getScheduleCell, handleOnChange, p],
  );

  return (
    <Container id="form-weekly-schedule">
      <Text
        fontSize={16}
        fontType={enums.FontTypes.regular}
        className="tw-mb-5"
      >
        Please indicate the number of hours worked each day in the corresponding
        cells of the work schedule e.g. 8:00 hours.
      </Text>
      <Text
        fontSize={16}
        fontType={enums.FontTypes.regular}
        className="tw-mb-5"
      >
        Please only add overtime (OT) hours if they are built into your regular
        schedule.
      </Text>
      <Text className="d-inline" fontSize={16}>
        Add Week
      </Text>
      &nbsp;&nbsp;
      {weekCount > 1 && (
        <Button
          id="form-weekly-schedule-adjust-minus"
          look="clear"
          onClick={(e) => {
            e.preventDefault();
            handleWeekCountChange(false);
          }}
        >
          <span
            role="presentation"
            className="k-icon k-i-minus-circle"
            style={{ color: '#037CB7' }}
          ></span>
        </Button>
      )}
      {weekCount < 4 && (
        <Button
          id="form-weekly-schedule-adjust-plus"
          look="clear"
          onClick={(e) => {
            e.preventDefault();
            handleWeekCountChange(true);
          }}
        >
          <span
            role="presentation"
            className="k-icon k-i-plus-circle"
            style={{ color: '#037CB7' }}
          ></span>
        </Button>
      )}
      <Grid weekCount={weekCount}>
        <div />
        {Array.from({ length: weekCount }).map((_v: unknown, i: number) => (
          <Text key={i} fontType={enums.FontTypes.semibold} fontSize={14}>
            Week {i + 1}
          </Text>
        ))}
        {weekdays.map((wd, wdi) => {
          return (
            <React.Fragment key={wdi}>
              <div>{wd}</div>
              {Array.from({ length: weekCount }).map((_v, wci) => (
                <MaskedTextBox
                  id={`form-weekly-schedule-mtb-${wci}_${wdi}`}
                  key={wci}
                  onChange={(e) => handleOnChange(e.target.value, wci, wdi)}
                  value={getScheduleCell(wci, wdi)}
                  mask="00:00"
                  placeholder="HH:MM"
                  valid={isCellValid(wci, wdi)}
                  onBlur={() => handleCellBlur(wci, wdi)}
                  dir="RTL"
                  onFocus={(e) => e.target.element?.select()}
                />
              ))}
            </React.Fragment>
          );
        })}
        <Text fontType={enums.FontTypes.semibold} color="#037CB7">
          Total
        </Text>
        {Array.from({ length: weekCount }).map((_w, i) => (
          <WeekHoursTotal key={i} schedule={schedule} weekIndex={i} />
        ))}
      </Grid>
      {/* <div className="mt-3">

      </div> */}
    </Container>
  );
};

export default WeeklySchedule;
