import dayjs from 'dayjs';
import { get } from '../';
import { overlapRuleType } from './rules';
import { ProcessedCalendarItem, DateRange } from '.';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

/**
 * Creates an array of individual dates from a date range.
 * @param range
 * @returns DateRange[]
 */
export const createDaysFromRange = (
  range: ProcessedCalendarItem,
  isRange = true,
) => {
  const dates: DateRange[] = [];
  let currentDay = dayjs(range.startDate);

  if (isRange) {
    while (currentDay.isSameOrBefore(dayjs(range.endDate), 'd')) {
      dates.push({
        date: currentDay,
        legendKey: range.legendKey,
        data: [range],
      });
      currentDay = currentDay.add(1, 'd');
    }
  } else {
    // intermittent objs should be a single day. (code above causes issues on absences that span midnight)
    dates.push({
      date: currentDay,
      legendKey: range.legendKey,
      data: [range],
    });
  }
  return dates;
};

/**
 * Operation that rolls up dateRanges, appending the rollupValue of the date range with the greatest value. Will keep
 * distict date ranges for the periods of time in the ranges that do not overlap if the property value does not match
 * the rollupValue. Ex. this is used for rolling up paid benefit percentages in the intake calendar.
 * @param ranges
 * @param params
 * @returns DateRange[]
 */
const handleGreatestValue = (ranges: any[], params: string[]) => {
  const dates: DateRange[] = [];

  //Create array of individual date arrays from the ranges
  ranges.forEach((item: any) => dates.push(...createDaysFromRange(item)));

  //sort the dates
  const sortFn = (a: DateRange, b: DateRange) =>
    dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1;
  const sorted = [...dates].sort(sortFn);

  //Reduce days to distinct dates with the greatest rollupValue
  const reduced = sorted.reduce((results: any, item: any) => {
    const current = results.find((i: any) =>
      dayjs(i.date).isSame(dayjs(item.date), 'd'),
    );
    if (current) {
      const rollupValue = get(item, `data.0.${params[0]}`);
      if (current.rollupValue < rollupValue) {
        current.rollupValue = rollupValue;
      }
    } else {
      results.push({ ...item, rollupValue: get(item, `data.0.${params[0]}`) });
    }
    return results;
  }, []);

  //Create new ranges from days
  const newRanges: any = [];
  let last = 0;
  reduced.forEach((d: any, i: number) => {
    if (i === 0) {
      newRanges.push({ startDate: d.date, endDate: d.date, ...d });
      return;
    }
    const prev = newRanges[last];
    const nextDateInRange = prev.endDate.add(1, 'd');
    const isContinuation =
      nextDateInRange.isSame(d.date) && prev.rollupValue === d.rollupValue;
    if (isContinuation) {
      prev.endDate = nextDateInRange;
    } else {
      newRanges.push({ startDate: d.date, endDate: d.date, ...d });
      last++;
    }
  });

  return newRanges;
};

/**
 * Operation to rollup overlapping date ranges/dates. The first param indicates the path to the rollup.
 * The second param is the key to append if the properties on the overlapping dates are mixed (ex. for the
 * intermittent absence calendar, mixed decisions are rolled up as the partialIntermittent style) The isRange
 * flag determines whether the end result will rollup by individual dates or date ranges.
 * @param items
 * @param params
 * @param isRange
 * @returns DateRange[]
 */
const handleRollup = (items: DateRange[], params: string[], isRange = true) => {
  const propToRollup = params[0];
  const rollupValueWhenMixed = params[1];

  const dates: any = [];
  items.forEach((item: any, _i: number) =>
    dates.push(...createDaysFromRange(item, isRange)),
  );

  const sortFn = (a: DateRange, b: DateRange) =>
    dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1;
  const sorted = [...dates].sort(sortFn);

  const result: any = [];
  let last = 0;
  sorted.forEach((day, i: number) => {
    if (i === 0) {
      result.push({
        startDate: day.date,
        endDate: day.date,
        ...day,
        [propToRollup]: get(day.data[0], propToRollup),
      });
      return;
    }

    const prev = result[last];
    const next = prev.endDate.add(1, 'd');

    //Same day && legendKey eqs legendKey. If both true, roll up with rollupValue
    const isSameDay = prev.endDate.isSame(day.date, 'd');
    const matchingRollupValue =
      get(day.data[0], propToRollup) === prev[propToRollup];

    //If same day, but legendKeys don't match, roll up with rollupValueWhenMixed.

    //If next day from previous date,
    const isContinuationOfRange = next.isSame(day.date, 'd');

    if (isSameDay || (isRange && isContinuationOfRange)) {
      result[last].endDate = day.date;

      if (isSameDay && !isRange) {
        result[last].data.push(...day.data);
      }
      result[last][propToRollup] = matchingRollupValue
        ? result[last][propToRollup]
        : rollupValueWhenMixed;
    } else {
      result.push({
        startDate: day.date,
        endDate: day.date,
        ...day,
        [propToRollup]: get(day.data[0], params[0]),
      });
      last++;
    }
  });
  return result;
};

/**
 * Groups items in an array by a specified key.
 * @param data any[]
 * @param key - the key to group by
 * @returns object with grouped items
 */
export const groupByKey = (data: any, key: string) => {
  return data.reduce((arr: any, item: any) => {
    const group = item[key];
    if (group) {
      arr[group] = arr[group] || [];
      arr[group].push(item);
    }
    return arr;
  }, {});
};

/**
 * Option to rollup the overlapping dates of items with a matching property. The property to match should be
 * passed as the first element in the params array and should indicate the path to the property on the date object.
 * Ex. For Continuous leaves, we want to rollup overlapping ranges that have the same status while keeping overlapping
 * ranges with different statuses distinct.
 * @param items DateRange[]
 * @param params string[]
 * @returns DateRang[]
 */
const handleRollupByGroup = (items: DateRange[], params: string[]) => {
  const grouped = groupByKey(items, params[0]);

  const result: DateRange[] = [];
  Object.keys(grouped).forEach((g: string) => {
    result.push(...handleRollup(grouped[g], [params[0], g]));
  });

  return result;
};

// The available overlap operations that can be referenced in the rules.
const overlapOperationMap: { [key: string]: Function } = {
  greatestValue: handleGreatestValue,
  rollup: (items: any, params: string[]) => handleRollup(items, params, false),
  rollupRange: handleRollup,
  rollupByGroup: handleRollupByGroup,
  none: (items: any, _params: string[]) => items,
};

/**
 * Finds the appropriate function to run against the date array based on the individual overlap rule. See overlapOperationMap.
 * @param items
 * @param overlapRule
 * @returns DateRange[]
 */
const handleOverlap = (items: DateRange[], overlapRule: overlapRuleType) => {
  const operation = overlapOperationMap[overlapRule.operation];

  return operation(items, overlapRule.params);
};

//TODO decide whether to change DateRange to be something more legible.
/**
 * The main overlap handler. Accepts an array of date ranges and the associated rules to handle overlapping dates and ranges.
 * Evaluates each rule against the date array. The rules should be comprehensive for the data set, and the operation of none
 * should be used for keys that should not evaluate overlaps.
 * @param dateRanges
 * @param overlapRules
 * @returns An array of evaluated dates
 */
export const mergeOverlappingDateRanges = (
  dateRanges: DateRange[],
  overlapRules: overlapRuleType[],
) => {
  const sortFn = (a: DateRange, b: DateRange) =>
    dayjs(a.startDate).isBefore(dayjs(b.startDate)) ? -1 : 1;

  const result: DateRange[] = [];
  overlapRules.forEach((overlapRule) => {
    const matchedItemsForRule = dateRanges.filter((r) =>
      overlapRule.keys.includes(r.legendKey),
    );

    const sortedItems = [...matchedItemsForRule].sort(sortFn);

    const evaluatedItems = handleOverlap(sortedItems, overlapRule);

    result.push(...evaluatedItems);
  });

  return result;
};
