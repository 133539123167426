import styled from 'styled-components';
import { colors } from '../../../../style';
import { prefixObjectValues } from '../../../../utils';
import { FadeLoader } from 'react-spinners';
import { Text } from '../../simple-styled-components';

interface LoadingIndicatorProps {
  keyId?: string;
  text?: string;
}

const domIdsStatic = {
  rootNode: 'loading-indicator',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  background-color: ${colors.coolNeutralL1};
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const LoadingIndicator: (p: LoadingIndicatorProps) => React.ReactElement = (
  p,
) => {
  const domIds = domIdsUnique(p.keyId);

  return (
    <Container id={domIds.rootNode}>
      <LoadingContainer>
        <FadeLoader
          color={colors.denim}
          css={
            'display: inline-block; vertical-align: middle; zoom: 0.9; margin-top: 24px; margin-bottom: 11px;'
          }
        />
        {p.text && (
          <Text
            fontSize={16}
            color={colors.denim}
            style={{
              textAlign: 'center',
              maxWidth: 248,
              fontWeight: 500,
              lineHeight: '22px',
            }}
          >
            {p.text}
          </Text>
        )}
      </LoadingContainer>
    </Container>
  );
};

export default LoadingIndicator;
