import React from 'react';
import styled from 'styled-components';
import { colors, fonts, rem } from '../../../style';
import { prefixObjectValues, getHtmlFromMarkdown } from '../../../utils';
import { FieldWrapper, FieldRenderProps } from '@progress/kendo-react-form';
import Check from '../../../images/white-check.svg';
import useHandlebars from '../../../hooks/use-handlebars';
import { PersonDataContext } from '../../../contexts/person-data-context';
import { PreferencesContext } from '../../../contexts/preferences-context';
import { models } from '../../../utils/cms-client';

interface IntakeCardProps extends FieldRenderProps {
  keyId?: string;
  card: models.IntakeCard;
  name: string;
}

const domIdsStatic = {
  rootNode: 'intake-card',
  radioButton: 'radio-button',
  notFound: 'not-found-card',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 16px;
  max-width: 488px;
  padding: 32px;
  width: 100%;
  min-width: 300px;
  flex: 0 0 calc(50% - 16px);

  &.modified.notChecked {
    background-color: #eff3f8;
    border: 1px solid #d3e1ee;
    box-shadow: 0 0 0 ${colors.white};
  }

  &:nth-child(odd) {
    margin-right: 32px;
  }

  @media screen and (max-width: 700px) {
    margin-right: 0 !important;
    min-width: 0;
    flex: 1 1 100%;
  }
`;

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Header = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: ${rem(28)};
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  line-height: 32px;
  margin-left: 16px;
`;

const Description = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(16)};
  font-weight: ${fonts.openSans.regular.fontWeight};
  line-height: 24px;
  margin: 19px 0 21px;
`;

const SmallOutlineButton = styled.label`
  background-color: ${colors.white};
  border: 2px solid ${colors.denim};
  border-radius: 24px;
  color: ${colors.denim};
  cursor: pointer;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: ${rem(16)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  line-height: 22px;
  margin: 0;
  max-width: 140px;
  padding: 3px 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
`;

const StyledRadio = styled.input`
  opacity: 0;
  position: fixed;
  width: 0;

  :checked + label {
    content: url(${Check});
    background-color: ${colors.denim};
    border: 2px solid ${colors.skyD2};
    color: ${colors.white};
    height: 32px;
    padding: 6px 0;
  }

  :hover + label {
    background-color: ${colors.denim};
    border: 2px solid ${colors.skyD2};
    color: ${colors.white};
  }
`;

const NotFoundContainer = styled(Container)`
  align-items: center;
  background-color: #eff3f8;
  border: 1px solid #d3e1ee;
  box-shadow: 0 0 0 ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 259px;
`;

const MediumHeader = styled(Header)`
  font-size: ${rem(20)};
  margin: 0;
`;

const IntakeCard: (p: IntakeCardProps) => React.ReactElement = (p) => {
  const { card } = p;
  const { personData } = React.useContext(PersonDataContext);
  const { employerPreferences } = React.useContext(PreferencesContext);
  const domIds = domIdsUnique(p.keyId);

  const content = useHandlebars(card.description.value, {
    ...personData,
    employerPreferences,
  });

  if (p.name && card.buttonText?.value?.length) {
    const optionChecked = p.value === card?.path?.value;

    return (
      <Container
        id={domIds.rootNode}
        className={`${p.modified && 'modified'} ${
          optionChecked ? 'checked' : 'notChecked'
        }`}
      >
        <TitleBar>
          {card.icon.value.length > 0 ? (
            <>
              <img src={card.icon.value[0].url} alt="" />
              <Header>{card.header.value}</Header>
            </>
          ) : (
            <Header style={{ marginLeft: '0px' }}>{card.header.value}</Header>
          )}
        </TitleBar>
        <Description dangerouslySetInnerHTML={getHtmlFromMarkdown(content)} />
        <FieldWrapper>
          <div>
            <StyledRadio
              {...p}
              type="radio"
              id={domIds.radioButton}
              name={p.name}
              value={card.path.value}
              checked={optionChecked}
              data-form-path={card.path.value}
            />
            <SmallOutlineButton htmlFor={domIds.radioButton}>
              {card.buttonText.value}
            </SmallOutlineButton>
          </div>
        </FieldWrapper>
      </Container>
    );
  } else {
    return (
      <NotFoundContainer id={domIds.notFound}>
        <MediumHeader>{card.header.value}</MediumHeader>
        <Description dangerouslySetInnerHTML={getHtmlFromMarkdown(content)} />
      </NotFoundContainer>
    );
  }
};

export default IntakeCard;
