import React from 'react';
import { getLocalizedOrDefault } from '../../../utils';
import { AppPaths, ButtonTypes } from '../../../utils/enums';
import ChatEventTemplate from '../chat-event-template';
import { useHistory } from 'react-router-dom';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';
import { ChatButton } from '../index.sc';

interface ConnectionFailedTemplateProps {
  message: any;
}

const ConnectionFailedTemplate: (
  p: ConnectionFailedTemplateProps,
) => React.ReactElement = (p) => {
  const { callCenter, localizedStrings } = React.useContext(KenticoDataContext);
  const history = useHistory();

  const handleScheduleCallback = React.useCallback(() => {
    history.push(AppPaths.contactUsCallback);
    global.displayLiveChat(false);
  }, [history]);

  return (
    <>
      {callCenter.open && (
        <div id="connection-failed-message" style={{ pointerEvents: 'all' }}>
          <ChatEventTemplate
            message="If you'd still like to chat with a specialist, you can try and connect again, or schedule a call for us to contact you."
            localizedName="live_chat_failure_options"
            messageStyle={{ fontSize: 16, marginTop: 20, marginBottom: 26 }}
          />
          <ChatButton
            id="connection-fail-retry"
            onClick={(e) => p.message.handleReconnect(e, true)}
            buttonStyle={ButtonTypes.primary}
            style={{ marginBottom: 16 }}
          >
            {getLocalizedOrDefault(
              localizedStrings,
              'live_chat_try_again_button',
              'Try again',
            )}
          </ChatButton>
          {/* <Link to={AppPaths.contactUsCallback}> */}
          <ChatButton
            id="connection-fail-callback"
            buttonStyle={ButtonTypes.outline}
            style={{ padding: '6px 48px', fontWeight: 700 }}
            onClick={handleScheduleCallback}
          >
            {getLocalizedOrDefault(
              localizedStrings,
              'live_chat_scheduled_callback',
              'Schedule a callback',
            )}
          </ChatButton>
          {/* </Link> */}
        </div>
      )}
    </>
  );
};

export default ConnectionFailedTemplate;
