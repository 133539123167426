import React from 'react';
import * as dm from '../../data-models';
import { getEmployerPreferences, getPerson } from '../../utils/web-apis-client';
import {
  getEmployerContent,
  IEmployerContent,
} from '../../utils/remote-config-manager';

export interface IPreferencesContext {
  employerPreferences: dm.EmployerPreferences | undefined;
  ERContent?: IEmployerContent;
}

export type RefreshPreferences = (key?: string) => Promise<void>;

export const PreferencesContext = React.createContext<{
  employerPreferences: IPreferencesContext['employerPreferences'];
  ERContent: IPreferencesContext['ERContent'];
  refresh: RefreshPreferences;
}>({
  employerPreferences: {} as any,
  ERContent: {} as any,
  refresh: async () => {},
});

export const PreferencesContextProvider = (p: {
  children: any;
  employerPreferences?: IPreferencesContext['employerPreferences'];
  ERContent?: IPreferencesContext['ERContent'];
}) => {
  const [employerPreferences, setEmployerPreferences] = React.useState<
    IPreferencesContext['employerPreferences']
  >(p.employerPreferences);
  const [ERContent, setERContent] = React.useState<
    IPreferencesContext['ERContent']
  >(p.ERContent);

  const refreshPreferences = async () => {
    setEmployerPreferences(await getEmployerPreferences());
  };

  const refreshERContent = async () => {
    const person = await getPerson();
    setERContent(await getEmployerContent(person.companyCode || ''));
  };

  const refresh = async (key?: string) => {
    switch (key) {
      case 'employerPreferences':
        await refreshPreferences();
        break;
      case 'ERContent':
        await refreshERContent();
        break;
      default:
        await refreshPreferences();
        await refreshERContent();
        break;
    }
  };

  React.useEffect(() => {
    if (!p.employerPreferences) {
      refreshPreferences();
    }

    if (!p.ERContent) {
      refreshERContent();
    }
  }, [p]);

  return (
    <PreferencesContext.Provider
      value={{ employerPreferences, ERContent, refresh }}
    >
      {p.children}
    </PreferencesContext.Provider>
  );
};
