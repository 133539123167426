import { Link } from 'react-router-dom';
import { useRemoteConfig } from '../../hooks/use-data';
import { enums } from '../../utils';
import Button from '../base/button';
import { ButtonTypes } from '../../utils/enums';
import { Eye } from './icons';

export const ExistingSuppLink = (
  props: Readonly<{ className?: string; text?: string }>,
) => {
  const { data: remoteConfig } = useRemoteConfig();

  return (
    <div className={props.className}>
      <a
        href={
          remoteConfig
            ? remoteConfig[enums.RemoteConfigKeys.iServicesIDPURL]
            : '#'
        }
      >
        <Button
          buttonStyle={ButtonTypes.secondary}
          id="start-supplemental-claim"
          className="tw-px-5 tw-rounded tw-w-full lg:tw-h-[72px] lg:tw-ml-4"
        >
          <Eye className="tw-fill-current tw-mr-2 tw-hidden lg:tw-inline" />
          {props.text}
        </Button>
      </a>
    </div>
  );
};

export const StartANewSuppLink = (
  props: Readonly<{
    className?: string;
    to?: { pathname: string; state: { defaults: { [name: string]: any } } };
  }>,
) => {
  return (
    <Link to={props.to ?? '#'} className={props.className}>
      <Button
        buttonStyle={ButtonTypes.primary}
        id="start-supplemental-claim"
        disabled={props.to === undefined}
        className="tw-px-5 tw-rounded lg:tw-rounded-full tw-w-full"
      >
        Start a New Supplemental Claim
      </Button>
    </Link>
  );
};
