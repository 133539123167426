import { Chat } from '@progress/kendo-react-conversational-ui';
import styled from 'styled-components';
import SendIcon from '../../images/airplane-icon.svg';
import { colors, fonts, rem, maxWidthQuery as widthQuery } from '../../style';
import Button from '../base/button';

const maxWidthQuery: any = widthQuery;

const MaxWidthNoneStyling = maxWidthQuery.phone`
max-width: none
`;

const MainContainer = styled.div`
  height: 100%;
  max-width: 375px;
  right: 0;
  position: fixed;
  ${MaxWidthNoneStyling};
  z-index: 1020;
  width: 100%;

  &.chat-minimized {
    z-index: -1;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 25px;
  top: 10px;
`;

const CoBrowseButtonContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
`;

const ReconnectContainer = styled.div`
  bottom: 15px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
`;

const ChatMod = styled(Chat)<{ hideMessageBox: boolean }>`
  height: 100%;
  margin: 0;
  max-width: 375px;
  ${MaxWidthNoneStyling};

  width: 100%;

  .k-message-box {
    border: 0;
    box-shadow: 0 -3px 12px #e0e7ed;
    display: ${(props) => (props.hideMessageBox ? 'none' : 'flex')};
    font-family: ${fonts.openSans.regular.fontFamily};
    font-size: ${rem(15)};
    font-weight: ${fonts.openSans.regular.fontWeight};
    padding: 0;

    .k-button-send {
      align-self: flex-end;
      color: #aec7d8;
      margin-bottom: 4px;
      :hover {
        color: ${colors.primaryBlue};
      }
      > div {
        background: url(${SendIcon});
      }
    }

    .k-input {
      resize: none;
    }

    .custom-message-box-input {
      padding: 5px 10px;
    }
  }

  &.k-chat {
    background: #d0dce3;
    background-position-y: 40px;
    border-left: 2px solid #9db7c6;

    .k-message-list-content {
      padding-bottom: ${(props) => (props.hideMessageBox ? '80px' : '32px')};
      padding-left: 32px;
      padding-right: 32px;
      padding-top: 2px;
    }

    .k-quick-replies {
      font-family: ${fonts.openSans.semibold.fontFamily};
      font-size: ${rem(16)};
      font-weight: ${fonts.openSans.semibold.fontWeight};
      margin-left: auto;
      margin-right: auto;
      max-width: 80%;

      .k-quick-reply {
        background: #fff;
        border: 0;
        display: block;
        margin-bottom: 12px;
        min-width: 125px;
        padding: 0;
        text-align: center;

        &.k-first {
          margin-top: 28px;
        }

        &:hover {
          background: #037cb7;
          border-color: #037cb7;
          color: #fff;
        }
      }
    }
    .k-message {
      margin-top: 0;
      pointer-events: none; /** don't expand on tapping a message */
      width: fit-content;

      &.k-state-selected {
        margin-bottom: 0;
        margin-top: 0;
      }

      &:nth-child(8) {
        padding-top: 30px;
      }
    }

    .k-message-group {
      display: flex;
      flex-flow: column;
      max-width: none;
      width: 95%;

      &.k-no-avatar:not(.k-alt) {
        max-width: 100%;
        width: 100%;

        .k-message {
          width: 100%;
        }
      }

      .k-bubble {
        border: 0;
        box-shadow: 0 6px 26px ${colors.bubbleShadow};
        font-size: ${rem(15)};
        margin-top: 8px;
        padding: 17px 30px 17px 23px;
      }

      &.k-alt {
        .k-bubble {
          background-color: ${colors.primaryBlue};
          border-radius: 30px 0 0 30px !important;
          color: ${colors.white};
          font-family: ${fonts.openSans.regular.fontFamily};
          font-weight: ${fonts.openSans.regular.fontWeight};
          transition: border-radius 0.5s;

          a {
            color: ${colors.white};
          }
        }

        .k-only,
        .k-first {
          .k-bubble {
            border-radius: 30px 30px 0 !important;
          }
        }

        .k-last {
          .k-bubble {
            border-radius: 30px 0 30px 30px !important;
            width: 100%;
          }
        }
      }

      &:not(.k-alt) {
        .k-bubble {
          background-color: ${colors.white};
          border-radius: 0 30px 30px 0;
          color: ${colors.warmNeutralL3};
          font-family: ${fonts.openSans.regular.fontFamily};
          font-weight: ${fonts.openSans.regular.fontWeight};
          transition: border-radius 0.5s;
        }

        .k-first {
          .k-bubble:not(.custom-tile) {
            border-radius: 30px 30px 30px 0 !important;
          }
        }

        .k-only,
        .k-last {
          .k-bubble {
            border-radius: 0 30px 30px !important;
            &.rounded {
              border-radius: 30px !important;
            }
          }
        }
      }

      p {
        margin-bottom: 0;
      }

      .k-typing-indicator {
        color: #7cc6ca;
      }
    }
  }

  .k-message-group:not(.k-alt) > .k-avatar {
    box-shadow: 0 3px 6px ${colors.bubbleShadow};
    top: 8px;
  }

  .k-typing-indicator span {
    opacity: 1;
  }

  .k-typing-indicator span:nth-of-type(1) {
    animation: 0.6s ease-in-out 0.07s infinite normal both running
      animation-10yals0;
  }

  .k-typing-indicator span:nth-of-type(2) {
    animation: 0.6s ease-in-out 0.17s infinite normal both running
      animation-10yals0;
  }

  .k-typing-indicator span:nth-of-type(3) {
    animation: 0.6s ease-in-out 0.27s infinite normal both running
      animation-10yals0;
  }

  @keyframes animation-10yals0 {
    33% {
      transform: translateY(2.5px);
    }
    66% {
      transform: translateY(-2.5px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

const ChatButton = styled(Button)`
  border-radius: 16px;
  font-size: 14px;
  line-height: 19px;
  width: 100%;
`;

export {
  ChatMod,
  MainContainer,
  ButtonContainer,
  ReconnectContainer,
  ChatButton,
  CoBrowseButtonContainer,
};
