import React from 'react';
import { Claim, Leave, PersonData } from '../../data-models';

export interface IFormContext extends PersonData {
  claimList?: Array<Claim | Leave>;
  dates?: {
    now: Date;
  };
  currentLeavePlan?: {
    planId: string;
  };
  stepIndexWithLeavePlanGraph?: number;
  [name: string]: any;
  paymentMethod?: string;
  smsOptIn?: string;
  config?: { [name: string]: string };
  leaveOnly?: boolean;
  hasVBCoverage?: boolean;
  isVBEmployerEnabled?: boolean;
  hidePaymentPrefs?: string;
  leavePlanSuccessfullyGenerated?: string;
  noApplicableNextSteps?: boolean;
}

export type TFormContextState = {
  formContext: IFormContext;
  setFormContext: React.Dispatch<React.SetStateAction<any>>;
  allowNavBack: boolean;
  setAllowNavBack: React.Dispatch<React.SetStateAction<boolean>>;
  allowNavForward: boolean;
  setAllowNavForward: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DefaultFormContextState = {
  formContext: {},
  setFormContext: () => {},
  allowNavBack: true,
  setAllowNavBack: () => {},
  allowNavForward: true,
  setAllowNavForward: () => {},
};

export const FormContext = React.createContext<TFormContextState>(
  DefaultFormContextState,
);

export const FormContextProvider = (p: {
  children: any;
  formContext?: { [name: string]: any };
}) => {
  const [formContext, setFormContext] = React.useState<IFormContext>(
    p.formContext ?? {},
  );
  const [allowNavBack, setAllowNavBack] = React.useState(true);
  const [allowNavForward, setAllowNavForward] = React.useState(true);

  React.useEffect(() => {
    if (p.formContext) {
      setFormContext(p.formContext);
    }
  }, [p.formContext]);

  return (
    <FormContext.Provider
      value={{
        formContext,
        setFormContext,
        allowNavBack,
        setAllowNavBack,
        allowNavForward,
        setAllowNavForward,
      }}
    >
      {p.children}
    </FormContext.Provider>
  );
};
