import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { colors, fonts, rem, zIndices } from '../../../style';
import { prefixObjectValues } from '../../../utils';
import { CenteredContentContainer } from '../simple-styled-components';
import { Spinner } from '../spinner';

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  rootNode: 'full-screen-loading-indicator-root-node',
};

/*******************************************************************************
 * Adds a unique prefix to the domIds to create a unique id
 * @param {string} prefix A unique prefix to add to the domIds
 * @returns {domIdsStatic} If a prefix is provided, then a copy of domIdsStatic
 * is returned with the property values modified to include the prefix. If a
 * prefix is not provided a copy of domIdsStatic is returned.
 ******************************************************************************/
const domIdsUnique = (prefix) => prefixObjectValues(prefix, domIdsStatic);

/*******************************************************************************
 * styles / styled components
 ******************************************************************************/

const Container = styled(CenteredContentContainer)`
  height: 400px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${zIndices.modal};
`;

const DisplayText = styled.p`
  color: ${colors.blue};
  font-family: ${fonts.ubuntu.light.fontFamily};
  font-weight: ${fonts.ubuntu.light.fontWeight};
  font-size: ${rem(46)};
  margin-bottom: 38px;
  margin-top: 106px;
`;

const DisableScrollY = createGlobalStyle`
  #root {
    overflow: hidden;
  }
`;

/*******************************************************************************
 * FullScreenLoadingIndicator component
 * @param {FullScreenLoadingIndicator.propTypes} props FullScreenLoadingIndicator propTypes
 ******************************************************************************/
const FullScreenLoadingIndicator = (props) => {
  const domIds = domIdsUnique(props.keyId);

  return (
    <Container className="fade-in-quick" id={domIds.rootNode}>
      <DisableScrollY />
      <DisplayText>{props.displayText}</DisplayText>
      <Spinner />
    </Container>
  );
};

/*******************************************************************************
 * prop types and defaults
 ******************************************************************************/
FullScreenLoadingIndicator.propTypes = {
  displayText: PropTypes.string.isRequired,
};

FullScreenLoadingIndicator.defaultProps = {
  displayText: 'Total Leave',
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { FullScreenLoadingIndicator, domIdsUnique };
