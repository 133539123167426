import React from 'react';
import { ElementModels } from '@kentico/kontent-delivery';
import {
  CallCenterDomainModel,
  getAllAssetReferences,
  getCallCenterClosureConfig,
  getFeatureFlags,
  getSimpleRemoteConfig,
  getLocalizedStrings,
  getMarkdownContent,
  IFeatureFlagMap,
  IDynamicContent,
  ILocalizedStrings,
} from '../../utils/remote-config-manager';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import { parseJSONOrDefault } from '../../utils';
import { defaults } from '../../components/forms/options';
import { KenticoDataKeys } from '../../utils/enums';
import { log } from '../../utils/log';
import { getPerson } from '../../utils/web-apis-client';

// const log = new Logger\('kentico-data-context');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
const TZ = 'America/New_York';

export interface IKenticoData {
  assets: {
    [name: string]: ElementModels.AssetModel;
  };
  callCenter: {
    closures: CallCenterDomainModel[];
    hoursOfOperation: { start: string; end: string };
    open?: boolean;
  };
  featureFlags: IFeatureFlagMap;
  config: any;
  localizedStrings: ILocalizedStrings;
  dynamicContent: IDynamicContent;
}

export interface IKenticoDataProviderProps {
  children: any;
  kenticoContext: IKenticoData;
}

export type RefreshKenticoData = (key?: KenticoDataKeys) => Promise<void>;

export const KenticoDataContext = React.createContext<{
  assets: IKenticoData['assets'];
  callCenter: IKenticoData['callCenter'];
  featureFlags: IKenticoData['featureFlags'];
  config: IKenticoData['config'];
  localizedStrings: IKenticoData['localizedStrings'];
  dynamicContent: IKenticoData['dynamicContent'];
  refreshData: RefreshKenticoData;
}>({
  assets: {} as any,
  callCenter: {} as any,
  featureFlags: {} as any,
  config: {} as any,
  localizedStrings: {} as any,
  dynamicContent: {} as any,
  refreshData: async () => {},
});

const getCompanyCode = async () => {
  const person = await getPerson();
  return person?.companyCode;
};

export const refreshCallCenter = async (companyCode?: string) => {
  const closures = await getCallCenterClosureConfig(
    dayjs().add(-1, 'd').toDate(),
  );
  const remoteConfig = await getSimpleRemoteConfig();

  const company = companyCode ?? (await getCompanyCode());

  const ids = remoteConfig['alternate_hours_of_operation_identifiers'];
  const configName =
    company && ids?.split?.('|')?.includes?.(company)
      ? 'unum_call_center_hours_of_operation'
      : 'call_center_hours_of_operation';

  // call center hours of operation
  const hoursOfOperation = parseJSONOrDefault(
    remoteConfig[configName],
    defaults.callCenterHoursOfOperation,
  );

  return { closures, hoursOfOperation };
};

export const isOpenBasedOnConfig = (
  d: dayjs.Dayjs,
  callCenter: IKenticoData['callCenter'],
) => {
  // 1. if during weekdays
  const dayOfWeek = d.day();
  let r = dayOfWeek > 0 && dayOfWeek < 6;
  log.debug('isOpenBasedOnConfig.1', r);

  // 2. and if during hours of operation
  // hours of operation start
  const hoos = dayjs(callCenter.hoursOfOperation.start)
    .tz(TZ, true)
    .set('year', d.year())
    .set('month', d.month())
    .set('date', d.date());
  // hours of operation end
  const hooe = dayjs(callCenter.hoursOfOperation.end)
    .tz(TZ, true)
    .set('year', d.year())
    .set('month', d.month())
    .set('date', d.date());

  r = r && d.isBetween(hoos, hooe);
  log.debug('isOpenBasedOnConfig.2', {
    start: hoos,
    end: hooe,
    compareDate: d,
  });

  // 3. and if not in a closure period
  r =
    r &&
    !callCenter.closures.some((c) => {
      let startOfClosure = dayjs(c.start);
      let endOfClosure = dayjs(c.end);
      if (c.allDay) {
        startOfClosure = dayjs(c.start)
          .set('hour', hoos.hour())
          .set('minute', hoos.minute())
          .set('second', hoos.second());
        endOfClosure = dayjs(c.end)
          .set('hour', hooe.hour())
          .set('minute', hooe.minute())
          .set('second', hooe.second());
      }
      return d.isBetween(startOfClosure, endOfClosure, 'm', '[)');
    });
  log.debug('isOpenBasedOnConfig.3', r);

  return r;
};

/**
 * Provides data from Kentico, allows for refreshes
 * @param p Props
 * @returns React Context Provider
 */
export const KenticoDataContextProvider = (p: {
  children: any;
  assets: IKenticoData['assets'];
  callCenter: IKenticoData['callCenter'];
  featureFlags: IKenticoData['featureFlags'];
  config: IKenticoData['config'];
  localizedStrings: IKenticoData['localizedStrings'];
  dynamicContent: IKenticoData['dynamicContent'];
}) => {
  const callCenterChangeTimer = React.useRef<NodeJS.Timeout>();
  const now = React.useMemo(() => dayjs().tz(TZ), []);
  const [assets, setAssets] = React.useState<IKenticoData['assets']>(p.assets);
  const [callCenter, setCallCenter] = React.useState<
    IKenticoData['callCenter']
  >(p.callCenter);
  const [featureFlags, setFeatureFlags] = React.useState<
    IKenticoData['featureFlags']
  >(p.featureFlags);
  const [config, setConfig] = React.useState<IKenticoData['config']>(p.config);
  const [localizedStrings, setLocalizedStrings] = React.useState<
    IKenticoData['localizedStrings']
  >(p.localizedStrings);
  const [dynamicContent, setDynamicContent] = React.useState<
    IKenticoData['dynamicContent']
  >(p.dynamicContent);

  /**
   * Refresh data from Kentico
   * @param key Optional
   */
  const refreshData = React.useCallback(
    async (key?: KenticoDataKeys) => {
      switch (key) {
        case KenticoDataKeys.assets:
          setAssets(await getAllAssetReferences());
          break;
        case KenticoDataKeys.callCenter:
          setCallCenter(await refreshCallCenter());
          break;
        case KenticoDataKeys.featureFlags:
          setFeatureFlags(await getFeatureFlags());
          break;
        case KenticoDataKeys.localizedStrings:
          setLocalizedStrings(await getLocalizedStrings());
          break;
        case KenticoDataKeys.dynamicContent:
          setDynamicContent(await getMarkdownContent());
          break;
        default:
          setAssets(await getAllAssetReferences());
          setCallCenter(await refreshCallCenter());
          setFeatureFlags(await getFeatureFlags());
          setConfig(await getSimpleRemoteConfig());
          setLocalizedStrings(await getLocalizedStrings());
          setDynamicContent(await getMarkdownContent());
      }
    },
    [
      setAssets,
      setCallCenter,
      setFeatureFlags,
      setConfig,
      setLocalizedStrings,
      setDynamicContent,
    ],
  );

  React.useEffect(() => {
    p.assets && setAssets(p.assets);
    p.featureFlags && setFeatureFlags(p.featureFlags);
    p.localizedStrings && setLocalizedStrings(p.localizedStrings);
    p.dynamicContent && setDynamicContent(p.dynamicContent);

    if (
      p.callCenter?.hoursOfOperation?.start &&
      p.callCenter?.hoursOfOperation?.end
    ) {
      const nowResult = isOpenBasedOnConfig(now, p.callCenter);
      setCallCenter({ ...p.callCenter, open: nowResult });

      // set a timer to flip the open flag if within the next 2 hours, things change
      const logoutResult = isOpenBasedOnConfig(
        now.add(2, 'hour'),
        p.callCenter,
      );

      // if the result would change sometime between now and logout, start an interval
      if (logoutResult !== nowResult && !callCenterChangeTimer.current) {
        callCenterChangeTimer.current = setInterval(() => {
          const r = isOpenBasedOnConfig(dayjs().tz(TZ), p.callCenter);
          log.debug('callCenterChangeTimer', { isOpenResult: r });
          setCallCenter((cc) => (cc.open === r ? cc : { ...cc, open: r }));
        }, 60000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p]);

  const contextValue = React.useMemo(
    () => ({
      assets,
      callCenter,
      featureFlags,
      config,
      localizedStrings,
      dynamicContent,
      refreshData,
    }),
    [
      assets,
      callCenter,
      featureFlags,
      config,
      localizedStrings,
      dynamicContent,
      refreshData,
    ],
  );

  React.useEffect(() => {
    return () =>
      callCenterChangeTimer.current &&
      clearInterval(callCenterChangeTimer.current);
  }, []);

  return (
    <KenticoDataContext.Provider value={contextValue}>
      {p.children}
    </KenticoDataContext.Provider>
  );
};
