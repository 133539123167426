import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import { prefixObjectValues, enums } from '../../../utils';
import {
  DropDownList as KendoDropDownList,
  DropDownListChangeEvent,
  ListItemProps,
} from '@progress/kendo-react-dropdowns';
import { ItemRenderWrapper } from '../simple-styled-components';
import dayjs from 'dayjs';
import * as dm from '../../../data-models';
import { PersonDataContext } from '../../../contexts/person-data-context';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(minMax);

interface CaseDropDownListWithParentNTNProps {
  keyId?: string;
  id?: string;
  disabled?: boolean;
  defaultOption: string;
  viewAll?: boolean;
  onChange: (v: any) => void;
  maxWidth?: string;
  value?: any;
  routedNTNId?: string;
}

interface CaseProps {
  caseId: string;
  startDate?: string;
  parentId: string;
}

const DropDownList = styled(KendoDropDownList)<any>`
  max-width: ${(p) => p.maxwidth};
  width: 100%;

  .k-dropdown-wrap {
    background-color: ${colors.white};
    border-color: ${colors.alto};
    height: 56px;
  }
  .k-input,
  option {
    align-self: center;
    color: ${colors.warmNeutralL3};
    font-family: ${fonts.openSans.regular.fontFamily};
    font-weight: ${fonts.openSans.regular.fontWeight};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const SubtitleStyled = styled.div`
  color: ${colors.dustygray};
  font-size: 14px;
`;

const ItemRenderWrapperMod = styled(ItemRenderWrapper)`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: space-around;
  padding: 25px 15px;
`;

const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LeaveReasonStyled = styled.span`
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`;

const DateStyled = styled.span`
  margin-left: 16px;
  text-align: right;
`;

const domIdsStatic = {
  rootNode: 'case-drop-down-list-with-parent-ntn',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

export const getReason = (leaveReason: string) => {
  return leaveReason === enums.PregnancyTextChanges.pregnancyBondingLiteral
    ? enums.PregnancyTextChanges.pregnancyBondingLiteralNew
    : leaveReason;
};

const sortByNTNDate = (a: any, b: any) => {
  const ntnDateA = dayjs(a.earliestNotificationDate);
  const ntnDateB = dayjs(b.earliestNotificationDate);

  return ntnDateB.diff(ntnDateA);
};

export const getGeneratedCases = (personData?: dm.PersonData) => {
  const cases: any = {};
  const leaves = (personData?.cases?.leaves ?? []).map((l) => ({
    ...l,
    caseId: l.leaveId,
    caseReason: getReason(l.reason ?? 'Unknown'),
    caseStartDate: l.startDate,
    caseStatus: l.status ?? 'Unknown',
  }));
  const claims = (personData?.cases?.claims ?? []).map((c) => ({
    ...c,
    caseId: c.claimId,
    caseReason: c.claimType ?? 'Group Disability Claim',
    caseStartDate: c.disability?.claimIncurredDate,
    caseStatus: c.status ?? 'Unknown',
  }));
  const accommodations = (personData?.cases?.accommodations ?? []).map(
    (acm) => ({
      ...acm,
      caseId: acm.accommodationId,
      caseReason: 'Accommodation Case',
      caseStartDate: acm.createdDate,
      caseStatus: acm.status ?? 'Unknown',
    }),
  );

  cases.leaves = leaves;
  cases.claims = claims;
  cases.accommodations = accommodations;

  const eventSet = [
    ...Array.from(
      new Set(
        [...leaves, ...claims, ...accommodations].map(
          (event) => event.parentId,
        ),
      ),
    ),
  ];

  const uniqueEvents = eventSet.filter((id): id is string => !!id);

  const groupedEvents = uniqueEvents.map((id) => {
    const eventLeaves = leaves.filter((l) => l.parentId === id);
    const eventClaims = claims.filter((c) => c.parentId === id);
    const eventACMs = accommodations.filter((acm) => acm.parentId === id);

    const matchingEvents = [...eventLeaves, ...eventClaims, ...eventACMs];
    const eventDates = matchingEvents.filter((me: any) => me.notificationDate);
    const earliestNTNDate = dayjs.min(
      eventDates.map((d) => dayjs(d.notificationDate)),
    );

    return {
      caseId: id,
      earliestNotificationDate: earliestNTNDate
        ? earliestNTNDate.format('YYYY-MM-DD')
        : '',
      integrated: eventLeaves.length > 0 && eventClaims.length > 0,
      subcases: matchingEvents,
      isParent: true,
    };
  });

  const sortedCases = [...groupedEvents].sort(sortByNTNDate);

  const caseList: any = [];

  sortedCases.forEach((c: any) => {
    caseList.push(c);
    caseList.push(...c.subcases);
  });

  return caseList;
};

/**
 * A custom styled dropdown component used for displaying a list of claims/leaves. Displays the
 * NTN number, reason, and startDate/created date for the leave/absence.
 *
 * @param p
 */
const CaseDropDownListWithParentNTN: (
  p: CaseDropDownListWithParentNTNProps,
) => React.ReactElement = (p) => {
  const domIds = domIdsUnique(p.keyId);
  const { defaultOption, onChange, viewAll, value, routedNTNId } = p;
  const { personData } = React.useContext(PersonDataContext);
  const [caseList, setCaseList] = React.useState<any[]>([]);
  const [defaultObject, setDefaultObject] = React.useState({
    caseId: 'Select',
  });

  React.useEffect(() => {
    const generatedCases = getGeneratedCases(personData);
    const defaultItem = value || {
      caseId: defaultOption,
      caseStartDate: false,
    };

    if (viewAll) {
      generatedCases.unshift(defaultItem);
    }

    const routedCase = generatedCases.find(
      (inputCase: any) => inputCase.caseId === routedNTNId,
    );

    routedCase ? setDefaultObject(routedCase) : setDefaultObject(defaultItem);
    setCaseList(generatedCases);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewAll]);

  React.useEffect(() => {
    if (value) {
      const newValue =
        caseList.find((fc: any) => fc?.caseId === value?.caseId) || value;
      JSON.stringify(value) !== JSON.stringify(newValue) &&
        onChange({ target: { value: newValue } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseList]);

  return (
    <DropDownList
      id={p.id || domIds.rootNode}
      data={caseList.map((c: CaseProps) => c)}
      itemRender={(li: any, items: any) =>
        DropDownItemRender(li, items, defaultOption)
      }
      valueRender={(element: any, v: any) => valueRender(element, v)}
      defaultValue={defaultObject}
      onChange={(e: DropDownListChangeEvent) => {
        if ((e.nativeEvent as any).key === 'ArrowUp') {
          e.syntheticEvent.stopPropagation();
          e.syntheticEvent.preventDefault();
        }
      }}
      onClose={onChange}
      disabled={p.disabled}
      maxwidth={p.maxWidth || '500px'}
      popupSettings={{
        height: '50vh',
      }}
    />
  );
};

const valueRender = (
  element: React.ReactElement<HTMLSpanElement>,
  value: any,
) => {
  if (!value) {
    return element;
  }

  //Defaults
  let children = <div>{value.caseId}</div>;

  if ('isParent' in value) {
    const subcaseReasons = value.subcases.map((sc: any) => sc.caseReason);
    children = (
      <span className="d-flex w-100">
        <span>{value.caseId}</span>
        {subcaseReasons.length > 0 && (
          <LeaveReasonStyled>{`(${subcaseReasons.join(
            ', ',
          )})`}</LeaveReasonStyled>
        )}
        {value.earliestNotificationDate && (
          <DateStyled>
            {dayjs(value.earliestNotificationDate).format('MMM DD, YYYY')}
          </DateStyled>
        )}
      </span>
    );
  }

  if (value.caseReason) {
    children = (
      <span className="d-flex w-100">
        <span>{value.caseReason}</span>
        <LeaveReasonStyled>{`(${value.caseId})`}</LeaveReasonStyled>
        {value.caseStartDate && (
          <DateStyled>
            {dayjs(value.caseStartDate).format('MMM DD, YYYY')}
          </DateStyled>
        )}
      </span>
    );
  }

  return React.cloneElement(element, { ...element.props }, children);
};

/**
 * DropDownItemRender - Renders a custom dropdown options in the caseID filter
 * @param li
 * @param itemProps
 */
const DropDownItemRender = (
  li: React.ReactElement,
  itemProps: ListItemProps,
  defaultOption: string,
) => {
  const data = itemProps.dataItem;
  const liClass = data.isParent ? 'parent-case-option ' : 'sub-case-option ';
  const newLiProps = {
    ...li.props,
    className: liClass + li.props.className,
    style: {
      padding: 0,
      margin: 0,
      borderBottom: `1px solid ${colors.geyser}`,
    },
  };

  let itemChildren = (
    <Container>
      <ItemRenderWrapperMod>
        <div>{data.caseId}</div>
      </ItemRenderWrapperMod>
    </Container>
  );

  if (data.isParent) {
    itemChildren = (
      <Container>
        <ItemRenderWrapperMod>
          <div className="d-flex">
            <div className="col-7 col-md-8 pl-0 pr-2">{data.caseId}</div>
            {data.earliestNotificationDate && (
              <div className="col-5 col-md-4 pl-0" style={{ textAlign: 'end' }}>
                {dayjs(data.earliestNotificationDate).format('MM/DD/YY')}
              </div>
            )}
          </div>
          <SubtitleStyled className="dropdown-caseid">
            {data?.subcases?.[0]?.caseReason}
          </SubtitleStyled>
        </ItemRenderWrapperMod>
      </Container>
    );
  } else if (data.caseId !== defaultOption) {
    itemChildren = (
      <Container>
        <ItemRenderWrapperMod>
          <div className="d-flex ml-3">
            {data.caseReason && (
              <div className="col-7 col-md-8 pl-0 pr-2">{data.caseReason}</div>
            )}
            {data.startDate && (
              <div className="col-5 col-md-4 pl-0" style={{ textAlign: 'end' }}>
                {dayjs(data.caseStartDate).format('MM/DD/YY')}
              </div>
            )}
          </div>
          <SubtitleStyled className="dropdown-caseid ml-3">
            {data.caseId}
          </SubtitleStyled>
        </ItemRenderWrapperMod>
      </Container>
    );
  }

  return React.cloneElement(li, newLiProps, itemChildren);
};

export default CaseDropDownListWithParentNTN;
