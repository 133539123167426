import styled from 'styled-components';
import { colors, fonts, rem, maxWidthQuery as mediaQuery } from '../../style';
import StandardButton from '../base/button/index';
import { Button } from '@progress/kendo-react-buttons';

const maxWidthQuery: any = mediaQuery;

const toastMessages = {
  success: 'Your documents have been received',
  postError:
    'There was an error uploading one or more of your documents. Please check the list below and try again',
  validationError:
    'One or more of your documents do not match the file requirements. Check the requirements and try again',
};

const LinkButtonStyled = styled.button`
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 18px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  pointer-events: all;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    box-shadow: unset;
    outline: 0;
  }
`;

const RetryButton = styled(Button)`
  .k-button {
    padding: 2px !important;
  }
`;

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  margin-bottom: 40px;
  max-width: 897px;
  padding: var(--Spacing-spacing-07, 32px) var(--Spacing-spacing-06, 24px)
    var(--Spacing-spacing-09, 48px) var(--Spacing-spacing-06, 24px);
  spacing
`;

const TitleBarPhoneStyling = maxWidthQuery.phone`
  align-items: start; 
  border-bottom: 0;
  flex-direction: column; 
  padding-left: 20px;
`;

const TitleBar = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.geyser};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 50px;
  padding: 13.5px;
  padding-left: 24px;
  ${TitleBarPhoneStyling};
`;

const UploadImageStyled = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const UploadContainerStyled = styled.div`
  background-color: #fcfcfc;
  border-radius: 8px;
  padding: 48px;
  position: relative;
  border: 2px dashed #c3c3c3;
  &.dropzone-entered {
    background: #d4effd;
  }
  .k-dropzone {
    border: unset;
    padding: unset;
    height: 100%;
  }
  .k-button.k-upload-button {
    background-color: ${colors.white};
    border: unset;
    min-height: 100%;
    min-width: 100%;
    pointer-events: all;
    span {
      z-index: -1;
    }
    .k-state-focused {
      border: unset;
      box-shadow: none !important;
      outline: 0;
      text-decoration: none;
    }
  }
  .k-widget.k-upload {
    border: unset;
    bottom: 1px;
    height: 100%;
    opacity: 0;
    position: absolute;
    right: 1px;
    width: 100%;
    .k-dropzone {
      align-items: start;
      height: 100%;
    }
    .k-upload-files {
      display: none;
    }
  }
  > p {
    color: #818181;
    font-family: ${fonts.openSans.regular.fontFamily};
    font-size: ${rem(14)};
    font-weight: ${fonts.openSans.regular.fontWeight};
    margin-bottom: 0;
  }
  .upload-file-text {
    color: ${colors.sirocco};
    display: flex;
    font-family: ${fonts.openSans.regular.fontFamily};
    font-size: ${rem(18)};
    font-weight: ${fonts.openSans.regular.fontWeight};
    justify-content: center;
    margin: 10px 0px 15px 0px;
    .upload-file-text span {
      font-family: ${fonts.openSans.semibold.fontFamily};
      font-weight: ${fonts.openSans.semibold.fontWeight};
      margin-right: 3px;
    }
  }
  .upload-info {
    color: ${colors.sirocco};
    font-family: ${fonts.openSans.semibold.fontFamily};
    font-size: ${rem(13)};
    text-align: center;
  }
  &:focus {
    outline: none;
  }
  .k-dropzone-hovered {
    background-color: #09aeff !important;
  }
`;

const DropDownListContainer = styled.div`
  max-width: 400px;
  width: 100%;
`;

const BodyPhoneStyling = maxWidthQuery.phone`
  padding: 7px 10px 12px;
`;

const Body = styled.div`
  padding: 40px 65px 12px 24px;
  ${BodyPhoneStyling};

  .k-listview-content {
    position: initial;
  }
`;

const ListContainer = styled.div`
  border-bottom: 1px solid ${colors.geyser};
  display: flex;
  flex-direction: row;
  padding: 20px 0 8px;
  vertical-align: middle;

  &:last-child {
    border-bottom: 0;
  }
`;

const Text = styled.div`
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(15)};
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

const FileTitle = styled.div`
  color: ${(p) => p.color};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(18)};
  font-weight: ${fonts.openSans.regular.fontWeight};
  padding-left: 20px;
  text-align: left;
`;

const ItalicText = styled(Text)`
  color: ${colors.warmNeutralL3};
  font-style: italic;
`;

const NoCasesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 405px;
  padding: 100px 0 200px;
  text-align: center;
`;

const TitlePhoneStyling = maxWidthQuery.phone`padding-bottom: 20px;`;

const Title = styled.div`
  color: #013249;
  font-family: ${fonts.ubuntu.light.fontFamily};
  font-size: ${rem(24)};
  font-weight: 400;
  ${TitlePhoneStyling};
`;

const HeaderLabel = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: ${rem(15)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
`;

const AcceptedFilesDivStyled = styled.div`
  border-bottom: solid 2px ${colors.backgroundAlt};
  margin: 16px 20px 0px 20px;
  padding-bottom: 16px;
  .k-bare {
    height: 25px;
    margin: unset;
    padding: unset;
    width: 25px;
  }
  .k-icon.k-i-trash {
    color: #5e88a1;
  }
  .k-icon.k-i-refresh {
    color: ${colors.primaryBlue};
  }
  div .k-icon.k-i-check-outline {
    color: ${colors.lawnD3};
  }
`;

const ErrorStyled = styled.div`
  color: ${colors.errorRed};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 15px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin-left: 30px;
  @media (max-width: 792px) {
    margin-left: unset;
  }
`;

const FileNameContainer = styled.div`
  display: flex;
  @media (max-width: 792px) {
    margin: 10px;
    min-width: initial;
  }
  min-width: 270px;
`;

const FileNameStyled = styled.span`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 15px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  overflow: hidden;
  text-overflow: ellipsis;
  width: 220px;
`;

const FileSize = styled.span`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 15px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  overflow: hidden;
  margin-left: 10px;
  width: 75px;
`;

const FileRow = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-left: 5px;
  @media (max-width: 792px) {
    margin-left: unset;
  }
`;

const UploadButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const UploadButton = styled(StandardButton)`
  @media (max-width: 380px) {
    padding: 3px;
  }
`;

export {
  Container,
  ErrorStyled,
  TitleBar,
  DropDownListContainer,
  Body,
  ListContainer,
  Text,
  ItalicText,
  NoCasesContainer,
  Title,
  HeaderLabel,
  LinkButtonStyled,
  UploadContainerStyled,
  UploadImageStyled,
  FileTitle,
  AcceptedFilesDivStyled,
  UploadButton,
  UploadButtonContainer,
  ButtonContainer,
  FileNameStyled,
  FileSize,
  FileRow,
  RetryButton,
  toastMessages,
  FileNameContainer,
};
