import styled from 'styled-components';
import CalendarIcon from '../../../images/calendar.svg';
import ListIcon from '../../../images/format-list-bulleted.svg';
import CalendarIconBlue from '../../../images/calendar-blue.svg';
import ListIconBlue from '../../../images/format-list-bulleted-blue.svg';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { CardHeader, ButtonGroup, IconButton, Icon } from '../index.sc';
import { DEFAULT_FILTER_OPTION } from '../defaults';
import FilterDropDown from '../../base/filter-drop-down';
import useRefreshHandler from '../../../hooks/use-refresh-handler';
import { intermittentFilterRules } from '../../../utils/calendars/rules';

type FilterSectionType = (p: {
  handleFilterChange: (e: ComboBoxChangeEvent) => void;
  showCalendarView: (show: boolean) => void;
  displayCalendarView: boolean;
}) => React.ReactElement;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;

  @media (max-width: 720px) {
    flex-direction: column;
    margin-top: 15px;
  }
`;

const getIcon = (displayCalendarView: boolean, isCalendarButton: boolean) => {
  if (isCalendarButton) {
    return displayCalendarView ? CalendarIcon : CalendarIconBlue;
  } else {
    return displayCalendarView ? ListIconBlue : ListIcon;
  }
};

const FilterSection: FilterSectionType = (p) => {
  const { handleFilterChange, showCalendarView, displayCalendarView } = p;
  const { isRefreshing } = useRefreshHandler([
    'refreshCases',
    'refreshCaseDetail',
  ]);

  return (
    <CardHeader id={'filter-section'}>
      Absences
      <Container>
        <FilterDropDown
          options={intermittentFilterRules}
          defaultValue={DEFAULT_FILTER_OPTION}
          onChange={handleFilterChange}
          disabled={isRefreshing}
        />
        <ButtonGroup>
          <IconButton
            id="calendar-view-button"
            onClick={() => showCalendarView(true)}
            disabled={displayCalendarView || isRefreshing}
            left
          >
            <Icon
              src={getIcon(displayCalendarView, true)}
              alt=""
              height="20px"
              width="18px"
            />{' '}
            Calendar
          </IconButton>
          <IconButton
            id="list-view-button"
            onClick={() => showCalendarView(false)}
            disabled={!displayCalendarView || isRefreshing}
          >
            <Icon
              src={getIcon(displayCalendarView, false)}
              alt=""
              height="18px"
              width="24px"
            />{' '}
            List
          </IconButton>
        </ButtonGroup>
      </Container>
    </CardHeader>
  );
};

export default FilterSection;
