import styled from 'styled-components';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { EditError, EditInput, OpenSansSemiBoldLabelMod } from '../../index.sc';
import exclamation from '../../../../images/exclamation.png';

const ErrorImage = styled.img`
  height: 17px;
  margin-left: 8px;
  width: 17px;
`;

const ErrorMessage = styled(EditError)`
  font-size: 14px;
`;

export const FormInput = (fieldRenderProps: any) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const errorId = showValidationMessage ? `${id}_error` : '';

  return (
    <FieldWrapper>
      <OpenSansSemiBoldLabelMod
        as={Label}
        editorId={id}
        editorDisabled={disabled}
        optional={optional}
      >
        {label}
      </OpenSansSemiBoldLabelMod>
      <div className="mb-3">
        <EditInput
          ariaDescribedBy={`${errorId}`}
          disabled={disabled}
          id={id}
          type={type}
          valid={valid}
          {...others}
        />
        {showValidationMessage && (
          <>
            <ErrorImage src={exclamation} alt="" />
            <ErrorMessage
              className="d-inline ml-1"
              id={errorId}
              style={{ fontSize: '14px' }}
            >
              {validationMessage}
            </ErrorMessage>
          </>
        )}
      </div>
    </FieldWrapper>
  );
};
