import React from 'react';
import styled from 'styled-components';
import { fonts, rem, colors } from '../../../style';
import { prefixObjectValues, getHtmlFromMarkdown } from '../../../utils';
import FadeLoader from 'react-spinners/FadeLoader';
import useHandlebars from '../../../hooks/use-handlebars';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';

const domIdsStatic = {
  rootNode: 'chat-event-message-container',
  message: 'chat-event-message',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  margin-top: 6px;
  text-align: center;
`;

const Message = styled.div`
  color: ${colors.blue};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(14)};
  font-weight: ${fonts.openSans.regular.fontWeight};
  line-height: ${rem(22)};
  margin-left: 6px;
  margin-right: 6px;
`;

const LoaderContainer = styled.div`
  margin-left: 11px;
  height: 60px;
  display: flex;
  justify-content: center;
`;

interface ChatEventTemplateProps {
  keyId?: string;
  leftImageUrl?: string;
  messageStyle?: any;
  showLoader?: boolean;
  message: string;
  context?: { [key: string]: any };
  localizedName?: string;
}

/**
 * A ChatEventTemplate component
 * @param {object} props
 */
function ChatEventTemplate(props: ChatEventTemplateProps) {
  const domIds = domIdsUnique(props.keyId);
  const { localizedStrings } = React.useContext(KenticoDataContext);

  const localized =
    props.localizedName &&
    localizedStrings[props.localizedName]?.translatedText;
  const message = localized || props.message;

  const processedMessage = useHandlebars(message, props.context || {});

  return (
    <Container id={domIds.rootNode}>
      {props.showLoader && (
        <LoaderContainer>
          <FadeLoader
            height={14}
            width={5}
            radius={2}
            margin={0}
            color={colors.blue}
          />
        </LoaderContainer>
      )}
      {props.leftImageUrl && <img src={props.leftImageUrl} alt="" />}
      <Message
        id={domIds.message}
        style={{ ...props.messageStyle }}
        dangerouslySetInnerHTML={getHtmlFromMarkdown(processedMessage)}
      />
    </Container>
  );
}

export default ChatEventTemplate;
