import { models } from '../../../../utils/cms-client';
import { ISpecialBenefit } from '../../../../utils/remote-config-manager';
import EmployerExpandedSectionItem from './employer-expanded-item';
import ExpandedSectionItem from './expanded-section-item';
import { IFormContext } from '../../../../contexts/form-context';

interface IExpandedSection {
  idPrefix: string;
  benefits?: ISpecialBenefit[];
  content?: models.AccordionCardItem[];
  formContext: IFormContext;
  formValueGetter: (name: string) => any;
}

const ExpandedSection: (p: IExpandedSection) => React.ReactElement = (p) => {
  return (
    <div>
      {p.benefits &&
        p.benefits.map((benefit: ISpecialBenefit, i: number) => (
          <EmployerExpandedSectionItem
            key={`${p.idPrefix}-${i}`}
            keyId={`${p.idPrefix}-${i}-`}
            item={benefit}
            formContext={p.formContext}
            formValueGetter={p.formValueGetter}
          />
        ))}
      {p.content &&
        p.content.map((cardItem: models.AccordionCardItem, i: number) => (
          <ExpandedSectionItem
            key={`${p.idPrefix}-${i}`}
            keyId={`${p.idPrefix}-${i}-`}
            item={cardItem}
            formContext={p.formContext}
            formValueGetter={p.formValueGetter}
          />
        ))}
    </div>
  );
};

export default ExpandedSection;
