import React from 'react';
import styled from 'styled-components';
import { colors, fonts, rem } from '../../../style';
import Chevron from '../../../images/chevron-down-lg.png';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import dayjs from 'dayjs';
import * as dm from '../../../data-models';
import { Reveal } from '@progress/kendo-react-animation';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import LeavePlanList from '../leave-plan-list';
import { analyticsTrackEvent, enums } from '../../../utils';
import { ProcessedCalendarItem } from '../../../utils/calendars';
import { PersonDataContext } from '../../../contexts/person-data-context';
import claimStatusGreen from '../../../images/claimStatusGreen.svg';
import claimStatusMix from '../../../images/claimStatusMix.svg';
import claimStatusOrange from '../../../images/claimStatusOrange.svg';
import claimStatusRed from '../../../images/claimStatusRed.svg';
import ClaimStatusInfo from '../../../images/claimStatusInfoIcon.svg';
import { Popover } from '@progress/kendo-react-tooltip';
import { getClaimStatusConfig } from '../../../utils/remote-config-manager';

dayjs.extend(utc);
dayjs.extend(timezone);

const TIMEZONE = 'America/New_York';

interface AbsenceCardProps extends ProcessedCalendarItem {
  legendKey: string;
  index: number;
  legend: any;
  caseId?: string;
  reason?: string;
}

interface TextProps {
  complete?: boolean;
}

interface ChevronIconProps {
  $expanded: boolean;
}

const Container = styled.div`
  margin-bottom: 24px;
`;

const Card = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.alto2};
  border-radius: 8px;
  justify-content: space-between;
  padding: 28px 35px 28px 31px;
  position: relative;
  z-index: 2;

  @media (max-width: 576px) {
    padding: 25px 21px 31px 24px;
  }
`;

const AbsenceDate = styled.div<TextProps>`
  color: ${colors.sanmarino};
  font-family: ${fonts.ubuntu.bold.fontFamily};
  font-size: ${rem(14)};
  font-weight: ${fonts.ubuntu.bold.fontWeight};

  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const AbsenceTime = styled.div<TextProps>`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: ${rem(20)};
  font-weight: ${fonts.ubuntu.regular.fontWeight};

  @media (max-width: 576px) {
    font-size: ${rem(16)};
  }
`;

const Description = styled.div<TextProps>`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(14)};
  font-weight: ${fonts.openSans.regular.fontWeight};
  width: 100%;
`;

const ExpandedSection = styled.div`
  background-color: ${colors.alabaster1};
  border: 1px solid ${colors.alto2};
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: start;
  margin-top: -5px;
  padding: 27px 38px 29px 31px;
  position: relative;
  z-index: 1;

  @media (max-width: 576px) {
    padding: 20px 24px 26px 24px;
  }
`;

const ChevronContainer = styled.div`
  align-self: center;
  width: 42px;

  @media (max-width: 576px) {
    align-self: start;
  }
`;

const StatusContainer = styled.div`
  align-self: center;
  margin-right: 32px;

  @media (max-width: 576px) {
    align-self: end;
    margin-top: 15px;
  }
`;

interface StatusFlagProps {
  style: any;
}

const StatusFlag = styled.div<StatusFlagProps>`
  background-color: white;
  border-radius: 30px;
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(14)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  padding: 8px 0;
  text-align: center;
  width: 140px;

  ${(p) => p.style}
`;

const TimeText = styled.div`
  color: ${colors.boulder1};
  display: inline;
  margin-left: 5px;
  line-height: 22px;
`;

const CaseText = styled.div`
  color: ${colors.darkgrey};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(12)};
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

const ChevronIcon = styled(KendoButton)<ChevronIconProps>`
  transform: ${(p) => (p.$expanded ? 'rotate(180deg)' : 'none')};
`;

const ClaimStatusInfoIcon = styled.button`
  background: #fff;
  border: 0px;
  margin-left: 6px;
  bottom: 1px;
`;

const getRequestedTime = (p?: { [key: string]: any }, timeOnly = true) => {
  if (dayjs(p?.startDateTime).isValid() && dayjs(p?.endDateTime).isValid()) {
    const startTime = dayjs(p?.startDateTime).tz(TIMEZONE).format('hh:mm A');
    const endTime = dayjs(p?.endDateTime).tz(TIMEZONE).format('hh:mm A');
    const timeString = `${startTime} - ${endTime}`;

    return timeOnly ? timeString : `from ${timeString}`;
  } else {
    const basis =
      p?.episodePeriodDuration === 1
        ? p?.episodePeriodBasis?.slice?.(0, -1)
        : p?.episodePeriodBasis;
    const duration = `${p?.episodePeriodDuration} ${basis}`;

    return timeOnly ? duration : `for ${duration}`;
  }
};

const getStatusIcon = (p: any, legend: any, meta: any) => {
  const displayedStatus =
    legend[p.legendKey]?.statusText ?? meta?.status?.toUpperCase?.();
  if (displayedStatus === 'APPROVED') {
    return claimStatusGreen;
  } else if (displayedStatus === 'PENDING') {
    return claimStatusOrange;
  } else if (displayedStatus === 'PARTIAL') {
    return claimStatusMix;
  } else {
    return claimStatusRed;
  }
};

const getStatusText = async (
  p: any,
  legend: any,
  setStatusMessage: Function,
) => {
  const statusConfigs = await getClaimStatusConfig();
  const foundStatus = statusConfigs.find(
    (s: { status: any }) =>
      s.status.toUpperCase?.() === legend[p.legendKey]?.statusText,
  );
  if (foundStatus && foundStatus.message) {
    setStatusMessage(foundStatus.message);
  }
};

const AbsenceCard: (p: AbsenceCardProps) => React.ReactElement | null = (p) => {
  const { legend, index, meta } = p;
  const [expanded, setExpanded] = React.useState(index === 0);
  const { personData } = React.useContext(PersonDataContext);
  const [statusMessage, setStatusMessage] = React.useState();
  const anchor = React.useRef<HTMLDivElement | null>(null);

  const selectedLeave = personData?.cases?.leaves?.find?.(
    (leave: dm.Leave) => leave?.leaveId === meta?.leaveId,
  );
  const leaveReason = selectedLeave?.reason ?? '';

  React.useEffect(() => {
    setExpanded(index === 0);
    getStatusText(p, legend, setStatusMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p, legend, meta]);

  const handleCardClick = () => {
    const eventProps = { ...p };
    delete eventProps.legend;
    !expanded &&
      analyticsTrackEvent(enums.AnalyticsEvents.absencesExpand, eventProps);

    setExpanded(!expanded);
  };

  const [show, setShow] = React.useState(false);

  return (
    <Container id={`absence-card-${index}`}>
      <Card onClick={handleCardClick} className="d-flex flex-row">
        <div className="d-flex flex-column flex-md-row w-100 justify-content-between">
          <div className="d-flex flex-column mr-2">
            <AbsenceDate>
              ABSENT {dayjs(meta?.actualDate).format('MM/DD/YYYY')}
            </AbsenceDate>
            <AbsenceTime>
              {`${
                meta?.type === 'Office Visit' ? 'Treatment' : 'Episode'
              } ${getRequestedTime(meta, false)}`}
            </AbsenceTime>
            <CaseText>
              {`${leaveReason} (${meta?.leaveId})`.toUpperCase()}
            </CaseText>
          </div>

          <div className="d-flex mt-2">
            <StatusContainer
              id={'test-id-' + meta?.leaveId}
              ref={anchor}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              {legend?.[p?.legendKey]?.cardStyle && (
                <StatusFlag
                  id={`absence-card-status-complete-${index}`}
                  style={legend[p.legendKey].cardStyle}
                >
                  <img
                    style={{ paddingRight: '5px' }}
                    src={getStatusIcon(p, legend, meta)}
                    alt=""
                  />
                  {legend[p.legendKey]?.statusText ??
                    meta?.status?.toUpperCase?.()}
                  <ClaimStatusInfoIcon>
                    <img alt="" src={ClaimStatusInfo} />
                  </ClaimStatusInfoIcon>
                  <Popover
                    id={'popover-id-' + meta?.leaveId}
                    show={show}
                    anchor={anchor.current}
                    position={'top'}
                  >
                    <div
                      style={{
                        width: 220,
                        backgroundColor: '#015294',
                        padding: '5px',
                        borderRadius: '2px',
                        color: '#FFF',
                        fontWeight: 400,
                        textAlign: 'left',
                      }}
                    >
                      {statusMessage}
                    </div>
                  </Popover>
                </StatusFlag>
              )}
            </StatusContainer>
          </div>
        </div>
        <ChevronContainer>
          <ChevronIcon
            id={`absence-card-expand-button-${index}`}
            imageUrl={Chevron}
            look="flat"
            $expanded={expanded}
          />
        </ChevronContainer>
      </Card>
      <Reveal style={{ display: 'block' }}>
        {expanded && (
          <ExpandedSection>
            <Description id={`absence-card-description-${index}`}>
              <LeavePlanList
                {...meta}
                legendKey={p.legendKey}
                index={index}
                legend={legend}
              />
              <div>
                REPORTED:
                <TimeText>
                  {`${dayjs(meta?.reportedDateTime)
                    .tz(TIMEZONE)
                    .format('MM/DD/YYYY')} at ${dayjs(meta?.reportedDateTime)
                    .tz(TIMEZONE)
                    .format('h:mm A')} ET`}
                </TimeText>
              </div>
            </Description>
          </ExpandedSection>
        )}
      </Reveal>
    </Container>
  );
};

export default AbsenceCard;
