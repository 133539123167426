import styled from 'styled-components';
import { colors, fonts, rem } from '../../style';
import { TitleBar, Container } from '../base/simple-styled-components';

const Title = styled.h1`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: ${rem(36)};
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  margin: 15px 0;

  @media (max-width: 500px) {
    font-size: ${rem(30)};
  }
`;

const TitleBarMod = styled(TitleBar)`
  background-color: ${colors.alabaster2};
  border-bottom: 0;
  padding: 32px 35px 32px 33px;
`;

const Body = styled.div`
  padding: 33px 32px 27px;
`;

const CardHeader = styled.div`
  align-items: center;
  color: ${colors.prussianblue};
  display: flex;
  flex-direction: row;
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: ${rem(28)};
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  justify-content: space-between;
  margin-bottom: 31px;

  @media (max-width: 720px) {
    align-items: start;
    flex-direction: column;
  }
`;

const Text = styled.div`
  color: ${colors.mineshaft};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(16)};
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin-bottom: 27px;

  @media (max-width: 500px) {
    font-size: ${rem(14)};
  }
`;

const BoldText = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: ${rem(16)};
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  margin-bottom: 20px;
  margin-top: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: end;
  max-width: 228px;
  width: 100%;

  @media (max-width: 720px) {
    justify-content: start;
    margin-top: 16px;
    max-width: 276px;
  }
`;

const IconButton = styled.button<{ left?: boolean }>`
  background-color: ${(p) => (p.disabled ? colors.alabaster : colors.white)};
  border: 1px solid ${colors.alto2};
  border-radius: ${(p) => (p.left ? '4px 0 0 4px' : '0 4px 4px 0')};
  color: ${(p) => (p.disabled ? colors.warmNeutralL3 : colors.denim)};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: ${rem(14)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  height: 30px;
  line-height: 14px;
  margin: 0;
  max-width: 114px;
  width: 100%;

  @media (max-width: 576px) {
    max-width: 138px;
  }
`;

const Icon = styled.img`
  vertical-align: text-bottom;
`;

const ComingSoonText = styled.div`
  text-align: center;
  color: ${colors.prussianblue};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(28)};
  font-family: ${fonts.openSans.regular.fontWeight};
  margin-top: 30px;
`;

const BlueText = styled.div`
  color: ${colors.sanmarino2};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(14)};
  font-style: italic;
  font-weight: ${fonts.openSans.regular.fontWeight};
  line-height: 22px;
  margin-top: 32px;

  a {
    text-decoration: underline;
    color: ${colors.sanmarino2};
  }

  p:last-child {
    margin: 0;
  }
`;

const NoLeavesContainer = styled(Container)`
  margin-top: 18px;
  max-width: 904px;
  padding: 96px 40px;
  text-align: center;

  @media only screen and (max-width: 450px) {
    padding: 50px 30px;
  }
`;

const NoAbsencesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 72px 0 60px;
  text-align: center;

  @media only screen and (max-width: 450px) {
    padding: 40px 0 40px;
  }
`;

export {
  Title,
  TitleBarMod,
  Body,
  CardHeader,
  Text,
  BoldText,
  ButtonGroup,
  IconButton,
  Icon,
  ComingSoonText,
  BlueText,
  NoLeavesContainer,
  NoAbsencesContainer,
};
