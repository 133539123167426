
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Notificationtype extends ContentItem {
    public emailSubject!: Elements.TextElement;
    public emailBody!: Elements.TextElement;
    public smsMessage!: Elements.TextElement;
    public businessrole!: Elements.LinkedItemsElement<ContentItem>;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'email_subject') {
                    return 'emailSubject';
                }
                if (elementName === 'email_body') {
                    return 'emailBody';
                }
                if (elementName === 'sms_message') {
                    return 'smsMessage';
                }
                return elementName;
            })
        });
    }
}
