
import { ContentItem, Elements } from '@kentico/kontent-delivery';
import { IntakeCard } from './intake_card';
/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class IntakeSection extends ContentItem {
    public fewerCardsButtonText!: Elements.TextElement;
    public moreCardsButtonText!: Elements.TextElement;
    public optionsDisplayedLimit!: Elements.NumberElement;
    public contentItems!: Elements.LinkedItemsElement<IntakeCard>;
    public header!: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'fewer_cards_button_text') {
                    return 'fewerCardsButtonText';
                }
                if (elementName === 'more_cards_button_text') {
                    return 'moreCardsButtonText';
                }
                if (elementName === 'options_displayed_limit') {
                    return 'optionsDisplayedLimit';
                }
                if (elementName === 'content_items') {
                    return 'contentItems';
                }
                return elementName;
            })
        });
    }
}
