import React from 'react';
import { Tooltip, TooltipPosition } from '@progress/kendo-react-tooltip';
import greyTooltip from '../../../images/grey-tooltip.svg';
type Props = {
  position?: TooltipPosition;
  anchor?: string;
  tooltipSyle?: React.CSSProperties;
  width?: string;
  svg?: string;
  content: string;
};

const CommonStyle: React.CSSProperties = {
  backgroundColor: '#FFFFFE',
  border: '0.2px solid #000000',
  color: '#666666',
  fontStyle: 'italic',
};

export const CommonToolTip: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({
  position = 'right',
  anchor = 'target',
  tooltipSyle = CommonStyle,
  width = '500px',
  svg = greyTooltip,
  content,
  ...rest
}) => {
  return (
    <Tooltip
      position={position}
      anchorElement={anchor}
      tooltipStyle={tooltipSyle}
      style={{
        width,
      }}
      content={() => <>{content}</>}
      {...rest}
    >
      <img src={svg} alt="tooltip" title="tooltip" />
    </Tooltip>
  );
};
