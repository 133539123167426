import styled from 'styled-components';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { colors, fonts, rem } from '../../../style';
import marked from 'marked';
import CloseX from '../../../images/modal-x-lg.svg';
import {
  CancelButton,
  DialogActionsStyle,
  SubmitButton,
  RegularCustom as StyledText,
} from '../simple-styled-components';

const RegularCustom = styled(StyledText)<{ bold?: boolean }>``;

const DialogMod = styled(Dialog)`
  z-index: 10009;
  .k-window {
    max-height: 675px;
    max-width: 557px;
    width: 90%;
    z-index: 10009;
  }
  .k-overlay {
    z-index: 10009;
  }
  .k-dialog-title {
    color: ${colors.warmNeutralL3};
    font-family: ${fonts.openSans.semibold.fontFamily};
    font-size: ${rem(18)};
    font-weight: ${fonts.openSans.semibold.fontWeight};
  }
  .k-window-titlebar {
    border-bottom-width: 0;
    padding: 20px 20px 0;
  }
  .k-dialog-close .k-icon.k-i-x::before {
    content: url(${CloseX});
  }
  .k-window-content {
    padding: 20px;
  }
  .k-dialog-buttongroup {
    border-top-width: 0;
  }
`;

/**
 * A ConfirmDialogModal component
 * @param {object} props
 */
const ConfirmDialogModal = (props: ConfirmDialogModalProps) => {
  const parser = marked.setOptions({});
  const parsedMessage = parser.parse(props.description);
  const htmlToinsert = { __html: parsedMessage };

  const activatingSMS = props.title.includes('Activate text messages');
  const handleClick = (confirm = true) => {
    if (props.handleConfirm && confirm) {
      props.handleConfirm(activatingSMS);
    }
    props.modalCallback(confirm);
  };

  return (
    <DialogMod
      id="confirmation-dialog"
      onClose={() => props.modalCallback(false)}
      title={props.title}
    >
      {props.markdown ? (
        <RegularCustom dangerouslySetInnerHTML={htmlToinsert} />
      ) : (
        <RegularCustom bold>{props.description}</RegularCustom>
      )}
      <DialogActionsBar>
        <DialogActionsStyle>
          <CancelButton
            id="confirm-dialog-modal-cancel-button"
            onClick={() => handleClick(false)}
            {...props.dataAttributes}
          >
            {props.cancelButtonText ? props.cancelButtonText : 'Cancel'}
          </CancelButton>
          <SubmitButton
            id={
              props.confirmButtonCustomId ||
              'confirm-dialog-modal-confirm-button'
            }
            onClick={() => handleClick(true)}
            {...props.dataAttributes}
          >
            {props.confirmButtonText}
          </SubmitButton>
        </DialogActionsStyle>
      </DialogActionsBar>
    </DialogMod>
  );
};

interface ConfirmDialogModalProps {
  title: string;
  description: string;
  markdown?: boolean;
  confirmButtonText: string;
  cancelButtonText?: string;
  modalCallback: any;
  handleConfirm?: any;
  dataAttributes?: any;
  confirmButtonCustomId?: string;
  cancelButtonCustomId?: string;
}

export default ConfirmDialogModal;
