import React from 'react';
import styled from 'styled-components';
import useDocumentTitle from '../../../../hooks/use-document-title';
import { colors, fonts, rem } from '../../../../style';
import { getFormattedHoursOfOperation } from '../../../../utils';
import { KenticoDataContext } from '../../../../contexts/kentico-data-context';

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  rootNode: 'fatal-error-screen-root-node',
};

/*******************************************************************************
 * styles / styled components
 ******************************************************************************/

interface DescriptionProps {
  regular?: boolean;
  size: number;
}

const Container = styled.div`
  background-color: #f7f9fb;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2009;
`;

const Description = styled.div<DescriptionProps>`
  color: ${colors.warmNeutralL3};
  font-family: ${(props) =>
    fonts.ubuntu[props.regular ? 'regular' : 'light'].fontFamily};
  font-size: ${(props) => rem(props.size)};
  font-weight: ${(props) =>
    fonts.ubuntu[props.regular ? 'regular' : 'light'].fontWeight};
  line-height: normal;
`;

/*******************************************************************************
 * LoadingScreen component
 * @param {LoadingScreenProps} props LoadingScreen propTypes
 ******************************************************************************/
const FatalError: (p: FatalErrorProps) => React.ReactElement = (props) => {
  useDocumentTitle('Unexpected Error');
  const { callCenter } = React.useContext(KenticoDataContext);

  const { hoost, hooet } = getFormattedHoursOfOperation(callCenter);

  return (
    <Container
      id={domIdsStatic.rootNode}
      className="d-flex flex-column justify-content-start pt-3 pt-md-5 pb-4 px-3"
    >
      <div className="d-flex justify-content-center">
        <img src={props.unumLogo} alt="Unum" />
      </div>
      {!props.dataError && (
        <div className="d-flex justify-content-center mt-5">
          <Description size={30} className="text-center">
            {props.chat ? (
              <>
                We aren't able to load&nbsp;
                <br className="d-none d-sm-flex d-md-none" />
                your chat messages
              </>
            ) : (
              <>Something went wrong</>
            )}
          </Description>
        </div>
      )}
      <div className="d-flex justify-content-center mt-4">
        <img
          src={props.fatalImage}
          alt={`Fatal ${props.chat ? 'Chat' : 'General'} ${
            props.dataError ? 'Data ' : ''
          }Error`}
        />
      </div>
      <div className="d-flex justify-content-center mt-3">
        {!props.dataError && (
          <Description className="text-center" size={18}>
            Don’t worry, we’re working on it.&nbsp;
            <br />
            Please try again later.
          </Description>
        )}
        {props.dataError && (
          <Description className="text-center" size={18}>
            We are experiencing technical difficulties.&nbsp;
            <br />
            Please try again later.
          </Description>
        )}
      </div>
      <div className="d-flex justify-content-center m-3">
        <hr
          style={{
            width: '50px',
            border: '0',
            borderTop: `4px solid ${colors.lightBlueGray}`,
          }}
        />
      </div>
      <div className="d-flex justify-content-center">
        <Description size={14} regular className="text-center">
          If you need to reach us, please give us a&nbsp;
          <br className="d-md-none" />
          call at&nbsp;
          <br className="d-none d-md-flex" />
          1-866-779-1054, Monday through&nbsp;
          <br className="d-md-none" />
          Friday {hoost} to {hooet} ET.
        </Description>
      </div>
    </Container>
  );
};

/*******************************************************************************
 * prop types and defaults
 ******************************************************************************/
interface FatalErrorProps {
  unumLogo: string;
  fatalImage: string;
  dataError?: boolean;
  chat?: boolean;
}

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { FatalError };
