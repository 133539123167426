import InfoCircle from '../../../../images/info-icon.svg';
import { Link } from 'react-router-dom';

import { enums } from '../../../../utils';
import { fonts } from '../../../../style';

type Props = {
  ntn: string;
};

export const AwaitingTasksBanner = ({ ntn }: Props) => {
  return (
    <div className="tw-flex tw-bg-[#d9ebf4] tw-p-2 tw-w-full tw-mt-2 tw-border tw-rounded-md tw-text-sm">
      <div>
        <img className="tw-pl-[5px]" alt="Info circle" src={InfoCircle}></img>
      </div>
      <div className="tw-w-[100%]">
        <div className="tw-pl-[15px]">
          A task must be completed in order to progress your claim to the next
          step.&nbsp;
          <Link
            style={{
              fontSize: '14px',
              fontWeight: fonts.openSans.regular.fontWeight,
            }}
            to={`${enums.AppPaths.tasks}/${ntn}`}
          >
            Go to Tasks
          </Link>
        </div>
      </div>
    </div>
  );
};
