import { colors } from '../../style';
import { RangeElement, PageFold } from '../generic-calendar';
import JPIcon from '../../images/leave-calendar/job-protection-legend.svg';
import PLIcon from '../../images/leave-calendar/income-protection-icon.svg';
import MilestoneIcon from '../../images/leave-calendar/key-date-flag.svg';
import ApprovedJP from '../../images/leave-calendar/approved-jp-continuous-icon.png';
import ApprovedPL from '../../images/leave-calendar/approved-pl-continuous.png';
import PendingJP from '../../images/leave-calendar/pending-jp-continuous.png';
import PendingPL from '../../images/leave-calendar/pending-pl-continuous.png';
import DeniedJP from '../../images/leave-calendar/denied-jp-continuous.png';
import DeniedPL from '../../images/leave-calendar/denied-pl-continuous.png';
import LeavePeriod from '../../images/leave-calendar/leave-period-icon.svg';

const leavePlanNameTemplate = '{{meta.leavePlanName}}';
const jobProtectionLiteral = 'JOB PROTECTION';
const paidProtectionLiteral = 'INCOME PROTECTION';
const fineosPaidBenefitLiteral =
  '{{#if meta.leavePlanName}}{{meta.leavePlanName}}{{else}}Unum Short Term Disability{{/if}}';

const overlayStyle = {
  backgroundColor: 'red',
  content: `''`,
  position: 'absolute',
  height: '100%',
  width: '100%',
  borderRadius: '0 18px 18px 0',
  background:
    'transparent linear-gradient(180deg, #FFFFFF 0%, #000000 100%) 0% 0% no-repeat padding-box',
  opacity: 0.2,
  mixBlendMode: 'overlay',
  marginLeft: '-2px',
};

const fontStyle = {
  fontSize: '9px',
  fontFamily: 'Roboto',
  fontWeight: 700,
};

export const continuousStyle = {
  approvedJPContinuous: {
    style: {
      backgroundColor: colors.greenHaze,
      zIndex: 3,
      '&::before': overlayStyle,
      ...fontStyle,
    },
    content: 'JOB',
    order: 0,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: ApprovedJP,
    legendText: 'JOB PROTECTION APPROVED',
    statusName: 'Approved',
    detail: leavePlanNameTemplate,
    group: jobProtectionLiteral,
  },
  AdminERPaidApprovedJPContinuous: {
    style: {
      backgroundColor: colors.greenHaze,
      zIndex: 3,
      '&::before': overlayStyle,
      ...fontStyle,
    },
    content: 'JOB',
    order: 0,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: ApprovedJP,
    legendText: 'JOB PROTECTION APPROVED',
    statusName: 'Approved',
    detail: leavePlanNameTemplate,
    group: jobProtectionLiteral,
  },
  approvedPLContinuous: {
    style: {
      backgroundColor: 'white',
      border: `1px solid ${colors.greenHaze}`,
      color: colors.greenHaze,
      zIndex: 3,
      ...fontStyle,
    },
    content: '$$$',
    order: 1,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: ApprovedPL,
    legendText: 'INCOME PROTECTION APPROVED',
    statusName: 'Approved',
    detail: fineosPaidBenefitLiteral,
    group: paidProtectionLiteral,
  },
  AdminERPaidApprovedPLContinuous: {
    style: {
      backgroundColor: 'white',
      border: `1px solid ${colors.greenHaze}`,
      color: colors.greenHaze,
      zIndex: 3,
      ...fontStyle,
    },
    content: '$$$',
    order: 1,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: ApprovedPL,
    legendText: 'INCOME PROTECTION APPROVED',
    statusName: 'Approved',
    detail: fineosPaidBenefitLiteral,
    group: paidProtectionLiteral,
  },
  pendingJPContinuous: {
    style: {
      backgroundColor: colors.bridesmaid,
      border: `1px dashed ${colors.christine}`,
      color: colors.christine,
      zIndex: 2,
      '&::before': overlayStyle,
      ...fontStyle,
    },
    content: 'JOB',
    order: 0,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: PendingJP,
    legendText: 'JOB PROTECTION PENDING',
    statusName: 'Pending',
    detail: leavePlanNameTemplate,
    group: jobProtectionLiteral,
  },
  AdminERPaidPendingJPContinuous: {
    style: {
      backgroundColor: colors.bridesmaid,
      border: `1px dashed ${colors.christine}`,
      color: colors.christine,
      zIndex: 2,
      '&::before': overlayStyle,
      ...fontStyle,
    },
    content: 'JOB',
    order: 0,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: PendingJP,
    legendText: 'JOB PROTECTION PENDING',
    statusName: 'Pending',
    detail: leavePlanNameTemplate,
    group: jobProtectionLiteral,
  },
  pendingPLContinuous: {
    style: {
      backgroundColor: 'white',
      border: `1px dashed ${colors.christine}`,
      color: colors.christine,
      zIndex: 2,
      ...fontStyle,
    },
    content: '$$$',
    order: 1,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: PendingPL,
    legendText: 'INCOME PROTECTION PENDING',
    statusName: 'Pending',
    detail: fineosPaidBenefitLiteral,
    group: paidProtectionLiteral,
  },
  AdminERPaidPendingPLContinuous: {
    style: {
      backgroundColor: 'white',
      border: `1px dashed ${colors.christine}`,
      color: colors.christine,
      zIndex: 2,
      ...fontStyle,
    },
    content: '$$$',
    order: 1,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: PendingPL,
    legendText: 'INCOME PROTECTION PENDING',
    statusName: 'Pending',
    detail: fineosPaidBenefitLiteral,
    group: paidProtectionLiteral,
  },
  deniedJPContinuous: {
    style: {
      backgroundColor: colors.cardinal,
      zIndex: 1,
      '&::before': overlayStyle,
      ...fontStyle,
    },
    content: 'JOB',
    order: 0,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: DeniedJP,
    legendText: 'JOB PROTECTION DENIED',
    statusName: 'Denied',
    detail: leavePlanNameTemplate,
    group: jobProtectionLiteral,
  },
  AdminERPaidDeniedJPContinuous: {
    style: {
      backgroundColor: colors.cardinal,
      zIndex: 1,
      '&::before': overlayStyle,
      ...fontStyle,
    },
    content: 'JOB',
    order: 0,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: DeniedJP,
    legendText: 'JOB PROTECTION DENIED',
    statusName: 'Denied',
    detail: leavePlanNameTemplate,
    group: jobProtectionLiteral,
  },
  deniedPLContinuous: {
    style: {
      backgroundColor: 'white',
      border: `1px solid ${colors.cardinal}`,
      color: colors.cardinal,
      zIndex: 1,
      ...fontStyle,
    },
    content: '$$$',
    order: 1,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: DeniedPL,
    legendText: 'INCOME PROTECTION DENIED',
    statusName: 'Denied',
    detail: fineosPaidBenefitLiteral,
    group: paidProtectionLiteral,
  },
  AdminERPaidDeniedPLContinuous: {
    style: {
      backgroundColor: 'white',
      border: `1px solid ${colors.cardinal}`,
      color: colors.cardinal,
      zIndex: 1,
      ...fontStyle,
    },
    content: '$$$',
    order: 1,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: DeniedPL,
    legendText: 'INCOME PROTECTION DENIED',
    statusName: 'Denied',
    detail: fineosPaidBenefitLiteral,
    group: paidProtectionLiteral,
  },
  absencePeriod: {
    legendItem: true,
    legendIcon: LeavePeriod,
    legendText: 'LEAVE REQUEST',
  },
  milestone: {
    style: {},
    order: 0,
    elementType: PageFold,
    legendItem: true,
    legendIcon: MilestoneIcon,
    legendText: 'Key Date',
  },
};

export const intakeStyle = {
  potentialJobProtection: {
    style: {
      backgroundColor: colors.denim1,
      '&::before': overlayStyle,
    },
    content: 'JOB',
    order: 0,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: JPIcon,
    legendText: 'ESTIMATED JOB PROTECTION',
    group: 'Estimated Job Protection',
    detail: '{{meta.name}}',
    statusName: 'Yes',
  },
  potentialPaidBenefits: {
    style: {
      backgroundColor: colors.white,
      border: `1px solid ${colors.denim1}`,
      color: colors.denim1,
    },
    content: `{{#if (eq rollupValue 0)}}$$$\{{else}}{{rollupValue}}%{{/if}}`,
    order: 1,
    elementType: RangeElement,
    legendItem: true,
    legendIcon: PLIcon,
    legendText: 'ESTIMATED INCOME PROTECTION',
    group: 'Estimated Income Protection',
    detail: 'Estimated Paid Benefit',
    statusName: `{{#if (eq meta.percentage 0)}}$$$\{{else}}{{meta.percentage}}%{{/if}}`,
  },
  milestone: {
    style: {},
    order: 0,
    elementType: PageFold,
    legendItem: true,
    legendIcon: MilestoneIcon,
    legendText: 'KEY DATE',
    group: 'Key Details',
    detail: '{{meta.name}}',
    statusName: '',
  },
  absencePeriod: {
    legendItem: true,
    legendIcon: LeavePeriod,
    legendText: 'LEAVE REQUEST',
  },
};
