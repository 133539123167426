import { ContentItem, Elements } from '@kentico/kontent-delivery';
import { FormStep } from './form_step';
import { FormText } from './form_text';
import { SharedSubmissionText } from "./shared___submission_text";

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Form extends ContentItem {
  public steps!: Elements.LinkedItemsElement<FormStep>;
  public submissionEndpoint!: Elements.TextElement;
  public optionsTemplate!: Elements.TextElement;
  public label!: Elements.TextElement;
  public submissionText!: Elements.LinkedItemsElement<FormText> | Elements.LinkedItemsElement<SharedSubmissionText>;
  public pathMatch!: Elements.TextElement;
  public flags!: Elements.MultipleChoiceElement;
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'submission_endpoint') {
          return 'submissionEndpoint';
        }
        if (elementName === 'submission_text') {
          return 'submissionText';
        }
        if (elementName === 'path_match') {
          return 'pathMatch';
        }
        if (elementName === 'options_template') {
          return 'optionsTemplate';
        }
        return elementName;
      },
    });
  }
}
