/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PersonDataContext } from '../../contexts/person-data-context';
import ClaimCard from '../base/claim-card';
import { Link } from 'react-router-dom';
import Button from '../base/button';
import { enums } from '../../utils';
import { isDueTask } from '../tasks/utils';
import { KenticoDataContext } from '../../contexts/kentico-data-context';
import * as dm from '../../data-models';
import { trackFSVars } from '../../utils/analytics';
import NoClaimsView from './no-claims';
import { TaskType } from '../tasks';

const Container = styled.div`
  display: flex;

  @media only screen and (max-width: 1200px) {
    display: block;
  }
`;

const Title = styled.h1`
  font-family: Ubuntu;
  font-size: 36px;
  font-weight: 400;
  color: #013249;
  margin-bottom: 20px;
  line-height: 43px;
`;

const SidebarTitle = styled.h2`
  font-size: 24px;
`;

const ListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 10px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #037cb7;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  :hover {
    text-decoration: none;
  }
`;

export const StyledExternalLink = styled.a`
  text-decoration: none;
  color: #037cb7;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  :hover {
    text-decoration: none;
  }
`;

const ButtonMod = styled(Button)`
  font-size: 16px;
  :hover {
    text-decoration: none !important;
  }
`;

const BlueSeparator = styled.hr`
  width: 80px;
  height: 4px;
  border-style: none;
  background: #99bad4;
  color: #99bad4;
  margin: 5px 0 25px 0;
  border-radius: 10px;
`;

const GreenSeparator = styled.hr`
  width: 80px;
  height: 4px;
  border-style: none;
  background: #04a874;
  color: #04a874;
  margin: 5px 0 25px 0;
  border-radius: 10px;
`;

type Props = {
  tasks: TaskType[];
  remoteCfg: { [name: string]: string };
  isEmployerEnabled: boolean;
  vbCoverageData: dm.ICoverageV1ResponseExt;
};

const Summary = ({
  tasks,
  remoteCfg,
  isEmployerEnabled,
  vbCoverageData,
}: Props) => {
  const { personData } = React.useContext(PersonDataContext);
  const { callCenter } = React.useContext(KenticoDataContext);
  const hasCoverage = vbCoverageData.hasCoverage;

  const ERTasks = tasks.filter(
    (t: dm.Task) =>
      isDueTask(t?.taskStatusId) && t.assigneeId === 1 && t.taskStatusId !== 10,
  );

  const EETasks = tasks.filter(
    (t: dm.Task) => isDueTask(t?.taskStatusId) && t.assigneeId === 2,
  );

  const mufmLink = remoteCfg
    ? remoteCfg[enums.RemoteConfigKeys.iServicesIDPURL]
    : '#';

  useEffect(() => {
    if (personData?.person?.companyCode) {
      const coverageNames =
        vbCoverageData.coverages?.map((c) => c.productDescription) || [];
      coverageNames.sort();

      trackFSVars({
        vbCoverages: coverageNames.join(', '),
        vbEmployerEnabled: isEmployerEnabled,
      });
    }
  }, [
    isEmployerEnabled,
    personData?.person?.companyCode,
    vbCoverageData.coverages,
  ]);

  return personData?.generated?.parentNTNs.length > 0 ? (
    <div>
      <div>
        <Container id="has-claims-view">
          <div className="col-md-9 pl-0">
            <Title>{`Welcome back, ${personData?.person?.firstName}!`}</Title>
            {personData?.generated?.parentNTNs.map(
              (claim: any, index: number) => (
                <div
                  key={`claim-card-${index}`}
                  style={{ position: 'relative', marginBottom: '2em' }}
                >
                  <ClaimCard
                    keyId={index}
                    claim={claim}
                    ERTasks={ERTasks}
                    EETasks={EETasks}
                  />
                </div>
              ),
            )}
          </div>

          <div className="col-sm-3" id="summary-sidebar">
            {hasCoverage && isEmployerEnabled && (
              <>
                <SidebarTitle>Supplemental benefits</SidebarTitle>
                <GreenSeparator />
                <ListContainer>
                  <li>
                    <StyledLink to="/app/supplemental">
                      View my supplemental coverage
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink to="/app/supplemental">
                      File a supplemental benefit claim
                    </StyledLink>
                  </li>
                  <li>
                    <StyledExternalLink
                      href={
                        remoteCfg
                          ? remoteCfg[enums.RemoteConfigKeys.iServicesIDPURL]
                          : '#'
                      }
                    >
                      View my existing supplemental benefit claims
                    </StyledExternalLink>
                  </li>
                </ListContainer>
                <br />
              </>
            )}
            {hasCoverage && !isEmployerEnabled && (
              <>
                <SidebarTitle>Supplemental benefits</SidebarTitle>
                <GreenSeparator />
                <ListContainer>
                  <li>
                    <StyledExternalLink href={mufmLink}>
                      View my supplemental coverage
                    </StyledExternalLink>
                  </li>
                  <li>
                    <StyledExternalLink href={mufmLink}>
                      File a supplemental benefit claim
                    </StyledExternalLink>
                  </li>
                  <li>
                    <StyledExternalLink href={mufmLink}>
                      View my existing supplemental benefit claims
                    </StyledExternalLink>
                  </li>
                </ListContainer>
                <br />
              </>
            )}
            <SidebarTitle>Update key dates</SidebarTitle>
            <BlueSeparator />
            <ListContainer>
              <li>
                <StyledLink to="/app/form/last-day-worked">
                  Last day of work
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/app/form/return-to-work">
                  Return to work
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/app/form/delivery-date">
                  Delivery date
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/app/form/surgery-date">
                  Surgery details
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/app/form/extension-request">
                  Request extension
                </StyledLink>
              </li>
            </ListContainer>
            <br />
            <SidebarTitle>Provide Unum details</SidebarTitle>
            <BlueSeparator />
            <ListContainer>
              <li>
                <StyledLink to="/app/form/intermittent-absence">
                  Report intermittent absence
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/app/form/e-signature">
                  Sign e-Signature authorization forms
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/app/upload">Upload a document</StyledLink>
              </li>
              <li>
                <StyledLink to="/app/form/physician-information">
                  Update physician information
                </StyledLink>
              </li>
            </ListContainer>
            <br />
            <SidebarTitle>Get support</SidebarTitle>
            <BlueSeparator />
            <ListContainer>
              <li>
                <ButtonMod
                  id="live-chat-button"
                  buttonStyle={enums.ButtonTypes.link}
                  onClick={() => (global as any)?.displayLiveChat?.(true)}
                  className={`live-chat-${
                    callCenter.open ? 'online' : 'offline'
                  }`}
                >
                  Live chat
                </ButtonMod>
              </li>
              <li>
                <StyledLink to="/app/form/scheduled-callback">
                  Schedule a callback
                </StyledLink>
              </li>
              <li>
                <ButtonMod
                  id="survey-button"
                  buttonStyle={enums.ButtonTypes.link}
                  onClick={() => (window as any)?.ShowSurveyFeedback?.()}
                >
                  Leave feedback
                </ButtonMod>
              </li>
            </ListContainer>
          </div>
        </Container>
      </div>
    </div>
  ) : (
    <NoClaimsView />
  );
};

export default Summary;
