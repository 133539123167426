import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { PersonDataContext } from '../../../contexts/person-data-context';
import { ApiError, getTasksWithKontent } from './api';
import Tasks, { TaskType } from '..';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../base/spinner';

export const TasksContainer = () => {
  const { personData } = React.useContext(PersonDataContext);
  const { ntn } = useParams<{ ntn?: string }>();

  const {
    data: tasksResponse,
    isLoading: tasksLoading,
    error: tasksError,
  } = useQuery<TaskType[], ApiError>(
    ['getTasksWithKontent', personData?.person?.customerNumber],
    () => getTasksWithKontent(personData!.person!.customerNumber!),
    { retry: false, enabled: !!personData?.person?.customerNumber },
  );

  if (tasksLoading) {
    return <Spinner key="spinner" />;
  }
  return (
    <>
      {tasksResponse && (
        <Tasks
          data={tasksResponse.filter((t) => t.assigneeId === 2) ?? []}
          tasksError={tasksError}
          routedViaNtn={ntn}
        />
      )}
    </>
  );
};
