const ChevronLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="13.694"
      viewBox="0 0 8 13.694"
    >
      <path
        id="Shape"
        d="M5.665,13.3.5,8.216A2.217,2.217,0,0,1,.626,5.269L5.588.38a1.329,1.329,0,0,1,1.861,0,1.281,1.281,0,0,1,0,1.833L2.866,6.729,7.6,11.393a1.334,1.334,0,0,1,0,1.907A1.383,1.383,0,0,1,5.665,13.3Z"
        fill={'#525252'}
        opacity="1"
      />
    </svg>
  );
};

const ChevronRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="13.694"
    viewBox="0 0 8 13.694"
  >
    <path
      id="Shape"
      d="M2.335,13.3,7.5,8.216a2.217,2.217,0,0,0-.122-2.947L2.412.38A1.329,1.329,0,0,0,.551.38a1.281,1.281,0,0,0,0,1.833L5.134,6.729.4,11.393A1.334,1.334,0,0,0,.4,13.3,1.383,1.383,0,0,0,2.335,13.3Z"
      fill="#525252"
      opacity="1"
    />
  </svg>
);

export { ChevronLeft, ChevronRight };
