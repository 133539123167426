import React from 'react';
import styled from 'styled-components';
import {
  getHtmlFromMarkdown,
  parseJSONOrDefault,
  prefixObjectValues,
} from '../../../../utils';
import { IFormContext } from '../../../../contexts/form-context';
import { models } from '../../../../utils/cms-client';
import * as options from '../../options';
import { colors } from '../../../../style';
import useHandlebars from '../../../../hooks/use-handlebars';
import pdfIcon from '../../../../images/file-pdf-solid.svg';

interface IExpandedSectionItem {
  keyId?: string;
  item: models.AccordionCardItem;
  formContext: IFormContext;
  formValueGetter: (name: string) => any;
}

const MarkdownText = styled.div`
  color: ${colors.warmNeutralL3};

  ul {
    padding-left: 25px;
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  *:first-child {
    margin-top: 0;
  }
`;

const LinkContainer = styled.div`
  border-bottom: 1px solid ${colors.alto2};
  padding: 16px 0;

  &.knowledge-article:last-child {
    padding-bottom: 0;
    border: none;
  }
`;

const domIdsStatic = {
  articleLink: 'link-item',
  markdown: 'markdown-item',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const ExpandedSectionItem: (p: IExpandedSectionItem) => React.ReactElement = (
  p,
) => {
  const domIds = domIdsUnique(p.keyId);
  const { item, formContext, formValueGetter } = p;
  const [optionsTemplate, setOptionsTemplate] = React.useState({});

  const markdown = useHandlebars(
    item?.content?.value || '',
    formContext,
    formValueGetter,
  );

  React.useEffect(() => {
    setOptionsTemplate(parseJSONOrDefault(item?.optionsTemplate?.value));
  }, [item]);

  if (!options.shouldShow(optionsTemplate, formValueGetter, formContext)) {
    return <></>;
  }

  if (item.article.value.length > 0) {
    return (
      <LinkContainer id={domIds.articleLink} className="knowledge-article">
        <a
          href={item.article.value[0].url}
          target="_blank"
          rel="noreferrer"
          className="d-flex align-items-start"
          download
        >
          <img src={pdfIcon} alt="" style={{ marginRight: 16 }} />
          <div>{item.headline.value}</div>
        </a>
      </LinkContainer>
    );
  } else {
    return (
      <MarkdownText
        id={domIds.markdown}
        dangerouslySetInnerHTML={getHtmlFromMarkdown(markdown)}
        style={{ color: colors.warmNeutralL3 }}
      />
    );
  }
};

export default ExpandedSectionItem;
