import styled from 'styled-components';
import { colors, fonts, rem } from '../../../style';
import LiveChatIcon from '../../../images/live-chat-icon.svg';
import {
  KenticoDataContext,
  IKenticoData,
} from '../../../contexts/kentico-data-context';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  enums,
  getDynamicOrDefault,
  getFormattedHoursOfOperation,
  getHtmlFromMarkdown,
  getLocalizedOrDefault,
} from '../../../utils';
import { convertAnchorTagsToRouterLinks } from '../../../utils/html-parsing-helpers';
import useHandlebars from '../../../hooks/use-handlebars';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
const TZ = 'America/New_York';

const Container = styled.div`
  padding-top: 34px;
  text-align: center;
  pointer-events: all;
`;

const Text = styled.p<{ fontSize?: number }>`
  color: ${colors.blue};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${(p) => rem(p.fontSize || 13)};
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

const SemiBoldText = styled(Text)`
  font-size: ${rem(20)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  padding-bottom: 32px;
  line-height: 24px;
  margin-top: 10px;
`;

const ScheduleCallCard = styled.div`
  background-color: ${colors.white};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
`;

const ScheduleCallButton = styled.div`
  background-color: ${colors.skyD2};
  border-radius: 16px;
  color: ${colors.white};
  font-size: ${rem(16)};
  padding: 4px 32px;
  cursor: pointer;
  pointer-events: initial;
`;

const getClosedText = (
  callCenter: IKenticoData['callCenter'],
  localizedStrings: IKenticoData['localizedStrings'],
  dynamicContent: IKenticoData['dynamicContent'],
) => {
  const upcomingClosures = callCenter.closures ?? [];

  // Sort DESC
  upcomingClosures.sort((a, b) => (dayjs(a.start).isAfter(b.start) ? 1 : -1));

  // find the nearest upcoming closure
  const nc = upcomingClosures?.[0];

  let closedText = getDynamicOrDefault(
    dynamicContent,
    'live_chat_closed_message',
    'dcContentFull',
    '**Live chat is currently offline.**</br></br>Check back any time, Monday through Friday, {{hoost}} - {{hooet}}, ET to chat to a representative.',
  );

  if (!nc) {
    return closedText;
  }

  let start = dayjs(nc?.start);
  let end = dayjs(nc?.end);

  // If closure is set to all day, change the start and end to reflect that.
  if (nc?.allDay) {
    start = dayjs(callCenter.hoursOfOperation.start)
      .tz(TZ, true)
      .set('year', start.year())
      .set('month', start.month())
      .set('date', start.date());
    end = dayjs(callCenter.hoursOfOperation.end)
      .tz(TZ, true)
      .set('year', end.year())
      .set('month', end.month())
      .set('date', end.date());
  }

  const currentlyInClosure = dayjs().tz(TZ).isBetween(start, end, 'm', '[)');
  if (currentlyInClosure) {
    closedText = `${nc.bannerMessage} ${getLocalizedOrDefault(
      localizedStrings,
      'call_center_closed_banner_append_text',
      'The call center is currently closed.',
    )}`;
  }

  return closedText;
};

/**
 * A HeaderMessageTemplate component
 */
function HeaderMessageTemplate() {
  const { callCenter, dynamicContent, localizedStrings } =
    React.useContext(KenticoDataContext);
  const [connected, setConnected] = React.useState(false);

  // if we ever get connected, and they keep chat open past the end of hours of operation, don't show the offline messaging
  React.useEffect(() => {
    if (callCenter.open === true) {
      setConnected(true);
    }
  }, [callCenter.open]);

  const closedText = useHandlebars(
    getClosedText(callCenter, localizedStrings, dynamicContent),
    getFormattedHoursOfOperation(callCenter),
  );

  return (
    <Container id="live-chat-header-message">
      <img alt="" src={LiveChatIcon} height="74px" width="74px" />
      <SemiBoldText>Live Chat</SemiBoldText>

      {!callCenter.open && !connected && (
        <div style={{ margin: '12px' }}>
          <Text fontSize={16}>
            {convertAnchorTagsToRouterLinks(
              getHtmlFromMarkdown(closedText).__html,
            )}
          </Text>
          <ScheduleCallCard className="d-flex flex-column align-items-center">
            <Text
              style={{ color: '#525252', marginTop: '0px' }}
              fontSize={16}
              dangerouslySetInnerHTML={getHtmlFromMarkdown(
                getLocalizedOrDefault(
                  localizedStrings,
                  'live_chat_suggest_callback',
                  'Would you like us to call you?',
                ),
              )}
            />
            <br />
            <Link
              id="contact-us-btn"
              to={enums.AppPaths.contactUsCallback}
              onClick={() => global.displayLiveChat(false)}
            >
              <ScheduleCallButton>Schedule a call</ScheduleCallButton>
            </Link>
          </ScheduleCallCard>
        </div>
      )}
    </Container>
  );
}

export default HeaderMessageTemplate;
