import { useState } from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import arrow from '../../../images/down_arrow.svg';
import {
  get,
  prefixObjectValues,
  analyticsTrackEvent,
  enums,
} from '../../../utils';
import { Reveal } from '@progress/kendo-react-animation';
import * as dm from '../../../data-models';
import {
  LeaveCardItems,
  ConcurrentLeaveCardItems,
  ClaimsCardItems,
  AccommodationCardItem,
  CardFooterSection,
} from './card-items';
import useRefreshHandler from '../../../hooks/use-refresh-handler';
import FadeLoader from 'react-spinners/FadeLoader';
import { Column } from './card-items/shared-styles';
import { StatusFlag } from '../../tasks/task-list-item';
import {
  isDueTask,
  taskAppliesToNTN,
  claimStatusesThatUseTaskFlag,
  taskIsRequired,
} from '../../tasks/utils';
export interface IGeneratedCase {
  NTNId: string;
  accommodations: Array<dm.Accommodation>;
  claims: Array<dm.Claim>;
  leaves: Array<dm.Leave>;
  isIntegrated: boolean;
}

interface IClaimCard {
  keyId?: number;
  claim: IGeneratedCase;
  ERTasks: Array<dm.Task>;
  EETasks: Array<dm.Task>;
}

type CardBodyType = {
  expand: boolean;
};

const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(46, 46, 46, 0.16);
  max-width: 992px;
  overflow: hidden;
  width: 100%;

  .k-animation-container {
    z-index: 1;
  }
`;

export const Arrow = styled.img.attrs({
  alt: 'Expand',
  src: arrow,
})`
  cursor: pointer;
  margin-right: 0.5em;
  transform: ${(props: { rotate: string }) =>
    props.rotate === 'true' ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: all 0.5s;
  user-select: none;
  width: 2.75em;

  @media (min-width: 500px) {
    margin-right: 1em;
  }
`;
const CardHeader = styled.div`
  align-items: stretch;
  background: #fff;
  border-left: solid 16px #037cb7;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(46, 46, 46, 0.16);
  cursor: pointer;
  display: flex;
  padding: 1.5em 0 1.5em 24px;
  position: relative;
  width: 100%;
  z-index: 2;
`;
const CardTitle = styled.h1`
  color: #013249;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.2em;
  width: 100%;
  word-wrap: break-word;
`;
const CardSubTitle = styled.h2`
  color: ${colors.gray};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.regular.fontWeight};
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  word-wrap: break-word;
`;

const CardBody = styled.div<CardBodyType>`
  height: ${(p) => (p.expand ? '100%' : '0')};
  overflow: hidden;
  visibility: ${(p) => (p.expand ? 'visible' : 'hidden')};
  width: 100%;

  &:first-child {
    height: 100%;
    visibility: visible;
  }
`;

const LoadingCover = styled.div`
  height: 110px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    width: 48px;
    height: 48px;
  }
`;

const CardItemsContainer = styled.div`
  border-bottom: 1px solid #dddddd;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
`;

const CardCreatedDate = styled.h2`
  color: ${colors.sanmarino};
  font-size: 14px;
  font-family: ${fonts.ubuntu.bold.fontFamily};
  font-weight: ${fonts.ubuntu.bold.fontWeight};
`;

const StatusFlagMod = styled(StatusFlag)`
  @media (max-width: 420px) {
    margin-left: unset;
    margin-bottom: 8px;
  }
`;

const domIdsStatic = {
  rootNode: 'claims-card',
  cardTitle: 'claim-card-title',
  dropDownArrow: 'dropdown-arrow',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const getCardTitle = (claim: any) => {
  if (claim?.leaves?.length > 0) {
    return claim.leaves[0].reason ===
      enums.PregnancyTextChanges.pregnancyBondingLiteral
      ? enums.PregnancyTextChanges.pregnancyBondingLiteralNew
      : claim.leaves[0].reason;
  } else if (claim?.claims?.length > 0) {
    return claim.claims[0].claimType;
  } else if (claim?.accommodations?.length > 0) {
    const firstAccommodation = claim.accommodations[0];
    const details = firstAccommodation?.accommodationDetails || [];

    return details.length > 0
      ? details[0].category
      : firstAccommodation?.accommodationId;
  }
  return 'N/A';
};

const getCreatedDate = (claim: IGeneratedCase) => {
  if (claim.claims?.length > 0) {
    return claim.claims[0].createdDate;
  } else if (claim.leaves?.length > 0) {
    return claim.leaves[0].createdDate;
  } else {
    return 'N/A';
  }
};

const getAllStatuses = (claim: IGeneratedCase) => {
  const statuses: string[] = [];

  claim.claims.forEach((claim) => {
    if (claim.status) {
      statuses.push(claim.status);
    }
  });
  claim.leaves.forEach((leave) => {
    if (leave.requestStatus) {
      statuses.push(leave.requestStatus);
    }
  });

  return statuses;
};

const getConcurrentLeaves = (claim: IGeneratedCase) => {
  const leavePlan = get(claim, 'leaves');
  let concurrentLeave;
  for (const element of leavePlan) {
    if (element?.deniedLeavePlans?.length > 0) {
      for (const element2 of element.deniedLeavePlans) {
        if (element2.adjudicationReason === 'Not Concurrent') {
          concurrentLeave = element2.adjudicationReason;
        }
      }
    }
  }

  return concurrentLeave;
};

const doesAnySubCaseInNTNUseTaskFlag = (claim: IGeneratedCase) => {
  const statuses = getAllStatuses(claim);

  return statuses.some((status) =>
    claimStatusesThatUseTaskFlag.includes(status),
  );
};

type ExpandedType = (
  p: {
    NTNId: string;
    ariaExpand: boolean;
    areTasksDue?: boolean;
  } & IClaimCard,
) => React.ReactElement;

const ExpandedSection: ExpandedType = (p) => {
  const { claim, ariaExpand, NTNId, ERTasks, EETasks, areTasksDue } = p;
  const allLeaveIds = claim?.leaves?.map((l) => l.leaveId ?? '');
  const allClaimIds = claim?.claims?.map((l) => l.claimId ?? '');
  const allAccommodationsIds = claim?.accommodations?.map(
    (l) => l.accommodationId ?? '',
  );
  const allIds = [...allLeaveIds, ...allClaimIds, ...allAccommodationsIds];
  const { status } = useRefreshHandler(
    [enums.RefreshDetailTypes.caseDetails],
    allIds,
  );
  const showDenied = getConcurrentLeaves(claim);

  return status !== enums.RefreshStatus.started ? (
    <CardBody expand={ariaExpand}>
      <CardItemsContainer>
        <>
          {claim?.leaves?.length > 0 && (
            <LeaveCardItems
              leaves={claim.leaves}
              ERTasks={ERTasks}
              EETasks={EETasks}
            />
          )}
        </>
      </CardItemsContainer>
      <CardItemsContainer>
        {showDenied === 'Not Concurrent' && (
          <ConcurrentLeaveCardItems leaves={claim.leaves} ERTasks={ERTasks} />
        )}
      </CardItemsContainer>
      <CardItemsContainer>
        {claim?.claims?.length > 0 && (
          <ClaimsCardItems
            claims={claim.claims}
            ERTasks={ERTasks}
            EETasks={EETasks}
          />
        )}
      </CardItemsContainer>
      <CardItemsContainer>
        {claim?.accommodations?.length > 0 && (
          <AccommodationCardItem accommodations={claim.accommodations} />
        )}
      </CardItemsContainer>
      <CardFooterSection {...claim} NTNId={NTNId} areTasksDue={areTasksDue} />
    </CardBody>
  ) : (
    <LoadingCover>
      <FadeLoader
        height={13}
        width={4}
        radius={5}
        margin={0}
        color={colors.blue}
      />
    </LoadingCover>
  );
};

const ClaimsCard: (p: IClaimCard) => React.ReactElement = (p) => {
  const { claim, keyId, EETasks, ERTasks } = p;
  const domIds = domIdsUnique(`sc-${keyId}-`);
  const [ariaExpand, setAriaExpand] = useState(false);
  const NTNId = get(claim, 'NTNId', '');

  const dueTasks: dm.Task[] = [];
  if (EETasks && doesAnySubCaseInNTNUseTaskFlag(claim)) {
    EETasks.forEach((task) => {
      if (
        isDueTask(task.taskStatusId) &&
        taskAppliesToNTN(task.notificationCaseId, NTNId) &&
        taskIsRequired(task.taskCategoryId) &&
        !dueTasks.includes(task)
      ) {
        dueTasks.push(task);
      }
    });
  }

  const areTasksDue = dueTasks.length > 0;

  const expandCard = () => {
    ariaExpand &&
      analyticsTrackEvent(enums.AnalyticsEvents.summaryExpand, {
        ntn: NTNId,
        leaves: claim?.leaves?.length,
        claims: claim?.claims?.length,
        accommodations: claim?.accommodations?.length,
      });
    setAriaExpand((e) => !e);
  };

  return (
    <Card id={domIds.rootNode}>
      <CardHeader onClick={expandCard}>
        <Column size={80} align={'flex-start'}>
          <CardCreatedDate>CREATED {getCreatedDate(claim)}</CardCreatedDate>
          <CardTitle id={domIds.cardTitle}>{getCardTitle(claim)}</CardTitle>
          <CardSubTitle>{NTNId}</CardSubTitle>
        </Column>
        <Column size={8} align={'flex-end'}>
          {areTasksDue && (
            <StatusFlagMod
              data-testid={`claim-${claim.NTNId}-task-awaiting-action-flag`}
              background={'#FEF3EA'}
              color={'#E1710E'}
            >
              {enums.TaskUIStatuses.awaiting_action}
            </StatusFlagMod>
          )}
        </Column>
        <Column size={12} align={'flex-end'}>
          <Arrow id={domIds.dropDownArrow} rotate={`${ariaExpand}`} />
        </Column>
      </CardHeader>
      <Reveal style={{ display: 'block' }}>
        {ariaExpand ? (
          <ExpandedSection
            {...p}
            NTNId={NTNId}
            ariaExpand={ariaExpand}
            areTasksDue={areTasksDue}
            EETasks={EETasks}
            ERTasks={ERTasks}
          />
        ) : null}
      </Reveal>
    </Card>
  );
};

export default ClaimsCard;
