import dayjs from 'dayjs';

/**
 * Determines the number of days to front fill on the calendar to create an even six rows. The calculation is based on the days in the month and start date of the month.
 * @param date
 * @returns
 */
export const numberOfDaysToFrontFill = (date: dayjs.Dayjs) => {
  const numDays = date.daysInMonth();
  const startOfMonth = date.date(1).day();

  const thirty = numDays === 30 && startOfMonth >= 6;
  const thirtyOne = numDays === 31 && startOfMonth >= 5;
  const twentyEight = numDays === 28 && startOfMonth === 0;

  if (twentyEight) {
    return 21;
  } else if (thirty || thirtyOne) {
    return 7;
  } else {
    return 14;
  }
};

/**
 * Generates a two-dimensional array for the days in the month of the passed date arg. Back fills the dates from the previous month if the start of the month
 * begins mid-week. Front fills the days from the next month if the end of the month ends mid-week.
 * @param initDate
 * @returns
 */
export const generateCalendarRows = (initDate: dayjs.Dayjs) => {
  const rows: any = [[]];
  const startOfMonth = initDate.date(1).format('d');
  const parsed = parseInt(startOfMonth);

  //Backfill calendar with the end of last month
  for (let i = parsed; i > 0; i--) {
    rows[0].push(initDate.date(1).subtract(i, 'days'));
  }

  //Fill with month days
  let rowIndex = 0;
  for (let i = 1; i <= initDate.daysInMonth(); i++) {
    if (rows[rowIndex].length === 7) {
      rowIndex++;
      rows[rowIndex] = [];
    }

    rows[rowIndex].push(initDate.date(i));
  }

  //Frontfill calender with start of next month
  let lastRow = rows[rows.length - 1];
  const daysToFill = numberOfDaysToFrontFill(initDate);
  const daysLeft = daysToFill - lastRow.length;

  for (let i = 1; i <= daysLeft; i++) {
    if (lastRow.length === 7) {
      rows.push([]);
      lastRow = rows[rows.length - 1];
    }
    lastRow.push(initDate.date(initDate.daysInMonth()).add(i, 'days'));
  }

  return rows;
};
