import React from 'react';
import { StepperChangeEvent, StepProps } from '@progress/kendo-react-layout';
import {
  enums,
  get,
  parseJSONOrDefault,
  trimPathEndSlash,
} from '../../../utils';
import { models } from '../../../utils/cms-client';
import { getFormConfig } from '../../../utils/remote-config-manager';
import { Case, PersonData } from '../../../data-models';
import { log } from '../../../utils/log';
import { ComponentProps } from '.';
import { IFormContext } from '../../../contexts/form-context';
import * as options from '../options';
import { FormSubmitClickEvent } from '@progress/kendo-react-form';
import { findFormStepIndex } from './handlers';

// const log = new Logger\('forms/form/utils');

export interface CustomStepProps extends StepProps {
  formStepId: string;
}

export const transformFormStepsForStepper = (
  steps: models.FormStep[],
): CustomStepProps[] =>
  steps.map((step) => {
    return {
      label: step.label?.value || '',
      formStepId: step.system.id,
    };
  });

export const isPreviousStepsValid = (
  steps: CustomStepProps[],
  currentStepIndex: number,
) =>
  steps
    .slice(0, currentStepIndex)
    .findIndex((currentStep) => currentStep.isValid === false) === -1;

export const findStepIndexWithLeavePlanGraph = (
  form: models.Form | undefined,
) => {
  // find the first step with a leave plan graph
  return (
    form?.steps?.value?.findIndex((fs) => {
      const fieldsOnly = fs.sections.value.filter(
        (s) => s.system.type === enums.FormSectionTypes.FormField,
      );

      return fieldsOnly.some(
        (s) =>
          s.formFieldTypes?.value[0].name ===
          enums.FormFieldTypes.leaveplangraph,
      );
    }) || -1
  );
};

export const setConfig = async (
  lastPath: string,
  setNotFound: React.Dispatch<React.SetStateAction<boolean>>,
  setForm: React.Dispatch<React.SetStateAction<models.Form | undefined>>,
  setFormSteps: React.Dispatch<React.SetStateAction<models.FormStep[]>>,
  form: models.Form | undefined,
  setFormContext: React.Dispatch<React.SetStateAction<any>>,
) => {
  const formResult = form || (await getFormConfig(lastPath))?.firstItem;

  if (!formResult) {
    // we weren't passed a form, and we didn't find a form, show 404
    log.error('setConfig', lastPath, form);
    setNotFound(true);
    return;
  }

  setForm(formResult);

  if (formResult?.steps?.value?.length > 0) {
    const stepIndexWithLeavePlanGraph =
      findStepIndexWithLeavePlanGraph(formResult);

    // set it on the formContext
    if (stepIndexWithLeavePlanGraph > -1) {
      setFormContext((c: any) =>
        Object.assign(c || {}, { stepIndexWithLeavePlanGraph }),
      );
    }
  }

  if (formResult?.system?.name) {
    setFormContext((c: any) =>
      Object.assign(c || {}, { formName: formResult.system.name }),
    );
  }

  const formConfigSteps = formResult?.steps?.value || [];
  setFormSteps(formConfigSteps);
};

export const determineIntegrated = (pd: PersonData) => {
  const isInOtherList = (parentId: string, list: Case[]) =>
    list?.some((c) => c.parentId === parentId) || false;

  for (const c of pd.cases?.claims || []) {
    c.integrated = isInOtherList(
      c.parentId as string,
      pd.cases?.leaves as Case[],
    );
  }

  for (const l of pd.cases?.leaves || []) {
    l.integrated = isInOtherList(
      l.parentId as string,
      pd.cases?.claims as Case[],
    );
  }

  return pd;
};

export interface IMoveToStepParams {
  allowNavBack: boolean;
  currentStepHasLeavePlanGraph: any;
  currentStepIndex: number;
  formContext: any;
  setCurrentStepIndex: Function;
  setDisplayConfirmationModal: Function;
  setFormStepIndex: Function;
  setStepGoBack: Function;
  stepperChangeEvent: StepperChangeEvent;
  steps: CustomStepProps[];
  formSteps: models.FormStep[] | undefined;
}

export const moveToStep = (p: IMoveToStepParams) => {
  if (p.stepperChangeEvent.value < p.currentStepIndex && p.allowNavBack) {
    // new step index
    const nsi = p.stepperChangeEvent.value;
    if (
      p.formContext?.currentLeavePlan?.planId &&
      p.currentStepHasLeavePlanGraph &&
      p.formContext?.stepIndexWithLeavePlanGraph > p.stepperChangeEvent.value
    ) {
      p.setStepGoBack(nsi);
      p.setDisplayConfirmationModal(true);
    } else {
      p.setCurrentStepIndex(nsi);
      p.setFormStepIndex(findFormStepIndex(p.steps[nsi], p.formSteps || []));
    }
  }
};

export const processNavigationObject = (p: ComponentProps) => {
  const path = trimPathEndSlash(get(p, 'location.pathname', '/not-found'));
  const pathParts = path.split('/');
  const lastPath = pathParts[pathParts.length - 1];
  const task = get(p, 'location.state.task', {});
  const defaults = get(p, 'location.state.defaults', {});

  return { lastPath, task, defaults };
};

export const isStepHidden = (
  submitEvent: FormSubmitClickEvent,
  formStep: models.FormStep,
  formContext: IFormContext,
) => {
  // only check further if the "Hide When No Fields" flag is set on the step
  if (
    formStep.flags.value.some(
      (f) => f.codename === enums.FormStepFlags.hideWhenNoFields,
    )
  ) {
    // check step sections
    return !formStep.sections.value.some((s) => {
      // only if they are fields
      if (s.system.type !== enums.FormSectionTypes.FormField) {
        return false;
      }

      // options template
      const ot = parseJSONOrDefault(s.optionsTemplate?.value);

      return options.shouldShow(
        ot,
        (key) => submitEvent.values[key],
        formContext,
      );
    });
  } else if (formStep.optionsTemplate?.value) {
    // options template
    const ot = parseJSONOrDefault(formStep.optionsTemplate?.value);

    return !options.shouldShow(
      ot,
      (key) => submitEvent.values[key],
      formContext,
    );
  }

  return false;
};
