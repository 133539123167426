import { isAuthenticated } from '../auth-manager';
import {
  getAllAssetReferences,
  getFeatureFlags,
  getSimpleRemoteConfig,
  getLocalizedStrings,
  getMarkdownContent,
  getSplashConfig,
  ISplashConfig,
  IEmployerContent,
  getEmployerContentSafely,
} from '../remote-config-manager';
import { enums } from '..';
import { getPersonData, getEmployerPreferences } from '../web-apis-client';
import {
  IKenticoData,
  refreshCallCenter,
} from '../../contexts/kentico-data-context';
import { handleRefresh, startRefresh } from '../../hooks/use-refresh-handler';
import {
  FullRefreshStatuses,
  LoginRefreshStatuses,
} from '../../contexts/refresh-context';
import { log } from '../log';

/*******************************************************************************
 * startup-manager.js is a collection of functions to assist with the startup
 * routine of the app (splash screen config, authentication, remote config, etc)
 ******************************************************************************/

export interface StartUpSequenceResult {
  state: string;
  context: {
    kentico: IKenticoData;
    remoteConfig?: {};
    splashConfig?: ISplashConfig;
    handleLoginResult?: boolean;
    personData?: { [name: string]: any };
    featureFlags?: {};
    employerPreferences?: {};
    refreshResult: {
      status: string;
      loginRefreshStatus: { status: LoginRefreshStatuses };
      fullRefreshStatus: { status: FullRefreshStatuses };
      statusDetail: any;
    };
    ERContent?: IEmployerContent;
  };
  error?: unknown;
}

/**
 * Executes the startup sequence and returns an object that indicates the state
 * along with any contextual data related to that state
 * @returns {{state:string, context:StartupContext}}
 */
export const startUpSequence = async () => {
  const returnObj: StartUpSequenceResult = {
    state: 'INIT',
    context: {
      refreshResult: {
        status: '',
        loginRefreshStatus: { status: enums.RefreshStatus.started },
        fullRefreshStatus: { status: enums.RefreshStatus.started },
        statusDetail: {},
      },
      kentico: {} as any,
    },
  };

  try {
    // get splash config first
    returnObj.state = enums.AppStates.GET_SPLASH_CONFIG;
    const splashConfig = await getSplashConfig();
    returnObj.context.splashConfig = splashConfig;
  } catch (error) {
    log.error(error);
    returnObj.state = enums.AppStates.GET_SPLASH_CONFIG_ERROR;
    returnObj.error = error;
    return returnObj;
  }

  try {
    returnObj.state = enums.AppStates.GET_KENTICO_DATA;
    const results = await Promise.all([
      await getAllAssetReferences(),
      await getFeatureFlags(),
      await getSimpleRemoteConfig(),
      await getLocalizedStrings(),
      await getMarkdownContent(),
    ]);
    returnObj.context.kentico.assets = results[0];
    returnObj.context.kentico.featureFlags = results[1];
    returnObj.context.kentico.config = results[2];
    returnObj.context.kentico.localizedStrings = results[3];
    returnObj.context.kentico.dynamicContent = results[4];
  } catch (error) {
    log.error(error);
    returnObj.state = enums.AppStates.GET_KENTICO_DATA_ERROR;
    returnObj.error = error;
    return returnObj;
  }

  if (returnObj.context.splashConfig.showSplash) {
    returnObj.state = enums.AppStates.SHOW_SPLASH;
    return returnObj;
  }

  // after confirming splash is not relevant, but before login redirects, store the entry point
  if (!sessionStorage.getItem(enums.CacheKeys.entryPoint)) {
    sessionStorage.setItem(
      enums.CacheKeys.entryPoint,
      window.location.pathname,
    );
  }

  try {
    returnObj.state = enums.AppStates.AUTHENTICATING;
    let loggedIn = false;
    loggedIn = await isAuthenticated();
    returnObj.context.handleLoginResult = loggedIn;
  } catch (error) {
    log.error(error);
    returnObj.state = enums.AppStates.AUTHENTICATION_ERROR;
    returnObj.error = error;
    return returnObj;
  }

  // Authenticating a user may require a redirection.
  // If the user is authenticated, then a redirect isn't required or has
  // already happened and the user has been navigated back to the app.
  if (returnObj.context.handleLoginResult) {
    returnObj.context.refreshResult = await handleRefresh(true, false);

    //trigger the full refresh
    await startRefresh(false, true);

    returnObj.state = enums.AppStates.AUTHENTICATED;

    log.debug('final refreshResult', returnObj?.context?.refreshResult);

    if (
      returnObj.context.refreshResult.loginRefreshStatus.status ===
      enums.RefreshStatus.failed
    ) {
      returnObj.state = enums.AppStates.FAILED;
      return returnObj;
    }

    if (
      returnObj.context.refreshResult.loginRefreshStatus.status ===
      enums.RefreshStatus.notRegisterable
    ) {
      returnObj.state = enums.AppStates.NOT_REGISTERABLE;
      returnObj.context.personData = {};
      returnObj.context.kentico.callCenter = await refreshCallCenter('-1');
      return returnObj;
    }

    // initialize person data
    returnObj.context.personData = await getPersonData();
    returnObj.context.kentico.callCenter = await refreshCallCenter(
      returnObj.context.personData?.person?.companyCode,
    );

    returnObj.context.ERContent = await getEmployerContentSafely(
      returnObj.context.personData?.person?.companyCode,
    );

    //Get Employer Preferences
    try {
      returnObj.context.employerPreferences = await getEmployerPreferences();
    } catch (error) {
      log.error(error);
    }
  } else {
    // still authenticating, return here as a redirect is imminent
    returnObj.state = enums.AppStates.AUTHENTICATING;
    return returnObj;
  }

  if (
    returnObj.context.refreshResult.loginRefreshStatus.status ===
    enums.RefreshStatus.failedWithData
  ) {
    returnObj.state = enums.AppStates.FAILED_WITH_DATA;
  } else {
    returnObj.state = enums.AppStates.COMPLETE;
  }
  return returnObj;
};
