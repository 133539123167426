import { get } from '../../utils';
import { DEFAULT_LEAVE_OPTION } from './defaults';
import * as dm from '../../data-models';
import {
  getLeavePlanData,
  filterElements,
  getCalendarDetails,
} from '../../utils/calendars';
import { calendarLegend } from './absence-history-wrapper/calendar-styles';
import { FilterRuleType } from '../../utils/calendars/rules';

type getLeaveByIdType = (
  ntn: string | undefined,
  leaves: dm.Leave[],
) => dm.Leave;

type handleUseEffectInitType = (
  personData: dm.PersonData | undefined,
  ntn: string | undefined,
  setCases: React.Dispatch<React.SetStateAction<dm.Leave[]>>,
  setSelectedLeave: React.Dispatch<React.SetStateAction<dm.Leave>>,
  setAllLeaves: React.Dispatch<React.SetStateAction<dm.Leave[]>>,
  setInitialized: React.Dispatch<React.SetStateAction<boolean>>,
) => void;

/**
 * Sorts the absencePeriods by the actual date - DESC
 */
export const sortDescending = (a: any, b: any) => {
  const d1 = new Date(a.actualDate);
  const d2 = new Date(b.actualDate);

  return d1 < d2 ? 1 : -1;
};

/**
 * Finds a specific leave in an array by NTN. If not found, will return an empty object.
 */
export const getLeaveById: getLeaveByIdType = (ntn, leaves) => {
  const filteredLeave = leaves.find((il: dm.Leave) => il.parentId === ntn);

  return filteredLeave ? filteredLeave : {};
};

/**
 * Sets the initial states necessary for displaying the calendar and list.
 */
export const handleUseEffectInit: handleUseEffectInitType = (
  personData,
  ntn,
  setCases,
  setSelectedLeave,
  setAllLeaves,
  setInitialized,
) => {
  setInitialized(false);
  const leaves = get(personData, 'cases.leaves', []);
  const intermittentLeaves = leaves.filter(
    (l: dm.Leave) => l.absenceType === 'Intermittent',
  );
  const leaveObj = getLeaveById(ntn, intermittentLeaves);

  setCases([...intermittentLeaves, { caseId: DEFAULT_LEAVE_OPTION }]);
  setSelectedLeave(leaveObj);
  setAllLeaves(intermittentLeaves);
  setInitialized(true);
};

export const handleCalendarInit = async (
  personData: dm.PersonData | undefined,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setElements: React.Dispatch<React.SetStateAction<any>>,
  setPeriods: React.Dispatch<React.SetStateAction<any>>,
  setFilteredPeriods: React.Dispatch<React.SetStateAction<any>>,
  setRawElements: React.Dispatch<React.SetStateAction<any>>,
  ntn?: string,
) => {
  setLoading(true);
  const selectedLeave = getLeaveById(ntn, personData?.cases?.leaves ?? []);
  const result = await getCalendarDetails(
    selectedLeave?.startDate,
    selectedLeave?.endDate,
    personData?.cases?.leaves,
    ntn,
    false,
  );

  const { elements, rawElements, periods, nonOverlappingPeriods } =
    getLeavePlanData(result, 'intermittent', ntn);

  const filteredElements = elements.filter(
    (elem) => elem.legendKey in calendarLegend,
  );

  const filteredRawElements = rawElements.filter(
    (elem) => elem.legendKey in calendarLegend,
  );

  setLoading(false);
  setRawElements(filteredRawElements);
  setElements(filteredElements);
  setPeriods(periods);
  setFilteredPeriods(nonOverlappingPeriods);
};

export const absenceFilter = (
  elements: any[],
  periods: any[],
  option: FilterRuleType,
) => filterElements(elements, periods, 'intermittent', option);
