import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../style';
import { enums, prefixObjectValues } from '../../utils';
import Form from './form';
import { MaxWidthWrapper } from '../base/simple-styled-components';
import { FormContextProvider } from '../../contexts/form-context';
import { TaskType } from '../tasks';
import { processNavigationObject } from './form/utils';
import { isDueTask } from '../tasks/utils';

interface FormsProps {
  tasks: TaskType[];
  keyId?: string;
}

const domIdsStatic = {
  rootNode: 'forms',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

type wrappedProps = {
  p: RouteComponentProps;
  tasks: TaskType[];
};

const WrappedForm = ({ p, tasks }: wrappedProps) => {
  const history = useHistory();
  const { lastPath: url } = processNavigationObject(p);

  // if total leave task exist navigate to task details
  let task: TaskType | undefined = undefined;
  let codeNames: string[] = [];
  switch (url) {
    case 'last-day-worked':
      task = tasks.find(
        (t) =>
          t.taskCodeName === 'last_day_worked' && isDueTask(t.taskStatusId),
      );
      if (task) {
        history.push(`/app/task/${task.taskId}/details`);
      }
      break;

    case 'return-to-work':
      codeNames = [
        'rtw_actual',
        'rtw_confirmation',
        'rtw___expected_rtw',
        'ee_rtw_conf_failed',
        'shared_rtw_conf_failed',
      ];
      task = tasks.find(
        (t) => codeNames.includes(t.taskCodeName) && isDueTask(t.taskStatusId),
      );
      if (task) {
        history.push(`/app/task/${task.taskId}/details`);
      }
      break;

    case 'delivery-date':
      codeNames = ['delivery_details', 'estimated_delivery_date'];
      task = tasks.find(
        (t) => codeNames.includes(t.taskCodeName) && isDueTask(t.taskStatusId),
      );
      if (task) {
        history.push(`/app/task/${task.taskId}/details`);
      }
      break;

    case 'surgery-date':
      task = tasks.find(
        (t) => t.taskCodeName === 'sx_details' && isDueTask(t.taskStatusId),
      );
      if (task) {
        history.push(`/app/task/${task.taskId}/details`);
      }
      break;
  }

  // continue normal form flow
  return (
    <FormContextProvider>
      <Form {...p} />
    </FormContextProvider>
  );
};

const Forms: (p: FormsProps) => React.ReactElement = (p) => {
  const domIds = domIdsUnique(p.keyId);

  return (
    <Container id={domIds.rootNode}>
      <MaxWidthWrapper>
        <Switch>
          <Route path={enums.AppPaths.form} exact>
            Forms Landing Page
          </Route>
          <Route
            render={(props) => <WrappedForm p={props} tasks={p.tasks} />}
          />
        </Switch>
      </MaxWidthWrapper>
    </Container>
  );
};

export default Forms;
