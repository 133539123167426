import React from 'react';
import styled from 'styled-components';
import {
  OpenSansRegularValue,
  VerticalSeparator,
  ActionLinkButton,
} from '../../../base/simple-styled-components';
import { colors, fonts } from '../../../../style';
import arrow from '../../../../images/down_arrow.svg';
import { TaskHistoryResponse, TaskNote } from '../../../../data-models';
import dayjs from 'dayjs';

const Title = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 28px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  margin-bottom: 8px;
`;

const TitleContainer = styled.div`
  border-radius: 8px;
  display: flex;
  border-bottom: 1px solid #dddddd;
  border-radius: 4px;
  padding: 24px;
`;

const Container = styled.div`
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #dddddd;
`;

const ExpandedSection = styled.div`
  padding: 24px;
  background: #fcfcfc;
`;

const DateSection = styled(OpenSansRegularValue)`
  color: ${colors.boulder1};
  font-size: 12px;
  margin-left: 16px;
`;

const ActionLinkButtonMod = styled(ActionLinkButton)`
  line-height: 16px;
  margin: 0;
  color: ${colors.skyD2};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

const Ellipsis = styled.span`
  color: ${colors.skyD2};
`;

const Arrow = styled.img.attrs({
  alt: 'Expand',
  src: arrow,
})`
  cursor: pointer;
  margin-right: 0.5em;
  transform: ${(props: { rotate: string }) =>
    props.rotate === 'true' ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: all 0.5s;
  user-select: none;
  width: 2.75em;
  margin-left: auto;
`;

const formatDateTimeString = (date: string) => {
  return dayjs(date).format('MMM DD, YYYY, HH:mm').toUpperCase();
};

const sortNotes = (notes: TaskNote[], setTaskNotes: Function) => {
  notes.sort((tn1: TaskNote, tn2: TaskNote) => {
    return (
      new Date(tn2.modifiedDate).getTime() -
      new Date(tn1.modifiedDate).getTime()
    );
  });
  setTaskNotes(notes);
};

interface TaskNoteComponentProps {
  note: TaskNote;
  index: number;
}

const TaskNoteComponent: (p: TaskNoteComponentProps) => React.ReactElement = (
  p,
) => {
  const NOTE_CHARACTER_LIMIT = 100;
  const [expandNote, setExpandNote] = React.useState<boolean>(false);

  return (
    <>
      {p.note.notes.length <= NOTE_CHARACTER_LIMIT ? (
        <VerticalSeparator color={p.index === 0 ? '#04a874' : '#dddddd'}>
          <DateSection>{formatDateTimeString(p.note.modifiedDate)}</DateSection>
          <OpenSansRegularValue style={{ marginLeft: '16px' }}>
            {p.note.notes}
          </OpenSansRegularValue>
        </VerticalSeparator>
      ) : (
        <VerticalSeparator color={p.index === 0 ? '#04a874' : '#dddddd'}>
          <DateSection>{formatDateTimeString(p.note.modifiedDate)}</DateSection>
          <OpenSansRegularValue style={{ marginLeft: '16px' }}>
            {expandNote
              ? `${p.note.notes}`
              : `${p.note.notes.substring(0, 101)}`}
            <Ellipsis>{' ... '}</Ellipsis>
            <ActionLinkButtonMod
              id="view-more-less-button"
              onClick={() => setExpandNote(!expandNote)}
              to=""
            >
              {expandNote ? 'View Less' : 'View More'}
            </ActionLinkButtonMod>
          </OpenSansRegularValue>
        </VerticalSeparator>
      )}
    </>
  );
};

const TaskNotes: (p: TaskHistoryResponse) => React.ReactElement = (p) => {
  const [expand, setExpand] = React.useState<boolean>(true);
  const [taskNotes, setTaskNotes] = React.useState<Array<TaskNote>>([]);

  React.useEffect(() => {
    sortNotes(p.taskNoteHistory, setTaskNotes);
  }, [p]);

  return (
    <Container>
      <TitleContainer onClick={() => setExpand(!expand)}>
        {' '}
        <Title>Notes</Title>
        <Arrow id="expand-notes-arrow" rotate={`${expand}`} />
      </TitleContainer>
      {expand && (
        <ExpandedSection>
          {taskNotes.map((tn: TaskNote, index: number) => {
            return (
              <TaskNoteComponent
                note={tn}
                index={index}
                key={`task-note-${index}`}
              />
            );
          })}
        </ExpandedSection>
      )}
    </Container>
  );
};

export default TaskNotes;
