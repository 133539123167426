import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../../style';
import { IFormContext } from '../../../../contexts/form-context';
import { ISpecialBenefit } from '../../../../utils/remote-config-manager';
import { PreferencesContext } from '../../../../contexts/preferences-context';
import { models } from '../../../../utils/cms-client';

import AccordionCard from '../../../base/accordion-card';
import ExpandedSection from './expanded-section';

interface IEmployerCard {
  idPrefix: string;
  content: models.AccordionCard;
  formContext: IFormContext;
  formValueGetter: (name: string) => any;
  index: number;
}

export const Title = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 28px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  line-height: 48px;

  @media only screen and (max-width: 650px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

const EmployerCard: (p: IEmployerCard) => React.ReactElement | null = (p) => {
  const { content, formContext, formValueGetter, idPrefix, index } = p;
  const { ERContent } = React.useContext(PreferencesContext);

  const benefits: ISpecialBenefit[] = (
    (ERContent?.specialBenefits as ISpecialBenefit[]) || []
  ).filter((benefit: ISpecialBenefit) => {
    return benefit?.intakes?.includes(formContext.formName);
  });

  return benefits.length > 0 ? (
    <>
      <AccordionCard
        idPrefix={idPrefix}
        titleElement={<Title>{content?.header.value}</Title>}
        expandedElement={
          <ExpandedSection
            idPrefix={idPrefix}
            benefits={benefits}
            formContext={formContext}
            formValueGetter={formValueGetter}
          />
        }
        index={index}
      />
    </>
  ) : null;
};

export default EmployerCard;
