import styled from 'styled-components';
import { fonts } from '../../../../style';

const dateFormat = 'MM/DD/YYYY';

const Items = styled.div`
  width: 100%;
`;
const Item = styled.div`
  padding: 0.5em 0;
  width: 100%;
`;
const CardItemTitle = styled.div`
  color: #013249;
  font-size: 20px;
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-weight: ${fonts.ubuntu.regular.fontWeight};
`;

type TextProps = {
  color: string;
};

const CardText = styled.div<TextProps>`
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  color: ${(p) => p.color};
  @media (max-width: 900px) {
    padding-left: 21%;
  }
  @media (max-width: 750px) {
    padding-left: 9%;
  }
  @media (max-width: 750px) {
    padding-left: 23%;
  }
  @media (max-width: 580px) {
    padding-left: 0;
  }
`;

interface StatusFlagProps {
  color: string;
}

const colorMap: { [name: string]: string } = {
  success: '#000000',
  approved: '#000000',
  adjudication: '#000000',
  open: '#000000',
  pending: '#000000',
  partial: '#000000',
  mixeddecision: '#000000',
  intakeinprogress: '#000000',
  denied: '#000000',
  closed: '#000000',
  none: '#000000',
  requestrecognized: '#000000',
};

const StatusFlag = styled.div<StatusFlagProps>`
  border-radius: 16px;
  color: ${(p) => colorMap[p.color]};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  padding: 4px 5px;
  text-align: center;
  border-color: ${(p) => colorMap[p.color]};
  width: 100%;
`;

const CardItemBody = styled.div`
  align-items: center;
  color: #013249;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 1.5em;
  width: 100%;
  margin-top: 32px;
  flex-wrap: wrap;

  @media (max-width: 580px) {
    div {
      margin-top: 8px;
      &:nth-child(1) {
        order: 1;
        width: 90% !important;
      }
      &:nth-child(2) {
        order: 3;
        width: 100% !important;
      }
      &:nth-child(3) {
        order: 4;
        width: 100% !important;
        max-width: 40%;
      }
      &:nth-child(4) {
        order: 2;
      }
    }
  }
`;

const CardBread = styled.p`
  color: #00909b;
  font-size: 14px;
  font-weight: 700;
  margin: 1.5em 0 0.5em;
  text-transform: uppercase;
`;

const Column = styled.div<ColumnType>`
  align-items: ${(p) => p.align};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(p) => (p.size ? p.size + '%' : '100%')};
`;

const TaskBadge = styled.div`
  width: 100%;
  padding: 16px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fef3ea;
`;

type ColumnType = {
  size: number;
  align: string;
};

export {
  dateFormat,
  Items,
  Item,
  CardItemTitle,
  CardItemBody,
  CardBread,
  Column,
  CardText,
  StatusFlag,
  TaskBadge,
};
