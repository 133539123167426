const levels: { [name: string]: number } = {
  SILENT: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4,
};

type LogType = 'error' | 'warn' | 'info' | 'debug';

const tryLog = (
  type: LogType,
  message?: unknown,
  ...optionalParams: unknown[]
): void => {
  if (levels[window.LOG_LEVEL || 'ERROR'] >= levels[type.toUpperCase()]) {
    console[type](message, ...optionalParams);
  }
};

const error = (message?: unknown, ...optionalParams: unknown[]): void =>
  tryLog('error', message, ...optionalParams);

const warn = (message?: unknown, ...optionalParams: unknown[]): void =>
  tryLog('warn', message, ...optionalParams);

const info = (message?: unknown, ...optionalParams: unknown[]): void =>
  tryLog('info', message, ...optionalParams);

const debug = (message?: unknown, ...optionalParams: unknown[]): void =>
  tryLog('debug', message, ...optionalParams);

export const log = {
  error,
  warn,
  info,
  debug,
};
