import { enums } from '..';
import * as dm from '../../data-models';
import {
  IPostDocumentArgsWithCaseId,
  IPostDocumentArgsWithPlanId,
} from '../../data-models/web-apis';
import { deleteApi, getApi, postApi, putApi } from './same-origin';

// deletes
export const deleteAutoSave = async (formName: string) =>
  deleteApi(enums.ApiPaths.autoSave, { 'x-unum-formname': formName });
export const deleteDraftClaim = async (planId: string) =>
  deleteApi(enums.ApiPathsEX.draftClaim, { planId });

// gets

export const getAutoSave = async (formName: string) =>
  getApi<dm.IAutoSaveResponse>(enums.ApiPaths.autoSave, {
    'x-unum-formname': formName,
  });
export const getAvailableCallbackTimes = async (
  companyCode: string,
  queryDate: string,
) =>
  getApi<{ availableTimesArray: dm.CallCenterHoursDropDownItem[] }>(
    `${enums.ApiPaths.callbacksAvailable}/?date=${queryDate}`,
    { 'x-unum-param-company-code': companyCode },
  );
export const getCalendarData = async (
  startDate?: string,
  endDate?: string,
  planId?: string,
) => {
  const headers: any = {};
  const queryParams: any = {
    startDate,
    endDate,
  };
  if (planId) {
    headers.planId = planId;
  }
  return getApi<any>('/ab/calendar', headers, queryParams);
};
export const getCases = async () => getApi<dm.Cases>(enums.ApiPathsEX.cases);
export const getDocument = async (caseid: string, documentid: string) =>
  getApi<any>(enums.ApiPathsEX.document, {
    caseid,
    documentid,
  });
export const getDocuments = async (caseid: string) =>
  getApi<dm.Documents>(enums.ApiPathsEX.documents, {
    caseid,
  });
export const getDraftClaims = async () =>
  getApi<dm.DraftClaims>(enums.ApiPathsEX.draftClaims);
export const getEmployerPreferences = async () =>
  getApi<{} | undefined>(enums.ApiPathsEX.employerPreferences);
export const getLeaveAvailibility = async (planId: string, calcDate: string) =>
  getApi<dm.LeaveAvailibility>(enums.ApiPaths.LeaveAvailibility, {
    'x-unum-param-plan-id': planId,
    'x-unum-param-calc-date': calcDate,
  });
export const getMergeUploadedFilesBucketUrl = async (
  caseId: string,
  fileName: string,
  contentType: string,
  timeStamp: string,
) =>
  getApi<any>(enums.ApiPaths.MergeUploadedFilesBucketUrl, {
    'x-unum-param-caseid': caseId,
    'x-unum-param-filename': fileName,
    'x-unum-param-contenttype': contentType,
    'x-unum-param-timestamp': timeStamp,
  });
export const getPaymentPreferences = async () =>
  getApi<any>(enums.ApiPathsEX.getPaymentPreferences); // no model
export const getPerson = async () => getApi<dm.Person>(enums.ApiPathsEX.person);
export const getPersonData = async () =>
  getApi<dm.PersonData>(enums.ApiPathsEX.personData);
export const getPreferences = async () =>
  getApi<dm.CommunicationPreferences>(enums.ApiPathsEX.preferences);
export const getRefresh = async () => getApi<any>(enums.ApiPathsEX.refresh);
export const getReminders = async (openOnly = true, smsPrefsEnabled = true) =>
  getApi<{
    finalRemindersArray: dm.ReminderItem[];
  }>(`${enums.ApiPaths.remindersFunction}`, {
    'x-unm-open-reminders-only': String(openOnly),
    'x-unm-sms-prefs-enabled': String(smsPrefsEnabled),
  });

export const getSessionInfo = async () =>
  getApi<{
    username?: string;
    maxIdleExpirationTime?: string;
    maxSessionExpirationTime?: string;
    eid?: string;
    firstName?: string;
    lastName?: string;
  }>(enums.ApiPaths.sessionInfo);
export const getTask = async (taskId: string) =>
  getApi<dm.Task>(`${enums.ApiPathsAB.tasks}/${taskId}`);
export const getTaskFormDownloadUrl = async (taskId: string) =>
  getApi<any>(`${enums.ApiPathsAB.tasks}/${taskId}/documents`) as Promise<
    dm.TaskDocumentsObject[]
  >;
export const getTaskHistory = async (taskId: string) =>
  getApi<any>(
    `${enums.ApiPathsAB.tasks}/${taskId}/history`,
  ) as Promise<dm.TaskHistoryResponse>;
export const getTasks = async (queryParams: {
  caseId?: string;
  notificationCaseId?: string;
  customerNumber?: number;
  taskStatusId?: number;
  assignee?: number;
  tokenId?: string;
  lastKey?: string;
  sortKey?: string;
  sortOrder?: string;
}) => getApi<dm.Tasks>(enums.ApiPathsAB.tasks, {}, queryParams);
export const getVBCoverage = async () =>
  getApi<dm.ICoverageV1Response>(enums.ApiPaths.vbCoverage);
export const getZelleEnrollmentsStatus = async (telephonenumber?: string) => {
  return getApi<any>(
    enums.ApiPathsEX.getZelleEnrollmentsStatus,
    telephonenumber
      ? {
          telephonenumber,
        }
      : undefined,
  );
};

// posts
export const closeReminder = async (reminderId: string) =>
  postApi(`${enums.ApiPaths.closeReminder}/?reminderId=${reminderId}`);
export const createReminder = async (body: dm.ReminderRequest) =>
  postApi(`${enums.ApiPaths.createReminder}`, {}, body);
export const postAutoSave = async (body: dm.IAutoSave) =>
  postApi<any>(
    enums.ApiPaths.autoSave,
    {
      'x-unum-formname': body.formName,
    },
    body,
  );
export const postAbsences = async (caseId: string, body: dm.EpisodicAbsence) =>
  postApi<any>(enums.ApiPathsEX.episodicAbsences, { caseid: caseId }, body);
export const postADA = async (body: dm.AccommodationSubmission) =>
  postApi<any>(enums.ApiPathsEX.ada, undefined, body);
export const postCallback = async (body: { [key: string]: any }) =>
  postApi<any>(enums.ApiPathsEX.callback, undefined, body);
export const postChatDetails = async (body: any) =>
  postApi<any>(enums.ApiPathsEX.chat, undefined, body);
export const postConvertFile = async (file: string) =>
  postApi<{ file: string }>(enums.ApiPaths.ConvertFile, undefined, {
    file,
  });
export const postDocument = async (
  args: IPostDocumentArgsWithCaseId | IPostDocumentArgsWithPlanId,
) => {
  const documentBody: any = {
    filename: args.fileName,
    contenttype: args.contentType,
    action: args.actionType,
    // we should have at least one of these at this point
    ...(args.caseId && { caseid: args.caseId }),
    ...(args.planId && { planid: args.planId }),
    // below are optional properties
    ...(args.taskKey && { taskkey: args.taskKey }),
  };

  return postApi<any>(enums.ApiPathsEX.document, undefined, documentBody);
};
export const postDraftClaim = async (body: dm.DraftClaim) =>
  postApi<any>(enums.ApiPathsEX.draftClaim, undefined, body);
export const postESignature = async (
  caseid: string,
  request: dm.EsignatureRequest,
) => postApi<any>(enums.ApiPathsEX.esignature, { caseid }, request);
export const postExtensionRequest = async (
  caseId: string,
  body: dm.LeaveExtensions,
) =>
  postApi<any>(
    enums.ApiPathsAN.leaveExtensions,
    {
      caseid: caseId,
    },
    body,
  );
export const postLDW = async (body: any, caseid: string) =>
  postApi<any>(enums.ApiPathsEX.ldw, { caseid }, body);
export const postLogout = async () => postApi<any>(enums.ApiPaths.logout);
export const postMergeUploadedFiles = async (
  sortableFiles: any[],
  docType: string,
) =>
  postApi<any>(enums.ApiPaths.MergeUploadedFiles, undefined, {
    fileOrder: sortableFiles,
    documentType: docType,
  });
export const postPaymentPreferences = async (body: any, id: string) =>
  postApi<any>(enums.ApiPathsEX.setPaymentPreferences, undefined, body, {
    paymentPreferenceId: id,
  });
export const postPreferences = async (
  preferences: dm.CommunicationPreferences,
) => postApi<any>(enums.ApiPathsEX.preferences, undefined, preferences);
export const postPregnancy = async (body: dm.Pregnancy) =>
  postApi<any>(enums.ApiPathsEX.pregnancy, undefined, body);
export const postQualtrics = async (body: dm.VBSurveyBody, survey: string) =>
  postApi<any>(
    enums.ApiPaths.qualtrics,
    { 'x-unum-param-survey': survey },
    body,
  );
export const postRefresh = async (
  triggerLoginRefresh: boolean,
  triggerFullRefresh: boolean,
) =>
  postApi<any>(enums.ApiPathsEX.refresh, undefined, {
    triggerLoginRefresh,
    triggerFullRefresh,
  });
export const postRefreshToken = async () =>
  postApi<any>(enums.ApiPaths.RefreshToken);
export const postSubmission = async (
  planid: string,
  submissionESignatures: dm.SubmissionESignature,
) =>
  postApi<any>(
    enums.ApiPathsEX.submission,
    {
      planid,
    },
    submissionESignatures,
  );
export const postTask = async (taskId: string, body: any) =>
  postApi(`${enums.ApiPathsAB.tasks}/${taskId}`, undefined, body);
export const postTrack = async (body: any) =>
  postApi<any>(enums.ApiPathsAN.track, undefined, body);
export const postVBSubmission = async (vbSubmission: dm.VBSubmission) =>
  postApi<any>(enums.ApiPaths.vbSubmission, undefined, vbSubmission);
export const postWebMessage = async (body: dm.Message, caseid?: string) =>
  postApi<any>(
    enums.ApiPathsEX.webmessage,
    caseid ? { caseid } : undefined,
    body,
  );

// puts
export const putDraftClaim = async () => putApi(enums.ApiPathsEX.draftClaim);
