import {
  startUpSequence,
  StartUpSequenceResult,
} from '../../../utils/startup-manager';
import useAsyncHandler from '../../../hooks/use-async-handler';
import { enums } from '../../../utils';
import App from '../app';
import { ErrorScreen } from '../exception-screens/error-screen';
import { LoadingScreen } from '../loading-screen';
import { MemoryRouter } from 'react-router-dom';
import { PersonDataProvider } from '../../../contexts/person-data-context';
import { KenticoDataContextProvider } from '../../../contexts/kentico-data-context';
import { PreferencesContextProvider } from '../../../contexts/preferences-context';
import { RefreshContextProvider } from '../../../contexts/refresh-context';

if (process.env.REACT_APP_MOCK) {
  require(process.env.REACT_APP_MOCK);
}

/*******************************************************************************
 * AppStartupHandler component
 * @returns A view based on the start up sequence
 ******************************************************************************/
const AppStartupHandler = () => {
  const { data, delayedNotify, error, started, startRequest } =
    useAsyncHandler<StartUpSequenceResult>(startUpSequence, undefined, 250);

  if (!started) {
    startRequest();
  }

  if (error) {
    return (
      <MemoryRouter>
        <ErrorScreen />
      </MemoryRouter>
    );
  }

  if (data && delayedNotify) {
    switch (data.state) {
      case enums.AppStates.SHOW_SPLASH:
        global.window.location.assign(enums.AppPaths.rootAlreadyRegistered);
        return <LoadingScreen />;

      case enums.AppStates.FAILED:
      case enums.AppStates.AUTHENTICATION_ERROR:
      case enums.AppStates.GET_SPLASH_CONFIG_ERROR:
      case enums.AppStates.GET_KENTICO_DATA_ERROR:
        return (
          <MemoryRouter>
            <ErrorScreen />
          </MemoryRouter>
        );

      case enums.AppStates.FAILED_WITH_DATA:
        return (
          <PersonDataProvider personData={data.context.personData}>
            <PreferencesContextProvider
              employerPreferences={data.context.employerPreferences}
              ERContent={data.context.ERContent}
            >
              <KenticoDataContextProvider {...data.context.kentico}>
                <RefreshContextProvider
                  loginStatus={
                    data.context.refreshResult.loginRefreshStatus.status
                  }
                  details={data.context.refreshResult.statusDetail}
                  status={data.context.refreshResult.fullRefreshStatus.status}
                  triggerBackgroundUpdates={true}
                >
                  <App failedWithData />
                </RefreshContextProvider>
              </KenticoDataContextProvider>
            </PreferencesContextProvider>
          </PersonDataProvider>
        );

      case enums.AppStates.NOT_REGISTERABLE:
        return (
          <PersonDataProvider personData={data.context.personData}>
            <KenticoDataContextProvider {...data.context.kentico}>
              <App notRegisterable />
            </KenticoDataContextProvider>
          </PersonDataProvider>
        );

      case enums.AppStates.AUTHENTICATING:
        return <LoadingScreen />;

      default:
        return (
          <PersonDataProvider personData={data.context.personData}>
            <PreferencesContextProvider
              employerPreferences={data.context.employerPreferences}
              ERContent={data.context.ERContent}
            >
              <KenticoDataContextProvider {...data.context.kentico}>
                <RefreshContextProvider
                  loginStatus={
                    data.context.refreshResult.loginRefreshStatus.status
                  }
                  details={data.context.refreshResult.statusDetail}
                  status={data.context.refreshResult.fullRefreshStatus.status}
                  triggerBackgroundUpdates={true}
                >
                  <App />
                </RefreshContextProvider>
              </KenticoDataContextProvider>
            </PreferencesContextProvider>
          </PersonDataProvider>
        );
    }
  }

  return <LoadingScreen />;
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export default AppStartupHandler;
