import { useCallback, useMemo, useState } from 'react';
import {
  DropDownTree as KendoDropDownTree,
  DropDownTreeChangeEvent,
  DropDownTreeExpandEvent,
} from '@progress/kendo-react-dropdowns';
import { CustomUploadFileInfo } from '../../../utils/upload-helpers';
import {
  processTreeData,
  expandedState,
} from '../document-types-data-operations';
import { docTypes } from '../document-types-data';
import styled from 'styled-components';
import { colors } from '../../../style';

interface UploadProps {
  setDocType(arg0: string): unknown;
  keyId?: string;
  files?: CustomUploadFileInfo[];
  uploadTried?: boolean;
}

const DropDownTree = styled(KendoDropDownTree)<any>`
  .k-dropdown-wrap {
    background-color: ${colors.white};
    border-color: ${colors.alto};
    height: 56px;
    align-items: center;
    display: flex;
  }
`;

const selectField = 'selected';
const expandField = 'expanded';
const dataItemKey = 'id';
const textField = 'text';
const subItemsField = 'items';
const fields = { selectField, expandField, dataItemKey, subItemsField };

const DocumentTypeSelection: (
  p: UploadProps,
  props: any,
) => React.ReactElement = (props) => {
  const [value, setValue] = useState<string>('');
  const [expanded, setExpanded] = useState([docTypes[0][dataItemKey]]);

  const onChange = (event: DropDownTreeChangeEvent) => {
    if (event.value.text !== 'Other') {
      if (event.level.length > 1) {
        setValue(event.value);
        props.setDocType(event.value.text);
      }
    } else {
      setValue(event.value);
      props.setDocType(event.value.text);
    }
  };

  const onExpandChange = useCallback(
    (event: DropDownTreeExpandEvent) =>
      setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded],
  );

  const treeData = useMemo(
    () => processTreeData(docTypes, { expanded, value }, fields),
    [expanded, value],
  );

  return (
    <div id={'docType'} style={{ paddingBottom: '24px' }}>
      <div
        style={{
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          color: '#757575',
          paddingTop: '16px',
          paddingBottom: '8px',
          lineHeight: '16px',
        }}
      >
        Document type
      </div>
      <DropDownTree
        id={'docTypeDropdown'}
        style={{
          maxWidth: '400px',
          width: '100%',
          backgroundColor: '#e4e7eb',
        }}
        data={treeData}
        value={value}
        onChange={onChange}
        placeholder="Please select ..."
        textField={textField}
        dataItemKey={dataItemKey}
        selectField={selectField}
        expandField={expandField}
        onExpandChange={onExpandChange}
      />
    </div>
  );
};
export default DocumentTypeSelection;
