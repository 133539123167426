import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../../style';
import { enums, analyticsTrackEvent } from '../../../utils';
import PlusIcon from '../../../images/blue-plus-in-circle.svg';
import dayjs from 'dayjs';

type AddAbsenceLinkType = (p: {
  selectedDate: any;
  selectedCaseId: string;
}) => React.ReactElement | null;

const LinkStyled = styled(Link)`
  color: ${colors.skyD2};
  font-weight: 600;
  float: right;
  margin: 20px 0;
`;

const LinkContent = styled.div`
  &:after {
    content: 'New absence';

    @media only screen and (max-width: 450px) {
      content: '';
    }
  }
`;

const AddAbsenceLink: AddAbsenceLinkType = (p) => {
  const { selectedDate, selectedCaseId } = p;

  return (
    <LinkStyled
      id="add-absence-link"
      to={{
        pathname: `${enums.AppPaths.form}/intermittent-absence`,
        state: {
          task: { caseId: selectedCaseId },
          defaults: {
            startDate: dayjs(selectedDate).toDate(),
          },
        },
      }}
      onClick={() =>
        analyticsTrackEvent(enums.AnalyticsEvents.absencesAdd, {
          ntn: selectedCaseId,
          date: dayjs(selectedDate).format('MMM DD, YYYY'),
        })
      }
    >
      <LinkContent>
        <img
          src={PlusIcon}
          alt=""
          width="24"
          height="24"
          style={{ marginRight: 8 }}
        />
      </LinkContent>
    </LinkStyled>
  );
};

export default AddAbsenceLink;
