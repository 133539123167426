import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { enums, get, prefixAndReCamelCase } from '../../../utils';
import { log } from '../../../utils/log';
import * as hbh from '../../../hooks/use-handlebars/helpers';
import { IFormContext } from '../../../contexts/form-context';

dayjs.extend(utc);
dayjs.extend(timezone);
// const log = new Logger\('forms/options');

export interface IFieldOptions {
  updateFieldsOnChange?: { [name: string]: any };
  showWhen?: {
    dateGreaterThan?: string[];
    dateGreaterThanOrEqual?: string[];
    isTrue?: string;
    isFalse?: string;
    fieldValueIs?: string[];
    fieldValueIsNot?: string[];
    fieldValueDefined?: string;
    fieldValueUndefined?: string;
    all?: [{ fieldValueIs: string[] }];
    any?: [{ fieldValueIs: string[] }];
    caseRefreshStatusIs?: string[];
  };
  validators?: (
    | {
        name: string;
        params: string[];
        property: string;
      }
    | string
  )[];
  validationMessages?: {
    [name: string]: string;
  };
}

export const defaults = {
  callCenterHoursOfOperation: {
    start: '2020-01-01T13:00:00.000Z',
    end: '2020-01-01T22:00:00.000Z',
  },
};

const msInHour = 1000 * 60 * 60;

export interface CallCenterHoursDropDownItem {
  displayTime: string;
  startTime: string;
  disabled?: boolean;
}

const checkIfValidDates = (fv: string | Date, v: string | Date) => {
  return dayjs(fv).isValid() && dayjs(v).isValid();
};

export const map = {
  showWhen: {
    dateGreaterThan: (d1: string | Date, d2: string | Date) =>
      dayjs(d1).isAfter(dayjs(d2)),
    dateGreaterThanOrEqual: (d1: string | Date, d2: string | Date) =>
      dayjs(d1).isAfter(dayjs(d2)) || dayjs(d1).isSame(dayjs(d2)),
    fieldValueIs: (fv: string | Date, v: string | Date) => {
      if (checkIfValidDates(fv, v)) {
        return dayjs(fv).isSame(dayjs(v));
      }

      return fv === v;
    },
    fieldValueIsNot: (fv: string | Date, v: string | Date) => {
      if (checkIfValidDates(fv, v)) {
        return !dayjs(fv).isSame(dayjs(v));
      }

      return fv !== v;
    },
  },
};

/** Types */
type FormValueTypes = string | Date | Array<string>;
export type FormValueGetterType = (name: string) => any;
export type GetOptionValue = (
  optionTemplate: string,
  formValueGetter: FormValueGetterType,
  formContext: IFormContext | undefined,
) => any;

export interface IModifierMap {
  [name: string]: (list: any[], params: string, formValueGetter: any) => any[];
}

export interface IModifierParam {
  value: string;
  literal: boolean;
}

export const resolveComparatorValues = (
  currentItem: any,
  params: IModifierParam[],
) => {
  const newParams = params.map((p) => {
    let v: any = p.value;
    let boolOrNumber = true;

    if (v === 'true') {
      v = true;
    } else if (v === 'false') {
      v = false;
    } else if (!isNaN(v)) {
      v = parseInt(v);
    } else {
      boolOrNumber = false;
    }

    if (!boolOrNumber && !p.literal) {
      v = get(currentItem, p.value, currentItem);
    }

    return v;
  });

  // if we're only passing 1 value in, assume it's to be compared to the value we're on
  if (newParams.length === 1) {
    newParams.push(currentItem);
  }

  return newParams;
};

export const modifierMap: IModifierMap = {
  filter: (list, params, formValueGetter) => {
    let newList = [...list];

    // handle params separated by space, or single quotes
    const paramParts = params.match(/[^\s']+|'([^']*)'/g) as string[];
    // if we have single quotes in the param, treat it as a literal
    const modifierParams: IModifierParam[] = paramParts?.map((p) => ({
      literal: p.includes("'"),
      value: p.includes("'") ? p.slice(1, -1) : p,
    }));

    // first value should always be the operation name
    const helperName: string = modifierParams.shift()?.value as string;
    const helper = hbh.helperMap[helperName];

    // helper is expecting position 1 to be formValueGetter and the last position to be the "options" from Handlebars
    newList = newList.filter((r: any) => {
      return helper(
        { formValueGetter },
        ...resolveComparatorValues(r, modifierParams),
        {},
      );
    });

    return newList;
  },
  sort: (list, params, _formValueGetter) => {
    return params.length > 0
      ? list.sort(function (a, b) {
          return a[params].localeCompare(b[params]);
        })
      : list.sort();
  },
  reverse: (list, _params, _formValueGetter) => {
    return list.reverse();
  },
  orFilter: (list, params, _formValueGetter) => {
    // handle params separated by space, or single quotes
    let newList = [...list];
    const paramParts = params.match(/[^\s']+|'([^']*)'/g) as string[];

    const property: string | undefined = paramParts.shift();
    paramParts.forEach((p: string, i: number) => {
      paramParts[i] = p.includes("'") ? p.slice(1, -1) : p;
    });

    newList = newList.filter((r: any) => {
      if (property && r[property]) {
        return paramParts.includes(r[property]);
      }

      return newList;
    });

    return newList;
  },
};

export const handleModifiers = (
  result: any,
  optionTemplate: string,
  formValueGetter: any,
) => {
  if (Array.isArray(result) && optionTemplate.includes(':')) {
    const modifiers = optionTemplate.split(':');
    // main options
    modifiers.shift();
    let newList = [...result];

    for (const m of modifiers) {
      const modifierParts = m.split('(');
      const modifierName: string = modifierParts[0];
      const modifierParams = modifierParts[1].split(')')[0];

      newList = modifierMap[modifierName](
        newList,
        modifierParams,
        formValueGetter,
      );
    }

    return newList;
  }

  return result;
};

export const extractTemplateWithoutModifiers = (t: string) => {
  // colon used in modifiers, skip if we're not using them
  if (!t.includes(':')) {
    return t;
  }

  // remove any sections where the first part matches the modifier names
  // with ( to match the start of the execution params
  const parts = t
    .split(':')
    .filter(
      (p) => !Object.keys(modifierMap).some((k) => p.startsWith(`${k}(`)),
    );

  return parts.join(':');
};

export const extractDataSources = (t: string) => {
  const optionTemplateParts = extractTemplateWithoutModifiers(t).split('.');

  // first part indicates where to pull data from
  const dataSourceIndicator = optionTemplateParts.shift();

  // second part+ is a path to the data
  const dataSourcePath = optionTemplateParts.join('.');

  return { dataSourceIndicator, dataSourcePath };
};

/**
 * Converts the template from the optionsTemplate into a value from either another form field or form context
 * @param optionTemplate String indicating where and how to get the value desired from
 * @param formValueGetter formRenderProps.valueGetter from rendered Kendo React <Form />
 * @param formContext Context built from the start of the app or after loading the form
 * @returns Value determined from the optionTemplate source and path to
 */
export const getOptionValue: GetOptionValue = (
  optionTemplate,
  formValueGetter,
  formContext,
) => {
  let result: any = optionTemplate;
  let skipModifiers = false;

  // skip if no extra processing is necessary
  if (!optionTemplate.startsWith('$')) {
    return result;
  }

  const { dataSourceIndicator, dataSourcePath } =
    extractDataSources(optionTemplate);

  switch (dataSourceIndicator) {
    case enums.DataSources.formField:
      if (dataSourcePath?.includes('.')) {
        const dsParts = dataSourcePath?.split('.');
        const vName = dsParts.shift() as string;
        const v = formValueGetter(vName);
        const r = get(v, dsParts.join('.'));

        log.debug(r);
        result = r;
      } else {
        result = formValueGetter(dataSourcePath);
      }
      break;
    case enums.DataSources.formContext:
      result = get(formContext, dataSourcePath);
      break;
    case enums.DataSources.toDate:
      result = new Date(dataSourcePath);
      break;
    case enums.DataSources.availableCallCenterTimesBasedOn:
      result = `${dataSourceIndicator}.${dayjs(
        formValueGetter(dataSourcePath),
      ).format('YYYYMMDD')}`;
      skipModifiers = true;
      break;
  }

  if (!skipModifiers) {
    result = handleModifiers(result, optionTemplate, formValueGetter);
  }

  return result;
};

export const processValidatorParams = (
  optionsTemplate: IFieldOptions,
  formValueGetter: FormValueGetterType,
  formContext: IFormContext,
) => {
  if (!optionsTemplate?.validators) {
    return optionsTemplate;
  }

  // options template
  const ot: IFieldOptions = JSON.parse(JSON.stringify(optionsTemplate));

  ot.validators = ot.validators?.map((v) => {
    if (typeof v === 'string') {
      return v;
    }

    v.params = v.params.map((p: string) => {
      return getOptionValue(p, formValueGetter, formContext)?.toString?.();
    });

    return v;
  });

  return ot;
};

export type CheckerFunction = (
  sw: { [name: string]: any },
  formValueGetter: FormValueGetterType,
  formContext: IFormContext,
  refreshContext?: any,
  sectionFieldNamePrefix?: string,
) => boolean;

export const checkDateGreaterThan: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const orEquals = !!sw.dateGreaterThanOrEqual;
  const d1 = orEquals ? sw.dateGreaterThanOrEqual[0] : sw.dateGreaterThan[0];
  const d2 = orEquals ? sw.dateGreaterThanOrEqual[1] : sw.dateGreaterThan[1];

  const dateValue1 = getOptionValue(d1, formValueGetter, formContext);

  const dateValue2 = getOptionValue(d2, formValueGetter, formContext);

  return orEquals
    ? map.showWhen.dateGreaterThanOrEqual(dateValue1, dateValue2)
    : map.showWhen.dateGreaterThan(dateValue1, dateValue2);
};

export const checkIsTrue: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const r = getOptionValue(sw.isTrue, formValueGetter, formContext);

  if (typeof r === 'boolean') {
    return r;
  } else if (typeof r === 'string') {
    return r.length > 0;
  } else {
    return false;
  }
};

export const checkIsFalse: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const r = getOptionValue(sw.isFalse, formValueGetter, formContext);

  if (typeof r === 'boolean') {
    return !r;
  } else if (typeof r === 'string') {
    return r.length === 0;
  } else {
    return true;
  }
};

export const checkFieldValueIs: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const fv = getOptionValue(sw.fieldValueIs[0], formValueGetter, formContext);
  const v = getOptionValue(sw.fieldValueIs[1], formValueGetter, formContext);
  return map.showWhen.fieldValueIs(fv, v);
};

const prefixDataSourceIndicator = (
  dataSourceIndicator: string | undefined,
  dataSourcePath: string,
) => {
  if (dataSourceIndicator?.length && !dataSourceIndicator?.startsWith('$')) {
    return dataSourceIndicator;
  }

  return dataSourceIndicator
    ? `${dataSourceIndicator}.${dataSourcePath}`
    : dataSourcePath;
};

export const checkStepFieldValueIs: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
  _refreshContext,
  sectionFieldNamePrefix = '',
) => {
  const fvds = extractDataSources(sw.stepFieldValueIs[0]);
  const vds = extractDataSources(sw.stepFieldValueIs[1]);

  const fv = getOptionValue(
    prefixDataSourceIndicator(
      fvds.dataSourceIndicator,
      prefixAndReCamelCase(sectionFieldNamePrefix, fvds.dataSourcePath),
    ),
    formValueGetter,
    formContext,
  );
  const v = getOptionValue(
    prefixDataSourceIndicator(
      vds.dataSourceIndicator,
      prefixAndReCamelCase(sectionFieldNamePrefix, vds.dataSourcePath),
    ),
    formValueGetter,
    formContext,
  );

  return map.showWhen.fieldValueIs(fv, v);
};

export const checkShowPaymentPrefs: CheckerFunction = (
  _sw,
  _formValueGetter,
  formContext,
) => {
  const erIds = formContext?.hidePaymentPrefs;
  const code = formContext?.person?.companyCode;
  return !erIds?.split('|').includes(code || '');
};

const checkArrayIsDefined = (value: any) =>
  Array.isArray(value) ? value.length > 0 : true;

const checkArrayIsUndefined = (value: any) =>
  Array.isArray(value) ? value.length === 0 : false;

export const checkIsValueDefined = (value: FormValueTypes) => {
  return (
    value !== undefined &&
    value !== null &&
    value !== '' &&
    checkArrayIsDefined(value)
  );
};

export const checkIsValueUndefined = (value: FormValueTypes) => {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    checkArrayIsUndefined(value)
  );
};

export const checkFieldValueIsNot: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const fv = getOptionValue(
    sw.fieldValueIsNot[0],
    formValueGetter,
    formContext,
  );
  const v = getOptionValue(sw.fieldValueIsNot[1], formValueGetter, formContext);

  const isDefined = checkIsValueDefined(fv);

  return isDefined ? map.showWhen.fieldValueIsNot(fv, v) : false;
};

export const checkStepFieldValueIsNot: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
  _refreshContext,
  sectionFieldNamePrefix = '',
) => {
  const fvds = extractDataSources(sw.stepFieldValueIsNot[0]);
  const vds = extractDataSources(sw.stepFieldValueIsNot[1]);

  const fv = getOptionValue(
    prefixDataSourceIndicator(
      fvds.dataSourceIndicator,
      prefixAndReCamelCase(sectionFieldNamePrefix, fvds.dataSourcePath),
    ),
    formValueGetter,
    formContext,
  );
  const v = getOptionValue(
    prefixDataSourceIndicator(
      vds.dataSourceIndicator,
      prefixAndReCamelCase(sectionFieldNamePrefix, vds.dataSourcePath),
    ),
    formValueGetter,
    formContext,
  );

  const isDefined = checkIsValueDefined(fv);

  return isDefined ? map.showWhen.fieldValueIsNot(fv, v) : false;
};

export const checkFieldIsValueDefined: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const fv = getOptionValue(sw.fieldValueDefined, formValueGetter, formContext);

  return checkIsValueDefined(fv);
};

export const checkFieldIsValueUndefined: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const fv = getOptionValue(
    sw.fieldValueUndefined,
    formValueGetter,
    formContext,
  );

  return checkIsValueUndefined(fv);
};

export const checkAll: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
  refreshContext,
) => {
  const all: { [name: string]: any }[] = sw.all;

  return all.every((v) => {
    const key = Object.keys(v)[0];
    return !!actionMap[key](v, formValueGetter, formContext, refreshContext);
  });
};

export const checkAny: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
  refreshContext,
) => {
  const any: { [name: string]: any }[] = sw.any;

  return any.some((v) => {
    const key = Object.keys(v)[0];
    return !!actionMap[key](v, formValueGetter, formContext, refreshContext);
  });
};

export const checkFieldValueIncludes: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const fv = getOptionValue(
    sw.fieldValueIncludes[0],
    formValueGetter,
    formContext,
  );
  const v = getOptionValue(
    sw.fieldValueIncludes[1],
    formValueGetter,
    formContext,
  );

  const isDefined = checkIsValueDefined(fv);

  return isDefined && fv.includes(v);
};

export const arrayObjectValueIncludes: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const v = sw.arrayObjectValueIncludes;
  const array: Array<{ [name: string]: string | number }> =
    getOptionValue(v[0], formValueGetter, formContext) || [];
  return array.some((a) => a[v[1]]?.toString().includes(v[2]));
};

export const checkCaseRefreshStatusIs: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
  refreshContext,
) => {
  const fv = getOptionValue(
    sw.caseRefreshStatusIs[0],
    formValueGetter,
    formContext,
  );
  const v = getOptionValue(
    sw.caseRefreshStatusIs[1],
    formValueGetter,
    formContext,
  );

  const caseStatus = (refreshContext?.refreshCaseDetail ?? []).find(
    (cds: any) => cds?.sourceKey === fv?.caseId,
  );

  return caseStatus?.status && map.showWhen.fieldValueIs(caseStatus.status, v);
};

export const checkCaseRefreshStatusIsNot: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
  refreshContext,
) => {
  const fv = getOptionValue(
    sw.caseRefreshStatusIsNot[0],
    formValueGetter,
    formContext,
  );
  const v = getOptionValue(
    sw.caseRefreshStatusIsNot[1],
    formValueGetter,
    formContext,
  );

  const caseStatus = (refreshContext?.refreshCaseDetail ?? []).find(
    (cds: any) => cds?.sourceKey === fv?.caseId,
  );

  return map.showWhen.fieldValueIsNot(caseStatus?.status, v);
};

export const isAValidDate: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const fv = getOptionValue(sw.isAValidDate, formValueGetter, formContext);

  return fv instanceof Date && !isNaN(fv.valueOf());
};

export const timeGreaterThan: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const dt1 = new Date(
    getOptionValue(sw.timeGreaterThan[0], formValueGetter, formContext),
  );
  const dt2 = new Date(
    getOptionValue(sw.timeGreaterThan[1], formValueGetter, formContext),
  );

  return dt1.getTime() > dt2.getTime();
};

export const durationGreaterThanOrEqual: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const dt1 = new Date(
    getOptionValue(
      sw.durationGreaterThanOrEqual[0],
      formValueGetter,
      formContext,
    ),
  );
  const dt2 = new Date(
    getOptionValue(
      sw.durationGreaterThanOrEqual[1],
      formValueGetter,
      formContext,
    ),
  );
  const diffValue = sw.durationGreaterThanOrEqual[2];

  const diff = (dt1.getTime() - dt2.getTime()) / msInHour;
  return diff >= diffValue;
};

export const durationGreaterThan: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const dt1 = new Date(
    getOptionValue(sw.durationGreaterThan[0], formValueGetter, formContext),
  );
  const dt2 = new Date(
    getOptionValue(sw.durationGreaterThan[1], formValueGetter, formContext),
  );
  const diffValue = sw.durationGreaterThan[2];

  let diff = (dt1.getTime() - dt2.getTime()) / msInHour;
  if (diff < 0) {
    dt1.setDate(dt1.getDate() + 1);
    diff = (dt1.getTime() - dt2.getTime()) / msInHour;
  }

  return diff > diffValue;
};

export const durationLessThanOrEqual: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const dt1 = new Date(
    getOptionValue(sw.durationLessThanOrEqual[0], formValueGetter, formContext),
  );
  const dt2 = new Date(
    getOptionValue(sw.durationLessThanOrEqual[1], formValueGetter, formContext),
  );
  const diffValue = sw.durationLessThanOrEqual[2];

  let diff = (dt1.getTime() - dt2.getTime()) / msInHour;
  if (diff < 0) {
    dt1.setDate(dt1.getDate() + 1);
    diff = (dt1.getTime() - dt2.getTime()) / msInHour;
  }

  return diff <= diffValue;
};

export const durationLessThan: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const dt1 = new Date(
    getOptionValue(sw.durationLessThan[0], formValueGetter, formContext),
  );
  const dt2 = new Date(
    getOptionValue(sw.durationLessThan[1], formValueGetter, formContext),
  );
  const diffValue = sw.durationLessThan[2];

  const diff = (dt1.getTime() - dt2.getTime()) / msInHour;
  return diff < diffValue;
};

//function to check if the gap in intake date is a weekend and not show the gap question if so
export const checkGapIsAWeekend: CheckerFunction = (
  sw,
  formValueGetter,
  formContext,
) => {
  const dt1 = new Date(
    getOptionValue(sw.containsWeekend[0], formValueGetter, formContext),
  );
  const dt2 = new Date(
    getOptionValue(sw.containsWeekend[1], formValueGetter, formContext),
  );
  const day1 = dt1.getDay();
  const day2 = dt2.getDay();

  const diff = (dt1.getTime() - dt2.getTime()) / msInHour;

  if (day1 === 1 && day2 === 5 && diff === 72) {
    return false;
  }
  return true;
};

export const actionMap: {
  [key: string]: CheckerFunction;
} = {
  all: checkAll,
  any: checkAny,
  arrayObjectValueIncludes,
  caseRefreshStatusIs: checkCaseRefreshStatusIs,
  caseRefreshStatusIsNot: checkCaseRefreshStatusIsNot,
  dateGreaterThan: checkDateGreaterThan,
  dateGreaterThanOrEqual: checkDateGreaterThan,
  durationGreaterThan,
  durationGreaterThanOrEqual,
  durationLessThan,
  durationLessThanOrEqual,
  fieldValueDefined: checkFieldIsValueDefined,
  fieldValueIncludes: checkFieldValueIncludes,
  fieldValueIs: checkFieldValueIs,
  fieldValueIsNot: checkFieldValueIsNot,
  fieldValueUndefined: checkFieldIsValueUndefined,
  isAValidDate,
  isFalse: checkIsFalse,
  isTrue: checkIsTrue,
  stepFieldValueIs: checkStepFieldValueIs,
  stepFieldValueIsNot: checkStepFieldValueIsNot,
  showPaymentPrefs: checkShowPaymentPrefs,
  timeGreaterThan,
  containsWeekend: checkGapIsAWeekend,
};

export const shouldShow = (
  optionsTemplate: {
    [name: string]: any;
  },
  formValueGetter: FormValueGetterType,
  formContext: IFormContext,
  refreshContext?: any,
  sectionFieldNamePrefix?: string,
) => {
  let result = true;

  if (optionsTemplate?.showWhen) {
    const sw = optionsTemplate.showWhen;
    // check if dateGreaterThan is defined

    const configuredActions = Object.keys(sw);

    const resolvedValues = configuredActions.map((action) =>
      actionMap[action](
        sw,
        formValueGetter,
        formContext,
        refreshContext,
        sectionFieldNamePrefix,
      ),
    );

    result = resolvedValues.every((value) => !!value);
  }

  return result;
};
