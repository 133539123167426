import React from 'react';
import { generateParentNTNsFromCases, setAnalyticsData } from '../../utils';
import { getPersonData } from '../../utils/web-apis-client';
import * as dm from '../../data-models';
import { log } from '../../utils/log';

// const log = new Logger\('contexts/person-data-context');

const PersonDataContext = React.createContext<{
  personData: dm.PersonData | undefined;
  setPersonData: React.Dispatch<React.SetStateAction<any>>;
  refreshPersonData: () => Promise<void>;
}>({
  personData: undefined,
  setPersonData: () => {},
  refreshPersonData: async () => {},
});

function PersonDataProvider(p: { children: any; personData?: dm.PersonData }) {
  const enhancePersonData = (personData?: dm.PersonData) => {
    if (!personData) {
      return personData;
    }

    const enhancedPersonData: dm.IGeneratedPersonData = { ...personData };

    enhancedPersonData.generated = enhancedPersonData.generated
      ? enhancedPersonData.generated
      : {};

    enhancedPersonData.generated.eid =
      enhancedPersonData.person && enhancedPersonData.person.eid
        ? enhancedPersonData.person.eid
        : '';

    enhancedPersonData.generated.parentNTNs = generateParentNTNsFromCases(
      enhancedPersonData.cases,
    );

    try {
      setAnalyticsData(enhancedPersonData);
    } catch (ex) {}

    return enhancedPersonData;
  };

  const [personData, setPersonData] = React.useState<dm.PersonData | undefined>(
    enhancePersonData(p.personData),
  );

  const refreshPersonData = async () => {
    const pd = await getPersonData();
    setPersonData(enhancePersonData(pd));
    log.debug('Refreshed personData context.');
  };

  return (
    <PersonDataContext.Provider
      value={{ personData, setPersonData, refreshPersonData }}
    >
      {p.children}
    </PersonDataContext.Provider>
  );
}

export { PersonDataContext, PersonDataProvider };
