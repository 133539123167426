import React from 'react';
import styled from 'styled-components';
import { prefixObjectValues } from '../../../utils';

interface LandingProps {
  keyId?: string;
}

const domIdsStatic = {
  rootNode: 'landing',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  font-size: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  button {
    margin: 15px;
  }
`;

const CustomButton = styled.button`
  font-size: 1.5em;
  text-align: center;
  border: 7px solid #666666;
  margin: 15px;
  width: 20em;
  padding: 5px;
  box-shadow: -5px 5px 15px #888888;
  cursor: pointer;
`;

const Landing: (p: LandingProps) => React.ReactElement = (p) => {
  const domIds = domIdsUnique(p.keyId);

  const mock = React.useCallback((mockFile) => {
    sessionStorage.setItem('activelogin', 'inProgress');
    sessionStorage.setItem('mockFile', mockFile);
    window.location.assign('/app?jwt=12345');
  }, []);

  return (
    <Container id={domIds.rootNode}>
      {process.env.REACT_APP_MOCK && (
        <>
          <CustomButton onClick={() => mock('data-benjamin-cochran')}>
            Mock Benjamin Cochran
          </CustomButton>
          <CustomButton onClick={() => mock('data-lisa-goodman')}>
            Mock Lisa Goodman
          </CustomButton>
          <CustomButton onClick={() => mock('data-thomas-cisneros')}>
            Thomas Cisneros
          </CustomButton>
          <CustomButton onClick={() => mock('data-carmen-watson')}>
            Carmen Watson
          </CustomButton>
        </>
      )}
    </Container>
  );
};

export default Landing;
