import iconAccident from '../../images/supplemental-benefits/accident.png';
import iconCriticalIllness from '../../images/supplemental-benefits/criticalillness.png';
import iconHospital from '../../images/supplemental-benefits/hospital.png';
import { enums } from '../../utils';

export const benefitList = [
  {
    value: 'Accident',
    disabled: true,
    icon: iconAccident,
    link: enums.AppPathForms.vbAccident,
    description:
      "Accident Insurance can pay a benefit directly to you if you are injured and need treatment, whether you go to a physician's office, urgent care center or the emergency room.",
  },
  {
    value: 'Critical Illness',
    disabled: true,
    icon: iconCriticalIllness,
    link: enums.AppPathForms.vbCriticalIllness,
    description:
      'Critical Illness insurance can pay a benefit directly to you when you are diagnosed with of the covered conditions listed in the plan, such as cancer, heart attack or stroke.',
  },
  {
    value: 'Hospital',
    disabled: true,
    icon: iconHospital,
    link: enums.AppPathForms.vbHospital,
    description:
      'Hospital Insurance pays a set amount for a hospital admission, as well as for other items covered by your plan, such as emergency room treatment and daily hospital confinement.',
  },
];
