
import { ContentItem, Elements } from '@kentico/kontent-delivery';
import { Businessrole } from './businessrole';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Faq extends ContentItem {
    public businessrole!: Elements.LinkedItemsElement<Businessrole>;
    public question!: Elements.TextElement;
    public response!: Elements.CustomElement;
}
