import React, { useContext } from 'react';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { PageChangeEvent } from '@progress/kendo-react-data-tools';
import DollarGraphic from '../../images/dollar-sign-in-cloud.png';
import { enums, get, showPaymentPageBasedOnEmployerId } from '../../utils';
import { PersonDataContext } from '../../contexts/person-data-context';
import { PreferencesContext } from '../../contexts/preferences-context';
import ClaimsDropDownList from '../base/claims-drop-down-list';
import ItemRender from './item-render';
import PagerSection from '../base/pager-section';
import {
  Container,
  TitleBar,
  Body,
  ItalicText,
  NoDocumentsContainer,
  Title,
  LoadingCover,
} from '../base/simple-styled-components';
import { ListView } from '@progress/kendo-react-listview';
import { Claim, Leave, Payment, PaymentDetail } from '../../data-models';
import useRefreshHandler from '../../hooks/use-refresh-handler';
import { FadeLoader } from 'react-spinners';
import { colors } from '../../style';

const defaultOption = 'All Claims';

interface PaymentProps {
  failedWithData?: boolean;
}

export interface IPaymentAggregate extends Payment {
  approvedThruDate?: string;
  caseId?: string;
  checkCutting?: string;
  claimName?: string;
}

//Extracts payment array from cases
const getPaymentsFromClaims = (claims: Array<Claim | Leave>) => {
  const payments: Array<IPaymentAggregate> = [];

  claims.forEach((claim) => {
    if (claim.payments && claim.payments.length > 0) {
      claim.payments.forEach((payment) => {
        const benefit = claim?.benefits?.find(
          (b) => b.benefitId === payment.benefitId,
        );

        const temp: IPaymentAggregate = {
          ...payment,
          approvedThruDate: benefit?.approvedThruDate,
          checkCutting: benefit?.checkCutting,
        };

        if ('claimId' in claim) {
          temp.caseId = claim.claimId;
          temp.claimName = claim.claimType;
        } else if ('leaveId' in claim) {
          temp.caseId = claim.leaveId;
          temp.claimName = claim.absenceType;
        }

        payments.push(temp);
      });
    }
  });

  return payments;
};

const getAndMapNextPaymentFromClaims = (claims: Array<Claim | Leave>) => {
  const nextPayments: Array<IPaymentAggregate> = [];

  claims.forEach((claim) => {
    if (claim.nextPayment && claim.nextPayment?.nextPaymentDate) {
      const benefit = claim?.benefits?.find(
        (b) =>
          b.benefitId?.slice(0, b.benefitId?.length - 3) ===
            claim?.nextPayment?.claimId ||
          b.benefitId?.split('-').slice(0, 2).join('-') ===
            claim?.nextPayment?.claimId,
      );

      const nextPayment: IPaymentAggregate = {
        benefitId: claim.nextPayment.claimId,
        paymentAmount: claim.nextPayment.nextPaymentAmount,
        paymentDate: claim.nextPayment.nextPaymentDate,
        periodEndDate: claim.nextPayment.nextPaymentPeriodEndDate,
        periodStartDate: claim.nextPayment.nextPaymentPeriodStartDate,
        isFuturePayment: true,
        approvedThruDate: benefit?.approvedThruDate,
        checkCutting: benefit?.checkCutting,
      };

      if ('claimId' in claim) {
        nextPayment.caseId = claim.claimId;
        nextPayment.claimName = claim.claimType;
      } else if ('leaveId' in claim) {
        nextPayment.caseId = claim.leaveId;
        nextPayment.claimName = claim.absenceType;
      }

      const lineItems: PaymentDetail[] = [];

      claim.nextPayment.nextPaymentLines?.forEach((l) => {
        const item: PaymentDetail = {
          detailReason: l.reason,
          detailAmount: l.amount,
        };
        lineItems.push(item);
      });

      nextPayment.paymentDetails = lineItems;
      nextPayments.push(nextPayment);
    }
  });

  return nextPayments;
};

/**
 * Sorts the payments by the payment date - DESC
 */
const sortDescending = (a: any, b: any) => {
  const d1 = new Date(a.paymentDate);
  const d2 = new Date(b.paymentDate);

  return d1 < d2 ? 1 : -1;
};

const getShowPaymentInfo = async (
  setShowPaymentInfo: Function,
  ERContent: any,
) => {
  const showInfo = await showPaymentPageBasedOnEmployerId(ERContent);
  setShowPaymentInfo(showInfo);
};

/**
 * The payments component. Displays a list of payments with associated details based on the user's personDataContext.
 */
const Payments: (p: PaymentProps) => React.ReactElement = (p) => {
  const { personData } = useContext(PersonDataContext);
  const { ERContent } = useContext(PreferencesContext);
  const { isRefreshing } = useRefreshHandler([
    enums.RefreshDetailTypes.caseDetails,
  ]);
  const [showPayments, setShowPayments] = React.useState<boolean>(true);
  const [intitialPaymentsArray, setIntitialPaymentsArray] = React.useState<
    Array<IPaymentAggregate>
  >([]);
  const [payments, setPayments] = React.useState<Array<IPaymentAggregate>>([]);
  const [filterPayments, setFilteredPayments] = React.useState<
    Array<IPaymentAggregate>
  >([]);
  const [page, setPage] = React.useState({
    skip: 0,
    take: 5,
  });
  //On component mount, extract payment data and format as appropriate.
  React.useEffect(() => {
    const claims = get(personData, 'cases.claims', []);
    const pastPayments = getPaymentsFromClaims(claims);
    const nextPayments = p.failedWithData
      ? []
      : getAndMapNextPaymentFromClaims(claims);

    const leaves = get(personData, 'cases.leaves', []);
    const pastLeavePayments = getPaymentsFromClaims(leaves);
    const nextLeavePayments = p.failedWithData
      ? []
      : getAndMapNextPaymentFromClaims(leaves);

    const mappedPayments = pastPayments
      .concat(nextPayments)
      .concat(pastLeavePayments)
      .concat(nextLeavePayments);
    mappedPayments.sort(sortDescending);

    setIntitialPaymentsArray(mappedPayments);
    setPayments(mappedPayments);
    setFilteredPayments(mappedPayments);
    getShowPaymentInfo(setShowPayments, ERContent);
  }, [ERContent, personData, p.failedWithData]);

  const handlePageChange = (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
  };

  //Filters the list of payments by the caseID (selecting 'All Claims' will reset payments to initial state)
  const filterByClaim = (e: DropDownListChangeEvent) => {
    let filteredPayments = intitialPaymentsArray;
    if (e.target.value.caseId !== defaultOption) {
      filteredPayments = intitialPaymentsArray.filter((payment: any) => {
        return payment.caseId === e.target.value.caseId;
      });
    }
    setPayments(filteredPayments);
    setFilteredPayments(filteredPayments);
  };

  const { skip, take } = page; //Used for Pagination

  if (!showPayments) {
    return <div></div>;
  }

  return (
    <Container id="payments-list">
      <TitleBar>
        <Title>Payments</Title>
        <ClaimsDropDownList
          caseList={get(personData, 'cases.claims', []).concat(
            get(personData, 'cases.leaves', []),
          )}
          defaultOption={defaultOption}
          onChange={filterByClaim}
          viewAll
          processedProps
          disabled={isRefreshing}
        />
      </TitleBar>
      {filterPayments.length > 0 ? (
        <Body
          className="d-flex flex-column justify-content-between position-relative"
          style={{ padding: '0px', minHeight: 474 }}
        >
          {isRefreshing && (
            <LoadingCover>
              <FadeLoader
                height={14}
                width={5}
                radius={2}
                margin={0}
                color={colors.blue}
              />
            </LoadingCover>
          )}
          <ListView
            data={filterPayments.slice(skip, skip + take)}
            item={ItemRender}
            style={{ border: 0, position: 'initial' }}
          ></ListView>
          <PagerSection
            skip={skip}
            take={take}
            handlePageChange={handlePageChange}
            elements={payments}
          />
        </Body>
      ) : (
        <NoDocumentsContainer>
          <img src={DollarGraphic} alt="" width="179" height="148" />
          <ItalicText className="mt-4">
            {`You will be able to view your payments here once you start to receive them.`}
          </ItalicText>
        </NoDocumentsContainer>
      )}
    </Container>
  );
};

export default Payments;
