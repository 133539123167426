import { log } from '../log';
import * as enums from '../enums';
import dayjs from 'dayjs';

// const log = new Logger\('StorageManager');
const appPrefix = 'tl-ee-';

export type CacheRegistry = {
  [name: string]: {
    expires: string;
  };
};

const cacheRegistry: CacheRegistry =
  getItem<CacheRegistry>(enums.CacheKeys.cache_registry, true) || {};

export function getItem<T = string>(
  key: string,
  bypassRegistry = false,
  tryToParse = true,
): T | null {
  try {
    if (!bypassRegistry && cacheRegistry[key]) {
      const expires = dayjs(cacheRegistry[key].expires);

      // If the item has expired, remove it from the cache
      if (expires.isBefore(dayjs())) {
        sessionStorage.removeItem(`${appPrefix}${key}`);
        delete cacheRegistry[key];
        sessionStorage.setItem(
          `${appPrefix}${enums.CacheKeys.cache_registry}`,
          JSON.stringify(cacheRegistry),
        );
        return null;
      }
    }

    const value = sessionStorage.getItem(`${appPrefix}${key}`);

    if (value) {
      return tryToParse ? JSON.parse(value) : value;
    }
  } catch (error) {
    log.error(error);
  }

  return null;
}

export function setItem(key: string, value: any, expireMinutes = 120) {
  try {
    const valueToStore =
      typeof value === 'string' ? value : JSON.stringify(value);

    sessionStorage.setItem(`${appPrefix}${key}`, valueToStore);
    cacheRegistry[key] = {
      expires: dayjs().add(expireMinutes, 'minutes').toISOString(),
    };
    sessionStorage.setItem(
      `${appPrefix}${enums.CacheKeys.cache_registry}`,
      JSON.stringify(cacheRegistry),
    );
  } catch (error) {
    log.error(error);
  }
}

export function clear() {
  sessionStorage.clear();
}
