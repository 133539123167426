import { ICoverageV1Response } from '../data-models';
import { RemoteConfigKeys } from './enums';
import { getSimpleRemoteConfig } from './remote-config-manager';

const employerVBConfigSync = (config: {
  [name: string]: string;
}): {
  mode: 'include' | 'exclude';
  list: string[];
} => {
  const mode =
    config[RemoteConfigKeys.employerVbMode] === 'exclude'
      ? 'exclude'
      : 'include';
  const list = config[RemoteConfigKeys.employerVbList]?.split('|') || [];
  return { mode, list };
};

const employerVBConfig = async (): Promise<{
  mode: 'include' | 'exclude';
  list: string[];
}> => employerVBConfigSync(await getSimpleRemoteConfig());

export const isEmployerEnabled = async (companyCode?: string) => {
  const evbc = await employerVBConfig();
  let employerEnabled = companyCode ? evbc.list.includes(companyCode) : false;
  if (evbc.mode === 'exclude') {
    employerEnabled = companyCode ? !employerEnabled : false;
  }

  return employerEnabled;
};

export const isEmployerEnabledSync = (
  config: {
    [name: string]: string;
  },
  companyCode?: string,
) => {
  const evbc = employerVBConfigSync(config);
  let employerEnabled = companyCode ? evbc.list.includes(companyCode) : false;
  if (evbc.mode === 'exclude') {
    employerEnabled = companyCode ? !employerEnabled : false;
  }

  return employerEnabled;
};

export const checkCoverage = (vbCoverageData: ICoverageV1Response) =>
  vbCoverageData?.coverages?.some(
    (c) =>
      c.beWellCovered || c.employeeCovered || c.spouseCovered || c.childCovered,
  );
