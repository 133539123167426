import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import Button from '../button';
import {
  enums,
  getHtmlFromMarkdown,
  getDynamicOrDefault,
  getLocalizedOrDefault,
  parseJSONOrDefault,
  analyticsTrackEvent,
} from '../../../utils';
import * as Bowser from 'bowser';
import { Dialog } from '@progress/kendo-react-dialogs';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';

const DEFAULT_SUPPORT_LIST = {
  desktop: {
    edge: '>=106',
    chrome: '>=105',
    firefox: '>=106',
  },
  mobile: {
    edge: '>=100',
    chrome: '>=106',
    firefox: '>=106',
    safari: '>=15',
  },
};

const DEFAULT_TEXT =
  'You may not be able to use the full functionality of Total Leave, as' +
  ' your browser is not supported. We recommend that you update your' +
  ' browser, or try installing a supported browser such as Google Chrome,' +
  ' Firefox, or Edge.';

const DEFAULT_TITLE = "We're sorry, your browser isn't fully supported";
const DEFAULT_BUTTON_TEXT = 'I understand';

const CustomModal = styled(Dialog)`
  .k-dialog {
    max-width: 800px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
  }
  .k-window-actions.k-dialog-actions {
    display: none;
  }
  .k-window-titlebar {
    font-family: ${fonts.ubuntu.regular.fontFamily};
    font-size: 20px;
    font-weight: ${fonts.ubuntu.regular.fontWeight};
    color: ${colors.prussianblue};
    line-height: 48px;
    white-space: break-spaces;
    padding: 27px 32px 23px;
    border-color: ${colors.alto2};
  }
  .k-window-content.k-dialog-content {
    padding: 0;
    border-radius: 0 0 10px 10px;
  }

  @media only screen and (max-width: 776px) {
    justify-content: center;
    top: 0;
    padding: 10px;

    .k-window-titlebar {
      padding: 27px 16px 23px;
    }
  }
`;

const Content = styled.div`
  padding: 32px 32px 23px;
  @media only screen and (max-width: 776px) {
    padding: 32px 16px 23px;
  }
`;

const Text = styled.div`
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  color: ${colors.warmNeutralL3};
  line-height: 24px;

  > p:last-child {
    margin: 0;
  }
`;

const ActionBar = styled.div`
  background-color: ${colors.alabaster};
  display: flex;
  justify-content: end;
  padding: 32px 26px;

  @media only screen and (max-width: 776px) {
    padding: 16px 26px;
  }
`;

type IsBrowserSupportedType = (browsers?: string) => boolean;

const isBrowserSupported: IsBrowserSupportedType = (browsers) => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const supportedBrowsers = parseJSONOrDefault(browsers, DEFAULT_SUPPORT_LIST);

  return browser.satisfies(supportedBrowsers) ?? false;
};

const BrowserWarningModal: () => React.ReactElement = () => {
  const { dynamicContent, localizedStrings, config } =
    React.useContext(KenticoDataContext);
  const [displayBrowserWarning, setDisplayBrowserWarning] = React.useState(
    !isBrowserSupported(config?.['supported_browsers_list']),
  );

  React.useEffect(() => {
    const unsupported = !isBrowserSupported(
      config?.['supported_browsers_list'],
    );

    if (unsupported) {
      const browser = Bowser.getParser(window.navigator.userAgent);

      const analyticsProps = {
        browser: browser.getBrowserName(),
        version: browser.getBrowserVersion(),
        platform: browser.getPlatform(),
        os: browser.getOS(),
      };

      analyticsTrackEvent(
        enums.AnalyticsEvents.unsupportedBrowserWarning,
        analyticsProps,
      );
    }
  });

  const handleContinue = () => {
    analyticsTrackEvent(enums.AnalyticsEvents.unsupportedBrowserIgnored);
    setDisplayBrowserWarning(false);
  };

  return displayBrowserWarning ? (
    <CustomModal
      id="unsupported-browser-modal"
      title={getLocalizedOrDefault(
        localizedStrings,
        'unsupported_browser_title',
        DEFAULT_TITLE,
      )}
    >
      <Content>
        <Text
          dangerouslySetInnerHTML={getHtmlFromMarkdown(
            getDynamicOrDefault(
              dynamicContent,
              'unsupported_browser_text',
              enums.DynamicContentMediums.full,
              DEFAULT_TEXT,
            ),
          )}
        />
      </Content>
      <ActionBar>
        <Button
          id="continue-unsupported"
          buttonStyle={enums.ButtonTypes.breadcrumb}
          onClick={handleContinue}
          style={{
            padding: '3px 5px',
            width: '100%',
            maxWidth: '190px',
            whiteSpace: 'break-spaces',
          }}
        >
          {getLocalizedOrDefault(
            localizedStrings,
            'unsupported_browser_button',
            DEFAULT_BUTTON_TEXT,
          )}
        </Button>
      </ActionBar>
    </CustomModal>
  ) : (
    <></>
  );
};

export default BrowserWarningModal;
