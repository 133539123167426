import React from 'react';
import { MaskedTextBox } from '../../simple-styled-components';
import styled from 'styled-components';
import { fonts, colors } from '../../../../style';

const TimeEntry = styled(MaskedTextBox)`
  padding-right: 0px;
  width: 94px !important;
  margin-bottom: 6px;

  input {
    text-align: center;
  }
`;

const AmPmButton = styled.div`
  margin-left: 8px;
  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  input:checked + label {
    background-color: ${colors.skyD2};
    color: ${colors.white};
  }
`;

const ButtonLabel = styled.label`
  display: inline-block;
  width: auto;
  background-color: ${colors.white};
  color: ${colors.skyD2};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  text-align: center;
  padding: 6px 14px;
  border: 1px solid #037cb7;
  border-radius: 0px 4px 4px 0px;
  transition: all 0.1s ease-in-out;

  :first-of-type {
    border-radius: 4px 0 0 4px;
  }

  :last-of-type {
    border-radius: 0 4px 4px 0;
  }

  :hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: baseline;
  width: 50%;
  flex-wrap: nowrap;

  @media (max-width: 703px) {
    width: 100%;
  }
`;

const InitialLabel = styled.span`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  text-align: left;
  margin-right: 8px;
`;

const inputHandle = (e: any, time: Date, onChange: Function) => {
  const target = { ...e.target, value: time };
  const event = {
    ...e,
    target,
    value: time,
  };
  onChange(event);
};

const formatTime = (timeValue: string, amPm: string) => {
  if (timeValue) {
    timeValue = timeValue.replace(/ /g, '');

    const time = new Date();
    let hours =
      amPm === 'AM'
        ? parseInt(timeValue.substring(0, 2))
        : parseInt(timeValue.substring(0, 2)) + 12;

    if (hours === 12 && amPm === 'AM') {
      hours = 0;
    } else if (hours === 24 && amPm === 'PM') {
      hours = 12;
    }

    time.setHours(hours);
    time.setMinutes(parseInt(timeValue.substring(3, 5)));
    time.setSeconds(0);
    time.setMilliseconds(0);

    return time;
  }

  return new Date('Invalid Date');
};

const formatValue = (time: Date) => {
  let tempTime = time;

  if (time) {
    // with autosave the dates can come in as strings, so handle that
    if (typeof tempTime === 'string') {
      tempTime = new Date(tempTime);
    }

    const hours = tempTime.getHours();
    const minutes = tempTime.getMinutes();
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const hour = hours % 12 || 12;

    return [
      `${String(hour).padStart(2, '0')} : ${String(minutes).padStart(2, '0')}`,
      amPm,
    ];
  }

  return ['', ''];
};

const TimePicker = (props: any) => {
  const { onChange, pickerLabel, defaultValue, defaultAmPm, value } = props;
  const [amPm, setAmPm] = React.useState(
    value ? formatValue(value)[1] : defaultAmPm,
  );
  const [timeValue, setTimeValue] = React.useState(
    value ? formatValue(value)[0] : defaultValue,
  );
  const [greaterthan2, setGreaterThan2] = React.useState(false);
  const rules = {
    F: /[0-1]/,
    S: greaterthan2 ? /[1-9]/ : /[0-2]/,
    M: /[0-5]/,
    N: /0/,
  };
  const mask = 'FS : M0';

  // sometimes the value is undefined and timeValue and amPm are defaulted to undefined
  // so when react gets around to setting the value, it will set the timeValue and amPm
  React.useEffect(() => {
    if (value && !timeValue && !amPm) {
      const time = formatValue(value);
      setTimeValue(time[0]);
      setAmPm(time[1]);
    }
  }, [amPm, timeValue, value]);

  React.useEffect(() => {
    if (defaultValue && defaultAmPm) {
      const time = formatTime(defaultValue, defaultAmPm);
      const event = {};
      inputHandle(event, time, onChange);
    }
  }, [defaultValue, defaultAmPm, onChange]);

  const handleTimeChange = (e: any) => {
    setTimeValue(e.target.value);
    if (e.target.value && e.target.value[0] === '0') {
      setGreaterThan2(true);
    } else {
      setGreaterThan2(false);
    }
    const time = formatTime(e.target.value, amPm);
    inputHandle(e, time, onChange);
  };

  const handleAmPmChange = (e: any) => {
    setAmPm(e.target.value);
    const time = formatTime(timeValue, e.target.value);
    inputHandle(e, time, onChange);
  };

  return (
    <Container id="root">
      <InitialLabel>{pickerLabel}</InitialLabel>
      <div
        style={{
          justifyContent: 'center',
          display: '-webkit-inline-box',
          alignItems: 'center',
        }}
      >
        <TimeEntry
          id={props.name + '_time_entry'}
          mask={mask}
          onChange={(e) => handleTimeChange(e)}
          rules={rules}
          valid={true}
          placeholder={'HH : MM'}
          value={timeValue}
        />
        <AmPmButton>
          <input
            type="radio"
            id={props.name + '_am'}
            name={props.name}
            value="AM"
            onChange={(e) => handleAmPmChange(e)}
            checked={amPm === 'AM'}
          />
          <ButtonLabel htmlFor={props.name + '_am'}>AM</ButtonLabel>

          <input
            type="radio"
            id={props.name + '_pm'}
            name={props.name}
            value="PM"
            onChange={(e) => handleAmPmChange(e)}
            checked={amPm === 'PM'}
          />
          <ButtonLabel htmlFor={props.name + '_pm'}>PM</ButtonLabel>
        </AmPmButton>
      </div>
    </Container>
  );
};

export default TimePicker;
