import styled, { css } from 'styled-components';
import { colors, fonts, rem } from '../../../style';
import { enums } from '../../../utils';
import {
  Checkbox as KendoCheckbox,
  Input as KendoInput,
  MaskedTextBox as KendoMaskedTextBox,
  Switch as KendoSwitch,
  TextArea as KendoTextArea,
  // NumericTextBox as KendoNumericTextBox,
} from '@progress/kendo-react-inputs';
import { Error as KendoError } from '@progress/kendo-react-labels';
import {
  DropDownList as KendoDropDownList,
  MultiSelect as KendiMultiSelect,
} from '@progress/kendo-react-dropdowns';
import {
  DatePicker as KendoDatePicker,
  TimePicker as KendoTimePicker,
} from '@progress/kendo-react-dateinputs';

/**
 * defaults
 */

export const defaults = {
  fontName: enums.FontNames.openSans,
  fontType: enums.FontTypes.regular,
  fontSize: 18,
  color: '#525252',
};

/**
 * helper functions
 */

export interface StyledTextProps {
  fontName?: enums.FontNames;
  fontType?: enums.FontTypes;
  fontSize?: number;
  color?: string;
  valid?: boolean;
}

export const getFont = (
  name: StyledTextProps['fontName'] = enums.FontNames.openSans,
  type: StyledTextProps['fontType'] = enums.FontTypes.regular,
) => fonts[name][type];

/**
 * simple styled components - read only labels / text fields
 */

export const Text = styled.div<StyledTextProps>`
  font-family: ${(p) => getFont(p.fontName, p.fontType).fontFamily};
  font-weight: ${(p) => getFont(p.fontName, p.fontType).fontWeight};
  font-size: ${(p) => rem(p.fontSize || defaults.fontSize)};
  color: ${(p) => p.color || defaults.color};
`;

export const InfoText = styled.div<StyledTextProps>`
  font-family: ${(p) => getFont(p.fontName, p.fontType).fontFamily};
  font-weight: ${(p) => getFont(p.fontName, p.fontType).fontWeight};
  font-size: ${(p) => rem(p.fontSize || defaults.fontSize)};
  color: ${(p) => p.color || defaults.color};
  background: #eff3f8 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 13px 16px 16px 40px;
`;

/**
 * Shared styles
 */

const sharedTextStyles = css<StyledTextProps>`
  border: ${(p) =>
    p.valid ? getBorder(1, colors.inputGray) : getBorder(2, colors.errorRed)};
  border-radius: 6px;
  font-family: ${(p) => getFont(p.fontName, p.fontType).fontFamily};
  font-weight: ${(p) => getFont(p.fontName, p.fontType).fontWeight};
  outline: none;
  width: 100%;
`;

/**
 * simple styled components - inputs
 */

const Checkbox = styled(KendoCheckbox)`
  pointer-events: all;
  transform: scale(1.3, 1.3);
  margin-right: 8px;
  height: 24px;
  width: 24px;
`;

const DatePicker = styled(KendoDatePicker)`
  .k-select {
    pointer-events: all;
  }
`;

const DropDownList = styled(KendoDropDownList)<StyledTextProps>`
  .k-dropdown-wrap {
    background-color: white;
    border-color: ${colors.alto};
  }
  .k-input {
    font-family: ${(p) =>
      getFont(p.fontName, p.fontType).fontFamily} !important;
    font-weight: ${(p) =>
      getFont(p.fontName, p.fontType).fontWeight} !important;
  }
  option {
    font-family: ${(p) => getFont(p.fontName, p.fontType).fontFamily};
    font-weight: ${(p) => getFont(p.fontName, p.fontType).fontWeight};
  }
`;

const Error = styled(KendoError)`
  color: ${colors.errorRed};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-weight: ${fonts.openSans.regular.fontWeight};
  font-size: ${rem(16)};
  text-align: left;
  &.centered {
    justify-content: center;
  }
`;

const getBorder = (px: number, color: string) => `${px}px solid ${color}`;

const Input = styled(KendoInput)<StyledTextProps>`
  ${sharedTextStyles}
`;

const MaskedTextBox = styled(KendoMaskedTextBox)<StyledTextProps>`
  ${sharedTextStyles}
`;

// TODO: if this is needed, fix errors from TS
// JSX element type 'FieldComponent' does not have any construct or call signatures.ts(2604)
// export const NumericTextBox = styled(KendoNumericTextBox)`
//   width: 68px !important;
//   height: 44px !important;
//   background: #ffffff 0% 0% no-repeat padding-box;
//   border: 1px solid #aaaaaa;
//   border-radius: 6px;
//   margin: 4px;
// `;

const Switch = styled(KendoSwitch)`
  transform: scale(0.8, 0.8);

  .k-switch-on {
    background-color: #037cb7 !important;
  }
  .k-switch-off {
  }
  .k-switch-on .k-switch-container {
    background-color: ${colors.skyD2} !important;
  }
  .k-switch-off .k-switch-container {
    background-color: ${colors.switchOff} !important;
  }
`;

const TextArea = styled(KendoTextArea)<StyledTextProps>`
  ${sharedTextStyles}
`;

const MultiSelect = styled(KendiMultiSelect)`
  .k-multiselect-wrap {
    background-color: white;
    border-color: ${colors.alto};
  }
  li.k-button {
    font-family: ${fonts.openSans.regular.fontFamily};
    font-weight: ${fonts.openSans.regular.fontWeight};
  }
`;

export const TimePicker = (props: any) => (
  <KendoTimePicker
    {...props}
    popupSettings={{ popupClass: 'timepicker-fix' }}
  />
);

export {
  Checkbox,
  DatePicker,
  DropDownList,
  Error,
  Input,
  MaskedTextBox,
  Switch,
  TextArea,
  MultiSelect,
};
