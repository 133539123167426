import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../style';
import { get, prefixObjectValues } from '../../utils';

interface ClaimStatusProps {
  keyId?: string;
}

const domIdsStatic = {
  rootNode: 'claim-status',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  background-color: ${colors.coolNeutralL1};
`;

const ClaimStatus: (p: ClaimStatusProps) => React.ReactElement = (p) => {
  const path = get(p, 'location.pathname', '/');
  const domIds = domIdsUnique(p.keyId);

  return (
    <Container id={domIds.rootNode}>
      <Switch>
        <Route path={`${path}/`} exact>
          ClaimStatus Landing
        </Route>
        {/* child routes here */}
      </Switch>
    </Container>
  );
};

export default ClaimStatus;
