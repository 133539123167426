import React from 'react';
import { DashboardMenu } from '../dashboard-menu/index';
import { Footer } from '../footer/index';
import { colors } from '../../../style';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import styled from 'styled-components';
import LiveChatIcon from '../../../images/live-chat-icon.svg';
import {
  enums,
  getLocalizedOrDefault,
  prefixObjectValues,
  scrollApp,
  parseJSONOrDefault,
} from '../../../utils';
import ContactUs from '../../contact-us';
import File from '../../file';
import Messages from '../../messages';
import NewUploadDocuments from '../../new-upload';
import Documents from '../../documents';
import Payments from '../../payments';
import ClaimStatus from '../../claim-status';
import ClaimList from '../../claim-list';
import Page from '../../page';
import TaskDetail from '../../tasks/task-detail';
import TaskLlDescription from '../../tasks/task-detail/task-ll-description';
import Profile from '../../profile';
import LiveChat from '../../live-chat';
import { LoadingScreen } from '../loading-screen';
import { FatalError } from '../exception-screens/fatal-error';
import { log } from '../../../utils/log';
import { CommonModal } from '../common-modal';
import ConfirmDialogModal from '../confirm-dialog-modal';
import BrowserWarningModal from '../browser-warning-modal';
import ClaimsLeavePlan from '../claim-card/claims-leave-plan';
import StartAClaim from '../../start-a-claim';
import AbsenceHistory from '../../absence-history';
import Banner from '../banner';
import LearningHub from '../../learning-hub';
import {
  KenticoDataContext,
  IKenticoData,
} from '../../../contexts/kentico-data-context';
import { pageViewEvent, trackGA4PageView } from '../../../utils/analytics';
import * as Bowser from 'bowser';
import Supplemental from '../../supplemental';
import AvailableTime from '../../available-time';
import { logout } from '../../../utils/auth-manager';
import { SummaryContainer } from '../../summary/container';
import { TasksContainer } from '../../tasks/container';
import { FormContainer } from '../../forms/container';

// const log = new Logger\('app');

interface AppProps {
  keyId?: string;
  initialLiveChatSetting?: boolean;
  notRegisterable?: boolean;
  failedWithData?: boolean;
}

export interface AppRoute {
  breadcrumb: string;
  path: string;
}

interface PortalContainerProps {
  livechat: boolean;
  minimized: boolean;
}

interface UserInfo {
  email?: string;
  firstName?: string;
  lastName?: string;
  eid?: string;
}

const DEFAULT_LINK_DISCLAIMER =
  'The website you will be entering is not owned or operated by, and its content, products and information are not owned ' +
  'or controlled by, Unum and/or its subsidiaries. Unum makes no representations about, does not endorse, and is not responsible ' +
  "or liable for damages relating to the third party's products or services, its website, its privacy policies or practices, or " +
  'the content of the third party website. Please be aware that the security and privacy policies on these sites may be different ' +
  "than Unum's policies, so please read third party privacy and security policies closely. If you have any questions or concerns " +
  'about the products and services offered on linked third party websites, please contact the third party directly. <br/><br/>By ' +
  'clicking on the <b>CONTINUE</b> button below, you acknowledge the above statement and will be taken to the linked site. If you ' +
  "want to remain on Unum's site, select the <b>CANCEL</b> button.";

const domIdsStatic = {
  rootNode: 'app-root-node',
};

const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

export const Container = styled.div`
  background-color: ${colors.background};
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const MainContainer = styled.main`
  flex-grow: 1;
  justify-content: space-between;
  padding: 25px 5rem;
  position: relative;
  @media (max-width: 1050px) {
    padding: 2rem;
  }

  @media (max-width: 400px) {
    padding: 16px;
  }
`;

const FlexBreak = styled.div`
  flex-basis: 100%;
  height: 0;
`;

export const PortalContainer = styled.div<PortalContainerProps>`
  min-height: 100vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  @media (max-width: 576px) {
    display: ${(p) => (p.livechat && !p.minimized ? 'none' : 'block')};
  }
`;

const LiveChatFab = styled.div`
  background-image: url(${LiveChatIcon});
  border-radius: 50%;
  bottom: 5%;
  box-shadow: 0px 2px 8px #00000029;
  cursor: pointer;
  height: 72px;
  position: absolute;
  right: 5%;
  width: 72px;
  z-index: 10;
`;

const LiveChatStatus = styled.div<{ online?: boolean }>`
  height: 16px;
  width: 16px;
  background-color: ${(p) => (p.online ? colors.greenHaze : colors.cardinal)};
  border-radius: 50%;
  display: inline-block;
  vertical-align: text-top;
  float: right;
`;

const onUnload = () => {
  if (global.connectSession) {
    global.connectSession.disconnectParticipant();
    logout();

    const start = Date.now();
    let now = start;
    while (now - start < 1000) {
      now = Date.now();
    }
  }
};

const getDocumentTitle = (
  pathname: string,
  config: IKenticoData[enums.KenticoDataKeys.config],
) => {
  const defaultTitle = 'Unum Total Leave';
  const documentTitleMapRaw = config[enums.RemoteConfigKeys.documentTitles];
  try {
    const documentTitleMap = JSON.parse(documentTitleMapRaw);

    return documentTitleMap[pathname]
      ? documentTitleMap[pathname]
      : defaultTitle;
  } catch (e) {
    log.error(e);
    return defaultTitle;
  }
};

const createGA4Data = () => {
  const browser = Bowser.parse(window.navigator.userAgent);
  const traits: any = parseJSONOrDefault(
    localStorage.getItem('ajs_user_traits'),
    {},
  );
  const userId = localStorage.getItem('ajs_user_id');
  const date = new Date();

  return {
    event: 'custom_pageview',
    env: getEnv(),
    app_source: browser.platform.type,
    journey_name: 'solutions',
    digital_asset: 'total leave - employee',
    page_location: window.location.href,
    page_referrer: document.referrer,
    unum_timestamp_utc: Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDay(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
    user_id: userId,
    user_role: 'employee',
    employer: traits.company ?? '',
    employer_id: traits.employerId ?? '',
    claims: traits.claims ?? 0,
    first_time_login: traits.firstTimeLogin ?? '',
    leaves: traits.leaves ?? 0,
    letters: traits.letters ?? 0,
    tasks: traits.tasks ?? 0,
  };
};

const getEnv = () => {
  switch (window.location.origin) {
    case 'https://portal.unum.com':
      return 'prod';
    case 'https://portal-ua.unum.com':
      return 'acpt';
    case 'https://portal-itest.unum.com':
      return 'itest';
    default:
      return 'dev';
  }
};

const onPathChange = (
  pathname: string,
  config: IKenticoData[enums.KenticoDataKeys.config],
) => {
  const ntnIndex = pathname.lastIndexOf('/NTN');
  const baseURL =
    ntnIndex > -1 ? pathname.slice(0, pathname.lastIndexOf('/NTN')) : pathname;
  document.title = getDocumentTitle(baseURL, config);

  pageViewEvent(baseURL);
};

export const handleUseEffectMount = async (
  setUserInfo: React.Dispatch<React.SetStateAction<{}>>,
  setRedirectPath: React.Dispatch<React.SetStateAction<string | undefined>>,
  config: IKenticoData[enums.KenticoDataKeys.config],
) => {
  //Get initial title
  onPathChange(window.location.pathname, config);
  const uiString = sessionStorage.getItem(enums.CacheKeys.userInfo) || '';

  if (uiString?.length > 0) {
    setUserInfo(JSON.parse(uiString));
  }

  const entryPoint = sessionStorage.getItem(enums.CacheKeys.entryPoint);

  if (entryPoint && entryPoint !== window.location.pathname) {
    setRedirectPath(entryPoint);
  }

  // if we are loading the app, we know we are logged in and can safely
  // remove this from storage at this point, no matter the outcome of
  // the previous statement
  sessionStorage.removeItem(enums.CacheKeys.entryPoint);

  return () => {};
};

//In case we can't get the configuration value from Kentico
const defaultApprovedURLList =
  'behavioralhealth.unum.com|portal-dev.unum.com|portal-itest.unum.com|portal-ua.unum.com|portal.unum.com|' +
  'preview-assets-us-01.kc-usercontent.com|www.unum.com|unmphenxphoenixdevd109-getdocument-cache.s3.amazonaws.com' +
  'assets-us-01.kc-usercontent.com|unmphenxphoenixprodp109-getdocument-cache';

export const disclaimerModal = (
  e: MouseEvent,
  setDisplayDisclaimer: React.Dispatch<React.SetStateAction<boolean>>,
  setContinueLink: React.Dispatch<React.SetStateAction<string>>,
  config: IKenticoData[enums.KenticoDataKeys.config],
) => {
  const anchor = (e.target as HTMLElement).closest('a');
  if (!anchor) {
    return;
  }

  const approvedURLStr = config['approved_url_list'] || defaultApprovedURLList;
  const approvedURLArray = approvedURLStr.split('|');

  const linkType = anchor.getAttribute('data-link-type');
  const showDisclaimer =
    linkType === 'external' || !approvedURLArray.includes(anchor.hostname);

  if (anchor.hostname && showDisclaimer) {
    e.preventDefault();
    setDisplayDisclaimer(true);
    setContinueLink(anchor.href);
  }
};

const handleLiveChatDisplay = (
  setDisplayLiveChat: React.Dispatch<React.SetStateAction<boolean>>,
  setDisplayChatFab: React.Dispatch<React.SetStateAction<boolean>>,
  minimizeChat: (flag: boolean) => void,
  minimized: boolean,
  display: boolean,
) => {
  if (display) {
    document.body.className = 'live-chat-displayed';
  } else {
    document.body.className = 'live-chat-hidden';
  }

  if (minimized) {
    minimizeChat(false);
  } else {
    setDisplayLiveChat(display);
    setDisplayChatFab(!display);
  }
};

const App: (p: AppProps) => React.ReactElement = (props) => {
  const p = enums.AppPaths;
  const domIds = domIdsUnique(props.keyId);
  const [userInfo, setUserInfo] = React.useState<UserInfo>({});
  const [displayLiveChat, setDisplayLiveChat] = React.useState(
    props.initialLiveChatSetting || false,
  );
  const [displayDisclaimer, setDisplayDisclaimer] = React.useState(false);
  const [continueLink, setContinueLink] = React.useState<string>('');
  const [displayFailedWithDataError, setDisplayFailedWithDataError] =
    React.useState(props.failedWithData || false);
  const [displayChatFab, setDisplayChatFab] = React.useState(true);
  const { config, dynamicContent, localizedStrings, callCenter } =
    React.useContext(KenticoDataContext);
  const [redirectPath, setRedirectPath] = React.useState<string>();
  const [minimized, setMinimized] = React.useState(false);
  const { pathname } = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const clickListener = (e: MouseEvent) => {
      disclaimerModal(e, setDisplayDisclaimer, setContinueLink, config);
    };
    document.body.addEventListener('click', clickListener);
    window.addEventListener('beforeunload', onUnload);

    handleUseEffectMount(setUserInfo, setRedirectPath, config);
    trackGA4PageView(createGA4Data()); // track first view

    return () => {
      document.body.removeEventListener('click', clickListener);
      window.removeEventListener('beforeunload', onUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const unlisten = history.listen((location) =>
      onPathChange(location.pathname, config),
    );

    return () => {
      unlisten();
    };
  }, [history, config]);

  React.useEffect(() => {
    const unlisten = history.listen(() => trackGA4PageView(createGA4Data())); // track all view after first one

    return () => {
      unlisten();
    };
  }, [history]);

  React.useEffect(() => {
    scrollApp();
  }, [pathname]);

  const toggleLiveChat = (display = true) => {
    handleLiveChatDisplay(
      setDisplayLiveChat,
      setDisplayChatFab,
      minimizeChat,
      minimized,
      display,
    );
  };

  const minimizeChat = (minimize = true) => {
    setDisplayChatFab(minimize);
    setMinimized(minimize);
  };

  global.displayLiveChat = toggleLiveChat;

  const showFab = displayChatFab;

  return (
    <>
      {props.notRegisterable ? (
        <Redirect to={enums.AppPaths.dataLoadFailed} />
      ) : (
        <>{redirectPath && <Redirect to={redirectPath} />}</>
      )}
      <Container
        id={domIds.rootNode}
        className={`fade-in-quick${displayLiveChat ? ' live-chat-mode' : ''}`}
      >
        <Banner />
        {/* Style API endpoint for css classes for dynamic Kentico content */}
        <PortalContainer
          id="app-portal-container"
          livechat={displayLiveChat}
          minimized={minimized}
        >
          <DashboardMenu />

          <MainContainer>
            <Switch>
              <Route exact path={p.appRoot} component={SummaryContainer} />
              <Route path={p.form} component={FormContainer} />
              <Route path={p.file} component={File} />
              <Route
                path={p.profile}
                render={() => <Profile userEmail={userInfo.email} />}
              />
              <Route path={p.contactUs} component={ContactUs} />
              <Route path={p.messages} component={Messages} />
              {/* <Route path={p.upload} component={UploadDocuments} /> */}
              <Route path={p.newUpload} component={NewUploadDocuments} />
              <Route path={p.documents} component={Documents} />
              <Route
                path={p.payments}
                render={() => (
                  <Payments failedWithData={props.failedWithData} />
                )}
              />
              <Route path={p.time} component={AvailableTime} />
              <Route path={p.claimStatus} component={ClaimStatus} />
              <Route path={p.claimList} component={ClaimList} />
              <Route path={p.page} component={Page} />
              <Route path={p.startClaim} component={StartAClaim} />
              <Route path={`${p.tasks}/:ntn?`} component={TasksContainer} />
              <Route path={p.taskDetails} component={TaskDetail} />
              <Route path={p.taskLlDescription} component={TaskLlDescription} />
              <Route path={p.loading} component={LoadingScreen} />
              <Route path={p.error} component={FatalError} />
              <Route path={p.claimsLeavePlan} component={ClaimsLeavePlan} />
              <Route path={p.absenceHistory} component={AbsenceHistory} />
              <Route path={p.learninghub} component={LearningHub} />
              <Route path={p.supplemental} component={Supplemental} />
            </Switch>
          </MainContainer>
        </PortalContainer>

        {showFab && (
          <LiveChatFab id="live-chat-fab" onClick={() => toggleLiveChat(true)}>
            <LiveChatStatus
              id="live-chat-fab-status"
              online={callCenter.open}
            />
          </LiveChatFab>
        )}

        {displayFailedWithDataError && (
          <CommonModal
            markdown={
              `We're having trouble accessing our files so we may not be able to give you updated information right now.` +
              ` You can still ask questions or see information from the last time you logged in. You can also come back and try again later.`
            }
            confirmButtonText="Got it"
            modalDownloadURL="false"
            modalId="app.FINEOSDataLoadFailWithContinueLogin"
            small={true}
            modalCallback={() => setDisplayFailedWithDataError(false)}
          />
        )}

        <FlexBreak />
        <Footer liveChat={displayLiveChat && !minimized} />
        {displayLiveChat && (
          <LiveChat
            hideShowLiveChat={() => toggleLiveChat(!displayLiveChat)}
            minimizeChat={minimizeChat}
            minimized={minimized}
          />
        )}
        {displayDisclaimer && (
          <ConfirmDialogModal
            title={getLocalizedOrDefault(
              localizedStrings,
              'disclaimer_modal_title',
              'Disclaimer',
            )}
            description={
              (dynamicContent['external_link_disclaimer']
                ?.dcContentFull as any) || DEFAULT_LINK_DISCLAIMER
            }
            markdown={true}
            confirmButtonText={getLocalizedOrDefault(
              localizedStrings,
              'disclaimer_continue_text',
              'Continue',
            )}
            cancelButtonText={getLocalizedOrDefault(
              localizedStrings,
              'disclaimer_cancel_text',
              'Cancel',
            )}
            modalCallback={() => setDisplayDisclaimer(false)}
            handleConfirm={() => {
              if (continueLink) {
                window.open(continueLink, '_blank');
              }
            }}
            dataAttributes={{ 'data-link-url': continueLink }}
          />
        )}
        <BrowserWarningModal />
      </Container>
    </>
  );
};

export default App;
