import React from 'react';
import { parseJSONOrDefault, prefixObjectValues } from '../../../utils';
import { models } from '../../../utils/cms-client';
import * as options from '../options';
import { FormContext } from '../../../contexts/form-context';
import AccordionCard from '../../base/accordion-card';
import EmployerCard, { Title } from './card-items/employer-card';
import ExpandedSection from './card-items/expanded-section';

interface IFormAccordionCard {
  keyId?: string;
  content: models.AccordionCard;
  formValueGetter: (name: string) => any;
  index: number;
}

const domIdsStatic = {
  rootNode: 'form-accordion',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const FormAccordionCard: (p: IFormAccordionCard) => React.ReactElement | null =
  (p) => {
    const domIds = domIdsUnique(p.keyId);
    const { content, formValueGetter, index } = p;
    const { formContext } = React.useContext(FormContext);

    const [optionsTemplate, setOptionsTemplate] = React.useState({});

    React.useEffect(() => {
      setOptionsTemplate(parseJSONOrDefault(content?.optionsTemplate?.value));
    }, [content]);

    const isEmployerCard =
      content.type.value.length > 0 &&
      content.type.value[0].codename === 'employer';

    const memoizedComponent = React.useMemo(
      () => {
        return isEmployerCard ? (
          <EmployerCard
            idPrefix={domIds.rootNode}
            content={content}
            formContext={formContext}
            formValueGetter={formValueGetter}
            index={index}
          />
        ) : (
          <AccordionCard
            idPrefix={domIds.rootNode}
            titleElement={<Title>{content?.header?.value}</Title>}
            expandedElement={
              <ExpandedSection
                idPrefix={domIds.rootNode}
                content={content?.content?.value}
                formContext={formContext}
                formValueGetter={formValueGetter}
              />
            }
            index={index}
          />
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    return options.shouldShow(optionsTemplate, formValueGetter, formContext) ? (
      <div>{memoizedComponent}</div>
    ) : null;
  };

export default FormAccordionCard;
