import React from 'react';
import { CustomSharedEntitlement } from '../../../data-models';
import shield from '../../../images/white-shield-blue-check.svg';
import approved from '../../../images/green-circle-check.svg';
import pending from '../../../images/yellow-circle-white-time-hands.svg';
import timeTaken from '../../../images/time-taken.svg';
import sharedPlan from '../../../images/shared-plan.svg';
import notAvailable from '../../../images/not-available.svg';
import { CircularGuage } from '../circular-gauge';
import { CommonToolTip } from '../../common/tooltip';
import { NY_PFL_PLANS, calcToolTip, unavailableTime } from '../util';

type Props = {
  data: CustomSharedEntitlement;
};

export const ItemRender: React.FC<Props> = ({ data }) => {
  const { mainEntitlement, entitlements } = data;

  return (
    <div className="tw-my-5 border tw-shadow-basic tw-rounded-md tw-text-unm-primary02-700">
      <div
        id={`item-container-${mainEntitlement.id}`}
        className="tw-flex border tw-shadow-basic tw-rounded-md tw-h-[180px]"
      >
        <div id="item-left" className="tw-p-6 tw-w-2/5 tw-bg-unm-adhoc3">
          <div id="item-name" className="tw-text-2xl ">
            {mainEntitlement.name}
          </div>
          <div className="tw-flex tw-mt-3 tw-h-[70%]">
            <div className="tw-w-1/5">
              <img src={timeTaken} alt="time taken" className="tw-mt-2" />
            </div>
            <div className="tw-w-4/5 tw-flex tw-flex-col">
              <div
                id="time-available"
                className="tw-text-xl tw-font-bold tw-flex"
              >
                {unavailableTime(mainEntitlement) ? (
                  <div id="unavailable-time-tooltip" className="tw-flex">
                    <span className="tw-italic tw-pr-2">Not available</span>
                    <CommonToolTip
                      content="We are unable to provide information regarding the
                          amount of leave the employee has used or has
                          available. Please review their most recent notice(s)
                          for details on your leave(s)."
                    />
                  </div>
                ) : (
                  <>
                    {mainEntitlement.availableTime} {mainEntitlement.timeBasis}
                  </>
                )}
              </div>
              <div id="time-date">
                Time available as of {mainEntitlement.periodEndDate}
              </div>
              <div id="time-calculation" className="tw-flex">
                <span className="tw-pr-2">
                  {mainEntitlement.calculationPeriod}
                </span>
                <CommonToolTip
                  id="common-tool-tip"
                  content={calcToolTip(mainEntitlement.calculationPeriod)}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="item-right"
          className="tw-py-6 tw-ml-[20px] tw-w-2/5 tw-flex tw-flex-col tw-justify-between"
        >
          {unavailableTime(mainEntitlement) ? (
            <div id="unavailable-time-tooltip" className="tw-flex">
              <span className="tw-italic tw-pr-2 tw-text-center tw-text-xl tw-font-bold">
                Not available
              </span>
            </div>
          ) : (
            <>
              <div id="item-maximum" className="tw-flex">
                <div className="tw-w-1/8 tw-text-center">
                  <img src={shield} alt="shield" />
                </div>
                <div className="tw-w-7/8 tw-mt-[8px]">
                  {mainEntitlement.timeEntitlement} {mainEntitlement.timeBasis}{' '}
                  in {mainEntitlement.timeWithinPeriod}{' '}
                  {mainEntitlement.timeWithinPeriodBasis} <b>Maximum</b> Plan
                  Entitlement
                </div>
              </div>
              <div id="item-approved" className="tw-flex tw-mt-[8px]">
                <div className="tw-w-1/8 tw-ml-[7px]">
                  <img src={approved} alt="approved" />
                </div>
                <div className="tw-w-7/8 tw-ml-[-7px] tw-mt-[2px]">
                  {mainEntitlement.approvedTime} {mainEntitlement.timeBasis}{' '}
                  <b>Total</b> Approved
                </div>
              </div>
              <div id="item-pending" className="tw-flex">
                <div className="tw-w-1/8 tw-text-center tw-ml-[7px]">
                  <img src={pending} alt="pending" />
                </div>
                <div className="tw-w-7/8 tw-ml-[-7px] tw-mt-[2px] ">
                  {mainEntitlement.pendingTime} {mainEntitlement.timeBasis}{' '}
                  <b>Total</b> Pending
                </div>
              </div>
            </>
          )}
        </div>
        <div
          id="progress-bar"
          className="tw-w-1/5 tw-flex tw-justify-center tw-items-center tw-h-full "
        >
          {unavailableTime(mainEntitlement) ? (
            <img
              src={notAvailable}
              alt="unavailable time"
              id="unavailable-time-shield-img"
            />
          ) : (
            <CircularGuage item={mainEntitlement} />
          )}
        </div>
      </div>

      {NY_PFL_PLANS.find((e) => e === mainEntitlement.name) && (
        <div
          id="ny-plans-info"
          className="tw-p-2 tw-px-10 tw-italic tw-text-sm border border-[#CFCFCF]"
        >
          The leave amounts displayed are approximate. They may be impacted by
          other leave requests and may not include time reported in the last two
          business days. Although time may be available, an employee must be
          eligible and on leave for a qualifying reason to use such time. In
          addition, under NY DBL/PFL, employees cannot take more than a combined
          total of 26 weeks of family and disability leave in a benefit year.
        </div>
      )}
      {entitlements.length > 0 && (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-bg-[#F5F5F566] tw-mb-6">
          <div className="tw-flex tw-my-5 tw-p-5 tw-bg-[#EAEAEA4D] tw-w-[90%] border border-[#CFCFCF] tw-rounded-md">
            <div>
              <img src={sharedPlan} alt="shared plan" className="tw-mt-[5px]" />
            </div>
            <div className="tw-ml-[10px]">
              This is a shared plan. The plans below each have an individual
              plan entitlement, however an eligible employee cannot take more
              than the maximum combined plan entitlement as indicated above.
            </div>
          </div>
          {entitlements.map((entitlement) => (
            <div
              id={`item-container-${entitlement.id}`}
              className=" tw-flex border tw-shadow-basic tw-rounded-md tw-h-[160px] tw-w-[90%] tw-mb-5"
              key={entitlement.id}
            >
              <div
                id="item-left"
                className="tw-p-6 tw-w-2/5 tw-bg-unm-adhoc3 tw-flex tw-flex-col tw-justify-between"
              >
                <div id="item-name" className="tw-text-2xl ">
                  {entitlement.name}
                </div>
                <div id="time-date">
                  Time available as of {entitlement.periodEndDate}
                </div>
                <div id="time-calculation">{entitlement.calculationPeriod}</div>
              </div>
              <div
                id="item-right"
                className="tw-py-6 tw-ml-[20px] tw-w-2/5 tw-flex tw-flex-col tw-justify-between"
              >
                <div id="item-maximum" className="tw-flex">
                  <div className="tw-w-1/8 tw-text-center">
                    <img src={shield} alt="shield" />
                  </div>
                  <div className="tw-w-7/8 tw-mt-[8px]">
                    {entitlement.timeEntitlement} {entitlement.timeBasis} in{' '}
                    {entitlement.timeWithinPeriod}{' '}
                    {entitlement.timeWithinPeriodBasis} Plan Entitlement
                  </div>
                </div>
                <div id="item-approved" className="tw-flex tw-mt-[8px]">
                  <div className="tw-w-1/8 tw-ml-[7px]">
                    <img src={approved} alt="approved" />
                  </div>
                  <div className="tw-w-7/8 tw-ml-[-7px] tw-mt-[2px]">
                    {entitlement.approvedTime} {entitlement.timeBasis} Approved
                  </div>
                </div>
                <div id="item-pending" className="tw-flex">
                  <div className="tw-w-1/8 tw-text-center tw-ml-[7px]">
                    <img src={pending} alt="pending" />
                  </div>
                  <div className="tw-w-7/8 tw-ml-[-7px] tw-mt-[2px] ">
                    {entitlement.pendingTime} {entitlement.timeBasis} Pending
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
