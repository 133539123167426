import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Form as KendoForm,
  FormElement,
  FormSubmitClickEvent,
  FormRenderProps,
} from '@progress/kendo-react-form';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import {
  enums,
  getHtmlFromMarkdown,
  analyticsTrackEvent,
  supplemental,
} from '../../../utils';
import { convertAnchorTagsToRouterLinks } from '../../../utils/html-parsing-helpers';
import { models } from '../../../utils/cms-client';
import { NotFoundScreen } from '../../base/exception-screens/not-found-screen';
import { rem } from '../../../style';
import styled from 'styled-components';
import { PersonDataContext } from '../../../contexts/person-data-context';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';
import { PreferencesContext } from '../../../contexts/preferences-context';
import { PaymentPreferencesModel } from '../../../data-models';
import {
  getSimpleRemoteConfig,
  getExitPromptFormList,
} from '../../../utils/remote-config-manager';
import { FormContext, IFormContext } from '../../../contexts/form-context';
import MobileStepper from './mobile-stepper';
import {
  generateSection,
  generateSubmittedComponent,
  updatedClaimList,
  SubmissionObject,
} from './generators';
import FormContactCard from '../form-contact-card';
import {
  CustomStepProps,
  moveToStep,
  processNavigationObject,
  transformFormStepsForStepper,
} from './utils';
import {
  handleUseEffectContexts,
  handleUseEffectInit,
  handleUseEffectStorybook,
  onStepSubmit,
} from './handlers';
import Buttons from './buttons';
import Notification from './notification';
import useHandlebars from '../../../hooks/use-handlebars';
import {
  getPaymentPreferences,
  getPreferences,
} from '../../../utils/web-apis-client';
import { FullScreenLoadingIndicator } from '../../base/full-screen-loading-indicator';
import { Prompt } from '../../base/prompt';
import { Feedback } from '../../feedback';
import {
  useAutosaveGet,
  useAutosavePost,
  useVBCoverage,
} from '../../../hooks/use-data';

// interfaces
export interface ComponentProps extends RouteComponentProps {
  form?: models.Form;
  formContext?: IFormContext;
  stepIndex?: number;
  submitted?: boolean;
  showError?: boolean;
  submitting?: boolean;
}

// styled components

const StepContainer = styled.div`
  margin-right: ${rem(80)};

  .k-step-done .k-step-label {
    color: #007cb7 !important;
    text-decoration: underline !important;
    cursor: pointer;
    font-weight: 600;
  }
`;

const FormSectionStyled = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const getPreferenceMethods = async (setFormContext: Function) => {
  const prefs = (await getPaymentPreferences()) as PaymentPreferencesModel;
  const commsPrefs = await getPreferences();

  if (prefs && commsPrefs) {
    const smsPref = commsPrefs?.communicationPreferences?.find(
      (c) => c.preferenceType === 'Texting',
    );
    setFormContext((fc: IFormContext) => ({
      ...fc,
      paymentMethod: prefs.paymentMethod,
      smsOptIn: smsPref?.preferenceValue,
    }));
  }
};

const setFieldsFromRemoteConfig = async (
  setFormContext: Function,
  companyCode: string | undefined,
) => {
  const con = await getSimpleRemoteConfig();

  if (con) {
    const list = con[enums.RemoteConfigKeys.hidePrefsForId];

    const employerEnabled = await supplemental.isEmployerEnabledSync(
      con,
      companyCode,
    );
    setFormContext((fc: IFormContext) => ({
      ...fc,
      config: con,
      hidePaymentPrefs: list,
      isVBEmployerEnabled: employerEnabled,
    }));
  }
};

const setLeaveOnlyFlag = (
  formContext: IFormContext,
  setFormContext: Function,
) => {
  let leaveOnly = false;
  const formName = formContext.formName;

  if (formName === 'Pregnancy') {
    leaveOnly = !formContext.eeCentric?.stdEligible[9280003];
  } else if (formName === 'Personal Medical') {
    leaveOnly =
      !formContext.eeCentric?.stdEligible[9280002] &&
      !formContext.eeCentric?.stdEligible[9280001];
  }

  setFormContext((fc: IFormContext) => ({
    ...fc,
    leaveOnly,
  }));
};

const getVBCoverage = async (
  setFormContext: Function,
  anyVBCoverage: boolean,
) => {
  setFormContext((fc: IFormContext) => ({
    ...fc,
    hasVBCoverage: anyVBCoverage,
  }));
};

const showSteps = (form: any, steps: any, currentStepIndex: number) => {
  return (
    form &&
    form?.flags?.value.filter(
      (f: any) => f.codename === enums.FormFlags.showSteps,
    ).length > 0 &&
    steps &&
    steps.length > 1 &&
    form?.steps?.value?.[currentStepIndex].flags?.value.filter(
      (f: any) => f.codename === enums.FormStepFlags.hideStepper,
    ).length === 0
  );
};

const FormLabel: (p: { labelMarkdown: string }) => React.ReactElement = (p) => {
  return (
    <div className="mb-3">
      {convertAnchorTagsToRouterLinks(
        getHtmlFromMarkdown(p.labelMarkdown).__html,
      )}
    </div>
  );
};

const submissionComponent = (
  submitting: boolean,
  form: models.Form | undefined,
  labelMarkdown: string,
  formContext: IFormContext,
  setFormContext: Function,
) => {
  if (submitting) {
    return <FullScreenLoadingIndicator displayText={'Please wait...' as any} />;
  }

  const submission: SubmissionObject = generateSubmittedComponent({
    form,
    labelMarkdown,
    formContext,
  });

  if (submission.noNextSteps && !formContext.noApplicableNextSteps) {
    setFormContext({ ...formContext, noApplicableNextSteps: true });
  }

  let surveyFlag = false;
  if (
    form?.system.codename === 'vb_hospital' ||
    form?.system.codename === 'vb_accident' ||
    form?.system.codename === 'vb_critical_illness'
  ) {
    surveyFlag = true;
  }
  return (
    <>
      {surveyFlag && <Feedback timer={2000} />}
      {submission.markdown}
    </>
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const Form = (p: ComponentProps) => {
  // path processing
  const { lastPath, task, defaults } = processNavigationObject(p);

  // Kentico Form* model states
  const [form, setForm] = React.useState<models.Form | undefined>(p.form);
  const [formSteps, setFormSteps] = React.useState<models.FormStep[]>(
    p.form?.steps?.value || [],
  );
  const [formStepIndex, setFormStepIndex] = React.useState(0);

  // form context from claims/leaves/etc
  const { formContext, setFormContext, allowNavBack } =
    React.useContext(FormContext);
  const { personData } = React.useContext(PersonDataContext);
  const { employerPreferences } = React.useContext(PreferencesContext);
  // Stepper states
  const [formState, setFormState] = React.useState(defaults);
  const [currentStepIndex, setCurrentStepIndex] = React.useState(
    p.stepIndex ?? 0,
  );
  const [notFound, setNotFound] = React.useState(false);
  const [steps, setSteps] = React.useState<CustomStepProps[]>([]);
  const [submitted, setSubmitted] = React.useState(p.submitted || false);
  const labelMarkdown = useHandlebars(form?.label?.value, formContext);

  // misc states
  const [showError, setShowError] = React.useState(p.showError || false);
  const [submitting, setSubmitting] = React.useState(p.submitting || false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    React.useState(false);
  const [scrollToElement, setScrollToElement] = React.useState('');
  //state value for telling the modal which step to return to when stepper is clicked
  const [stepGoBack, setStepGoBack] = React.useState(-1);
  const { config } = React.useContext(KenticoDataContext);
  const { data: autosaveData, isFetching: autosaveIsFetching } =
    useAutosaveGet(lastPath);
  const { mutate: mutateAutosavePost } = useAutosavePost(lastPath);
  const { data: vbCoverageData } = useVBCoverage();
  // this is only here for storybook
  React.useEffect(() => {
    handleUseEffectStorybook(
      p,
      form,
      setForm,
      setFormSteps,
      setSubmitted,
      setShowError,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p.form, p.submitted, p.showError]);

  React.useEffect(() => {
    setSteps(transformFormStepsForStepper(formSteps));
  }, [formSteps]);

  React.useEffect(() => {
    personData &&
      setFormContext((fc: any) => {
        const { claimList, ipd } = updatedClaimList(personData);
        return { ...fc, claimList, ...ipd };
      });
  }, [personData, setFormContext]);

  React.useEffect(() => {
    handleUseEffectContexts(
      personData,
      employerPreferences,
      setFormContext,
      task,
      defaults,
      config,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFormContext]);

  React.useEffect(() => {
    setLeaveOnlyFlag(formContext, setFormContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext.formName]);

  React.useEffect(() => {
    handleUseEffectInit({
      p,
      personData,
      employerPreferences,
      setFormContext,
      lastPath,
      setNotFound,
      setForm,
      setFormSteps,
      task,
      defaults,
      setSubmitted,
      setCurrentStepIndex,
      setFormStepIndex,
    });
    getPreferenceMethods(setFormContext);
    setFieldsFromRemoteConfig(setFormContext, personData?.person?.companyCode);
    if (vbCoverageData) {
      getVBCoverage(setFormContext, vbCoverageData.hasCoverage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPath]);

  const handleOnStepSubmit = React.useCallback(
    (event: FormSubmitClickEvent) => {
      onStepSubmit({
        event,
        steps,
        currentStepIndex,
        setSteps,
        setCurrentStepIndex,
        setFormState,
        setSubmitted,
        form,
        setShowError,
        formContext,
        setSubmitting,
        setFormContext,
        setFormStepIndex,
        autosaveParams: {
          mutateAutosavePost,
          autosaveData: {
            formName: lastPath,
            formStepIndex: currentStepIndex,
            formStepName: steps[currentStepIndex].label!,
            submitted: false,
            data: autosaveData?.result?.data
              ? autosaveData.result.data
              : event.values,
            savedTimestamp: new Date().toISOString(),
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStepIndex, form, formContext, steps, task, personData, lastPath],
  );

  const currentStepHasLeavePlanGraph =
    currentStepIndex === formContext?.stepIndexWithLeavePlanGraph;

  const shouldShowSteps = React.useCallback(() => {
    return showSteps(form, steps, currentStepIndex);
  }, [form, steps, currentStepIndex]);

  const moveToStepCall = (
    stepperChangeEvent: StepperChangeEvent,
    scrollToName?: string,
  ) => {
    moveToStep({
      stepperChangeEvent,
      currentStepIndex,
      setCurrentStepIndex,
      setDisplayConfirmationModal,
      formContext,
      currentStepHasLeavePlanGraph:
        currentStepIndex >= (formContext.stepIndexWithLeavePlanGraph ?? -1),
      setStepGoBack,
      allowNavBack,
      setFormStepIndex,
      steps,
      formSteps: form?.steps?.value,
    });
    setScrollToElement(scrollToName ?? '');
  };

  if (autosaveIsFetching) {
    return <FullScreenLoadingIndicator displayText={'Loading...' as any} />;
  }

  if (submitting || submitted) {
    return submissionComponent(
      submitting,
      form,
      labelMarkdown,
      formContext,
      setFormContext,
    );
  }

  return (
    <>
      <div
        className={`d-flex${
          shouldShowSteps() ? ' flex-column flex-md-row' : ' flex-column'
        } `}
      >
        {shouldShowSteps() && (
          <FormStepperSection
            currentStepIndex={currentStepIndex}
            steps={steps}
            moveToStepCall={moveToStepCall}
          />
        )}
        <div className="d-flex flex-column flex-grow-1">
          <FormLabel labelMarkdown={labelMarkdown} />
          <div className="flex-grow-1">
            <KendoForm
              initialValues={formState}
              onSubmitClick={handleOnStepSubmit}
              render={(formRenderProps) => {
                return (
                  <div>
                    <FormElement>
                      <fieldset className="k-form-fieldset">
                        <MainFormSection
                          formSteps={formSteps}
                          currentStepIndex={formStepIndex}
                          formStepIndex={formStepIndex}
                          formRenderProps={formRenderProps}
                          form={form}
                          moveToStepCall={moveToStepCall}
                          formName={formContext.formName}
                        />
                        <Buttons
                          currentStepIndex={currentStepIndex}
                          setCurrentStepIndex={setCurrentStepIndex}
                          formRenderProps={formRenderProps}
                          form={form}
                          formSteps={formSteps}
                          currentStepHasLeavePlanGraph={
                            currentStepHasLeavePlanGraph
                          }
                          submitting={submitting}
                          displayConfirmationModal={displayConfirmationModal}
                          setDisplayConfirmationModal={
                            setDisplayConfirmationModal
                          }
                          stepGoBack={stepGoBack}
                          setStepGoBack={setStepGoBack}
                          steps={steps}
                          setFormStepIndex={setFormStepIndex}
                          scrollToElement={scrollToElement}
                          setScrollToElement={setScrollToElement}
                        />
                        {notFound && <NotFoundScreen />}
                      </fieldset>
                    </FormElement>
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
      <Notification showError={showError} setShowError={setShowError} />
    </>
  );
};

const FormStepperSection = (p: {
  currentStepIndex: number;
  steps: CustomStepProps[];
  moveToStepCall: Function;
}) => {
  const { currentStepIndex, steps, moveToStepCall } = p;
  return (
    <>
      <MobileStepper
        className="d-flex d-md-none"
        currentStepIndex={currentStepIndex}
        items={steps}
      />
      <StepContainer className="d-none d-md-flex">
        <Stepper
          className="align-self-start"
          value={currentStepIndex}
          items={steps}
          orientation={'vertical'}
          onChange={(stepperChangeEvent: StepperChangeEvent) =>
            moveToStepCall(stepperChangeEvent)
          }
        />
      </StepContainer>
    </>
  );
};

const getPromptData = async (
  setExitReasons: Function,
  setReasonUseTextBox: Function,
  setReasonMandatory: Function,
  setIntakeList: Function,
  config: any,
) => {
  const showExitPromptList = await getExitPromptFormList();

  if (config) {
    const reasons =
      config?.[enums.RemoteConfigKeys.intakeExitReasons]?.split?.('|') ?? [];
    setExitReasons(reasons);
    //if one reason has a star at the end, it will be used to denote that this is the reason to trigger a free form text box on
    let reasonUseTextBox;
    reasons.forEach((r: string, i: number) => {
      if (r.endsWith('*')) {
        reasons[i] = r.replace(/\*/g, '');
        reasonUseTextBox = reasons[i];
      }
    });
    setReasonUseTextBox(reasonUseTextBox);
    setReasonMandatory(
      config?.[enums.RemoteConfigKeys.mandatoryExitReason] === 'true',
    );
    setIntakeList(showExitPromptList);
  }
};

const MainFormSection = (p: {
  formSteps: models.FormStep[];
  currentStepIndex: number;
  formStepIndex: number;
  formRenderProps: FormRenderProps;
  form: any;
  moveToStepCall: Function;
  formName: string;
}) => {
  const {
    formSteps,
    currentStepIndex,
    formStepIndex,
    formRenderProps,
    form,
    moveToStepCall,
    formName,
  } = p;

  const [exitReasons, setExitReasons] = React.useState<string[]>([]);
  const [reasonUseTextBox, setReasonUseTextBox] = React.useState<string>('');
  const [reasonMandatory, setReasonMandatory] = React.useState<boolean>(false);
  const [intakeList, setIntakeList] = React.useState<string[]>([]);
  const { config } = React.useContext(KenticoDataContext);

  React.useEffect(() => {
    getPromptData(
      setExitReasons,
      setReasonUseTextBox,
      setReasonMandatory,
      setIntakeList,
      config,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExit = (args: any) => {
    const analyticsData = {
      formName,
      exitReason: args[0],
      otherText: args[1],
    };
    analyticsTrackEvent(enums.AnalyticsEvents.formExit, analyticsData);
    return true;
  };

  return formSteps?.length > 0 ? (
    <>
      {intakeList && intakeList.length > 0 && intakeList.includes(formName) && (
        <Prompt
          title="Before you exit..."
          when={true}
          onOK={handleExit}
          onCancel={() => false}
          okText={'Exit claim'}
          cancelText={'Continue with claim'}
          description={
            "Exiting now will lose the progress you've made so far and you'll have to start a new claim. If you meant to exit your claim, could you please tell us why?"
          }
          options={exitReasons}
          useTextBox={reasonUseTextBox}
          mandatory={reasonMandatory}
        />
      )}
      <FormSectionStyled>
        <div className="flex-column w-100">
          {formSteps[formStepIndex]?.sections?.value?.map((f, i) =>
            generateSection(
              f,
              i,
              currentStepIndex,
              formRenderProps,
              form?.optionsTemplate?.value || '',
              moveToStepCall,
              formSteps[formStepIndex].sectionFieldNamePrefix?.value || '',
            ),
          )}
        </div>
        <FormContactCard step={formSteps[currentStepIndex]} />
      </FormSectionStyled>
    </>
  ) : null;
};

export default Form;
