import { Pager } from '../simple-styled-components';

interface PagerProps {
  elements: any;
  skip: number;
  take: number;
  handlePageChange: (e: any) => void;
}

/**
 * A styled pagination section component. See usage details at https://www.telerik.com/kendo-react-ui/components/listview/paging/.
 * @param props
 */
const PagerSection = (props: PagerProps) => {
  const { elements, skip, take, handlePageChange } = props;
  return (
    <>
      {elements && elements.length > take ? (
        <Pager
          skip={skip}
          take={take}
          onPageChange={handlePageChange}
          total={elements.length}
          previousNext
          type="numeric"
          info={false}
          buttonCount={3}
        />
      ) : (
        <div id="pager-hidden" className="pb-4" />
      )}
    </>
  );
};

export default PagerSection;
