import React from 'react';
import {
  StyledNotification,
  ToastNotificationGroupStyle,
} from '../../../base/simple-styled-components';

export interface NotificationProps {
  showError: boolean;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
}

const Notification = ({ showError, setShowError }: NotificationProps) => (
  <div id="form-notification">
    <ToastNotificationGroupStyle>
      {showError && (
        <StyledNotification
          key="error"
          type={{
            style: 'error',
            icon: true,
          }}
          closable={true}
          onClose={() => setShowError(false)}
        >
          Sorry, there was a problem submitting the form.
        </StyledNotification>
      )}
    </ToastNotificationGroupStyle>
  </div>
);

export default Notification;
