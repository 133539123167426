
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class FormElement extends ContentItem {
    public label!: Elements.CustomElement;
    public showLogic!: Elements.CustomElement;
    public inputType!: Elements.LinkedItemsElement<ContentItem>;
    public validations!: Elements.CustomElement;
    public required!: Elements.MultipleChoiceElement;
    public helpText!: Elements.LinkedItemsElement<ContentItem>;
    public form!: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'show_logic') {
                    return 'showLogic';
                }
                if (elementName === 'input_type') {
                    return 'inputType';
                }
                if (elementName === 'help_text') {
                    return 'helpText';
                }
                return elementName;
            })
        });
    }
}
