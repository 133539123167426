import React from 'react';
import { useHistory } from 'react-router-dom';
import { colors } from '../../../style';
import styled from 'styled-components';
import { prefixObjectValues, enums } from '../../../utils';
import { FooterLink, CopyrightText } from '../simple-styled-components/index';
import { getContent, getHtmlFromMarkdown } from '../../../utils/index';
import FooterWave from '../../../images/unum-footer-bg.svg';
import UnumLogoWhite from '../../../images/unum-intercom-logo-white.svg';
import { PersonDataContext } from '../../../contexts/person-data-context';
import { PersonData } from '../../../data-models';
import Handlebars from 'handlebars';
import { registerSelf } from '../../../hooks/use-handlebars/helpers';
import {
  IKenticoData,
  KenticoDataContext,
} from '../../../contexts/kentico-data-context';
import { log } from '../../../utils/log';
import { postRefreshToken } from '../../../utils/web-apis-client';
import { logout } from '../../../utils/auth-manager';

// const log = new Logger\('app-footer');

const DEFAULT_REFRESH_CACHE_TIME = 300000; // 5 minutes

interface FooterProps {
  keyId?: string;
  personData?: PersonData;
  liveChat?: boolean;
}

/*******************************************************************************
 * Adds a unique prefix to the domIds to create a unique id
 * @param {string} prefix A unique prefix to add to the domIds
 * @returns {domIdsStatic} If a prefix is provided, then a copy of domIdsStatic
 * is returned with the property values modified to include the prefix. If a
 * prefix is not provided a copy of domIdsStatic is returned.
 ******************************************************************************/
export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  contactUsLink: 'footer-link-contact',
  accessibilityLink: 'footer-link-accessibility',
  feedbackLink: 'footer-link-feedback',
  privacyLink: 'footer-link-privacy',
  legalLink: 'footer-link-legal',
  copyrightText: 'footer-copyright-text',
  rootNode: 'footer-container',
  links: 'footer-links',
};

/*******************************************************************************
 * styles / styled components
 ******************************************************************************/
interface ContainerProps {
  liveChat?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${colors.background};
  bottom: 0px;
  width: 100%;
  @media (max-width: 576px) {
    display: ${(props) => (props.liveChat ? 'none' : 'block')};
  }
`;

const BlueFooter = styled.div`
  background-color: #015294;
  margin-top: -2px;
  padding: 0 1.5em 1.5em;
`;

const Links = styled.div`
  padding-bottom: 0px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`;

const FooterLinkMod = styled(FooterLink)`
  margin-right: 24px;
  margin-top: 24px;
  z-index: 2;
`;

const CopyrightTextMod = styled(CopyrightText)`
  padding-top: 24px;
  width: 100%;
  z-index: 2;
  @media (min-width: 768px) {
    width: auto;
    margin-left: auto;
  }
`;

const UnumLogo = styled.img`
  max-width: 50px;
  z-index: 2;
  @media (max-width: 600px) {
    margin-right: auto;
  }
`;

const FooterImage = styled.img`
  width: fill-available;
  @media (min-width: 500px) {
    position: relative;
    bottom: -20%;
  }
  @media (min-width: 1000px) {
    bottom: -40%;
  }
`;

const getDynamicFooterContent = async (
  setContent: Function,
  personData: PersonData | undefined,
  assets: IKenticoData['assets'],
) => {
  const result = await getContent(window.location.pathname, 'footer');
  registerSelf(Handlebars, () => '', assets);
  setContent(Handlebars.compile(result)(personData));
};

const Footer: (p: FooterProps) => React.ReactElement = (props) => {
  const domIds = domIdsUnique(props.keyId);
  const [content, setContent] = React.useState<any>();

  const history = useHistory();
  const { personData } = React.useContext(PersonDataContext);
  const { assets, featureFlags } = React.useContext(KenticoDataContext);

  React.useEffect(() => {
    const refreshTimer = setInterval(async () => {
      // refresh timer result
      const rtr = await postRefreshToken();
      log.debug('refresh token result:', rtr);
      if (rtr === 'false' || rtr === false) {
        // if the refresh token is invalid, logout
        await logout();
      }
    }, DEFAULT_REFRESH_CACHE_TIME);

    return () => {
      clearInterval(refreshTimer);
    };
  }, []);

  React.useEffect(() => {
    return history.listen(() => {
      getDynamicFooterContent(setContent, personData, assets);
    });
  }, [assets, history, personData]);

  React.useEffect(() => {
    if (!content) {
      getDynamicFooterContent(setContent, personData, assets);
    }
  }, [assets, content, personData]);

  return (
    <Container liveChat={props.liveChat} id={domIds.rootNode}>
      {content ? (
        <div dangerouslySetInnerHTML={getHtmlFromMarkdown(content)} />
      ) : null}
      <FooterImage src={FooterWave} alt="" />
      <BlueFooter>
        <div
          className="d-flex justify-content-start"
          style={{ flexWrap: 'wrap' }}
        >
          <UnumLogo alt="" src={UnumLogoWhite} />
        </div>
        <Links id={domIds.links} className="d-flex footer-links">
          <FooterLinkMod
            to={enums.AppPaths.contactUs}
            id={domIds.contactUsLink}
          >
            Contact Us
          </FooterLinkMod>
          {featureFlags.feedback ? (
            <FooterLinkMod
              to={enums.AppPaths.feedback}
              id={domIds.feedbackLink}
            >
              Feedback
            </FooterLinkMod>
          ) : null}
          <FooterLinkMod
            id={domIds.legalLink}
            target="_blank"
            to={{ pathname: 'https://www.unum.com/legal' }}
          >
            Legal
          </FooterLinkMod>
          <FooterLinkMod
            id={domIds.privacyLink}
            target="_blank"
            to={{ pathname: 'https://www.unum.com/privacy' }}
          >
            Privacy
          </FooterLinkMod>
          <FooterLinkMod
            id={domIds.accessibilityLink}
            target="_blank"
            to={{ pathname: 'https://www.unum.com/legal/accessibility' }}
          >
            Accessibility
          </FooterLinkMod>
          <CopyrightTextMod id={domIds.copyrightText}>
            &copy; {new Date().getFullYear()} Unum Group. All rights reserved.
            NS-200
          </CopyrightTextMod>
        </Links>
      </BlueFooter>
    </Container>
  );
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { Footer };
