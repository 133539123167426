import React from 'react';
import { useHistory } from 'react-router';
import { Info, LeftArrow } from './icons';
import { useVBCoverage } from '../../hooks/use-data';
import ProductSelector from './product-selector';
import { ExistingSuppLink, StartANewSuppLink } from './links';
import { benefitList } from './config';

export default function Supplemental() {
  const history = useHistory();
  const { data: vbCoverage } = useVBCoverage();

  const [selectedProduct, setSelectedProduct] = React.useState<
    string | undefined
  >();
  const [selectedBeneficiary, setSelectedBeneficiary] = React.useState<
    string | undefined
  >();

  const selectProduct = React.useCallback(
    (value: string): void => {
      setSelectedProduct(value);
      setSelectedBeneficiary(undefined);
    },
    [setSelectedProduct],
  );

  const selectBeneficiary = React.useCallback(
    (event: { target?: any; value?: any }): void => {
      console.log(event.target.value);
      setSelectedBeneficiary(event.target.value);
    },
    [],
  );

  const isBeneficiaryChecked = React.useCallback(
    (beneficiary: { value: string; disabled: boolean }): boolean =>
      selectedBeneficiary === beneficiary.value || beneficiary.disabled,
    [selectedBeneficiary],
  );

  const getBeneficiaryList = React.useCallback(() => {
    // result
    const r = [
      { value: 'Employee', labelValue: 'You', disabled: false },
      { value: 'Spouse', labelValue: 'Spouse', disabled: false },
      { value: 'Dependents', labelValue: 'Dependents', disabled: false },
    ];

    if (selectedProduct) {
      const coverage = vbCoverage?.coverages.find(
        (c) => c.productDescription === selectedProduct,
      );
      if (coverage) {
        r[0].disabled = !coverage.employeeCovered;
        r[1].disabled = !coverage.spouseCovered;
        r[2].disabled = !coverage.childCovered;
      }
    }
    return r;
  }, [selectedProduct, vbCoverage?.coverages]);

  const getStartLinkTo = React.useCallback(() => {
    if (selectedBeneficiary) {
      return {
        pathname:
          benefitList.find((b) => b.value === selectedProduct)?.link || '#',
        state: {
          defaults: {
            whoIsThisFor: selectedBeneficiary,
          },
        },
      };
    }

    return undefined;
  }, [selectedBeneficiary, selectedProduct]);

  return (
    <div className="w-100 tw-flex-col tw-flex">
      <button
        id="go-back"
        onClick={() => history.goBack()}
        className="tw-items-center tw-flex tw-h-full tw-justify-start tw-mb-0.5 tw-max-w-full tw-border-none tw-bg-transparent tw-cursor-pointer tw-underline tw-text-unm-primaryblue tw-p-0"
      >
        <LeftArrow className="tw-rotate-180 tw-fill-unm-primaryblue tw-mr-2" />
        <div className="">Back</div>
      </button>
      <div className="tw-w-full tw-text-2xl tw-text-unm-interactive01-800 tw-my-2">
        Supplemental Benefits
      </div>
      <p className="tw-mt-2 tw-mb-5 tw-text-unm-adhoc2">
        Select one of the Supplemental Benefits to start a claim. Only those
        Products associated with your portfolio will show below. If you would
        like to check on an existing claim, click on "View My Existing
        Supplemental Claims." If you would like to file a Wellness claim, click
        on "See or File My Wellness Claim"
      </p>
      <div className="tw-flex tw-justify-between max-md:tw-min-w-full">
        <ProductSelector
          className="tw-mb-5 max-lg:tw-min-w-full"
          onChange={selectProduct}
          selectedProduct={selectedProduct}
        />
        <div className="tw-flex tw-justify-between tw-gap-3">
          <ExistingSuppLink
            className="tw-hidden lg:tw-flex"
            text="View My Existing Supplemental Claims"
          />
          <ExistingSuppLink
            className="tw-hidden lg:tw-flex"
            text="See or File My Wellness Claim"
          />
        </div>
      </div>
      {selectedProduct && vbCoverage && (
        <>
          <p className="tw-text-unm-adhoc2">
            {benefitList.find((b) => b.value === selectedProduct)?.description}
          </p>
          <div className="lg:tw-w-96 tw-w-full">
            <p className="tw-mt-5">Please select one insured</p>
            <hr className="tw-w-full tw-h-0.5 tw-bg-[#99bad4] tw-m-0" />
            <div className="tw-flex tw-flex-col tw-items-stretch">
              <div className="tw-grid tw-grid-cols-3 tw-gap-1 tw-gap-y-4 tw-place-items-center tw-my-4">
                {getBeneficiaryList().map((b, i) => (
                  <div
                    key={`key-${i.toString()}`}
                    className={`${b.disabled ? 'tw-text-[#909090]' : ''}`}
                  >
                    {b.labelValue}
                  </div>
                ))}
                {getBeneficiaryList().map((b, i) => (
                  <input
                    checked={isBeneficiaryChecked(b)}
                    key={`key-${i.toString()}`}
                    className="tw-scale-150"
                    type="radio"
                    name={
                      b.disabled ? `disabled-ben-${i}` : 'selectedBeneficiary'
                    }
                    disabled={b.disabled}
                    value={b.value}
                    onChange={selectBeneficiary}
                  ></input>
                ))}
              </div>
              <div className="tw-bg-unm-adhoc1 tw-rounded tw-p-3 tw-text-unm-adhoc2 tw-text-sm tw-flex tw-items-center tw-my-5">
                <div>
                  <Info className="tw-fill-current tw-mr-3 tw-text-unm-interactive01-400" />
                </div>
                <p className="m-0">Only people with coverage are selectable</p>
              </div>
              <StartANewSuppLink to={getStartLinkTo()} className="tw-mt-5" />
            </div>
          </div>
        </>
      )}
      <div className="tw-flex tw-flex-col tw-items-stretch tw-gap-3 lg:tw-hidden">
        <p className="tw-text-center tw-mt-3 tw-mb-0">Or</p>
        <ExistingSuppLink text="View My Existing Supplemental Claims" />
        <ExistingSuppLink text="See or File My Wellness Claim" />
      </div>
    </div>
  );
}
