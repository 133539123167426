import { useContext, useState } from 'react';
import { Container, ItalicText, NoCasesContainer, Title } from './index.sc';
import { enums, get, prefixObjectValues } from '../../utils';
import { CustomUploadFileInfo } from '../../utils/upload-helpers';
import { PersonDataContext } from '../../contexts/person-data-context';
import DocumentGraphic from '../../images/document-in-cloud.png';
import DocumentTypeSelection from './doc-type-select';
import CaseSelection from './case-selection';
import FileSelection from './file-selection';
import successTick from '../../images/success-tick.svg';
import errorImage from '../../images/yellow-exclamation.svg';
import { Button } from '@progress/kendo-react-buttons';
import { Link } from 'react-router-dom';

interface UploadProps {
  keyId?: string;
  files?: CustomUploadFileInfo[];
  uploadTried?: boolean;
  selectedCase?: object;
  sortableFiles?: object[];
  docType?: string;
  success?: boolean;
  error?: boolean;
}

const domIdsStatic = {
  rootNode: 'newupload',
  dropzone: 'dropzone',
};

const uploadSuccess = (
  <>
    <div
      id="successScreen"
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <img
        alt="success"
        src={successTick}
        style={{
          paddingBottom: '24px',
          display: 'flex',
          justifyContent: 'center',
        }}
      />
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Ubuntu',
        fontSize: '26px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '32px',
        paddingBottom: '24px',
        textAlign: 'center',
      }}
    >
      Your documents have submitted!
    </div>
    <div
      style={{
        textAlign: 'center',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
      }}
    >
      We’ll review what you’ve submitted and get back to you <br /> if we need
      anything else.
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '36px',
      }}
    >
      <Button
        style={{
          display: 'flex',
          justifyContent: 'center',
          borderRadius: '360px',
          backgroundColor: '#037CB7',
        }}
      >
        <Link
          to={enums.AppPaths.appRoot}
          style={{
            color: '#fff',
          }}
        >
          Back to Summary
        </Link>
      </Button>
    </div>
  </>
);

const uploadError = (
  setError: any,
  setShowDropDowns: any,
  setDocType: any,
  setSelectedCaseNew: any,
  setSelectedLeaveReason: any,
) => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <img alt="error" src={errorImage} style={{ paddingBottom: '24px' }} />
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Ubuntu',
        fontSize: '26px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '32px',
        paddingBottom: '24px',
        textAlign: 'center',
      }}
    >
      Something went wrong
    </div>
    <div
      style={{
        textAlign: 'center',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
      }}
    >
      Please try to upload the documents again
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '36px',
      }}
    >
      <Button
        style={{
          display: 'flex',
          justifyContent: 'center',
          borderRadius: '360px',
          backgroundColor: '#fff',
          border: '1px solid #037CB7',
        }}
      >
        <Link
          to={enums.AppPaths.appRoot}
          style={{
            color: '#037CB7',
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px',
          }}
        >
          Cancel
        </Link>
      </Button>
      <Button
        style={{
          display: 'flex',
          justifyContent: 'center',
          borderRadius: '360px',
          backgroundColor: '#037CB7',
          marginLeft: '16px',
          color: '#fff',
          fontFamily: 'Open Sans',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
        }}
        onClick={() => {
          setError(false);
          setShowDropDowns(true);
          setDocType('');
          setSelectedLeaveReason('');
          setSelectedCaseNew('');
        }}
      >
        Retry
      </Button>
    </div>
  </>
);

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const NewUploadDocuments: (p: UploadProps, props: any) => React.ReactElement =
  () => {
    const { personData } = useContext(PersonDataContext);
    const [docType, setDocType] = useState<string>('');
    const [selectedCaseNew, setSelectedCaseNew] = useState<string>('');
    const [selectedLeaveReason, setSelectedLeaveReason] = useState<string>('');
    const [success, setSuccess] = useState<boolean>(false);
    const [showDropDowns, setShowDropDowns] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    const claims = get(personData?.cases, 'claims', []);
    const leaves = get(personData?.cases, 'leaves', []);
    const accommodations = get(personData?.cases, 'accommodations', []);

    console.log(selectedLeaveReason);

    //Combine claims and leaves
    const formattedCases = claims.concat(leaves).concat(accommodations);

    const containerId = 'new-upload-container';

    if (formattedCases.length === 0) {
      return (
        <Container id={containerId}>
          <Title>Upload Documents</Title>
          <NoCasesContainer>
            <img src={DocumentGraphic} alt="" width="179" height="148" />
            <ItalicText className="mt-4">
              {`You will be able to upload documents once you have an active claim or leave.`}
            </ItalicText>
          </NoCasesContainer>
        </Container>
      );
    }

    if (success === true && error === false) {
      return <Container id={containerId}>{uploadSuccess}</Container>;
    } else if (error === true) {
      return (
        <Container id={containerId}>
          {uploadError(
            setError,
            setShowDropDowns,
            setDocType,
            setSelectedCaseNew,
            setSelectedLeaveReason,
          )}
        </Container>
      );
    } else {
      return (
        <>
          <Container id={containerId}>
            <Title>Upload Documents</Title>
            {showDropDowns === true ? (
              <CaseSelection
                setSelectedCaseNew={setSelectedCaseNew}
                setSelectedLeaveReason={setSelectedLeaveReason}
              />
            ) : null}
            {selectedCaseNew !== '' &&
            selectedCaseNew !== 'Select a claim' &&
            showDropDowns === true ? (
              <DocumentTypeSelection setDocType={setDocType} />
            ) : null}
            {docType !== '' && docType !== undefined ? (
              <FileSelection
                selectedCase={selectedCaseNew}
                docType={docType}
                setSuccess={setSuccess}
                setError={setError}
                setShowDropDowns={setShowDropDowns}
              />
            ) : null}
          </Container>
        </>
      );
    }
  };

export default NewUploadDocuments;
