import dayjs from 'dayjs';
import styled from 'styled-components';
import { DateProps } from '../';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

type DayButtonType = (p: {
  date: dayjs.Dayjs;
  format: string;
  calendarDate: dayjs.Dayjs;
  details: DateProps[];
  handleDateSelection: Function;
  periods: { startDate: string; endDate: string; legendKey: string }[];
  selectedDate: dayjs.Dayjs;
  showSelectedDate?: boolean;
}) => React.ReactElement;

interface IDayStyle {
  periodStart?: boolean;
  periodEnd?: boolean;
  periodDate?: boolean;
  isSelectedDate?: boolean;
  theme: any;
  showSelectedDate?: boolean;
}

const getDayBackground = (p: IDayStyle) => {
  if (p.isSelectedDate && p.showSelectedDate) {
    return p.theme.selectedDayBackground;
  }

  if (p.periodDate) {
    return p.theme.periodStyles.background;
  } else {
    return p.theme.dayBackground;
  }
};

const Day = styled.button<IDayStyle>`
  display: flex;
  background: ${(props) => getDayBackground(props)};
  margin: 0;
  border: 0;
  border-right: ${(props) =>
    props.periodEnd
      ? props.theme.periodStyles.endOfPeriodBorder
      : props.theme.border};
  height: ${(props) => props.theme.dayHeight};
  align-items: ${(props) => props.theme.dayNumber.vertical};
  justify-content: ${(props) => props.theme.dayNumber.horizontal};
  color: ${(props) =>
    props.periodDate
      ? props.theme.periodStyles.dayNumberColor
      : props.theme.dayNumber.color};
  font-family: ${(props) => props.theme.dayNumber.font};
  font-size: ${(props) => props.theme.dayNumber.size};
  font-weight: ${(props) => props.theme.dayNumber.weight};
  border-left: ${(props) =>
    props.periodStart ? props.theme.periodStyles.startOfPeriodBorder : 0};

  &:last-child {
    border-right: ${(props) =>
      props.periodEnd ? props.theme.periodStyles.endOfPeriodBorder : 0};
  }

  &:disabled {
    background: ${(props) => props.theme.dayNumber.disabledBackground};
    color: ${(props) => props.theme.dayNumber.disabledColor};
    border-left: 0;
    border-right: ${(props) => props.theme.border};
    &:last-child {
      border-right: 0;
    }
  }
`;

const DayButton: DayButtonType = ({
  date,
  format,
  calendarDate,
  details,
  handleDateSelection,
  periods,
  selectedDate,
  showSelectedDate,
}) => {
  const isCurrentMonth = date.month() === calendarDate.month();

  const dateDetails = details.find((det) => dayjs(det.date).isSame(date, 'd'));

  const dayInPeriod = periods.find((p) =>
    date.isBetween(dayjs(p.startDate), dayjs(p.endDate), 'd', '[]'),
  );

  const isSelectedDate = date.isSame(selectedDate, 'd');

  const periodStart =
    dayInPeriod && date.isSame(dayjs(dayInPeriod.startDate), 'd');
  const periodEnd = dayInPeriod && date.isSame(dayjs(dayInPeriod.endDate), 'd');

  const props = {
    id: `calendar-day-${date.format('MM-DD')}-${
      !isCurrentMonth ? 'disabled' : 'active'
    }`,
    isSelectedDate,
    isCurrentMonth,
    disabled: !isCurrentMonth,
    onClick: (e: React.MouseEvent<HTMLElement>) =>
      handleDateSelection(e, date, dateDetails),
    periodStart,
    periodEnd,
    periodDate: dayInPeriod !== undefined,
    showSelectedDate,
  };

  return (
    <Day {...props}>
      <span>{date.format(format)}</span>
    </Day>
  );
};

export default DayButton;
