//@ts-nocheck
export function QGetFSSessionUrl() {
  let returnValue = '';
  try {
    returnValue =
      window['_fs_namespace'] &&
      window[window['_fs_namespace']] &&
      window[window['_fs_namespace']].getCurrentSessionURL
        ? window[window['_fs_namespace']].getCurrentSessionURL()
        : '';
  } catch (ex) {}
  return returnValue;
}
