import { ContentItem, Elements } from '@kentico/kontent-delivery';
import { Descriptionbybusinessrole } from './descriptionbybusinessrole';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class StatusHelpText extends ContentItem {
  public helpText!: Elements.LinkedItemsElement<Descriptionbybusinessrole>;
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'help_text') {
          return 'helpText';
        }
        if (elementName === 'task_status_id') {
          return 'taskStatusId';
        }
        return elementName;
      },
    });
  }
}
