
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class EmployerCallToAction extends ContentItem {
    public buttonText!: Elements.TextElement;
    public header!: Elements.TextElement;
    public buttonLink!: Elements.TextElement;
    public downloadableLink!: Elements.AssetsElement;
    public description!: Elements.CustomElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'button_text') {
                    return 'buttonText';
                }
                if (elementName === 'button_link') {
                    return 'buttonLink';
                }
                if (elementName === 'downloadable_link') {
                    return 'downloadableLink';
                }
                return elementName;
            })
        });
    }
}
