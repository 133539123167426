import styled from 'styled-components';
import { prefixObjectValues } from '../../../../../utils';

interface IPosition {
  top: number;
  left: number;
}

export interface IPageFold {
  id: string;
  position: {
    top: number;
    left: number;
  };
  style: React.CSSProperties;
}

const Container = styled.div<{
  position: IPosition;
}>`
  position: absolute;
  top: calc((100% / 6) * ${(p) => p.position.top});
  left: calc((100% / 7) * ${(p) => p.position.left});
  width: calc((100% / 7) * 1);
`;

const StyledPageFold = styled.div<{ elemStyle: any }>`
  content: '';
  border-width: 8px 0 0 8px;
  border-style: solid;
  border-color: #1296de #fff;
  width: 8px;
  height: 8px;
  margin-left: auto;

  ${(p) => p.elemStyle}
`;

const domIdsStatic = {
  rootNode: '-page-fold',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const PageFold = (p: IPageFold) => {
  const domIds = domIdsUnique(p.id);
  return (
    <Container id={domIds.rootNode} position={p.position}>
      <StyledPageFold elemStyle={p.style} />
    </Container>
  );
};

export default PageFold;
