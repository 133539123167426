import Button from '../../../base/button';
import { enums } from '../../../../utils';
import { useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { FieldRenderProps } from '@progress/kendo-react-form';
import styled from 'styled-components';

export const RadioButtonsGroupStyled = styled.div.attrs(
  (props: { length: number }) => ({
    length: props.length,
  }),
)`
  display: flex;
  flex-direction: ${(props) => props.length > 2 && 'column'};
  max-width: ${(props) => props.length > 2 && '500px'};
`;

export const RadioButton = styled(Button).attrs(
  (props: { length: number }) => ({
    length: props.length,
  }),
)`
  margin-bottom: 1em;
  margin-left: ${(props) => props.length > 2 && '0px'};
  margin-right: 10px;
`;

export const handleOnClick = (
  e: any,
  props: FieldRenderProps,
  data: string,
  setSelected: any,
) => {
  e.preventDefault();
  props.onChange({ value: data, target: { value: data } });
  props.onBlur();
  return setSelected(data);
};

export const onChange = (props: FieldRenderProps, selected: string | any) =>
  props.onChange({ value: selected, target: { value: selected } });

export const isButtonSelected = (data: string, props: FieldRenderProps) => {
  if (data) {
    if (data.toString() === props.value) {
      return enums.ButtonTypes.primary;
    } else {
      return enums.ButtonTypes.secondary;
    }
  } else {
    return enums.ButtonTypes.secondary;
  }
};

const RadioButtonGroup = (props: FieldRenderProps) => {
  const [selected, setSelected] = useState();

  return (
    (props?.data && (
      <RadioButtonsGroupStyled
        id={`${props.id}-group`}
        className={props?.classnames && props.classnames}
        length={parseInt(props.data.length)}
      >
        {props.data.map((value: string, index: number) => (
          <RadioButton
            id={`${props.id}-${index}`}
            key={`key-${index.toString()}`}
            length={parseInt(props.data.length)}
            buttonStyle={isButtonSelected(value, props)}
            onClick={(e: any) => handleOnClick(e, props, value, setSelected)}
            disabled={props.disabled}
          >
            {value?.toString()}
          </RadioButton>
        ))}
        <Input
          hidden
          id={`${props.id}-value`}
          name={props.name}
          value={props.value}
          onChange={() => onChange(props, selected)}
        />
      </RadioButtonsGroupStyled>
    )) ||
    null
  );
};

export default RadioButtonGroup;
