import styled from 'styled-components';
import { fonts, colors } from '../../style';
import Button from '../base/button/index';
import { Spinner } from '../base/spinner/index';
import {
  SectionTitle,
  SectionSubTitle,
  OpenSansSemiBoldLabel,
  OpenSansRegularValue,
  OpenSansRegularSmall,
  SwitchButton,
  EditButton,
} from '../base/simple-styled-components/index';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels'; // NOSONAR

const Container = styled.div`
  background-color: ${colors.white};
  max-width: 992px;
`;

const toastMessages = {
  success: 'Preferences updated successfully',
  postError:
    'There was an error updating your preferences. Please contact us to resolve this issue.',
  getError:
    'There was an error fetching your preferences.  Please contact us to resolve this issue.',
};

const StyledSpinner = styled(Spinner)`
  margin-left: auto !important;
  margin-right: auto !important;
`;

const ErrorText = styled.div`
  color: ${colors.errorRed};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 15px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  padding-bottom: 14px;
  padding-left: 24px;
`;

const PageTitle = styled(SectionTitle)`
  text-align: left;
  font-family: ${fonts.openSans.bold.fontFamily};
  font-size: 36px;
  font-weight: ${fonts.openSans.bold.fontWeight};
  padding-bottom: 32px;
`;

const SectionTitleMod = styled(SectionTitle)`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 23px;
`;

const SectionSubTitleMod = styled(SectionSubTitle)`
  padding: 0px 24px;
`;

const OpenSansSemiBoldLabelMod = styled(OpenSansSemiBoldLabel)`
  margin-bottom: 23px;
  margin-right: auto;
  width: auto;
`;

const OpenSansRegularSmallMod = styled(OpenSansRegularSmall)`
  padding-right: 24px;
`;

const CellNumberLabel = styled(OpenSansSemiBoldLabel)`
  margin-bottom: 2px;
  width: auto;
`;

const OpenSansRegularValueMod = styled(OpenSansRegularValue)`
  max-width: 560px;
  margin-top: 5px;
  @media (max-width: 992px) {
    margin-left: unset;
  }
`;

const SwitchButtonMod = styled(SwitchButton)`
  margin-top: 10px;
  margin-bottom: 24px;
  margin-left: 34px;
  @media (max-width: 992px) {
    margin-left: unset;
  }
`;

const PhoneText = styled.div`
  margin-left: 24px;
  @media (max-width: 992px) {
    margin-left: unset;
  }
`;

const EditImage = styled.img`
  margin-right: 2px;
`;

type EditModeProps = {
  editmode?: boolean;
};

const EditButtonMod = styled(EditButton)<EditModeProps>`
  background-color: ${(props) =>
    props.editmode ? colors.coolneutrald2 : colors.white};
  margin-bottom: 23px;
  margin-left: auto;
  @media (max-width: 992px) {
    margin-left: unset;
  }
`;

type StatusLabelProps = {
  active?: boolean;
};

const StatusLabel = styled(OpenSansRegularValueMod)<StatusLabelProps>`
  color: ${(props) => (props.active ? colors.skyD2 : colors.gray)};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  margin-bottom: 23px;
  margin-left: auto;
  @media (max-width: 992px) {
    margin-left: unset;
  }
`;

const EditContainer = styled.div<EditModeProps>`
  align-items: baseline;
  background-color: ${(props) =>
    props.editmode ? colors.coolneutrald2 : colors.white};
  display: flex;
  flex-wrap: wrap;
  padding: 12px 24px;
`;

const ReadOnlyContainer = styled.div`
  align-items: baseline;
  background-color: ${colors.white};
  display: flex;
  flex-wrap: wrap;
  padding: 12px 24px;
  margin-top: 5px;
`;

const CancelButton = styled(Button)`
  margin-left: auto;
  margin-right: 4px;
  margin-top: 15px;
  @media (max-width: 992px) {
    margin-right: unset;
  }
`;

const SubmitButton = styled(Button)`
  margin-left: 4px;
  margin-right: auto;
  margin-top: 15px;
  @media (max-width: 992px) {
    margin-left: unset;
  }
`;

const MutedLabel = styled.p`
  color: #657676;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 13px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  margin: 0;
`;

const AccountNumberLabel = styled.p`
  color: #031e47;
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin: 0;
`;

const RadioGroupMod = styled.div`
  color: #525252;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 15px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  line-height: 1.25;

  .k-radio {
    height: 24px;
    margin-left: 16px;
    width: 24px;
  }

  .k-radio:checked {
    background-color: #fff;
    border-color: #b9b9b9;
    color: #127cb9;
  }

  .k-radio::before {
    height: 16px;
    width: 16px;
  }

  .k-radio-label {
    margin-left: 11px;
  }
`;

const EditError = styled(Error)`
  color: ${colors.errorRed};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  text-align: left;
  &.centered {
    justify-content: center;
  }
`;

interface InputProps {
  valid?: boolean;
  regular?: string;
}

const EditInput = styled(Input)<InputProps>`
  border: ${(props) =>
    props.valid ? '1px solid #b9b9b9' : '2px solid #b30311'};
  border-radius: 6px;
  font-family: ${(props) =>
    props.regular === 'true'
      ? fonts.openSans.regular.fontFamily
      : fonts.openSans.semibold.fontFamily};
  font-weight: ${(props) =>
    fonts.ubuntu[props.regular === 'true' ? 'regular' : 'semibold'].fontWeight};
  outline: none;
  width: 265px;
`;

const HeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PrefSeparator = styled.hr`
  border-top: 1px solid #c7c8ca;
  margin: 5px 24px;
`;

export {
  Container,
  SectionTitleMod,
  SubmitButton,
  CancelButton,
  EditContainer,
  EditButtonMod,
  EditImage,
  PhoneText,
  SwitchButtonMod,
  OpenSansRegularValueMod,
  OpenSansSemiBoldLabelMod,
  MutedLabel,
  AccountNumberLabel,
  RadioGroupMod,
  ReadOnlyContainer,
  EditError,
  EditInput,
  HeaderArea,
  StyledSpinner,
  toastMessages,
  ErrorText,
  CellNumberLabel,
  SectionSubTitleMod,
  OpenSansRegularSmallMod,
  PageTitle,
  PrefSeparator,
  StatusLabel,
};
