import { useContext } from 'react';
import { DropDownListContainer } from '../index.sc';
import { get, prefixObjectValues } from '../../../utils';
import { CustomUploadFileInfo } from '../../../utils/upload-helpers';
import { PersonDataContext } from '../../../contexts/person-data-context';
import ClaimsDropDownList from '../../base/claims-drop-down-list';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';

interface UploadProps {
  setSelectedLeaveReason?: any;
  setSelectedCaseNew?: any;
  keyId?: string;
  files?: CustomUploadFileInfo[];
  uploadTried?: boolean;
}

const domIdsStatic = {
  rootNode: 'newupload',
  dropzone: 'dropzone',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const defaultOption = 'Select a claim';

const CaseSelection: (p: UploadProps, props: any) => React.ReactElement = (
  p,
) => {
  const { personData } = useContext(PersonDataContext);

  const handleCaseSelect = (e: DropDownListChangeEvent) => {
    p.setSelectedCaseNew(e.target.value.caseId);
    p.setSelectedLeaveReason(e.target.value.leaveReason);
  };

  const claims = get(personData?.cases, 'claims', []);
  const leaves = get(personData?.cases, 'leaves', []);
  const accommodations = get(personData?.cases, 'accommodations', []);

  //Combine claims and leaves
  const formattedCases = claims.concat(leaves).concat(accommodations);

  return (
    <div id={'caseSelection'} style={{ paddingTop: '26px' }}>
      <div
        style={{
          fontFamily: 'Open Sans',
          color: '#757575',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          paddingBottom: '24px',
        }}
      >
        Please select a claim to associate the uploads. After a claim is
        selected, you can select the document type.
      </div>
      <div
        style={{
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          color: '#757575',
          paddingBottom: '8px',
          lineHeight: '16px',
        }}
      >
        Claim or NTN
      </div>
      <DropDownListContainer style={{}}>
        <ClaimsDropDownList
          id={'caseSelectionDropdown'}
          caseList={formattedCases}
          defaultOption={defaultOption}
          onChange={handleCaseSelect}
          processedProps
        />
      </DropDownListContainer>
    </div>
  );
};

export default CaseSelection;
