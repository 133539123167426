import React from 'react';
import styled from 'styled-components';
import { fonts, colors } from '../../../../style';
import { NumericTextBox } from '@progress/kendo-react-inputs';

const Container = styled.div`
  display: flex;
  align-items: baseline;
  width: 50%;
  flex-wrap: nowrap;

  @media (max-width: 703px) {
    width: 100%;
  }
`;

const InitialLabel = styled.span`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  text-align: left;
  margin-right: 8px;
`;

const NumericTextBoxMod = styled(NumericTextBox)`
  width: 74px !important;
  margin-right: 16px;
`;

const inputHandle = (e: any, duration: string, onChange: Function) => {
  const target = { ...e.target, value: duration };
  const event = {
    ...e,
    target,
    value: duration,
  };
  onChange(event);
};

const formatDurationValue = (hours: number, mins: number) => {
  const opts = {
    minimumIntegerDigits: 2,
  };
  const formattedHours = (hours ?? 0).toLocaleString('en-US', opts);
  const formattedMinutes = (mins ?? 0).toLocaleString('en-US', opts);
  return `${formattedHours}:${formattedMinutes}`;
};

const DurationPicker = (p: any) => {
  const { onChange, defaultHours, defaultMins, maxHours, maxMins } = p;
  const [hoursValue, setHoursValue] = React.useState(defaultHours);
  const [minsValue, setMinsValue] = React.useState(defaultMins);

  const handleHoursChange = (e: any) => {
    let duration;
    if (e.target.value > maxHours) {
      setHoursValue(maxHours);
      duration = formatDurationValue(maxHours, minsValue);
    } else {
      setHoursValue(e.target.value);
      duration = formatDurationValue(e.target.value, minsValue);
    }

    inputHandle(e, duration, onChange);
  };

  const handleMinsChange = (e: any) => {
    let duration;
    if (e.target.value > maxMins) {
      setMinsValue(maxMins);
      duration = formatDurationValue(hoursValue, maxMins);
    } else {
      setMinsValue(e.target.value);
      duration = formatDurationValue(hoursValue, e.target.value);
    }

    inputHandle(e, duration, onChange);
  };

  return (
    <Container id="root">
      <InitialLabel>Hour(s)</InitialLabel>
      <NumericTextBoxMod
        id={p.name + '_hours'}
        onChange={(e) => handleHoursChange(e)}
        max={maxHours}
        min={0}
        placeholder="00"
        defaultValue={defaultHours}
        value={hoursValue}
      />
      <InitialLabel>Min(s)</InitialLabel>
      <NumericTextBoxMod
        id={p.name + '_mins'}
        onChange={(e) => handleMinsChange(e)}
        max={maxMins}
        min={0}
        placeholder="00"
        defaultValue={defaultMins}
        value={minsValue}
      />
    </Container>
  );
};

export default DurationPicker;
