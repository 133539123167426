import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, fonts } from '../../style';
import { enums, getFormattedHoursOfOperation } from '../../utils';
import chatCircle from '../../images/chat-circle.svg';
import phoneCircle from '../../images/phone-circle.svg';
import { KenticoDataContext } from '../../contexts/kentico-data-context';
import React from 'react';

const Container = styled.div`
  background-color: inherit;
`;

const Heading = styled.h2`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.ubuntu.light.fontFamily};
  font-size: 46px;
  font-weight: ${fonts.ubuntu.light.fontWeight};
  margin-left: 16px;
`;

const Text = styled.h5`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.ubuntu.light.fontFamily};
  font-weight: ${fonts.ubuntu.light.fontWeight};
  margin: 20px 20px 26px 16px;
`;

const Card = styled(Link)`
  display: flex;
  padding: 16px;
  margin: 16px;
  flex-direction: column;
  background-color: ${colors.white};
  padding: 24px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  width: 280px;
  height: 194px;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`;

const LiveChatCard = styled.button`
  margin: 16px;
  background-color: ${colors.white};
  border: none;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 24px;
  width: 280px;
  height: 194px;
`;

const CardHeader = styled.div`
  color: #013249;
  font-family: ${fonts.ubuntu.light.fontFamily};
  font-weight: ${fonts.ubuntu.light.fontWeight};
  font-size: 22px;
  text-align: left;
`;

const CardBody = styled.div`
  color: ${colors.boulder1};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  text-align: left;
  height: 100%;
  margin-top: 16px;
`;

const ContactUs: (p: any) => React.ReactElement = (_p) => {
  const { callCenter } = React.useContext(KenticoDataContext);

  const openLiveChat = () => {
    if ((global as any).displayLiveChat) {
      (global as any).displayLiveChat(true);
    }
  };

  const { hoost, hooet } = getFormattedHoursOfOperation(callCenter);

  return (
    <Container id="contact-us-container">
      <Heading>Contact Us</Heading>
      <Text>
        We’re happy to answer your questions. Select an option below to connect
        to one of our specialists.
      </Text>

      <div className="d-flex flex-wrap">
        <LiveChatCard id="live-chat-card" onClick={openLiveChat}>
          <CardHeader className="d-flex align-items-center">
            <img src={chatCircle} className="mr-2" alt="live chat"></img>
            <div
              className={`d-flex align-items-center live-chat-${
                callCenter.open ? 'online' : 'offline'
              }`}
            >
              Live chat
            </div>
          </CardHeader>
          <CardBody>
            {callCenter.open === false ? (
              <>
                <b>Live chat is currently offline.</b> Check back any time,
                Monday through Friday, {hoost} - {hooet}, ET to chat to a
                representative.
              </>
            ) : (
              <>
                Chat online, in real-time, with one of our representatives,
                <br />
                <b>
                  Monday through Friday,
                  <br /> {hoost} - {hooet}, ET.
                </b>
              </>
            )}
          </CardBody>
        </LiveChatCard>
        <Card to={enums.AppPaths.contactUsCallback}>
          <CardHeader className="d-flex align-items-center">
            <img
              src={phoneCircle}
              className="mr-2"
              alt="schedule a callback"
            ></img>
            Schedule a call
          </CardHeader>
          <CardBody>
            Schedule a call to speak with one of our representatives,
            <br />
            <b>
              Monday through Friday,
              <br /> {hoost} - {hooet}, ET.
            </b>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default ContactUs;
