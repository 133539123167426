import { Reveal } from '@progress/kendo-react-animation';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import {
  KenticoDataContext,
  IKenticoData,
} from '../../../contexts/kentico-data-context';
import { colors, fonts } from '../../../style';
import {
  enums,
  parseJSONOrDefault,
  getHtmlFromMarkdown,
  getLocalizedOrDefault,
  getFormattedHoursOfOperation,
} from '../../../utils';
import { getSplashConfig } from '../../../utils/remote-config-manager';
import Button from '../button';
import { convertAnchorTagsToRouterLinks } from '../../../utils/html-parsing-helpers';
import useHandlebars from '../../../hooks/use-handlebars';

dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

export const BannerContainer = styled.div<{ backgroundColor: string }>`
  flex-direction: row;
  display: flex;
  justify-content: center;
  background-color: ${(p) => p.backgroundColor};
  color: white;
  width: 100vw;
  p {
    margin: 0px;
  }
  a {
    color: ${colors.white};
    text-decoration: underline;
  }
`;

export const BannerText = styled.div`
  font-family: ${fonts.openSans.regular.fontFamily};
  font-weight: ${fonts.openSans.regular.fontWeight};
  font-size: 16px;
  margin: 20px 0px;
  max-width: 70%;
  color: white;
`;

export interface ILandingProps {
  showSplash?: boolean;
  content?: string;
}

export interface IWarning {
  message: string;
  hoursBefore: number;
  canDismiss?: boolean;
  rememberDismiss?: boolean;
  backgroundColor?: string;
  nextStartDateTime?: Date;
  stickyTop?: boolean;
}

export interface IClosure {
  message: string;
  backgroundColor: string;
}

export interface ISplashContent {
  header: string;
  paragraph: string;
  paragraphAlreadyRegistered: string;
  warnings?: [IWarning];
}

export const isOutageDismissed = (
  startDateTime?: Date,
  hoursBefore?: number,
) => {
  const json = localStorage.getItem(enums.CacheKeys.dismissedOutageWarnings);
  const list = parseJSONOrDefault(json, []);
  return list.some(
    (o: string) => o === `${startDateTime?.toISOString()}_${hoursBefore}`,
  );
};

export const dismissOutageLocal = (
  startDateTime?: Date,
  hoursBefore?: number,
) => {
  if (!startDateTime) {
    return;
  }

  const json = localStorage.getItem(enums.CacheKeys.dismissedOutageWarnings);
  const list = parseJSONOrDefault(json, []);
  list.push(`${startDateTime.toISOString()}_${hoursBefore}`);
  localStorage.setItem(
    enums.CacheKeys.dismissedOutageWarnings,
    JSON.stringify(list),
  );
};

const handleInitEffect = async (
  setWarning: React.Dispatch<React.SetStateAction<IWarning | undefined>>,
  setShowOutageWarning: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const upcomingSplash = await getSplashConfig(true);

  if (upcomingSplash.showSplash) {
    const content: ISplashContent = parseJSONOrDefault(upcomingSplash.content);

    if ((content?.warnings?.length || 0) > 0) {
      // grab the first warning in the list that matches the current hours
      // before the outage

      const applicableWarning = content?.warnings?.find((w) => {
        const startWarningTime = dayjs(upcomingSplash.nextStartDateTime).add(
          -w.hoursBefore,
          'hour',
        );

        return startWarningTime.isBefore(dayjs());
      });

      if (applicableWarning) {
        const isDismissed = isOutageDismissed(
          upcomingSplash.nextStartDateTime,
          applicableWarning.hoursBefore,
        );
        if (!isDismissed) {
          setWarning({
            ...applicableWarning,
            nextStartDateTime: upcomingSplash.nextStartDateTime,
          });
          setShowOutageWarning(true);
        }
      }
    }
  }
};

const genericClosureMessage =
  `**Live chat is currently offline.** You can <a href="${enums.AppPaths.contactUsCallback}"` +
  `data-link-type="internal">schedule a call</a> or chat with a representative at {{hoost}} ET the next business day.`;

const handleClosuresEffect = (
  setClosure: React.Dispatch<React.SetStateAction<IClosure | undefined>>,
  setShowClosureWarning: React.Dispatch<React.SetStateAction<boolean>>,
  callCenter: IKenticoData['callCenter'],
  localizedStrings: IKenticoData['localizedStrings'],
) => {
  const upcomingClosures = callCenter.closures ?? [];

  // Sort DESC
  upcomingClosures.sort((a, b) => (dayjs(a.start).isAfter(b.start) ? 1 : -1));

  // find the nearest upcoming closure
  const nc = upcomingClosures?.[0];

  let closureColor = colors.skyD2;
  let closureMessage = `${getLocalizedOrDefault(
    localizedStrings,
    'call_center_closed_banner_text',
    genericClosureMessage,
  )}`;
  let showBanner = callCenter.open === false;

  // If there are no upcoming closure, end function with default settings.
  if (!nc) {
    setClosure({
      backgroundColor: closureColor,
      message: closureMessage,
    });
    setShowClosureWarning(showBanner);
    return;
  }

  let start = dayjs(nc.start);
  let end = dayjs(nc.end);

  // If closure is set to all day, change the start and end to reflect that.
  if (nc.allDay) {
    start = dayjs(callCenter.hoursOfOperation.start)
      .set('year', start.year())
      .set('month', start.month())
      .set('date', start.date());
    end = dayjs(callCenter.hoursOfOperation.end)
      .set('year', end.year())
      .set('month', end.month())
      .set('date', end.date());
  }

  const isCurrentBanner = dayjs().isBetween(start, end, 'm', '[)');
  if (isCurrentBanner) {
    // If current time is between the start and end of the closure, show the call center closed styling and text.
    closureColor = colors.skyD2;
    closureMessage = `${nc.bannerMessage} ${getLocalizedOrDefault(
      localizedStrings,
      'call_center_closed_banner_append_text',
      'The call center is currently closed.',
    )}`;
    showBanner = true;
  } else {
    const dayBefore = dayjs(callCenter.hoursOfOperation.start)
      .set('year', start.year())
      .set('month', start.month())
      .set('date', start.date())
      .subtract(1, 'day');

    // If current time is the same or after 8am the previous day of the closure, show the future closure styling and text.
    const isDayBefore = dayjs().isBetween(dayBefore, start, 'm', '[)');

    if (isDayBefore) {
      closureColor = colors.christine;
      closureMessage =
        callCenter.open === false
          ? `${nc.bannerMessage} ${getLocalizedOrDefault(
              localizedStrings,
              'call_center_closed_banner_append_text',
              'The call center is currently closed.',
            )}`
          : nc.bannerMessage;
      showBanner = true;
    }
  }

  setClosure({
    backgroundColor: closureColor,
    message: closureMessage,
  });
  setShowClosureWarning(showBanner);
};

type BannerElementType = {
  id: string;
  backgroundColor?: string;
  message?: string;
  canDismiss?: boolean;
  onDismiss: () => void;
};

export const BannerElement: FC<BannerElementType> = ({
  id,
  backgroundColor,
  message,
  canDismiss,
  onDismiss,
}): ReactElement => {
  const { callCenter } = React.useContext(KenticoDataContext);
  const compiledMessage = useHandlebars(
    message,
    getFormattedHoursOfOperation(callCenter),
  );
  canDismiss = canDismiss ?? true;
  return (
    <BannerContainer id={id} backgroundColor={backgroundColor ?? ''}>
      <span
        className="k-icon k-i-warning align-self-center mr-2"
        style={{ fontSize: '24px' }}
      ></span>
      <BannerText>
        {convertAnchorTagsToRouterLinks(
          getHtmlFromMarkdown(compiledMessage ?? '').__html,
        )}
      </BannerText>
      {canDismiss && (
        <div style={{ position: 'absolute', right: '10px', height: '100%' }}>
          <Button
            id={`${id}-warning-dismiss-button`}
            style={{ color: 'white', height: '100%', width: '40px' }}
            onClick={onDismiss}
            buttonStyle={enums.ButtonTypes.flat}
          >
            <span
              style={{ color: 'white' }}
              className="k-icon k-i-close"
            ></span>
          </Button>
        </div>
      )}
    </BannerContainer>
  );
};

const Banner = () => {
  const [showOutageWarning, setShowOutageWarning] = React.useState(false);
  const [closure, setClosure] = React.useState<IClosure>();
  const [warning, setWarning] = React.useState<IWarning>();
  const [showClosureWarning, setShowClosureWarning] = React.useState(false);
  const { callCenter, localizedStrings } = React.useContext(KenticoDataContext);

  React.useEffect(() => {
    handleInitEffect(setWarning, setShowOutageWarning);
  }, []);

  React.useEffect(() => {
    handleClosuresEffect(
      setClosure,
      setShowClosureWarning,
      callCenter,
      localizedStrings,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callCenter.open]);

  const dismissWarning = React.useCallback(() => {
    setShowOutageWarning(false);
    if (warning?.rememberDismiss) {
      dismissOutageLocal(warning?.nextStartDateTime, warning?.hoursBefore);
    }
  }, [
    warning?.hoursBefore,
    warning?.nextStartDateTime,
    warning?.rememberDismiss,
  ]);

  return (
    <>
      <Reveal
        style={{ display: 'block' }}
        className={warning?.stickyTop ? 'sticky-top' : ''}
      >
        {showOutageWarning && (
          <BannerElement
            id="outage-banner"
            backgroundColor={warning?.backgroundColor}
            message={warning?.message}
            canDismiss={warning?.canDismiss}
            onDismiss={dismissWarning}
          />
        )}
      </Reveal>
      <Reveal style={{ display: 'block' }}>
        {showClosureWarning && (
          <>
            <BannerElement
              id="closure-banner"
              backgroundColor={closure?.backgroundColor}
              message={closure?.message}
              canDismiss={true}
              onDismiss={() => setShowClosureWarning(false)}
            />
          </>
        )}
      </Reveal>
    </>
  );
};

export default Banner;
