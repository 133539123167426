import { StepProps } from '@progress/kendo-react-layout';
import styled from 'styled-components';
import { colors } from '../../../../style';
import { enums, prefixObjectValues } from '../../../../utils';
import { Text } from '../../simple-styled-components';

export interface MobileStepperProps {
  keyId?: string;
  currentStepIndex: number;
  items: StepProps[];
  className?: string;
}

const domIdsStatic = {
  rootNode: 'mobile-stepper',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 5px;
  border: 1px solid ${colors.geyser2};
  padding: 5px;
`;

const MobileStepper: (p: MobileStepperProps) => React.ReactElement = ({
  currentStepIndex,
  items,
  keyId,
  className,
}) => {
  const domIds = domIdsUnique(keyId);

  return (
    <Container id={domIds.rootNode} className={`flex-row ${className}`}>
      <Text fontType={enums.FontTypes.semibold} fontSize={16}>
        {currentStepIndex + 1} of {items?.length}{' '}
      </Text>
      &nbsp;&nbsp;&nbsp;
      <Text fontSize={16}>{items && items[currentStepIndex]?.label}</Text>
    </Container>
  );
};

export default MobileStepper;
