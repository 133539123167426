import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import { prefixObjectValues, enums } from '../../../utils';
import {
  DropDownList as KendoDropDownList,
  DropDownListChangeEvent,
  ListItemProps,
} from '@progress/kendo-react-dropdowns';
import { ItemRenderWrapper } from '../simple-styled-components';
import dayjs from 'dayjs';
import * as dm from '../../../data-models';

type DefaultOption = string | dm.Leave | dm.Claim;

interface ClaimsDropDownListProps {
  keyId?: string;
  id?: string;
  disabled?: boolean;
  caseList: any;
  defaultOption: DefaultOption;
  viewAll?: boolean;
  onChange: (v: any) => void;
  processedProps?: boolean;
  maxWidth?: string;
  value?: any;
  size?: string;
}

interface CaseProps {
  caseId: string;
  startDate?: string;
  parentId: string;
}

const DropDownList = styled(KendoDropDownList)<any>`
  max-width: ${(p) => p.maxwidth};
  width: 100%;

  .k-dropdown-wrap {
    background-color: ${colors.white};
    border-color: ${colors.alto};
    height: 56px;
  }
  .k-input,
  option {
    align-self: center;
    color: ${colors.warmNeutralL3};
    font-family: ${fonts.openSans.regular.fontFamily};
    font-weight: ${fonts.openSans.regular.fontWeight};
  }
`;

const SubtitleStyled = styled.div`
  color: ${colors.dustygray};
  font-size: 14px;
`;

const ItemRenderWrapperMod = styled(ItemRenderWrapper)`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: space-around;
  padding: 25px 15px;
`;

const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const domIdsStatic = {
  rootNode: 'claims-drop-down-list',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const getReason = (leaveReason: string) => {
  return leaveReason === enums.PregnancyTextChanges.pregnancyBondingLiteral
    ? enums.PregnancyTextChanges.pregnancyBondingLiteralNew
    : leaveReason;
};

const formatCase = (c: any) => {
  //Extract the reason for the claim/leave/accomodation
  let leaveReason = c.reason ? c.reason : c.claimType;

  if (c.accommodationDetails && c.accommodationDetails.length > 0) {
    leaveReason = c.accommodationDetails[0].category;
  }

  leaveReason = getReason(leaveReason);

  //Get the startDate (leave) or claimIncurredDate (claim)
  const startDate = c.startDate ? c.startDate : c.disability?.claimIncurredDate;

  return { leaveReason, startDate, ...c };
};

//Make sure that each claim/leave has an associated caseid.
const formatCasesArray = (caseList: any) => {
  return caseList.map((c: any) => formatCase(c));
};

//The default item must match the correct object type, whether a string or defaulted case.
const getDefaultObject = (defaultValue: any, onChange: any) => {
  if (typeof defaultValue === 'object') {
    const formattedCase = formatCase(defaultValue);
    onChange({ target: { value: formattedCase } });
    return formattedCase;
  }

  return {
    caseId: defaultValue,
    startDate: false,
    reason: '',
  };
};

/**
 * A custom styled dropdown component used for displaying a list of claims/leaves. Displays the
 * NTN number, reason, and startDate/created date for the leave/absence.
 *
 * @param p
 */
const ClaimsDropDownList: (p: ClaimsDropDownListProps) => React.ReactElement = (
  p,
) => {
  const domIds = domIdsUnique(p.keyId);
  const { defaultOption, onChange, viewAll, value } = p;
  const [caseList, setCaseList] = React.useState([]);
  const [defaultObject, setDefaultObject] = React.useState({
    caseId: 'Select',
  });

  React.useEffect(() => {
    if (p.processedProps) {
      const formattedCases = formatCasesArray(p.caseList);

      const defaultItem = value || getDefaultObject(defaultOption, onChange);

      if (viewAll) {
        formattedCases.push(defaultItem);
      }

      setDefaultObject(defaultItem);
      setCaseList(formattedCases);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p.caseList, viewAll, p.processedProps]);

  React.useEffect(() => {
    if (value) {
      const newValue =
        caseList.find((fc: any) => fc?.caseId === value?.caseId) || value;

      setDefaultObject(newValue);
    }
  }, [value, caseList]);

  React.useEffect(() => {
    if (value) {
      const newValue =
        caseList.find((fc: any) => fc?.caseId === value?.caseId) || value;
      JSON.stringify(value) !== JSON.stringify(newValue) &&
        onChange({ target: { value: newValue } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseList]);

  return (
    <DropDownList
      id={p.id || domIds.rootNode}
      data={caseList.map((c: CaseProps) => c)}
      itemRender={(li: any, items: any) =>
        DropDownItemRender(li, items, defaultOption)
      }
      valueRender={(element: any, v: any) =>
        valueRender(element, v, defaultOption)
      }
      defaultValue={defaultObject}
      onChange={(e: DropDownListChangeEvent) => {
        if ((e.nativeEvent as any).key === 'ArrowUp') {
          e.syntheticEvent.stopPropagation();
          e.syntheticEvent.preventDefault();
        }
      }}
      onClose={onChange}
      disabled={p.disabled}
      maxwidth={p.maxWidth || '500px'}
    />
  );
};

const valueRender = (
  element: React.ReactElement<HTMLSpanElement>,
  value: any,
  defaultOption: DefaultOption,
) => {
  if (!value) {
    return element;
  }

  //Defaults
  let children = <div>{value.caseId}</div>;

  if (value.caseId !== defaultOption) {
    children = (
      <div className="d-flex w-100" style={{ width: '100%' }}>
        <div>{getReason(value.leaveReason || value.reason)}</div>
        {value.startDate && (
          <div className="pl-2" style={{ paddingRight: '35px' }}>
            ({dayjs(value.startDate).format('MM/DD/YY')})
          </div>
        )}
        <div className="ml-auto">{value.caseId}</div>
      </div>
    );
  }

  return React.cloneElement(element, { ...element.props }, children);
};

/**
 * DropDownItemRender - Renders a custom dropdown options in the caseID filter
 * @param li
 * @param itemProps
 */
const DropDownItemRender = (
  li: React.ReactElement,
  itemProps: ListItemProps,
  defaultOption: DefaultOption,
) => {
  const newLiProps = {
    ...li.props,
    style: {
      padding: 0,
      margin: 0,
      borderBottom: `1px solid ${colors.geyser}`,
    },
  };

  const data = itemProps.dataItem;

  let itemChildren = (
    <Container>
      <ItemRenderWrapperMod>
        <div>{data.caseId}</div>
      </ItemRenderWrapperMod>
    </Container>
  );

  if (itemProps.dataItem.caseId !== defaultOption) {
    itemChildren = (
      <Container>
        <ItemRenderWrapperMod>
          <div className="d-flex">
            {data.leaveReason && (
              <div className="col-7 col-md-8 pl-0 pr-2">{data.leaveReason}</div>
            )}
            {data.startDate && (
              <div className="col-5 col-md-4 pl-0" style={{ textAlign: 'end' }}>
                {dayjs(data.startDate).format('MM/DD/YY')}
              </div>
            )}
          </div>
          <SubtitleStyled className="dropdown-caseid">
            {data.caseId}
          </SubtitleStyled>
        </ItemRenderWrapperMod>
      </Container>
    );
  }

  return React.cloneElement(li, newLiProps, itemChildren);
};

export default ClaimsDropDownList;
