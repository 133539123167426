interface IIconProps {
  className: string;
}

export const LeftArrow = (p: IIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.003"
      height="16.168"
      data-name="Icons / Utility / 16px"
      className={p.className}
    >
      <path d="M6.8 1.045 7.6.25a.854.854 0 0 1 1.211 0l6.943 6.94a.854.854 0 0 1 0 1.211L8.808 15.35a.854.854 0 0 1-1.208 0l-.8-.8a.858.858 0 0 1 .014-1.225l4.3-4.1H.857A.855.855 0 0 1 0 8.37V7.227a.855.855 0 0 1 .857-.857h10.265l-4.3-4.1A.852.852 0 0 1 6.8 1.045Z" />
      <path fill="none" d="M0 .168h16v16H0z" data-name="16" />
    </svg>
  );
};

export const Eye = (p: IIconProps) => {
  return (
    <svg className={p.className} width="24" height="24">
      <path
        fill-rule="evenodd"
        d="M12 5C7 5 2.73 8.11 1 12.5 2.73 16.89 7 20 12 20s9.27-3.11 11-7.5C21.27 8.11 17 5 12 5Zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5Zm-3-5c0-1.66 1.34-3 3-3s3 1.34 3 3-1.34 3-3 3-3-1.34-3-3Z"
        clip-rule="evenodd"
      />
    </svg>
  );
};

export const Plus = (p: IIconProps) => {
  return (
    <svg className={p.className} width="25" height="24">
      <path
        fill-rule="evenodd"
        d="M10.786 0v10.286H.5v3.428h10.286V24h3.428V13.714H24.5v-3.428H14.214V0h-3.428Z"
        clip-rule="evenodd"
      />
    </svg>
  );
};

export const Info = (p: IIconProps) => {
  return (
    <svg className={p.className} width="24" height="24">
      <circle cx="12" cy="12" r="8" fill="#fff" />
      <path d="M11.365 16h1.404v-4.77h-1.404V16Zm.633-6.346c.207 0 .38-.068.521-.204a.672.672 0 0 0 .212-.504.735.735 0 0 0-.21-.546.707.707 0 0 0-.517-.208.716.716 0 0 0-.533.204.725.725 0 0 0-.202.533.712.712 0 0 0 .729.725ZM11.996 20a7.676 7.676 0 0 1-3.106-.635 8.178 8.178 0 0 1-2.537-1.718 8.165 8.165 0 0 1-1.718-2.541A7.716 7.716 0 0 1 4 11.99c0-1.103.212-2.14.636-3.111a8.205 8.205 0 0 1 1.725-2.545A8.042 8.042 0 0 1 8.9 4.623C9.867 4.208 10.9 4 12 4c1.105 0 2.144.208 3.119.623a7.955 7.955 0 0 1 2.546 1.712 8.071 8.071 0 0 1 1.709 2.55c.417.975.625 2.015.625 3.12a7.748 7.748 0 0 1-.623 3.102 8.017 8.017 0 0 1-1.711 2.53 8.272 8.272 0 0 1-2.55 1.723 7.694 7.694 0 0 1-3.12.64Z" />
    </svg>
  );
};
