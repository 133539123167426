import {
  scrollApp,
  removeSpecialCharactersFromObject,
  removeNullishValues,
} from '../../../utils';
import { models } from '../../../utils/cms-client';
import { log } from '../../../utils/log';
import { submit } from '../../../utils/form-submitter';
import {
  CustomStepProps,
  findStepIndexWithLeavePlanGraph,
  isPreviousStepsValid,
  isStepHidden,
  setConfig,
  transformFormStepsForStepper,
} from './utils';
import { generateFormContext } from './generators';
import { FormSubmitClickEvent } from '@progress/kendo-react-form';
import { IFormContext } from '../../../contexts/form-context';
import { SyntheticEvent } from 'react';
import { UseMutateFunction } from '@tanstack/react-query';
import { IAutoSave } from '../../../data-models';

export interface FinalStepOptions {
  form?: models.Form;
  formContext: IFormContext;
  setFormContext: React.Dispatch<React.SetStateAction<IFormContext>>;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IAutoSaveSubmitParams {
  mutateAutosavePost: UseMutateFunction<any, unknown, IAutoSave, unknown>;
  autosaveData: IAutoSave;
}

export interface StepSubmitOptions extends FinalStepOptions {
  currentStepIndex: number;
  event: FormSubmitClickEvent;
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>;
  setFormState: React.Dispatch<React.SetStateAction<{ [name: string]: any }>>;
  setFormStepIndex: React.Dispatch<React.SetStateAction<number>>;
  setSteps: React.Dispatch<React.SetStateAction<CustomStepProps[]>>;
  steps: CustomStepProps[];
  autosaveParams: IAutoSaveSubmitParams;
}

export interface StepPrevOptions {
  currentStepHasLeavePlanGraph: boolean;
  currentStepIndex: number;
  event: SyntheticEvent<any>;
  formContext: IFormContext;
  formSteps: models.FormStep[];
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>;
  setDisplayConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setFormStepIndex: React.Dispatch<React.SetStateAction<number>>;
  steps: CustomStepProps[];
}

export const rebuildSteps = (
  steps: CustomStepProps[],
  event: StepSubmitOptions['event'],
  form: StepSubmitOptions['form'],
  formContext: StepSubmitOptions['formContext'],
  currentStepIndex: StepSubmitOptions['currentStepIndex'],
  isValid: boolean,
) => {
  const newSteps: CustomStepProps[] = [];
  // adjustment value
  let av = 0;

  for (let i = 0; i < (form?.steps?.value.length || 0); i++) {
    // current step
    const cfs = form?.steps.value[i];

    if (!cfs) {
      continue;
    }

    // hide current value
    const hcv = isStepHidden(event, cfs, formContext);

    if (hcv) {
      av++;
      continue;
    }

    const cs = findStepperIndex(cfs, steps);

    const newStep = {
      ...transformFormStepsForStepper([cfs])[0],
      isValid: i - av === currentStepIndex ? isValid : steps[cs]?.isValid,
    };

    newSteps.push(newStep);
  }

  return newSteps;
};

export const findFormStepIndex = (
  step: CustomStepProps,
  formSteps: models.FormStep[],
) => formSteps.findIndex((s) => s?.system?.id === step?.formStepId);

export const findStepperIndex = (
  formStep: models.FormStep | undefined,
  steps: CustomStepProps[],
) => steps.findIndex((s) => s?.formStepId === formStep?.system?.id);

export const handleFinalSubmission = async (
  {
    form,
    formContext,
    setFormContext,
    setShowError,
    setSubmitted,
    setSubmitting,
  }: FinalStepOptions,
  values: {
    [name: string]: any;
  },
) => {
  setSubmitting(true);
  const result = await submit(form as models.Form, values, formContext);
  setFormContext({
    ...formContext,
    formValues: values,
    submissionResult: result,
  });
  setSubmitting(false);

  if (result === false) {
    setShowError(true);
  } else {
    setSubmitted(true);
    return true;
  }

  return false;
};

export const onStepSubmit = async ({
  currentStepIndex,
  event,
  form,
  formContext,
  setCurrentStepIndex,
  setFormContext,
  setFormState,
  setFormStepIndex,
  setShowError,
  setSteps,
  setSubmitted,
  setSubmitting,
  steps,
  autosaveParams,
}: StepSubmitOptions) => {
  log.info('onStepSubmit', event);
  const { isValid, values } = event;
  const processedValues = removeSpecialCharactersFromObject(values);
  const newSteps = rebuildSteps(
    steps,
    event,
    form,
    formContext,
    currentStepIndex,
    isValid,
  );
  setSteps(newSteps);

  // form step index with leave plan graph
  const fsiwlpg = findStepIndexWithLeavePlanGraph(form);
  const siwlpg = findStepperIndex(form?.steps?.value[fsiwlpg], newSteps);

  // set it on the formContext
  siwlpg > -1 &&
    setFormContext((c: any) =>
      Object.assign(c || {}, { stepIndexWithLeavePlanGraph: siwlpg }),
    );

  if (!isValid) {
    return;
  }

  const csi = Math.min(currentStepIndex + 1, newSteps.length - 1);
  const isLastStep = newSteps.length - 1 === currentStepIndex;
  scrollApp();
  setCurrentStepIndex(csi);
  setFormStepIndex(findFormStepIndex(newSteps[csi], form?.steps?.value || []));
  setFormState(processedValues);

  let submitted = false;

  if (
    isLastStep &&
    isPreviousStepsValid(newSteps, currentStepIndex) &&
    isValid
  ) {
    submitted = await handleFinalSubmission(
      {
        form,
        formContext,
        setFormContext,
        setShowError,
        setSubmitted,
        setSubmitting,
      },
      processedValues,
    );
  }

  if (
    Object.keys(autosaveParams.autosaveData.data).length ||
    Object.keys(values).length
  ) {
    const { data, ...others } = autosaveParams.autosaveData;
    autosaveParams.mutateAutosavePost({
      ...others,
      submitted: submitted,
      submittedTimestamp: submitted ? new Date().toISOString() : undefined,
      data: removeNullishValues({
        ...data,
        ...processedValues,
      }),
    });
  }
};

export const onPrevClick = ({
  event,
  setCurrentStepIndex,
  formContext,
  setDisplayConfirmationModal,
  currentStepHasLeavePlanGraph,
  steps,
  formSteps,
  currentStepIndex,
  setFormStepIndex,
}: StepPrevOptions) => {
  event.preventDefault();

  if (formContext?.currentLeavePlan?.planId && currentStepHasLeavePlanGraph) {
    setDisplayConfirmationModal(true);
  } else {
    // current step index
    const csi = currentStepIndex - 1;
    setCurrentStepIndex(csi);
    setFormStepIndex(findFormStepIndex(steps[csi], formSteps));
    scrollApp();
  }
};

// TODO: try removing this
export const handleUseEffectStorybook = (
  p: any,
  form: any,
  setForm: any,
  setFormSteps: any,
  setSubmitted: any,
  setShowError: any,
) => {
  if (p.form && JSON.stringify(p.form) !== JSON.stringify(form)) {
    setForm(p.form);
    setFormSteps(p.form?.steps?.value || []);
  }

  if (p?.submitted !== null && p?.submitted !== undefined) {
    setSubmitted(p.submitted);
  }

  if (p?.showError !== null && p?.showError !== undefined) {
    setShowError(p.showError);
  }
};

export const handleUseEffectInit = ({
  p,
  personData,
  employerPreferences,
  setFormContext,
  lastPath,
  setNotFound,
  setForm,
  setFormSteps,
  task,
  defaults,
  setSubmitted,
  setCurrentStepIndex,
  setFormStepIndex,
}: any) => {
  if (!p) {
    return;
  }

  // if we don't get formContext passed in, go out and get it from the API or cache
  if (!p.formContext && personData) {
    setFormContext(
      generateFormContext(personData, employerPreferences, task, defaults),
    );
  }

  setSubmitted(false);
  setCurrentStepIndex(0);
  setFormStepIndex(0);

  // if we're rendering with no form in props, go out to the CMS to get the form
  if (!p.form) {
    setConfig(
      lastPath,
      setNotFound,
      setForm,
      setFormSteps,
      p.form,
      setFormContext,
    );
  }
};

export const handleUseEffectContexts = (
  personData: any,
  employerPreferences: any,
  setFormContext: any,
  task: any,
  defaults: any,
  config: { [name: string]: string },
) => {
  personData &&
    setFormContext((fc: any) => {
      Object.assign(
        fc || {},
        generateFormContext(
          personData,
          employerPreferences,
          task,
          defaults,
          config,
        ),
      );
    });
};
