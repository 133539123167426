import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../style';
import { enums, getLocalizedOrDefault, getHtmlFromMarkdown } from '../../utils';
import { KenticoDataContext } from '../../contexts/kentico-data-context';
import { PersonDataContext } from '../../contexts/person-data-context';
import LeftArrow from '../../images/arrow-left.svg';
import {
  Title,
  Text,
  ComingSoonText,
  NoLeavesContainer,
  BlueText,
} from './index.sc';
import AbsenceHistoryWrapper from './absence-history-wrapper';
import * as dm from '../../data-models';
import NoAbsenceGraphic from '../../images/absence-calendar-graphic.svg';

const defaultText =
  'This reflects the status of your absence only and does not reflect' +
  ' the status of your pay. Absences are generally processed in 1-2' +
  ' business days for approved leave requests. If you have submitted' +
  ' an absence online and do not see it reflected on the calendar' +
  ' please check back in 1-2 business days.';

/**
 * The Absence History Component. Renders the calendar and status cards.
 */
const AbsenceHistory: () => React.ReactElement = () => {
  const { featureFlags, assets, localizedStrings } =
    React.useContext(KenticoDataContext);
  const { personData } = React.useContext(PersonDataContext);

  const showIntroduction = React.useCallback(() => {
    const leaves = personData?.cases?.leaves?.filter(
      (l: dm.Leave) => l.absenceType === 'Intermittent',
    );
    return (leaves || []).length > 0;
  }, [personData]);

  if (featureFlags.absence_history) {
    return (
      <div className="col-12 col-lg-9">
        <div>
          <Link
            id="summary-link"
            style={{ color: colors.skyD2, fontWeight: 600 }}
            to={enums.AppPaths.appRoot}
          >
            <img src={LeftArrow} alt="" style={{ verticalAlign: 'sub' }} />{' '}
            Summary
          </Link>
        </div>
        <Title className="absence-history-header">Absence History</Title>
        {showIntroduction() ? (
          <>
            <Text
              className="absence-history-noticeText"
              style={{ maxWidth: 904 }}
              dangerouslySetInnerHTML={getHtmlFromMarkdown(
                getLocalizedOrDefault(
                  localizedStrings,
                  'absence_history___notice',
                  defaultText,
                ),
              )}
            />
            <AbsenceHistoryWrapper />
          </>
        ) : (
          <div>
            <NoLeavesContainer id="no-leaves-view">
              <img
                src={
                  assets['absence-calendar-graphic.svg']?.url ||
                  NoAbsenceGraphic
                }
                alt=""
              />
              <BlueText>
                {getLocalizedOrDefault(
                  localizedStrings,
                  'absence_history___no_leaves',
                  "You'll be able to view and report absences here, once you've submitted an intermittent leave.",
                )}
              </BlueText>
            </NoLeavesContainer>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <ComingSoonText className="coming-soon-text">
        This feature is coming soon - Come back later
      </ComingSoonText>
    );
  }
};

export default AbsenceHistory;
