import React from 'react';
import {
  getContent,
  getHtmlFromMarkdown,
  getFormattedHoursOfOperation,
  prefixObjectValues,
} from '../../utils';
import { convertAnchorTagsToRouterLinks } from '../../utils/html-parsing-helpers';
import { FullScreenLoadingIndicator } from '../base/full-screen-loading-indicator';
import { PersonDataContext } from '../../contexts/person-data-context';
import { PersonData } from '../../data-models';
import styled from 'styled-components';
import { colors } from '../../style';
import { AppPaths } from '../../utils/enums';
import Handlebars from 'handlebars';
import { registerSelf } from '../../hooks/use-handlebars/helpers';
import { useHistory, Redirect } from 'react-router-dom';
import {
  IKenticoData,
  KenticoDataContext,
} from '../../contexts/kentico-data-context';

interface PageProps {
  keyId?: string;
}

const domIdsStatic = {
  rootNode: 'page',
  notFound: '404-page',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  background-color: ${colors.coolNeutralL1};
`;

const getDynamicContentForPath = async (
  setContent: Function,
  personData: PersonData | undefined,
  setIsLoading: Function,
  assets: IKenticoData['assets'],
  callCenter: IKenticoData['callCenter'],
) => {
  try {
    const hours = getFormattedHoursOfOperation(callCenter);
    const result = await getContent(window.location.pathname, 'page');
    registerSelf(Handlebars, () => '', assets);
    setContent(
      Handlebars.compile(result)({ ...personData, callCenter, ...hours }),
    );
    setIsLoading(false);
  } catch (err) {
    window.location.replace('/app/error');
  }
};

const Page: (p: PageProps) => React.ReactElement = (p) => {
  const domIds = domIdsUnique(p.keyId);
  const [content, setContent] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { assets, callCenter } = React.useContext(KenticoDataContext);
  const history = useHistory();

  const { personData } = React.useContext(PersonDataContext);

  React.useEffect(() => {
    getDynamicContentForPath(
      setContent,
      personData,
      setIsLoading,
      assets,
      callCenter,
    );
  }, [personData, history.location.pathname, assets, callCenter]);

  if (history.location.pathname === AppPaths.startClaimOld) {
    return <Redirect to={AppPaths.startClaim} />;
  }

  if (isLoading) {
    return (
      <Container id={domIds.rootNode}>
        <FullScreenLoadingIndicator />
      </Container>
    );
  }

  return (
    <Container id={domIds.rootNode}>
      {content ? (
        convertAnchorTagsToRouterLinks(getHtmlFromMarkdown(content).__html)
      ) : (
        <div id={domIds.notFound}>This is the 404 page</div>
      )}
    </Container>
  );
};

export default Page;
