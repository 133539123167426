import { useState, useEffect, useContext } from 'react';
import { prefixObjectValues, get } from '../../../utils';
import {
  Container,
  SectionTitleMod,
  EditContainer,
  SwitchButtonMod,
  OpenSansRegularValueMod,
  OpenSansSemiBoldLabelMod,
  StyledSpinner,
  toastMessages,
  SectionSubTitleMod,
  PrefSeparator,
  StatusLabel,
} from '../index.sc';
import {
  SectionSeparator,
  StyledNotification,
  ToastNotificationGroupStyle,
  ActionLink,
} from '../../base/simple-styled-components/index';
import {
  getPreferences,
  postPreferences,
} from '../../../utils/web-apis-client/index';
import * as dm from '../../../data-models/index';
import { ToastTypes } from '../../../utils/enums';
import ConfirmDialogModal from '../../base/confirm-dialog-modal';
import { PersonDataContext } from '../../../contexts/person-data-context';
import { renderToStaticMarkup } from 'react-dom/server';
import PhoneNumberModal from './phone-number-modal';
import { useQueryClient } from '@tanstack/react-query';

interface CommunicationPreferencesProps {
  keyId?: string;
}

const domIdsStatic = {
  rootNode: 'communication-preferences',
  textsSwitchButton: 'texts-switch-button',
  digitalSwitchButton: 'digital-switch-button',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const missingTextPrefs = (commsPrefs: dm.CommunicationPreference[]) => {
  const textPref = commsPrefs.find((p) => p.preferenceType === 'Texting');
  if (!textPref) {
    const defaultPref: dm.CommunicationPreference = {
      preferenceType: 'Texting',
      preferenceValue: 'Opt-Out',
      phoneNumber: { telephoneNo: '', phoneType: 'Cell' },
    };
    commsPrefs.push(defaultPref);
  }
};

//Fetch comms preferenes from external API
export const getCommsPreferences = async (
  setPrefs: Function,
  setIsLoading: Function,
  handleCompleteUpdate: Function,
) => {
  try {
    const prefs = await getPreferences();

    if (prefs && prefs.communicationPreferences) {
      missingTextPrefs(prefs.communicationPreferences);
      setPrefs(prefs);
    }

    setIsLoading(false);
  } catch (e) {
    handleCompleteUpdate(toastMessages.getError, 'error'); //show toast error if get fails
  }
};

//Update preferences through external API
export const updatePrefs = async (
  prefs: dm.CommunicationPreferences,
  handleCompleteUpdate: Function,
  setIsLoading: Function,
) => {
  try {
    setIsLoading(true);
    await postPreferences(prefs);
    handleCompleteUpdate(toastMessages.success, 'success');
    setIsLoading(false);
  } catch (e) {
    handleCompleteUpdate(toastMessages.postError, 'error');
    setIsLoading(false);
  }
};

//Update the components version of the prefs in state
export const updatePrefsInState = (
  singlePref: dm.CommunicationPreference,
  prefs: dm.CommunicationPreferences,
  setPrefs: Function,
  setData: Function,
  phoneNumber?: string,
) => {
  switch (singlePref.preferenceType) {
    case 'Texting':
      if (phoneNumber) {
        singlePref.preferenceValue = !singlePref.phoneNumber?.telephoneNo
          ? 'Opt-In'
          : singlePref.preferenceValue;
        singlePref.phoneNumber = {
          phoneType: 'Cell',
          telephoneNo: phoneNumber,
        };
      } else {
        singlePref.preferenceValue =
          singlePref.preferenceValue === 'Opt-In' ? 'Opt-Out' : 'Opt-In';
      }
      break;

    case 'Digital':
      singlePref.preferenceValue =
        singlePref.preferenceValue === 'Paperless' ? 'Paper' : 'Paperless';
      break;

    default:
      return;
  }

  prefs?.communicationPreferences?.forEach(
    (p: dm.CommunicationPreference, index: number) => {
      if (
        prefs.communicationPreferences &&
        p.preferenceType === singlePref.preferenceType
      ) {
        prefs.communicationPreferences[index] = singlePref;
      }
    },
  );

  setPrefs(prefs);
  setData(setIndividualPrefs(prefs));
};

//text descriptions for the modals that appear when a switch is clicked
//contact info is the email address, phone etc. that should be injected in
const modalDescriptions = (
  type: string | undefined,
  contactInfo: string | undefined,
) => {
  let html;
  switch (type) {
    case 'StartTexting':
      html = (
        <div>
          By activating text messages, we will send you updates and
          notifications to <strong>{contactInfo}</strong>. You can deactivate
          this preference at any time.
        </div>
      );
      break;
    case 'StopTexting':
      html = (
        <div>
          By deactivating text messages, we will no longer send you updates and
          notifications to <strong>{contactInfo}</strong>. You can reactivate
          this preference at any time.
        </div>
      );
      break;
    case 'StartLetters':
      html = (
        <div>
          By activating digital letters, you will receieve a notification via
          email whenever there is new correspondence available for you to view
          on the Total Leave Employee Portal. No correspondence will be mailed
          to you.
        </div>
      );
      break;
    case 'StopLetters':
      html = (
        <div>
          By deactivating digital letters, all of your Unum correspondence will
          be sent physically by post to <strong>{contactInfo}</strong> as well
          as being available on the Total Leave Employee Portal. You can
          reactivate this preference at any time.
        </div>
      );
      break;
    default:
      return '';
  }

  return renderToStaticMarkup(html);
};

//Format the address to be comma separated
const formatAddress = (address: any) => {
  const lineOne = get(address, 'addressLine1', '')
    ? `${get(address, 'addressLine1', '')},`
    : '';
  const lineTwo = get(address, 'addressLine2', '')
    ? ` ${get(address, 'addressLine2')},`
    : '';
  const lineThree = get(address, 'addressLine3', '')
    ? ` ${get(address, 'addressLine3')},`
    : '';
  const city = get(address, 'city', '') ? ` ${get(address, 'city')},` : '';
  const state = get(address, 'state', '') ? ` ${get(address, 'state')},` : '';
  const zip = get(address, 'postalCode', '')
    ? ` ${get(address, 'postalCode', '')}`
    : '';

  return lineOne + lineTwo + lineThree + city + state + zip;
};

interface UserData {
  textPrefs: dm.CommunicationPreference;
  digitalPrefs: dm.CommunicationPreference;
  userPhone?: string;
  userEmail?: string;
}

const setIndividualPrefs = (prefs: dm.CommunicationPreferences) => {
  const data: UserData = {
    textPrefs: {},
    digitalPrefs: {},
    userEmail: '',
    userPhone: '',
  };
  if (prefs && prefs.communicationPreferences) {
    prefs.communicationPreferences.forEach(
      (pref: dm.CommunicationPreference) => {
        if (pref.preferenceType === 'Texting') {
          data.textPrefs = pref;
          data.userPhone = pref.phoneNumber?.telephoneNo;
        }
        if (pref.preferenceType === 'Digital') {
          data.digitalPrefs = pref;
        }
        if (pref.preferenceType === 'Email' && pref.emailAddress) {
          data.userEmail = pref.emailAddress.emailAddress;
        }
      },
    );
  }
  return data;
};

const HandleModalTexts = (
  pref: dm.CommunicationPreference,
  setModalText: Function,
  setModalTitle: Function,
  setModalButton: Function,
  data: UserData,
  address: string,
) => {
  switch (pref.preferenceType) {
    case 'Texting':
      if (pref.preferenceValue === 'Opt-In') {
        setModalText(modalDescriptions('StopTexting', data.userPhone));
        setModalTitle('Deactivate text messages?');
        setModalButton('Yes, deactivate');
      } else {
        setModalText(modalDescriptions('StartTexting', data.userPhone));
        setModalTitle('Activate text messages?');
        setModalButton('Yes, activate');
      }
      break;

    case 'Digital':
      if (pref.preferenceValue === 'Paperless') {
        setModalText(modalDescriptions('StopLetters', address));
        setModalTitle('Deactivate digital letters?');
        setModalButton('Yes, deactivate');
      } else {
        setModalText(modalDescriptions('StartLetters', data.userEmail));
        setModalTitle('Activate digital letters?');
        setModalButton('Yes, activate');
      }
      break;

    default:
      return;
  }
};

export const formatPhoneNumber = (number: string) => {
  return number
    .replace(' ', '')
    .replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace(/_/g, '');
};

const loadingElement = (
  domIds: any,
  displayToast: boolean,
  toastType: ToastTypes['style'],
  closeToast: () => void,
  toastMessage: string,
) => {
  return (
    <Container id={domIds.rootNode}>
      <ToastNotificationGroupStyle
        style={{
          top: 0,
          right: 0,
          alignItems: 'flex-end',
          position: 'fixed',
        }}
      >
        {displayToast && (
          <StyledNotification
            key="success"
            type={{ style: toastType, icon: true }}
            closable={true}
            onClose={closeToast}
          >
            {toastMessage}
          </StyledNotification>
        )}
      </ToastNotificationGroupStyle>
      <SectionTitleMod>Communications</SectionTitleMod>
      <SectionSeparator />
      <StyledSpinner />
    </Container>
  );
};

const phoneModal = (
  data: UserData,
  handleConfirm: Function,
  setDisplayPhoneModal: Function,
  setPhoneNumber: Function,
) => {
  return (
    <PhoneNumberModal
      title="Update Your Mobile Number"
      description={
        data.userPhone
          ? `If you no longer want to receive text messages at ${data.userPhone}, provide us with the new number where we can send notifications.`
          : 'Please proide us with the mobile number we can send notifications to.'
      }
      confirmButtonText="Update Number"
      cancelButtonText="Cancel"
      handleConfirm={handleConfirm}
      modalCallback={() => setDisplayPhoneModal(false)}
      setNumber={setPhoneNumber}
    />
  );
};

const digitalPrefs = (
  data: UserData,
  domIds: any,
  handleUpdate: Function,
  isLoading: boolean,
) => {
  return (
    <EditContainer>
      <OpenSansSemiBoldLabelMod>
        Digital Letters
        <br />{' '}
        <OpenSansRegularValueMod>
          If active, you will recieve a notification via email to{' '}
          {data.userEmail} that a new letter from Unum is available on the Total
          Leave Employee Portal. No correspondence will be mailed to you.
        </OpenSansRegularValueMod>
      </OpenSansSemiBoldLabelMod>
      <StatusLabel active={data.digitalPrefs.preferenceValue === 'Paperless'}>
        {data.digitalPrefs.preferenceValue === 'Paperless'
          ? 'ACTIVATED'
          : 'NOT ACTIVATED'}
      </StatusLabel>
      <SwitchButtonMod
        id={domIds.digitalSwitchButton}
        checked={data.digitalPrefs.preferenceValue === 'Paperless'}
        onChange={() => handleUpdate(data.digitalPrefs)}
        disabled={isLoading}
      />
    </EditContainer>
  );
};

const textPrefs = (
  data: UserData,
  domIds: any,
  handleUpdate: Function,
  handlePhoneNumberUpdate: Function,
  isLoading: boolean,
) => {
  return (
    <EditContainer>
      <OpenSansSemiBoldLabelMod>
        Text Message
        <br />{' '}
        {data.userPhone ? (
          <OpenSansRegularValueMod>
            If active, notifications will be messaged to {data.userPhone}.{' '}
            <ActionLink
              to={'#'}
              onClick={() => handlePhoneNumberUpdate(data.textPrefs)}
            >
              Update number.
            </ActionLink>
          </OpenSansRegularValueMod>
        ) : (
          <OpenSansRegularValueMod>
            Please update your mobile number{' '}
            <ActionLink
              to={'#'}
              onClick={() => handlePhoneNumberUpdate(data.textPrefs)}
            >
              here
            </ActionLink>{' '}
            to get text notifications.
          </OpenSansRegularValueMod>
        )}
      </OpenSansSemiBoldLabelMod>
      <StatusLabel active={data.textPrefs.preferenceValue === 'Opt-In'}>
        {data.textPrefs.preferenceValue === 'Opt-In'
          ? 'ACTIVATED'
          : 'NOT ACTIVATED'}
      </StatusLabel>
      <SwitchButtonMod
        id={domIds.textsSwitchButton}
        onChange={() => handleUpdate(data.textPrefs)}
        checked={data.textPrefs.preferenceValue === 'Opt-In'}
        disabled={isLoading || !data.userPhone}
      />
    </EditContainer>
  );
};

const CommunicationPreferences: (
  p: CommunicationPreferencesProps,
) => React.ReactElement = (p) => {
  const domIds = domIdsUnique(p.keyId);
  const [isLoading, setIsLoading] = useState(true);
  const [prefs, setPrefs] = useState<dm.CommunicationPreferences>({});
  const [displayToast, setDisplayToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<ToastTypes['style']>('success');
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [displayPhoneModal, setDisplayPhoneModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalButton, setModalButton] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [updatingPref, setUpdatingPref] = useState<dm.CommunicationPreference>(
    {},
  );
  const [data, setData] = useState<UserData>({
    textPrefs: {},
    digitalPrefs: {},
  });
  const { personData } = useContext(PersonDataContext);
  const queryClient = useQueryClient();

  //once prefs are retrieved split them out into their individual prefs and get any data needed
  useEffect(() => {
    if (personData && personData.person && personData.person.addresses) {
      setAddress(formatAddress(personData.person.addresses[0]));
    }
  }, [personData]);

  //retrieve inital comms preferences, account settings link and address
  useEffect(() => {
    getCommsPreferences(setPrefs, setIsLoading, handleCompleteUpdate);
  }, []);

  useEffect(() => {
    setData(setIndividualPrefs(prefs));
  }, [prefs]);

  //set toast messages
  const handleCompleteUpdate = (message: string, type: any) => {
    setToastMessage(message);
    setToastType(type);
    setDisplayToast(true);
  };

  //modal function to handle soneone clicking yes
  const handleConfirm = (textPrefUpdate = false) => {
    setDisplayModal(false);
    setDisplayPhoneModal(false);
    updatePrefsInState(
      updatingPref,
      prefs,
      setPrefs,
      setData,
      formatPhoneNumber(phoneNumber),
    );
    if (textPrefUpdate) {
      queryClient.invalidateQueries({ queryKey: ['reminders'] });
    }
    updatePrefs(prefs, handleCompleteUpdate, setIsLoading);
    setPhoneNumber('');
  };

  const handleUpdate = (pref: dm.CommunicationPreference) => {
    HandleModalTexts(
      pref,
      setModalText,
      setModalTitle,
      setModalButton,
      data,
      address,
    );
    setUpdatingPref(pref);
    setDisplayModal(true);
  };

  const handlePhoneNumberUpdate = (pref: dm.CommunicationPreference) => {
    setUpdatingPref(pref);
    setDisplayPhoneModal(true);
  };

  const closeToast = () => {
    setDisplayToast(false);
  };

  setTimeout(() => {
    if (displayToast) {
      closeToast();
    }
  }, 4000);

  if (isLoading) {
    return loadingElement(
      domIds,
      displayToast,
      toastType,
      closeToast,
      toastMessage,
    );
  }

  return (
    <Container id={domIds.rootNode}>
      <ToastNotificationGroupStyle
        style={{
          top: 0,
          right: 0,
          alignItems: 'flex-end',
          position: 'fixed',
        }}
      >
        {displayToast && (
          <StyledNotification
            key="success"
            type={{ style: toastType, icon: true }}
            closable={true}
            onClose={closeToast}
          >
            {toastMessage}
          </StyledNotification>
        )}
      </ToastNotificationGroupStyle>
      {displayModal && (
        <ConfirmDialogModal
          title={modalTitle}
          description={modalText}
          confirmButtonText={modalButton}
          cancelButtonText="No"
          handleConfirm={handleConfirm}
          modalCallback={() => setDisplayModal(false)}
          markdown={true}
        />
      )}
      {displayPhoneModal &&
        phoneModal(data, handleConfirm, setDisplayPhoneModal, setPhoneNumber)}
      <SectionTitleMod>Communications</SectionTitleMod>
      <SectionSeparator />
      <SectionSubTitleMod>
        We will always let you know about important updates by email, you choose
        how else you want to hear from us.
      </SectionSubTitleMod>
      <EditContainer>
        <OpenSansSemiBoldLabelMod>
          Email
          <br />{' '}
          <OpenSansRegularValueMod>
            Notifications will be sent to {data.userEmail}
          </OpenSansRegularValueMod>
        </OpenSansSemiBoldLabelMod>
        <StatusLabel active>Always Activated</StatusLabel>
        <SwitchButtonMod defaultChecked={true} disabled={true} />
      </EditContainer>
      <PrefSeparator />
      {textPrefs(
        data,
        domIds,
        handleUpdate,
        handlePhoneNumberUpdate,
        isLoading,
      )}
      <PrefSeparator />
      {digitalPrefs(data, domIds, handleUpdate, isLoading)}
    </Container>
  );
};

export default CommunicationPreferences;
