import personCarry from '../../../images/person-carry.svg';
import { log } from '../../../utils/log';
import { get, isJSONParsable } from '../../../utils';
import { colors, rem } from '../../../style';
import { getSimpleRemoteConfig } from '../../../utils/remote-config-manager';

// const log = new Logger\('live-chat-event-message-handler');

interface AgentMessageProps {
  leftImageUrl?: string;
  message?: string;
  messageStyle?: any;
}

export interface BotProps {
  id: number;
  avatarUrl: string;
}

type MessageHandlerType = (p: {
  md: any;
  loadedFromTranscript: boolean;
  addNewMessage: Function;
  preChatContext: { [key: string]: string };
  setShowMessageBox: Function;
  setShowReconnect: Function;
}) => void;

export const systemMessage = {
  messageStyle: { color: colors.blue, fontSize: rem(16) },
};

export const messageConstants = {
  agent: 'AGENT',
  customer: 'CUSTOMER',
  message: 'MESSAGE',
  plainText: 'text/plain',
};
/**
 * Ends the chat session.
 */
export const disconnectFromSession = async () => {
  if (global.connectSession && global.connectSession.disconnectParticipant) {
    try {
      log.debug('Disconnecting the user...');
      await global.connectSession.disconnectParticipant();
    } catch (e) {
      log.error('Error disconnecting from session', e);
    }
  }
};

/**
 * Modify the agent message to include the agent name if available
 * @param {Object} messageObj The full message object
 * @param {String} agentDisplayName The agent name to display
 * @param {Boolean} showAgentName A value indicating whether to show the agent name
 */
export const modifyAgentMessage = (
  messageObj: AgentMessageProps,
  agentDisplayName: string | undefined,
) => {
  if (!agentDisplayName) {
    return messageObj;
  }

  const moddedMsg = JSON.parse(JSON.stringify(messageObj));
  moddedMsg.message = moddedMsg.message.replace('Agent', agentDisplayName);
  return moddedMsg;
};

const startMessage = {
  message: 'Start Chatting',
  messageStyle: { fontSize: 16, fontWeight: 700, marginBottom: '23px' },
  localizedName: 'live_chat_start_chatting',
};

const agentJoined = {
  message: "You've been connected with a specialist.",
  messageStyle: { fontSize: 16, marginBottom: '14px' },
  localizedName: 'live_chat_agent_joined',
};

const agentLeft = {
  message: 'The specialist has left the chat',
  messageStyle: { fontSize: 16, marginBottom: '20px' },
  localizedName: 'live_chat_agent_left',
};

const transferSucceeded = {
  leftImageUrl: personCarry,
  message: 'The specialist has transferred the chat',
  messageStyle: { fontSize: 16 },
  localizedName: 'live_chat_transferred',
};

const chatEnded = {
  message: 'Your chat has ended. Press "Reconnect" to start a new chat.',
  messageStyle: { fontSize: 16 },
  localizedName: 'live_chat_ended',
};

/**
 * Adds the chat message for a successful agent transfer.
 * @param p
 */
const transferSuccessHandler: MessageHandlerType = (p) => {
  const message = {
    author: { id: -1 },
    chatEventProps: modifyAgentMessage(transferSucceeded, p.md.DisplayName),
    type: 'chatEvent',
  };
  p.addNewMessage({ message, loadedFromTranscript: p.loadedFromTranscript });
};

/**
 * Adds the chat message for a successful connection to an agent, sends the pre-form results to the agent.
 * @param p
 */
const agentJoinedHandler: MessageHandlerType = (p) => {
  if (p.md.ParticipantRole === messageConstants.agent) {
    const startChatting = {
      author: { id: -1 },
      chatEventProps: startMessage,
      type: 'chatEvent',
    };
    p.addNewMessage(
      { message: startChatting, loadedFromTranscript: p.loadedFromTranscript },
      1,
    );

    const message = {
      author: { id: -1 },
      chatEventProps: agentJoined,
      type: 'chatEvent',
    };
    p.addNewMessage(
      { message, loadedFromTranscript: p.loadedFromTranscript },
      2,
    );

    Object.keys(p.preChatContext?.answers).forEach((key: any) => {
      p.addNewMessage(
        {
          message: {
            author: { id: 1 },
            text: `${key}: ${p.preChatContext.answers[key]}`,
          },
        },
        false,
      );
    });
    p.setShowMessageBox(true);
  }
};

/**
 * Adds the chat message for when the agent leaves the chat.
 * @param p
 */
const agentLeftHandler: MessageHandlerType = (p) => {
  if (p.md.ParticipantRole === messageConstants.agent) {
    const message = {
      author: { id: -1 },
      chatEventProps: modifyAgentMessage(agentLeft, p.md.DisplayName),
      type: 'chatEvent',
    };
    p.setShowMessageBox(false);

    p.addNewMessage({ message, loadedFromTranscript: p.loadedFromTranscript });
  }
};

/**
 * Adds the chat message for when chat session ends, disconnects, and enables the reconnect button.
 * @param p
 */
const chatEndedHandler: MessageHandlerType = async (p) => {
  await disconnectFromSession();
  const message = {
    author: { id: -1 },
    chatEventProps: chatEnded,
    type: 'chatEvent',
  };
  p.addNewMessage({ message, loadedFromTranscript: p.loadedFromTranscript });
  p.setShowReconnect(true);
};

export const eventMap: {
  [key: string]: any;
} = {
  'application/vnd.amazonaws.connect.event.transfer.succeeded':
    transferSuccessHandler,
  'application/vnd.amazonaws.connect.event.participant.joined':
    agentJoinedHandler,
  'application/vnd.amazonaws.connect.event.participant.left': agentLeftHandler,
  'application/vnd.amazonaws.connect.event.chat.ended': chatEndedHandler,
};

/**
 * Let's the user know the estimated wait time. The message is the time returned from the Queue Status lambda.
 * @param message
 * @param addNewMessage
 */
export const handleQueueStatusMessage = (
  message: string,
  addNewMessage: Function,
) => {
  if (isJSONParsable(message)) {
    const attribs = JSON.parse(message);
    const placeInLine = {
      author: { id: -1 },
      chatEventProps: {
        message: `You are number ${attribs.placeInLine} in line`,
        messageStyle: {
          ...systemMessage.messageStyle,
          fontWeight: '700',
          marginBottom: '21px',
        },
        showLoader: true,
        localizedName: 'live_chat_place_in_line',
        context: attribs,
      },
      type: 'chatEvent',
    };
    const minutes = attribs.waitTimeInMinutes === '1' ? 'minute' : 'minutes';

    const waitTime = {
      author: { id: -1 },
      chatEventProps: {
        message: `A specialist should be able to assist you in ${attribs.waitTimeInMinutes} ${minutes}.`,
        messageStyle: systemMessage.messageStyle,
        localizedName: 'live_chat_wait_time',
        context: attribs,
      },
      type: 'chatEvent',
    };

    addNewMessage({ message: placeInLine, loadedFromTranscript: false }, 1);
    addNewMessage({ message: waitTime, loadedFromTranscript: false });
  }
};

export const connectedMessage = (handleReconnect?: Function) => {
  const connectionFail = handleReconnect !== undefined;
  return {
    author: { id: -1 },
    chatEventProps: {
      message: connectionFail
        ? "Sorry, we couldn't connect you"
        : 'Please wait while we connect you to a specialist.',
      localizedName: connectionFail
        ? 'live_chat_problem'
        : 'live_chat_please_wait',
      messageStyle: { fontSize: 16, fontWeight: 700 },
    },
    type: 'chatEvent',
  };
};

/**
 * Special logic to handle hiding/showing initial connection status messages
 * @param {*} messages
 * @param {*} setMessages
 * @param {*} handleReconnect
 */
export const handleInitialConnectionStatusEvents = (
  messages: any,
  setMessages: Function,
  handleReconnect?: Function,
) => {
  if (messages.length > 1 && get(messages, '1.type', '') === 'preForm') {
    setMessages((prevState: any) => {
      prevState[1] = connectedMessage(handleReconnect);
      if (handleReconnect !== undefined) {
        return [
          ...prevState,
          {
            author: { id: -1 },
            chatEventProps: {},
            type: 'connectionFailed',
            handleReconnect,
          },
        ];
      } else {
        return prevState;
      }
    });
  }
};

const botResponseCopy = (obj: any) => {
  const timestamp = obj.timestamp;
  const copy = JSON.parse(JSON.stringify(obj));
  copy.timestamp = timestamp;
  return copy;
};

export const handleSystemMessage = async (
  message: any,
  newMessage: any,
  addNewMessage: Function,
) => {
  const remoteConfig = await getSimpleRemoteConfig();

  const hideChatMessagePrefix =
    remoteConfig['hide_chat_message_prefix'] || '¥¥';

  const showMessage = !message.data.Content.startsWith(hideChatMessagePrefix);
  if (showMessage) {
    newMessage = {
      author: { id: -1 },
      chatEventProps: {
        message: message.data.Content,
        messageStyle: systemMessage.messageStyle,
      },
      type: 'chatEvent',
    };
    addNewMessage({ message: newMessage, loadedFromTranscript: false });
  } else {
    const messageWithoutPrefix = message.data.Content.replace('¥¥', '');
    handleQueueStatusMessage(messageWithoutPrefix, addNewMessage);
  }
};

export const handleBotMessage = (
  bot: BotProps,
  messageId: string,
  messageContent: string | undefined,
  messageTimestamp: string,
  addNewMessage: Function,
  loadedFromTranscript: boolean,
) => {
  let model = null;
  const botResponse: any = {
    author: bot,
    id: messageId,
    text: messageContent,
    timestamp: new Date(messageTimestamp),
  };

  try {
    model = JSON.parse(messageContent ?? '');
  } catch {
    if (
      ['one sec...', '"one sec..."'].includes(botResponse.text.toLowerCase())
    ) {
      if (loadedFromTranscript) {
        return; // bail we don't want to show this when loading a prior transcript
      } else {
        botResponse.typing = true;
      }
    }
    botResponse.text = botResponse.text.replace(/(\n)/gm, '<br>');
    addNewMessage({ message: botResponse, loadedFromTranscript });
    return;
  }

  const newMessages = [];

  for (let i = 0; i < get(model, 'responses.length', 0); i++) {
    const response = model.responses[i];
    const newMessage = botResponseCopy(botResponse);

    if (response.type === 'markdown' || response.type === 'text') {
      newMessage.text = response.message;
      newMessages.push(newMessage);
    } else if (
      get(response, 'element', '') === 'loadingIndicator' &&
      !loadedFromTranscript
    ) {
      newMessage.typing = true;
      newMessages.push(newMessage);
    }
  }

  newMessages.forEach((m) =>
    addNewMessage({ message: m, loadedFromTranscript }),
  );
};
