import React from 'react';
import styled from 'styled-components';
import { KenticoDataContext } from '../../contexts/kentico-data-context';
import { PersonDataContext } from '../../contexts/person-data-context';
import { Link } from 'react-router-dom';

const LargeHeader = styled.h1`
  font-family: 'Ubuntu', sans-serif;
  font-size: 2.875rem;
  color: #525252;
  font-weight: 300;
`;

const MediumHeader = styled.h2`
  font-family: 'Ubuntu', sans-serif;
  font-size: 2.125rem;
  color: #525252;
  font-weight: 300;
`;

const SmallHeader = styled.h3`
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.75rem;
  color: #525252;
  font-weight: 300;
`;

const RegularText = styled.p`
  font-size: 1.25rem;
  color: #525252;
`;

const MiniStep = styled.p`
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.125rem;
  color: #99bad4;
  margin: 0 0 5px 0;
`;

const CallToAction = styled.div`
  width: 80%;
  padding: 3rem;
  border-radius: 8px;
  display: flex;
  box-shadow: 0 2px 16px rgba(46, 46, 46, 0.15);
  margin: 2rem auto;

  @media only screen and (max-width: 1000px) {
    display: block;
    width: 100%;
    margin: 15px 0 0 0;
  }
`;

const Prompt = styled.div`
  width: 60%;
  align-items: center;
  display: flex;

  @media only screen and (max-width: 1000px) {
    display: block;
    width: 100%;
    margin: 15px 0 0 0;
  }
`;

const PromptText = styled.p`
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
  font-size: 1.375rem;
  color: #525252;
  margin: 0;
`;

const HeroImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 550px;
  display: block;
  margin: 0 auto;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

const BlueBar = styled.hr`
  border-style: none;
  height: 4px;
  width: 80px;
  color: #99bad4;
  background: #99bad4;
  margin: 20px 0 15px 0;
`;

const ButtonsContainer = styled.div`
  width: 40%;
  display: block;
  margin: 15px 0 0 0;

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const OutlineButton = styled(Link)`
  display: inline-block;
  width: 40%;
  margin: 0 4%;
  border: solid 1px #037cb7;
  padding: 0.8rem;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  background: #fff;
  color: #037cb7;
  transition: all linear 0.4s;
  text-decoration: none;

  &:hover {
    color: #fff;
    background: #037cb7;
    text-decoration: none;
  }

  @media only screen and (max-width: 1000px) {
    display: block;
    width: 100%;
    margin: 15px 0 0 0;
  }
`;

const BlueButton = styled(OutlineButton)`
  color: #fff;
  background: #037cb7;

  &:hover {
    color: #037cb7;
    background: #fff;
    text-decoration: none;
  }
`;

const SectionBlock = styled.div`
  background: #fff;
  padding: 3rem 0;

  &.blue {
    background: #f6f8fa;
  }

  @media only screen and (max-width: 1000px) {
    padding: 2rem 0;
  }
`;

const Side = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1000px) {
    display: block;
    width: 100%;
  }
`;

const SectionContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  padding: 0 25px;

  @media only screen and (max-width: 1000px) {
    display: block;
  }
`;

const NoClaimsView = () => {
  const { personData } = React.useContext(PersonDataContext);
  const { assets } = React.useContext(KenticoDataContext);
  return (
    <div id="no-claims-view">
      <style>
        {`
            #app-root-node main { padding: 0; }
            #footer-container { background: #fff; }
            #menu-container { padding-bottom: 0; }
            #menu-container .home-dynamic-content { margin-bottom: 0; }
            .home-dynamic-content { margin-bottom: 0 !important; max-width: 1280px; }
       `}
      </style>
      <div>
        <SectionBlock className="full-width blue">
          <SectionContent>
            <Side className="left">
              <div className="content-center">
                <LargeHeader>
                  Welcome, {personData?.person?.firstName}.
                </LargeHeader>
                <RegularText>
                  We know dealing with leave can be complicated and confusing at
                  times, so let us guide you through it, while we do all the
                  hard work.
                </RegularText>
              </div>
            </Side>
            <Side className="right">
              <HeroImage alt="" src={assets['home1.svg']?.url} />
            </Side>
          </SectionContent>
        </SectionBlock>

        <SectionBlock className="full-width">
          <SectionContent>
            <MediumHeader>
              Not sure how this works? We've made it easy for you ...
            </MediumHeader>
          </SectionContent>
        </SectionBlock>

        <SectionBlock className="full-width">
          <SectionContent>
            <Side className="left">
              <HeroImage alt="" src={assets['home2.svg']?.url} />
            </Side>
            <Side className="right">
              <div className="content-center">
                <MiniStep>Step 1</MiniStep>
                <SmallHeader>We help you file your claim online</SmallHeader>
                <BlueBar />
                <RegularText>
                  You'll be guided through a few simple questions about your
                  event, electronically sign your documents and submit your
                  claim for review, all in less than 10 minutes.
                </RegularText>
              </div>
            </Side>
          </SectionContent>
        </SectionBlock>

        <SectionBlock className="full-width">
          <SectionContent>
            <Side className="left">
              <div className="content-center">
                <MiniStep>Step 2</MiniStep>
                <SmallHeader>Our specialists review your claim</SmallHeader>
                <BlueBar />
                <RegularText>
                  Once submitted, Total Leaves' specialists will review your
                  event to match it to any possible benefits that may apply to
                  your claim, before making a decision.
                </RegularText>
              </div>
            </Side>
            <Side className="right">
              <HeroImage alt="" src={assets['home3.svg']?.url} />
            </Side>
          </SectionContent>
        </SectionBlock>

        <SectionBlock className="full-width">
          <SectionContent>
            <Side className="left">
              <HeroImage alt="" src={assets['home4.svg']?.url} />
            </Side>
            <Side className="right">
              <div className="content-center">
                <MiniStep>Step 3</MiniStep>
                <SmallHeader>We'll contact you about your claim</SmallHeader>
                <BlueBar />
                <RegularText>
                  We will contact you after we review your submission to provide
                  you with an update on the next steps in the process.
                </RegularText>
              </div>
            </Side>
          </SectionContent>
        </SectionBlock>

        <SectionBlock className="full-width">
          <SectionContent>
            <CallToAction>
              <Prompt>
                <PromptText>What would you like to do next?</PromptText>
              </Prompt>
              <ButtonsContainer>
                <OutlineButton to="/app/contact-us">Chat with us</OutlineButton>
                <BlueButton to="/app/start">Get started</BlueButton>
              </ButtonsContainer>
            </CallToAction>
          </SectionContent>
        </SectionBlock>
      </div>
    </div>
  );
};

export default NoClaimsView;
