import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import * as dm from '../../../data-models';
import DetailCard from './detail-card';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import {
  get,
  enums,
  analyticsTrackEvent,
  translateLeaveStatuses,
  getRelatedTask,
} from '../../../utils';
import AccordionCard from '../../base/accordion-card';
import { StatusFlag } from '../../base/claim-card/card-items/shared-styles';
import BlueChevron from '../../../images/leave-calendar/small-blue-chevron.svg';
import { CustomLink, CardButton } from '../../base/simple-styled-components';
import { UpdateLeavePopover } from '../../base/claim-card/card-items/leave-item';
import { PreferencesContext } from '../../../contexts/preferences-context';
import { PersonDataContext } from '../../../contexts/person-data-context';
import { isDueTask } from '../../tasks/utils';
import { useQuery } from '@tanstack/react-query';
import { getTasksWithKontent } from '../../tasks/container/api';
import { EmployerTaskInfo } from '../../tasks/employer';

dayjs.extend(minMax);

const dtFormat = 'MMM DD, YYYY';

interface DetailViewProps {
  keyId?: string;
  benefits?: {
    jobProtectionBenefits?: dm.Calendar[];
    incomeProtectionBenefits?: dm.Calendar[];
  };
  visualType: 'intake' | 'continuous';
  leaves?: dm.Leave[];
  claims?: dm.Claim[];
  legend?: { [key: string]: any };
}

const Container = styled.div`
  background-color: ${colors.white};
  padding: 0;
`;

const JobProtectionDetail: (p: {
  details: dm.JobProtectedBenefit[];
}) => React.ReactElement | null = (p) => {
  let description = 'No potential job protection benefits were found.';

  if (p.details.length > 0) {
    const earliestStart = dayjs.min(p.details.map((d) => dayjs(d.startDate)));
    const latestEnd = dayjs.max(p.details.map((d) => dayjs(d.endDate)));

    description = `**Job protected leave may be available to you** between ${earliestStart?.format(
      'MMM DD',
    )} and ${latestEnd?.format(dtFormat)}.`;
  }

  return (
    <DetailCard
      idPrefix="jp"
      header="Job Protection"
      description={description}
      benefits={p.details}
      index={1}
      onExpandCallback={() =>
        analyticsTrackEvent(enums.AnalyticsEvents.lpvJobProtectionExpanded)
      }
    />
  );
};

const IncomeProtectionDetail: (p: {
  details: dm.Calendar[];
}) => React.ReactElement | null = (p) => {
  const percentages = p.details.filter(
    (d: any) => d.legendKey === 'potentialPaidBenefits',
  );
  const benefits = p.details.filter((d: any) => d.legendKey === 'paidBenefits');

  if (percentages.length === 0) {
    return null;
  }

  let description = '';
  if (
    percentages.length === 1 &&
    percentages[0].meta?.percentage &&
    percentages[0].meta.percentage !== 0
  ) {
    const first = percentages[0];
    description = `**You may receive up to ${first?.meta?.percentage}% of your pay**, subject to applicable deductions (taxes, other income, etc.).`;
  } else {
    const earliestStart = dayjs.min(
      percentages.map((d: any) => dayjs(d.startDate)),
    );
    description = `**You may receive a percentage of your earnings** starting ${dayjs(
      earliestStart,
    ).format(dtFormat)}.`;
  }

  return (
    <DetailCard
      idPrefix="pl"
      header="Income Protection"
      description={description}
      benefits={benefits}
      index={2}
      onExpandCallback={() =>
        analyticsTrackEvent(enums.AnalyticsEvents.lpvPaidLeaveExpanded)
      }
    />
  );
};

const Subtitle = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 20px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  margin-bottom: 15px;
`;

const ListText = styled.div`
  color: ${colors.boulder1};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

const ListItemContainer = styled.div`
  // border-bottom: 1px dashed ${colors.alto};
  padding-bottom: 16px;
  margin-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px 0;

  &:not(:last-child) {
    border-bottom: 1px dashed ${colors.alto2};
  }
`;

const FooterContainer = styled.div`
  background-color: ${colors.alabaster};
  display: flex;
  justify-content: end;
  padding: 16px 31px;
  width: 100%;
  border-color: ${colors.alto2};
  border-width: 1px;
  border-style: none solid solid;
  border-radius: 0 0 8px 8px;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 16px;
  }
`;

const OutlineComboButton = styled.button`
  border: 1px solid ${colors.denim1};
  background-color: #fff;
  border-radius: 16px;
  color: ${colors.denim1};
  font-size: 14px;
  padding: 5px 25px;
  display: flex;
  align-items: center;
  gap: 0 5px;
  max-width: 128px;
  justify-content: center;

  &:hover {
    background-color: #fff;
    color: ${colors.denim1};
  }
`;

const CustomButtonMod = styled(CardButton)`
  padding: 6px 15px;
  max-width: 156px;
  width: 100%;
`;

const Icon = styled.img<{ menuOpen: boolean }>`
  transform: ${(p) => (p.menuOpen ? 'rotate(180deg)' : 0)};
`;

const ExpandedContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px 15px;
`;

const ExpandedListItem = styled.div`
  &:not(:last-child) {
    border-bottom: 1px dashed ${colors.alto2};
    padding-bottom: 18px;
    margin-bottom: 18px;
  }
`;

const dateformat = 'MM/DD/YYYY';
const benefitsAccordian = (p: any, benefit: any, i: number) => {
  return (
    <div>
      <ListItemContainer
        key={`jp-benefit-${i}`}
        className="col-12 d-flex flex-row"
      >
        <ListText className="col-1 p-0 font-weight-bold">{'TYPE'}</ListText>
        <ListText className="col-3 p-0">
          {benefit?.meta?.leavePlanName ?? 'Unum Short Term Disability'}
        </ListText>
        <ListText className="col-1 p-0 font-weight-bold">{'FROM'}</ListText>
        <ListText className="col p-0">
          {dayjs(benefit?.startDate).format(dateformat)}
        </ListText>
        <ListText className="col-1 p-0 pr-2 text-left text-md-right font-weight-bold">
          {'TO'}
        </ListText>
        <ListText className="col p-0">
          {dayjs(benefit?.endDate).format(dateformat)}
        </ListText>
        <ListText className="col-1 p-0 font-weight-bold">{'STATUS:'}</ListText>
        <ListText className="col p-0 pl-2">
          {p.legend?.[benefit?.legendKey]?.statusName}
        </ListText>
      </ListItemContainer>
    </div>
  );
};

const BenefitDetail = (p: any) => {
  if (
    p.benefits?.jobProtectionBenefits?.length === 0 &&
    p.benefits?.incomeProtectionBenefits?.length === 0
  ) {
    return (
      <div>
        <ListText>No benefits were found for this leave request.</ListText>
      </div>
    );
  }
  return (
    <div>
      {p.benefits?.jobProtectionBenefits?.length > 0 && (
        <div className="d-flex flex-column">
          <AccordionCard
            idPrefix="benefitsJP"
            index={2}
            titleString="Job Protection"
            expandedElement={
              <div>
                {p.benefits?.jobProtectionBenefits?.map?.(
                  (benefit: any, i: number) => benefitsAccordian(p, benefit, i),
                )}
              </div>
            }
          ></AccordionCard>
        </div>
      )}
      {p.benefits?.incomeProtectionBenefits?.length > 0 && (
        <div className="d-flex flex-column">
          <AccordionCard
            idPrefix="benefitsIP"
            index={2}
            titleString="Income Protection"
            expandedElement={
              <div>
                {p.benefits?.incomeProtectionBenefits?.map?.(
                  (benefit: any, i: number) => benefitsAccordian(p, benefit, i),
                )}
              </div>
            }
          ></AccordionCard>
        </div>
      )}
    </div>
  );
};

const LeaveExpanded = (p: {
  leaves?: dm.Leave[];
  erTasks?: Array<dm.Task>;
}) => {
  return (
    <>
      {p.leaves?.map?.((leave, i) => {
        const task = p.erTasks
          ? getRelatedTask(leave.leaveId, p.erTasks)
          : null;
        return (
          <>
            <ExpandedListItem key={`leave-item-${i}`}>
              <Subtitle>{leave?.reason}</Subtitle>
              <ExpandedContainer>
                <ListText>EVENT ID: {leave?.leaveId}</ListText>
                <ListText>{`PERIOD: ${dayjs(leave?.startDate).format(
                  dateformat,
                )} - ${dayjs(leave?.endDate).format(dateformat)}`}</ListText>
                <ListText>{`STATUS: ${translateLeaveStatuses(
                  leave?.requestStatus,
                )}`}</ListText>
                <EmployerTaskInfo
                  show={task && isDueTask(task.taskStatusId) ? true : false}
                />
              </ExpandedContainer>
            </ExpandedListItem>
          </>
        );
      })}
    </>
  );
};

const ClaimExpanded = (p: {
  claims?: dm.Claim[];
  erTasks?: Array<dm.Task>;
}) => {
  return (
    <>
      {p.claims?.map?.((claim, i) => {
        const task = p.erTasks
          ? getRelatedTask(claim.claimId, p.erTasks)
          : null;
        return (
          <ExpandedListItem key={`claim-item-${i}`}>
            <Subtitle>{claim?.claimType}</Subtitle>
            <ExpandedContainer>
              <ListText>EVENT ID: {claim?.claimId}</ListText>
              <ListText>
                CREATED: {dayjs(claim?.notificationDate).format(dateformat)}
              </ListText>
              <ListText>STATUS: {claim?.status}</ListText>
              <EmployerTaskInfo
                show={task && isDueTask(task.taskStatusId) ? true : false}
              />
            </ExpandedContainer>
          </ExpandedListItem>
        );
      })}
    </>
  );
};

const Footer = (p: dm.Leave) => {
  const [showPopover, setShowPopover] = React.useState(false);
  const anchor = React.useRef<HTMLButtonElement | null>(null);
  const { employerPreferences } = React.useContext(PreferencesContext);

  const togglePopover = (show: boolean) => {
    setShowPopover(show);
  };

  return (
    <FooterContainer>
      <OutlineComboButton
        ref={anchor}
        onClick={() => togglePopover(!showPopover)}
        onBlur={() => togglePopover(false)}
      >
        Update <Icon menuOpen={showPopover} src={BlueChevron} alt="" />
      </OutlineComboButton>
      <CustomLink
        className="m-0"
        to={{
          pathname: `${enums.AppPaths.form}/extension-request`,
          state: {
            task: {
              caseId: get(p, 'leaveId', null),
            },
          },
        }}
      >
        <CustomButtonMod>Request Extension</CustomButtonMod>
      </CustomLink>
      <UpdateLeavePopover
        leave={p}
        showPopover={showPopover}
        anchor={anchor}
        employerPreferences={employerPreferences}
      />
    </FooterContainer>
  );
};

const getOverallStatus = (leaves: dm.Leave[], field: string) => {
  const firstStatus = get(leaves, `0.${field}`);

  const isAllSameStatus = leaves.every((l) => get(l, field) === firstStatus);

  if (firstStatus && isAllSameStatus) {
    return firstStatus;
  } else if (!firstStatus && isAllSameStatus) {
    return undefined;
  } else {
    return 'Mixed Decision';
  }
};

const getERTasks = async (customerNumber: string) => {
  const tasks = await getTasksWithKontent(customerNumber);
  return tasks.filter((t) => t.assigneeId === 1 && isDueTask(t.taskStatusId));
};

const DetailView: (p: DetailViewProps) => React.ReactElement = (p) => {
  const { benefits } = p;
  const { personData } = React.useContext(PersonDataContext);

  const { data: erTasks } = useQuery(
    ['getTasksWithKontent - all', personData?.person?.customerNumber, 0],
    () => getERTasks(personData!.person!.customerNumber!),
  );

  if (p.visualType === 'continuous') {
    const leaveStatus = getOverallStatus(p?.leaves ?? [], 'requestStatus');
    const claimStatus = getOverallStatus(p?.claims ?? [], 'status');
    return (
      <div>
        {p.leaves && p.leaves.length > 0 && (
          <AccordionCard
            idPrefix="leave"
            index={0}
            titleString="Leave"
            statusElement={
              <StatusFlag
                color={translateLeaveStatuses(leaveStatus ?? 'None')
                  .replace(/ /g, '')
                  .toLowerCase()}
              >
                {translateLeaveStatuses(leaveStatus)}
              </StatusFlag>
            }
            expandedElement={
              <LeaveExpanded leaves={p.leaves} erTasks={erTasks} />
            }
            footerElement={<Footer {...p.leaves[0]} />}
          />
        )}
        {p.claims && p.claims.length > 0 && (
          <AccordionCard
            idPrefix="claim"
            index={1}
            titleString="Claim"
            statusElement={
              <StatusFlag
                color={(claimStatus ?? 'None').replace(/ /g, '').toLowerCase()}
              >
                {claimStatus ?? 'N/A'}
              </StatusFlag>
            }
            expandedElement={
              <ClaimExpanded claims={p.claims} erTasks={erTasks} />
            }
          />
        )}
        <AccordionCard
          idPrefix="benefits"
          index={2}
          titleString="Benefits"
          expandedElement={
            <BenefitDetail benefits={benefits} legend={p.legend} />
          }
        />
      </div>
    );
  }
  return (
    <Container id="lpv-detail-view-container">
      <JobProtectionDetail details={benefits?.jobProtectionBenefits ?? []} />
      <IncomeProtectionDetail
        details={benefits?.incomeProtectionBenefits ?? []}
      />
    </Container>
  );
};

export default DetailView;
