import FadeLoader from 'react-spinners/FadeLoader';
import styled from 'styled-components';
import { colors, fonts, rem } from '../../../style';
import { loadingScreenTestData as td } from '../../../testing/loading-screen';

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  rootNode: 'loading-screen-root-node',
};

/*******************************************************************************
 * styles / styled components
 ******************************************************************************/

const Container = styled.div`
  background-color: rgba(250, 250, 250, 0.9);
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
`;

const Description = styled.div`
  color: ${colors.blueD1};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(20)};
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

/*******************************************************************************
 * LoadingScreen component
 * @param {LoadingScreenProps} props LoadingScreen propTypes
 ******************************************************************************/
const LoadingScreen: (p: LoadingScreenProps) => React.ReactElement = (
  props,
) => {
  return (
    <Container
      id={domIdsStatic.rootNode}
      className="d-flex flex-column justify-content-center"
    >
      <div className="d-flex" style={{ flexGrow: 1 }} />
      <div className="d-flex justify-content-center">
        <img
          src={props.unumLogo || td.unumLogo}
          alt="Unum"
          style={{
            transform: 'scale(2)',
          }}
        />
      </div>
      <div className="d-flex justify-content-center mt-3">
        <hr
          style={{
            width: '50px',
            border: '0',
            borderTop: `4px solid ${colors.lightBlueGray}`,
          }}
        />
      </div>
      <div className="d-flex justify-content-center mb-5">
        <Description>Loading your experience</Description>
      </div>
      <div className="d-flex justify-content-center mb-3">
        <FadeLoader
          height={15}
          width={5}
          radius={2}
          margin={2}
          color={colors.primaryBlue}
        />
      </div>
      <div className="d-flex" style={{ flexGrow: 1 }} />
      <img
        className="d-flex d-sm-none"
        src={props.bottomImage || td.bottomImage}
        alt="background"
        style={{
          position: 'fixed',
          bottom: '0',
          left: '0',
          minWidth: '100vw',
          height: 'auto',
        }}
      />
      <div className="d-none d-sm-flex justify-content-center">
        <img
          src={props.bottomImageDesktop || td.bottomImageDesktop}
          alt="background"
        />
      </div>
    </Container>
  );
};

/*******************************************************************************
 * prop types and defaults
 ******************************************************************************/
interface LoadingScreenProps {
  unumLogo?: string;
  bottomImage?: string;
  bottomImageDesktop?: string;
}

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { LoadingScreen };
