import { postDraftClaim, putDraftClaim } from '../../web-apis-client';
import {
  generateDraftAnswersFromForm,
  removeBlankOrNullProperties,
} from '../utils';
import { DraftClaim } from '../../../data-models';
import { IFormOptions } from '../../../kontent-models';
import Handlebars from 'handlebars';
import * as hbh from '../../../hooks/use-handlebars/helpers';
import { log } from '../../log';
import { getCalendarDetails } from '../../calendars';

// const log = new Logger\('form-submitter-draft-claim');

export const getFieldValues = (
  fvg: (key: string) => any,
  fieldList?: string[],
) => {
  // return object
  const r: any = {};

  fieldList?.forEach((fieldName) => {
    // field value getter result
    let fvgr = fvg(fieldName);

    if (typeof fvgr === 'string') {
      // just handle newlines for now, tried JSON.stringify()
      // but other issues arise with that approach
      fvgr = fvgr.replace(/\n/g, '\\n');
    }

    r[fieldName] = fvgr;
  });

  return r;
};

export const generatePlan = async (
  formValueGetter: (id: string) => any,
  formOptionsTemplate: string,
) => {
  const initialOptions: IFormOptions = JSON.parse(formOptionsTemplate);
  log.debug({ initialOptions });

  // get the other field values based on the draftFields list
  hbh.registerSelf(Handlebars, formValueGetter);
  const formResult = getFieldValues(
    formValueGetter,
    initialOptions.draftFields,
  );
  log.debug({ formResult });

  // activate the template
  const activatedOptions = Handlebars.compile(formOptionsTemplate)(formResult);
  log.debug({ activatedOptions });

  // build an answer object based on the formResult and the options.draftAnswerMap
  const finalOptions: IFormOptions = JSON.parse(activatedOptions);
  log.debug({ finalOptions });

  const finalAnswers = removeBlankOrNullProperties(
    finalOptions.draftAnswerMap as any,
  );

  // convert to array of answers
  const dcAnswersArray = generateDraftAnswersFromForm(
    finalAnswers,
    finalOptions.draftQuestionMap as any,
  );
  log.info({ dcAnswersArray });

  const finalDraftObject: DraftClaim = {
    answers: dcAnswersArray,
    mode: 'reset',
  };

  // await postDraftClaim(answers)
  const dcResult = await postDraftClaim(finalDraftObject);
  log.info({ dcResult });

  const draftClaim: DraftClaim = (await putDraftClaim()) as DraftClaim;
  log.info({ draftClaim });

  const calendarData = await getCalendarDetails(
    draftClaim.startDate,
    draftClaim.endDate,
    [],
    draftClaim.planId,
    true,
  );
  log.info({ calendarData });

  // return plan
  return { draftClaim, calendarData };
};
