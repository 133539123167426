
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Task extends ContentItem {
    public customTask!: Elements.MultipleChoiceElement;
    public description!: Elements.TextElement;
    public action!: Elements.MultipleChoiceElement;
    public form!: Elements.TextElement;
    public type!: Elements.TextElement;
    public name!: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'custom_task') {
                    return 'customTask';
                }
                return elementName;
            })
        });
    }
}
