import { ISubmitOptions } from '..';
import { enums, get } from '../..';
import * as dm from '../../../data-models';
import { IFormContext } from '../../../contexts/form-context';
import { log } from '../../log';
import { models } from '../../cms-client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { SignerResult } from '../../../components/forms/form-field/signer';
// const log = new Logger\('form-submitter-utils');
dayjs.extend(utc);
dayjs.extend(timezone);

export const possibleCaseIdPaths = [
  'chosenClaim.caseid',
  'chosenClaim.caseId',
  'caseid',
  'caseId',
];

export const getFieldByName = (form: models.Form, fieldName: string) => {
  let foundField: models.FormField | undefined;

  try {
    // step
    for (const s of form.steps.value) {
      // field
      for (const f of s.sections.value) {
        // only look at form fields
        if (f.system.type === enums.FormSectionTypes.FormField) {
          const p = JSON.parse(f.propsTemplate.value);

          if (fieldName === p.name) {
            foundField = f as models.FormField;
          }
        }
      }
    }
  } catch (e) {
    log.error(e);
  }

  return foundField;
};

export const getFieldTypeByName = (form: models.Form, fieldName: string) => {
  const f = getFieldByName(form, fieldName);
  return f?.formFieldTypes.value[0].name || 'Unknown';
};

export const getFieldLabelByName = (form: models.Form, fieldName: string) => {
  const f = getFieldByName(form, fieldName);
  return f?.label.value;
};

export const generateDraftAnswersFromForm = (
  draftClaimAnswers: { [key: string]: any },
  answerQuestionMap: { [key: string]: string },
) => {
  return Object.keys(draftClaimAnswers).map((a) => {
    const questionText = answerQuestionMap[a],
      fieldName = a,
      answerText = draftClaimAnswers[a];

    return {
      fieldName,
      questionText,
      answerText,
    };
  });
};

export const generateAttribute = (
  name: string,
  value: any,
  valueType?: string,
) => {
  const r: dm.Attribute = {
    attributeName: name,
    attributeValue: value,
  };

  if (valueType) {
    r.attributeValueType = valueType;
  }

  return r;
};

export interface IPMFormResultAuth {
  signedLegalNotice: boolean;
  medicalAuthSignature: SignerResult;
  authorizeOthers: string;
  authorizedPerson1Name?: string;
  authorizedPerson1Relationship?: string;
  authorizedPerson1Phone?: string;
  authorizedPerson1DoNotShare?: string;
}

export const getCaseId = (
  formResult: ISubmitOptions['formResult'],
  formContext: ISubmitOptions['formContext'],
) => {
  let id = '';

  // try to find it from the form result first
  const possiblePath = possibleCaseIdPaths.find(
    (p) => get(formResult, p, '').length > 0,
  );

  if (possiblePath) {
    id = get(formResult, possiblePath, '');
  }

  // if that fails, look at the claims in the context
  if (id.length === 0) {
    const claimList: IFormContext['claimList'] = get(
      formContext,
      'claimList',
      [],
    )?.filter((c: dm.Claim | dm.Leave) => c.status !== enums.CaseStatus.closed);

    if ((claimList || []).length > 0) {
      // at this point we should know that claimList has at least 1 value,
      // sort it by date descending and get the first one's caseId
      id =
        claimList
          ?.sort(
            (a, b) =>
              new Date(a.createdDate || 0).getTime() -
              new Date(b.createdDate || 0).getTime(),
          )
          ?.reverse()[0].caseId || '';
    }
  }

  // if we can't guess it from the form or the context, throw an error and try anyway
  // in case the API at one point is able to process without a caseid in the future
  if (id.length === 0) {
    log.error(
      'Unable to find caseId from form or context',
      formResult,
      formContext,
    );
  }

  return id;
};

export const getPhoneNumber = (p: string) => {
  const r: dm.PhoneNumber = {};

  try {
    r.areaCode = p.split('(')[1].split(')')[0];
    r.intCode = '+1';
    r.phoneType = 'Unknown';
    r.preferred = true;
    r.telephoneNo = p.split(')')[1].trim();
  } catch (e) {
    log.error(e);
  }

  return r;
};

export const getTimeRange = (d: Date) => {
  const d1 = dayjs(d).tz('America/New_York');
  const d2 = d1.add(1, 'h');

  return `${d1.format('hhA')}-${d2.format('hhA')}`;
};

export const removeBlankOrNullProperties = (
  obj: { [name: string]: any },
  path?: string,
) =>
  Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => {
      // scope value
      const sv = path ? get(v, path, 'default') : v;
      return (
        sv !== null &&
        sv !== undefined &&
        sv !== '' &&
        sv !== 'undefined' &&
        sv !== 'null'
      );
    }),
  );

export const escapeNewLineCharacters = (formResult?: {
  [name: string]: any;
}) => {
  const escapedResult = formResult ?? {};
  Object.keys(escapedResult).forEach((fieldName: any) => {
    // field value getter result
    const fv = escapedResult[fieldName];

    if (typeof fv === 'string') {
      // just handle newlines for now, tried JSON.stringify()
      // but other issues arise with that approach
      escapedResult[fieldName] = fv.replace(/\n/g, '\\n');
    }
  });
  return escapedResult;
};
