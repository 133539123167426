import { Rating, TextArea } from '@progress/kendo-react-inputs';
import React, { useState, useContext } from 'react';
import closeSvg from '../../../../images/close-icon.svg';
import { Button } from '@progress/kendo-react-buttons';
import './style.css';
import { useMutation } from '@tanstack/react-query';
import { postQualtrics } from '../../../../utils/web-apis-client/index';
import { Spinner } from '../../../base/spinner';
import { VBSurveyBody } from '../../../../data-models/index';
import { QGetFSSessionUrl } from '../../utils';
import { PersonDataContext } from '../../../../contexts/person-data-context/index';

type Props = {
  showDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export const VBSurvey: React.FC<Props> = ({ showDialog }) => {
  const { personData } = useContext(PersonDataContext);
  const [page, setPage] = useState(1);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');

  const { mutate, isLoading } = useMutation((data: VBSurveyBody) =>
    postQualtrics(data, 'vb'),
  );

  const submit = () => {
    let name = '';
    if (personData?.person?.firstName) {
      name = personData.person.firstName;
    }
    if (personData?.person?.lastName) {
      name += ' ' + personData.person.lastName;
    }

    let email = '';
    if (personData?.person?.emailAddresses?.length) {
      email = personData?.person?.emailAddresses[0].emailAddress ?? '';
    }

    const data: VBSurveyBody = {
      values: {
        QID1: rating,
        QID2_TEXT: review,
        fsSessionUrl: QGetFSSessionUrl(),
        employer: personData?.person?.employerName ?? '',
        userEmail: email,
        userName: name,
      },
    };
    mutate(data);
    showDialog(false);
  };

  return (
    <div id="vb-survey" className="tw-h-[280px] tw-w-[360px] tw-p-6">
      <div className="tw-flex tw-mb-3">
        <Button
          className="tw-cursor-pointer tw-ml-auto tw-w-1 tw-px-3 tw-bg-white tw-border-white"
          onClick={() => showDialog(false)}
        >
          <img src={closeSvg} alt="close icon" />
        </Button>
      </div>
      {page === 1 && (
        <div className="tw-text-center">
          <div className="tw-text-sm tw-font-semibold tw-mb-4">
            How satisfied are you with the submission process you just completed
            for your Supplemental Benefits?
          </div>
          <div className="tw-mb-6">
            <Rating
              id="vb-survey-rating"
              style={{ scale: '125%' }}
              onChange={(event) => setRating(event.value)}
            />
          </div>
          <div>
            <Button
              id="vb-survey-continue-button"
              className="tw-bg-unm-primary02-400 tw-text-white tw-rounded-lg"
              onClick={() => setPage(2)}
              disabled={!Boolean(rating)}
            >
              Continue
            </Button>
          </div>
        </div>
      )}
      {page === 2 && (
        <div id="vb-survey-page-two" className="tw-text-center">
          <div className="tw-text-m tw-font-semibold tw-mb-4">
            Would you like to leave a review?
          </div>
          <div className="tw-mb-6">
            <TextArea rows={3} onChange={(event) => setReview(event.value)} />
          </div>
          <div>
            <Button
              id="vb-survey-submit-btn"
              className="tw-bg-unm-primary02-400 tw-text-white tw-rounded-lg"
              onClick={() => submit()}
              disabled={!!isLoading}
            >
              {isLoading ? <Spinner /> : <>Submit</>}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
