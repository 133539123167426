import React from 'react';
import { getRefresh } from '../../utils/web-apis-client';
import { get, enums, parseIntOrDefault } from '../../utils';
import { PersonDataContext } from '../../contexts/person-data-context';
import { log } from '../../utils/log';
import { KenticoDataContext } from '../kentico-data-context';

// const log = new Logger\('contexts/refresh-context');

export type LoginRefreshStatuses =
  | enums.RefreshStatus.refreshed
  | enums.RefreshStatus.failed
  | enums.RefreshStatus.started
  | enums.RefreshStatus.failedWithData
  | enums.RefreshStatus.notRegisterable;

export type FullRefreshStatuses =
  | enums.RefreshStatus.refreshed
  | enums.RefreshStatus.failed
  | enums.RefreshStatus.started;

export const RefreshContext = React.createContext<{
  loginStatus: LoginRefreshStatuses;
  status: FullRefreshStatuses;
  refreshDetails: any;
  isRefreshing: boolean;
  setRefreshDetails: any;
  pollForUpdates: any;
}>({
  loginStatus: enums.RefreshStatus.refreshed,
  status: enums.RefreshStatus.started,
  refreshDetails: {} as any,
  isRefreshing: false,
  setRefreshDetails: () => {},
  pollForUpdates: () => {},
});

export const handleFullRefreshPolling = async (
  setStatus: React.Dispatch<React.SetStateAction<FullRefreshStatuses>>,
  setRefreshDetails: React.Dispatch<React.SetStateAction<any>>,
  refreshPersonData: () => Promise<void>,
  setTriggerBackgroundUpdates: React.Dispatch<React.SetStateAction<boolean>>,
  interval: NodeJS.Timeout,
) => {
  try {
    const result = await getRefresh();
    log.debug('GET refresh results:', result);

    const rs = get(result, 'fullRefreshStatus.status', '');
    const rd = get(result, 'statusDetail', {});
    const complete = rs.length > 0 && rs !== enums.RefreshStatus.started;

    if (complete) {
      log.debug('Full refresh complete');
      setRefreshDetails(rd);
      setStatus(rs);
      await refreshPersonData();
      setTriggerBackgroundUpdates(false);
      clearInterval(interval);
    }
  } catch (e) {
    log.error('GET refresh failed', e);
    setStatus(enums.RefreshStatus.failed);
    setTriggerBackgroundUpdates(false);
    clearInterval(interval);
  }
};

/**
 * Provides data from Kentico, allows for refreshes
 * @param p Props
 * @returns React Context Provider
 */
export const RefreshContextProvider = (p: {
  children: any;
  loginStatus: LoginRefreshStatuses;
  status?: FullRefreshStatuses;
  details?: any;
  triggerBackgroundUpdates?: boolean;
}) => {
  const [loginStatus, setLoginStatus] = React.useState<LoginRefreshStatuses>(
    p.loginStatus ?? enums.RefreshStatus.refreshed,
  );
  const [status, setStatus] = React.useState<FullRefreshStatuses>(
    p.status ?? enums.RefreshStatus.started,
  );

  const [triggerBackgroundUpdates, setTriggerBackgroundUpdates] =
    React.useState<boolean>(p.triggerBackgroundUpdates || false);

  const [refreshDetails, setRefreshDetails] = React.useState<any>(p?.details);
  const { refreshPersonData } = React.useContext(PersonDataContext);
  const { config } = React.useContext(KenticoDataContext);

  const pollForUpdates = () => {
    setTriggerBackgroundUpdates(true);
  };

  React.useEffect(() => {
    p.loginStatus && setLoginStatus(p.loginStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let interval: NodeJS.Timeout;
    if (triggerBackgroundUpdates) {
      const refreshCheckInterval = parseIntOrDefault(
        get(config, enums.RemoteConfigKeys.fullRefreshCheckIntervalMs),
        2000,
      );
      const refreshCheckCount = parseIntOrDefault(
        get(config, enums.RemoteConfigKeys.fullRefreshCheckCount),
        60,
      );
      let attempts = 0;
      interval = setInterval(async () => {
        await handleFullRefreshPolling(
          setStatus,
          setRefreshDetails,
          refreshPersonData,
          setTriggerBackgroundUpdates,
          interval,
        );

        attempts++;
        log.debug('Full refresh polling attempt:', attempts);
        if (attempts >= refreshCheckCount) {
          log.error('Full refresh timeout');
          clearInterval(interval);
        }
      }, refreshCheckInterval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerBackgroundUpdates]);

  return (
    <RefreshContext.Provider
      value={{
        loginStatus,
        status,
        isRefreshing: triggerBackgroundUpdates,
        refreshDetails,
        setRefreshDetails,
        pollForUpdates,
      }}
    >
      {p.children}
    </RefreshContext.Provider>
  );
};
