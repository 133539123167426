/* eslint-disable no-unused-vars */

/**
 * enums for the fields tat go into a claim submission
 */
export enum AnswerFieldName {
  leaveScenario = 'leaveScenario',
  eventDate = 'eventDate',
  planType = 'planType',
  employeeType = 'employeeType',
  healthInsuranceProvider = 'healthInsuranceProvider',
  reason = 'reason',
  reasonQualifier1 = 'reasonQualifier1',
  reasonQualifier2 = 'reasonQualifier2',
  primaryFamilyMemberRelationship = 'primaryFamilyMemberRelationship',
  primaryFamilyMemberRelationshipQualifier1 = 'primaryFamilyMemberRelationshipQualifier1',
  primaryFamilyMemberRelationshipQualifier2 = 'primaryFamilyMemberRelationshipQualifier2',
  workRelated = 'workRelated',
  claimType = 'claimType',
  dateSymptomsFirstAppeared = 'dateSymptomsFirstAppeared',
  expectedReturnToWorkDate = 'expectedReturnToWorkDate',
  employerDateLastWorked = 'employerDateLastWorked',
  dismembermentOrLoss = 'dismembermentOrLoss',
  insuredSpouseWorking = 'insuredSpouseWorking',
  salaryCountNumDays = 'salaryCountNumDays',
  actualDeliveryMethod = 'actualDeliveryMethod',
  additionalComments = 'additionalComments',
}

/**
 * Paths for SOL_TotalLeave_Employee built external APIs
 */
export enum ApiPaths {
  autoSave = '/autosave',
  callbacksAvailable = '/callbacks-available',
  closeReminder = '/close-reminder',
  createReminder = '/create-reminder',
  ConvertFile = '/convert-uploaded-file',
  LeaveAvailibility = '/fc-leave-availibility',
  logout = '/logout',
  MergeUploadedFiles = '/merge-uploaded-files',
  MergeUploadedFilesBucketUrl = '/merge-uploaded-files-url',
  qualtrics = '/qualtrics',
  RefreshToken = '/refresh-token',
  remindersFunction = '/reminders-function',
  sessionInfo = `/session-info`,
  vbCoverage = '/vb-coverage',
  vbSubmission = '/vb-submission',
}

/**
 * Paths for IRM_phenx-iac repo, `external-apis.yaml` file
 */
export enum ApiPathsEX {
  ada = '/ex/accommodation',
  callback = '/ex/callback',
  cases = '/ex/cases',
  chat = '/ex/chat',
  document = '/ex/document',
  documents = '/ex/documents',
  draftClaim = '/ex/draftClaim',
  draftClaims = '/ex/draftClaims',
  employerPreferences = '/ex/employerpreferences',
  episodicAbsences = '/ex/episodicAbsences',
  esignature = '/ex/esignature',
  getPaymentPreferences = '/ex/getPaymentPreferences',
  getZelleEnrollmentsStatus = '/ex/getZelleEnrollmentsStatus',
  ldw = '/ex/ldw',
  person = '/ex/person',
  personData = '/ex/personData',
  preferences = '/ex/preferences',
  pregnancy = '/ex/pregnancy',
  refresh = '/ex/refresh',
  setPaymentPreferences = '/ex/setPaymentPreferences',
  submission = '/ex/submission',
  task = '/ex/task',
  webmessage = '/ex/webmessage',
}

/**
 * Paths for IRM_phenx-iac repo, `external-analytics-apis.yaml` file
 */
export enum ApiPathsAN {
  error = '/an/error',
  leaveExtensions = '/an/leaveExtension',
  track = '/an/track',
}

/**
 * Paths for IRM_phenx-iac repo, `absence-apis.yaml` file
 */
export enum ApiPathsAB {
  tasks = '/ab/employee-tasks',
}

/**
 * Internal URL paths within the app
 */
export enum AppPaths {
  absenceHistory = '/app/absence-history/:ntn?',
  absenceHistoryAll = '/app/absence-history',
  appRoot = '/app',
  claimList = '/app/claim-list',
  claimsLeavePlan = '/app/leave-plan/:NTNId',
  claimStatus = '/app/claim-status',
  contactUs = '/app/contact-us',
  contactUsCallback = '/app/form/scheduled-callback',
  contactUsChat = '/app/contact-us/chat',
  contactUsMessages = '/app/contact-us/messages',
  dataLoadFailed = '/app/page/data-load-failed',
  documents = '/app/documents',
  error = '/app/error',
  feedback = '/app/page/feedback',
  file = '/app/file',
  filePersonalMedical = '/app/file/personal-medical',
  filePregnancy = '/app/file/pregnancy',
  form = '/app/form',
  learninghub = '/app/learning-hub',
  livechat = '/app/live-chat',
  loading = '/app/loading',
  messages = '/app/messages',
  page = '/app/page/',
  payments = '/app/payments',
  time = '/app/time',
  profile = `/app/profile`,
  profileCommunicationPreferences = '/app/profile/communication-preferences',
  profileLanguagePreferences = '/app/profile/language-preferences',
  profilePaymentPreferences = '/app/profile/payment-preferences',
  root = '/',
  rootAlreadyRegistered = '/?a',
  startClaim = '/app/start',
  startClaimOld = '/app/page/start',
  supplemental = '/app/supplemental',
  taskDetails = '/app/task/:taskKey/details',
  taskLlDescription = '/app/task/:taskKey/ll-description',
  tasks = '/app/tasks',
  newUpload = '/app/upload',
}

export const AppPathForms = {
  vbAccident: '/app/form/vb-accident',
  vbCriticalIllness: '/app/form/vb-critical-illness',
  vbHospital: '/app/form/vb-hospital',
} as const;

export enum AppStates {
  GET_KENTICO_DATA = 'GET_KENTICO_DATA',
  GET_KENTICO_DATA_ERROR = 'GET_KENTICO_DATA_ERROR',
  GET_SPLASH_CONFIG = 'GET_SPLASH_CONFIG',
  GET_SPLASH_CONFIG_ERROR = 'GET_SPLASH_CONFIG_ERROR',
  SHOW_SPLASH = 'SHOW_SPLASH',
  AUTHENTICATING = 'AUTHENTICATING',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  AUTHENTICATED = 'AUTHENTICATED',
  NOT_REGISTERABLE = 'NOT_REGISTERABLE',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  FAILED_WITH_DATA = 'FAILED_WITH_DATA',
}

export enum TaskStatuses {
  draft = 1,
  pending_notification = 2,
  pending = 3,
  returned = 4,
  under_review = 5,
  escalated = 6,
  completed = 7,
  deleted = 8,
  ignored = 9,
  archived = 10,
}

export enum TaskUIStatuses {
  awaiting_action = 'AWAITING ACTION',
  in_review = 'RECEIVED',
  needs_action = 'RETURNED',
  ignored = 'IGNORED',
  completed = 'COMPLETED',
  archived = 'ARCHIVED',
}

export enum ButtonTypes {
  breadcrumb = 'breadcrumb',
  primary = 'primary',
  secondary = 'secondary',
  secondaryRed = 'secondaryRed',
  flat = 'flat',
  link = 'link',
  outline = 'outline',
  primaryLarge = 'primaryLarge',
  smallOutline = 'smallOutline',
}

/**
 * Keys for accessing objects in the cache
 */
export enum CacheKeys {
  activelogin = 'activelogin',
  assetReferences = 'asset_references',
  auth_state = 'auth_state',
  cache_registry = 'cache_registry',
  callCenterClosures = 'callCenterClosures',
  cognitoid = 'cognitoid',
  content_blob = 'content_blob',
  dimissedCalendarGuide = 'dismissed_calendar_guide',
  dismissedOutageWarnings = 'dismissed_outage_warnings',
  dynamicContent = 'dynamic_content',
  employer_content = 'employer_content',
  entryPoint = 'entryPoint',
  featureFlags = 'feature_flags',
  form_config = 'form_config',
  intakes_list = 'intakes_list',
  lastLoginTime = 'lastLoginTime',
  live_chat_form = 'live_chat_form',
  localized_string = 'localized_string',
  reference_remote_config = 'reference_remote_config',
  remote_config = 'remote_config',
  sessionCredentials = 'sessionCredentials',
  splash_config = 'splash_config',
  splash_config_recurring = 'splash_config_recurring',
  ssoCookieKey = 'ssoCookieKey',
  ssoCookieValue = 'ssoCookieValue',
  tasksConfig = 'tasks-config',
  tasksMap = 'task',
  taskStatusConfig = 'task-status-help',
  claimStatusConfig = 'status-help-icon',
  userInfo = 'userInfo',
}

export type CacheKeyType = `${CacheKeys}`;

export enum CaseStatus {
  closed = 'Closed',
  open = 'Open',
}

export enum CodeNames {
  allAssets = 'all_assets',
  refProjectId = 'kontent_project_id',
  refPreviewKey = 'kontent_preview_key',
  refPreviewFlag = 'kontent_preview_flags',
  refTasksProjectId = 'kontent_tasks_project_id',
  refTasksPreviewKey = 'kontent_tasks_preview_key',
  refTasksPreviewFlag = 'kontent_tasks_preview_flags',
}

export enum AbsenceStatuses {
  pending = 'Pending',
  partial = 'Partial',
  approved = 'Approved',
  denied = 'Denied',
  accepted = 'Accepted',
  rejected = 'Rejected',
}

export enum CredentialPath {
  accessKeyId = 'config.credentials.accessKeyId',
  secretAccessKey = 'config.credentials.secretAccessKey',
  sessionToken = 'config.credentials.sessionToken',
}

export enum DataSources {
  availableCallCenterTimesBasedOn = '$availableCallCenterTimesBasedOn',
  callCenterClosures = '$callCenterClosures',
  formField = '$formField',
  formContext = '$formContext',
  toDate = '$toDate',
  weekends = '$weekends',
}

export enum EventTypes {
  navBarMount = 'navBarMount',
  navBarUnmount = 'navBarUnmount',
  navBarChange = 'navBarChange',
}

export enum FontNames {
  openSans = 'openSans',
  ubuntu = 'ubuntu',
}

export enum FontTypes {
  light = 'light',
  regular = 'regular',
  medium = 'medium',
  semibold = 'semibold',
  bold = 'bold',
}

export enum FormFieldTypes {
  checkbox = 'Checkbox',
  datepicker = 'DatePicker',
  dropdownlist = 'DropDownList',
  input = 'Input',
  leaveplangraph = 'LeavePlanGraph',
  maskedtextbox = 'MaskedTextBox',
  signer = 'Signer',
  switch = 'Switch',
  textarea = 'TextArea',
  timepicker = 'TimePicker',
  weeklyschedule = 'WeeklySchedule',
  claimsdropdownlist = 'ClaimsDropDownList',
}

export enum FormFlags {
  allDay = 'all_day',
  includeCallCenterClosureContext = 'include_call_center_closure_context',
  plainLabel = 'plain_label',
  required = 'required',
  card = 'card',
  showSteps = 'show_steps',
}

export enum FormStepFlags {
  hideWhenNoFields = 'hide_when_no_fields',
  hideStepper = 'hide_stepper',
}

export enum FormSectionTypes {
  FormButton = 'form_button',
  FormField = 'form_field',
  FormText = 'form_text',
}

export enum HubChannels {
  datePicker = 'date-picker',
  formContext = 'form-ontext',
  navBarButtonChannel = 'use-nav-bar-button',
  personDataContext = 'person-data-context',
  userInfo = 'user-info',
}

export enum HubEvents {
  append = 'append',
  init = 'init',
  refresh = 'refresh',
  refreshed = 'refreshed',
  set = 'set',
}

export enum KenticoDataKeys {
  assets = 'assets',
  callCenter = 'callCenter',
  featureFlags = 'featureFlags',
  config = 'config',
  localizedStrings = 'localizedStrings',
  dynamicContent = 'dynamicContent',
}

export enum DynamicContentMediums {
  full = 'dcContentFull',
  medium = 'dcContentMedium',
  short = 'dcContentShort',
}

export enum NoticeRequirementsKeys {
  asap = 'as_soon_as_possible',
  calendar_singular = 'calendar_day',
  calendar_plural = 'calendar_days',
  working_singular = 'working_day',
  working_plural = 'working_days',
}

export enum EREnvironmentCodes {
  dev = 'identifier_dev1',
  itest = 'identifier_itest1',
  uat = 'identifier_uat',
  prod = 'identifier',
}

export enum ERContentTypes {
  ERContent = 'employer_content',
  multipleChoice = 'multiple_choice',
  customTask = 'employer_custom_task',
  nextStep = 'employer_next_step',
}

export enum PregnancyTextChanges {
  pregnancyBondingLiteral = 'Pregnancy/Maternity',
  pregnancyBondingLiteralNew = 'Pregnancy',
}

export enum LeaveTypes {
  personalMedical = 'personalMedical',
  pregnancy = 'pregnancy',
}

export enum PaymentPrefsIds {
  Accounting = 66236000,
  DirectDeposit = 66236001,
  InternalAdjustment = 66236003,
  Check = 66236007,
  AdviceToPay = 66239010,
  Zelle = 66239997,
}

export enum RefreshStatus {
  failed = 'Failed',
  failedWithData = 'Failed with data',
  refreshed = 'refreshed',
  started = 'started',
  notRegisterable = 'Not registerable',
}
export enum ReminderTypes {
  SMSPref = 'SMSPref',
}

export enum ReminderIcons {
  ChatBubbles = 'ChatBubbles',
}

/**
 * keys for accessing items in the remote config
 */
export enum RemoteConfigKeys {
  awsSessionCheckIntervalMs = 'aws_session_check_interval_ms',
  awsSessionDurationHours = 'aws_session_duration_hours',
  uploadLimit = 'upload_file_size_limit',
  refreshCheckCount = 'refresh_check_count',
  refreshCheckIntervalMs = 'refresh_check_interval_ms',
  fullRefreshCheckCount = 'full_refresh_check_count',
  fullRefreshCheckIntervalMs = 'full_refresh_check_interval_ms',
  odyessiUrl = 'odyssei_preferences_url',
  showPaymentPrefs = 'show_payment_preferences_on_profile',
  hidePrefsForId = 'hide_payment_preferences_on_profile_for_employer_i',
  documentTitles = 'document_titles',
  hidePaymentInfoForIds = 'hide_payment_info_for_employer_ids',
  mandatoryExitReason = 'intake_exit_reason_mandatory',
  intakeExitReasons = 'intake_exit_reasons',
  exitPromptIntakes = 'exit_prompt_for_intakes',
  absenceApiInvokeUrl = 'absence_api_invoke_url',
  iServicesIDPURL = 'iservices_idp_url',
  employerVbMode = 'employer_vb_mode',
  employerVbList = 'employer_vb_list',
}

export type ToastTypes = {
  style: 'none' | 'success' | 'error';
  icon: boolean;
};

/**
 * Messages for field validations on the forms
 */
export enum ValidationErrors {
  hasContent = 'Please fill out this required field',
  signerHasContent = 'Please ensure that you have signed and filled out the required fields',
  weeklyScheduleHasContent = 'Please ensure all time values are valid',
  noAlpha = 'No letters are allowed in this field',
  noNumeric = 'No number are allowed in this field',
  noSpecial = 'No special characters are allowed in this field',
  onlyAlpha = 'Only letters are allowed in this field',
  onlyNumberic = 'Only numbers are allowed in this field',
  matchesPattern = 'Please check this value again',
  dateGreaterThan = 'Please provide a date greater than the minimum',
  dateLesserThan = 'Please provide a date lesser than the maximum',
  dateGreaterThanOrEqual = 'Please provide a date greater than the minimum',
  dateLesserThanOrEqual = 'Please provide a date lesser than the maximum',
  isValidDate = 'Please enter a valid date',
  noSpecialChars = 'Special characters are not allowed. Please remove them to proceed.',
}

export enum UploadStatus {
  allSuccessful = 'all_successful',
  someErrors = 'some_errors',
  allErrors = 'all_errors',
}

export enum AbsenceTypes {
  treatment = 'Treatment',
  episode = 'Episode',
  fineosType = 'Office Visit',
}

export enum AnalyticsEvents {
  absencesMonth = 'Absences: Changed Month',
  absencesDay = 'Absences: Clicked Day',
  absencesAdd = 'Absences: Add Absence',
  absencesExpand = 'Absences: Card Expanded',
  absencesList = 'Absences: List View',
  absencesCalendar = 'Absences: Calendar View',
  absencesLeaveSelect = 'Absences: Select Leave',
  summaryAbsences = 'Summary: View Absences',
  summaryAddAbsence = 'Summary: Add Absence',
  summaryLeavePlan = 'Summary: View Leave Plan',
  summaryExpand = 'Summary: Expand NTN',
  failedUploadValidation = 'Upload: Validation Failure',
  failedUploadSend = 'Upload: Failed To Send',
  figNextStepShown = 'Fig Next Step: Shown',
  figNextStepClicked = 'Fig Next Step: Clicked',
  genericNextStepClicked = 'Next Step: Clicked',
  formExit = 'Intake Form: Exited',
  lpvDetailsView = 'LPV: Details View Selected',
  lpvCalendarView = 'LPV: Calendar View Selected',
  lpvEditPressed = 'LPV: Edit Button Pressed',
  lpvEditOptionPressed = 'LPV: Edit Option Selected',
  lpvJobProtectionExpanded = 'LPV: Job Protection Details Expanded',
  lpvPaidLeaveExpanded = 'LPV: Income Protection Details Expanded',
  unsupportedBrowserWarning = 'Unsupported Browser Warning: Shown',
  unsupportedBrowserIgnored = 'Unsupported Browser Warning: User Pressed Continue',
}

export enum RefreshDetailTypes {
  cases = 'refreshCases',
  caseDetails = 'refreshCaseDetail',
  documents = 'refreshDocuments',
  leavePlans = 'refreshLeavePlans',
  person = 'refreshPerson',
  register = 'refreshRegister',
  tasks = 'refreshTasks',
}

export enum MilitaryReasonDescriptions {
  shortNoticeDeployment = 'This is a period of leave which lasts seven or less calendar days immediately preceding deployment.',
  militaryEvents = 'This describes any related official ceremony, program, or event sponsored by the military.',
  childcareActivities = 'This is on an urgent, immediate need basis only.',
  financialArrangements = "These are absences to make or update financial or legal arrangements to address the covered servicemember's absence " +
    '(e.g. preparing and executing financial and healthcare powers of attorney, transferring bank account signature authority).',
  counselling = 'This is for counseling for employee, military member, or the military member’s minor child or adult disabled child.',
  restLeave = 'This is a rest and recuperation period of up to 15 calendar days.',
  postDeploymentActivities = 'These are activities sponsored by the military following the termination of active duty status.',
  militaryDeath = 'These are activities sponsored by the military following the death of an active duty member.',
  parentalCare = 'This is on an urgent, immediate need basis only.',
  additionalActivities = 'This is for military related activities that you have agreed upon with your employer.',
}
