import PropTypes from 'prop-types';
import notFound from '../../../../images/exception-screens/not-found-image.png';
import useDocumentTitle from '../../../../hooks/use-document-title';
import { ArrowIcon, ActionLink } from '../../simple-styled-components';
import { ExceptionScreen } from '../exception-screen';
import { prefixObjectValues } from '../../../../utils';

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  actionLink: 'not-found-screen-action-link',
};

/*******************************************************************************
 * Adds a unique prefix to the domIds to create a unique id
 * @param {string} prefix A unique prefix to add to the domIds
 * @returns {domIdsStatic} If a prefix is provided, then a copy of domIdsStatic
 * is returned with the property values modified to include the prefix. If a
 * prefix is not provided a copy of domIdsStatic is returned.
 ******************************************************************************/
const domIdsUnique = (prefix) => prefixObjectValues(prefix, domIdsStatic);

/*******************************************************************************
 * NotFoundScreen component
 * @param {NotFoundScreen.propTypes} props NotFoundScreen propTypes
 ******************************************************************************/
const NotFoundScreen = (props) => {
  useDocumentTitle('Not Found');
  const domIds = domIdsUnique(props.keyId);

  return (
    <ExceptionScreen
      messageHeader={props.messageHeader}
      messageImageUrl={props.messageImageUrl}
      messageSubHeader={props.messageSubHeader}
    >
      <ActionLink id={domIds.actionLink} to="/">
        {props.actionText}
        <ArrowIcon />
      </ActionLink>
    </ExceptionScreen>
  );
};

/*******************************************************************************
 * prop types and defaults
 ******************************************************************************/
NotFoundScreen.propTypes = {
  actionText: PropTypes.string,
  messageHeader: PropTypes.string.isRequired,
  messageImageUrl: PropTypes.string.isRequired,
  messageSubHeader: PropTypes.string.isRequired,
};

NotFoundScreen.defaultProps = {
  actionText: 'Home',
  messageHeader: 'Oh my, how did we end up here',
  messageImageUrl: notFound,
  messageSubHeader: 'Error 404. Please check your link and try again',
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { NotFoundScreen, domIdsUnique };
