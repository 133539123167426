
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class SharedSubmissionText extends ContentItem {
    public optionsTemplate!: Elements.TextElement;
    public additionalResources!: Elements.LinkedItemsElement<ContentItem>;
    public markdownText!: Elements.TextElement;
    public label!: Elements.TextElement;
    public nextSteps!: Elements.LinkedItemsElement<ContentItem>;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'options_template') {
                    return 'optionsTemplate';
                }
                if (elementName === 'additional_resources') {
                    return 'additionalResources';
                }
                if (elementName === 'markdown_text') {
                    return 'markdownText';
                }
                if (elementName === 'next_steps') {
                    return 'nextSteps';
                }
                return elementName;
            })
        });
    }
}
