import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import CloseX from '../../../images/close-x-white.svg';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import { groupByKey } from '../../../utils/calendars/overlap-utils';
import dayjs from 'dayjs';
import Handlebars from 'handlebars';
import * as h from '../../../hooks/use-handlebars/helpers';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';

interface DetailModalProps {
  modalCallback: () => void;
  details?: any;
  legend?: any;
}

const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid white;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;

  @media (max-width: 1279px) {
    justify-content: start;
  }
`;

const ModalWindow = styled.div`
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${colors.alto2};
  width: 100%;
  min-height: 458px;
  display: flex;
  max-width: 568px;
  flex-direction: column;
`;

const Body = styled.div`
  padding: 16px 32px 32px;
  overflow: auto;
  @media (max-width: 767px) {
    padding: 16px;
  }
`;

const TitleBar = styled.div`
  background-color: ${colors.denim};
  border-radius: 4px 4px 0 0;
  display: flex;
  position: relative;
  justify-content: center;
  color: white;
  width: 100%;
  padding: 5px;
  min-height: 48px;
  align-items: center;
`;

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${colors.boulder};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.regular.fontWeight};

  &:not(:last-child) {
    border-bottom: 1px dashed ${colors.alto2};
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;

const GroupContainer = styled.div`
  &:not(:last-child) {
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.alto2};
  }
`;

const GroupName = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  margin-bottom: 5px;
  margin-top: 12px;
`;

const LegendItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const LegendText = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin-left: 10px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
`;

const DetailModal: (p: DetailModalProps) => React.ReactElement = (p) => {
  const { assets, config, localizedStrings, dynamicContent } =
    React.useContext(KenticoDataContext);

  const hbi = Handlebars.create();

  h.registerSelf(
    hbi,
    () => {},
    assets,
    config,
    localizedStrings,
    dynamicContent,
    {},
  );

  const data = p?.details?.data?.map?.((d: any) => {
    const legendItem = p.legend?.[d.legendKey];
    const template = hbi.compile(legendItem?.statusName ?? '');
    const statusName = template(d);
    const detailTemplate = hbi.compile(legendItem?.detail ?? '');
    const detail = detailTemplate(d);

    if (legendItem) {
      return {
        ...d,
        icon: legendItem.legendIcon,
        statusName,
        group: legendItem.group,
        detail,
      };
    } else {
      return null;
    }
  });
  const filteredData =
    data && data.length > 0 ? data.filter((d: any) => d !== null) : [];

  const dataGroups =
    filteredData.length > 0 ? groupByKey(filteredData, 'group') : {};
  const groupKeys = Object.keys(dataGroups);

  return groupKeys.length > 0 ? (
    <Container id="calendar-day-detail-modal">
      <ModalWindow>
        <TitleBar>
          {dayjs(p.details?.date).format('MMMM DD, YYYY').toUpperCase()}
          <ButtonContainer>
            <KendoButton
              id="calendar-guide-close-x"
              imageUrl={CloseX}
              onClick={p.modalCallback}
              look="flat"
            />
          </ButtonContainer>
        </TitleBar>
        <Body>
          {groupKeys.map((key: string, groupIndex: number) => (
            <GroupContainer key={`group-${groupIndex}`}>
              <GroupName>{key}</GroupName>
              {dataGroups[key].map((detail: any, detailIndex: number) => (
                <ListItemContainer key={`detail-${detailIndex}`}>
                  <LegendItem>
                    <img src={detail.icon} alt="" />
                    <LegendText>{detail?.detail ?? 'N/A Benefit'}</LegendText>
                  </LegendItem>
                  {detail?.statusName}
                </ListItemContainer>
              ))}
            </GroupContainer>
          ))}
        </Body>
      </ModalWindow>
    </Container>
  ) : (
    <div></div>
  );
};

export default DetailModal;
