import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { PersonDataContext } from '../../../../contexts/person-data-context';
import * as dm from '../../../../data-models';
import { ReactComponent as leftArrow } from '../../../../images/arrow-solid-right.svg';
import styled from 'styled-components';
import { colors } from '../../../../style';
import { get, prefixObjectValues, enums } from '../../../../utils';
import LeavePlan from '../../../leave-plan';

const domIdsStatic = {
  rootNode: 'claims-leave-plan',
  goBack: 'go-back-to-summary',
};

type PersonData = ExtendedPersonData | dm.PersonData | undefined;

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

export interface LeavePlanParamsProps {
  NTNId?: string;
}
export interface ExtendedPersonData extends dm.PersonData {
  submissions?: {
    submissions?: Array<Submissions>;
  };
  generated: {
    eid?: string;
    parentNTNs?: Array<ParentNTN>;
  };
}

export interface Submissions {
  eid?: string;
  planId?: string;
  submissionDate?: string;
  processDate?: string;
  notificationId?: string;
}

export interface ParentNTN {
  NTNId?: string;
  eid?: string;
  claims?: Array<dm.Claim>;
  leaves?: Array<dm.Leave>;
  isIntegrated?: boolean;
}
const Container = styled.div`
  width: 100%;
`;
const Row = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  margin-bottom: 0.25em;
  max-width: 100%;
`;

const Arrow = styled(leftArrow)`
  cursor: pointer;
  margin-right: 0.5em;
  transform: rotate(180deg);
  path {
    fill: ${colors.primaryBlue};
  }
`;

const BreadcrumbText = styled.p`
  color: ${colors.primaryBlue};
  cursor: pointer;
  margin: 0;
  text-decoration: underline;
`;

const Heading1 = styled.h1`
  font-size: 26px;
  width: 100%;

  @media screen and (min-width: 500px) {
    font-size: 32px;
    width: 100%;
  }
`;

const SubHeading = styled.p`
  font-size: 16px;
`;

export const generateClaimsData = (
  data: PersonData,
  params: LeavePlanParamsProps,
) => {
  return get(data, 'generated.parentNTNs', []).find(
    (p: { NTNId: string }) => p?.NTNId && p.NTNId === params.NTNId,
  );
};

export const isParamAvailable = (params: string | undefined) =>
  params ? params : null;

const ClaimsLeavePlan: () => React.ReactElement = () => {
  const { personData } = useContext(PersonDataContext);
  const params = useParams<LeavePlanParamsProps>();
  const domIds = domIdsUnique(params.NTNId);
  const history = useHistory();
  const claim = generateClaimsData(personData, params);

  const handleOnClick = () => history.goBack();

  const getHeader = React.useCallback(() => {
    let leaveReason = get(claim, 'leaves[0].reason', 'N/A');
    const claimReason = get(claim, 'claims[0].claimType', 'N/A');

    leaveReason =
      leaveReason === enums.PregnancyTextChanges.pregnancyBondingLiteral
        ? enums.PregnancyTextChanges.pregnancyBondingLiteralNew
        : leaveReason;

    return claim?.leaves?.length > 0 ? leaveReason : claimReason;
  }, [claim]);

  return (
    <>
      {isParamAvailable(params.NTNId) ? (
        <Container className="col-12 col-lg-9" id={domIds.rootNode}>
          <Row id={domIds.goBack} onClick={handleOnClick}>
            <Arrow />
            <BreadcrumbText>Summary</BreadcrumbText>
          </Row>
          <Row>
            <Heading1>{getHeader()}</Heading1>
          </Row>
          <Row>
            <SubHeading>Event ID: {get(params, 'NTNId', 'N/A')}</SubHeading>
          </Row>
          <Row>
            <LeavePlan
              systemId={params.NTNId}
              visualType="continuous"
              editConfig={{}}
              leaves={get(claim, 'leaves', [])}
              claims={get(claim, 'claims', [])}
            />
          </Row>
        </Container>
      ) : (
        <Container id={domIds.rootNode}>
          <p>ID Not Found</p>
        </Container>
      )}
    </>
  );
};

export default ClaimsLeavePlan;
