import { CustomEntitlement } from '../../data-models';

export const calcToolTip = (calc: string) => {
  if (calc.toLocaleLowerCase().includes('rolling forward')) {
    return 'This method looks forward over the entitlement period (see total plan entitlement above) from the first date an employee takes leave.  The next entitlement period would begin the first time the employee takes leave after the completion of the prior period. For example, the FMLA provides 12 weeks of leave in a 12-month period so the rolling forward method would look forward 12 months from the first day of FMLA leave. The amount of leave taken during that 12-month period is then deducted from the 12-week entitlement to calculate how much leave remains available to the employee. The employee’s current fixed period ends on the “time available as of” date.';
  }
  if (calc.toLocaleLowerCase().includes('rolling back')) {
    return 'This method looks back over the entitlement period (see total plan entitlement above) to determine how much leave has been taken during that time and subtract it from the total amount of leave permitted. The result is the amount of leave the employee has remaining on the “time available as of” date. For example, the FMLA provides 12 weeks of leave in a 12-month period so the rolling back method would look back 12 months to determine how much of the 12-week entitlement the employee used during that period to calculate what leave remains available to the employee.  ';
  }

  if (calc.toLocaleLowerCase().includes('calendar year')) {
    return " The calendar year is defined as January 1 through December 31.Fixed year: The fixed year calculation period starts on a specified date and ends 12 months later. The year is determined by the employer and can be the fiscal year, a year required by State law, or a year starting on an employee's anniversary date. The employee’s current fixed period ends on the “time available as of” date.";
  }

  if (calc.toLocaleLowerCase().includes('fixed year - ee date of birth')) {
    return 'This fixed year starts on the employee’s date of birth and ends 12 months later. The employee’s current fixed period ends on the “time available as of” date.';
  }

  if (calc.toLocaleLowerCase().includes('fixed year - ee anniversary')) {
    return 'This fixed year starts on the employee’s hire date anniversary and ends 12 months later. The employee’s current fixed period ends on the “time available as of” date.';
  }

  return '';
};

export const unavailableTime = (mainEntitlement: CustomEntitlement) =>
  mainEntitlement.timeWithinPeriodBasis.toLocaleLowerCase() === 'per event' ||
  mainEntitlement.calculationPeriod.toLocaleLowerCase() === 'per event' ||
  mainEntitlement.timeWithinPeriodBasis.toLocaleLowerCase() === 'reasonable' ||
  mainEntitlement.calculationPeriod.toLocaleLowerCase() === 'reasonable' ||
  mainEntitlement.timeEntitlement.toLocaleString() === 'per event' ||
  mainEntitlement.timeEntitlement.toLocaleString() === 'reasonable' ||
  Number(mainEntitlement.availableTime) < 0 ||
  (mainEntitlement.timeEntitlement >= 999 &&
    mainEntitlement.timeBasis.toLocaleLowerCase() === 'hours') ||
  (mainEntitlement.timeEntitlement >= 999 &&
    mainEntitlement.timeBasis.toLocaleLowerCase() === 'weeks');

export const NY_PFL_PLANS = ['NY PFL', 'NY PFL - PT', 'NY PFL - FT'];

export const TRACKING_PLANS = [
  'MA PFML - Tracking',
  'WA PFML - Tracking',
  'WA PFML State Pay 50+ Track',
  'WA PFML State Pay 1-49 Track',
  'CA PFL - Tracking',
  'CT PFML - Tracking',
  'CA SDI - Tracking',
];
