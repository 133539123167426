import React from 'react';
import { PersonDataContext } from '../../../contexts/person-data-context';
import { useQuery } from '@tanstack/react-query';
import { TaskType } from '../../tasks';
import { ApiError, getTasksWithKontent } from '../../tasks/container/api';
import { Spinner } from '../../base/spinner';
import Forms from '..';

export const FormContainer = () => {
  const { personData } = React.useContext(PersonDataContext);

  const { data: tasksResponse, isLoading: tasksLoading } = useQuery<
    TaskType[],
    ApiError
  >(
    ['getTasksWithKontent', personData?.person?.customerNumber],
    () => getTasksWithKontent(personData!.person!.customerNumber!),
    { retry: false },
  );

  console.log(tasksResponse);

  if (tasksLoading) {
    return <Spinner />;
  }

  return (
    <Forms tasks={tasksResponse?.filter((t) => t.assigneeId === 2) ?? []} />
  );
};
