import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class CallCenterClosure extends ContentItem {
    public end!: Elements.DateTimeElement;
    public ivrMessageSpanish!: Elements.TextElement;
    public portalUnumComBannerMessage!: Elements.TextElement;
    public flags!: Elements.MultipleChoiceElement;
    public ivrMessage!: Elements.TextElement;
    public start!: Elements.DateTimeElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'ivr_message_spanish') {
                    return 'ivrMessageSpanish';
                }
                if (elementName === 'portal_unum_com_banner_message') {
                    return 'portalUnumComBannerMessage';
                }
                if (elementName === 'ivr_message') {
                    return 'ivrMessage';
                }
                return elementName;
            })
        });
    }
}
