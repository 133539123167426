import React from 'react';
import styled from 'styled-components';
import { models } from '../../../utils/cms-client';
import { enums } from '../../../utils';
import IntakeCard from '../intake-card';
import Button from '../../base/button';
import { Field } from '@progress/kendo-react-form';

interface ICardSection {
  content: models.IntakeSection;
  name: string;
}

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 18px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  max-width: 400px;

  > button {
    margin-bottom: 20px;
  }
`;

const fieldValidator = (value: string) => (value ? '' : 'Selection required.');

const CardSection: (p: ICardSection) => React.ReactElement = (p) => {
  const { content, name } = p;
  const [displayAll, setDisplayAll] = React.useState(false);

  const optionsLimit = content?.optionsDisplayedLimit?.value || 0;
  const hasLimit = optionsLimit > 0;

  const toggleDisplayAll = () => {
    setDisplayAll(!displayAll);
  };

  const getLimit = () => {
    return !hasLimit || displayAll
      ? content.contentItems.value.length
      : optionsLimit;
  };
  const contentItems = [...content.contentItems.value];

  return (
    <>
      <CardContainer id={'section-container'}>
        {contentItems
          .splice(0, getLimit())
          .map((card: models.IntakeCard, i: number) => (
            <Field
              key={`types-${i}`}
              keyId={`types-${i}-`}
              card={card}
              component={IntakeCard}
              validator={fieldValidator}
              name={name}
            />
          ))}
      </CardContainer>
      {hasLimit && (
        <div>
          <Button
            id={'display-cards-button'}
            type="button"
            buttonStyle={enums.ButtonTypes.flat}
            onClick={toggleDisplayAll}
            style={{ fontSize: 16 }}
          >
            {displayAll
              ? content.fewerCardsButtonText.value
              : content.moreCardsButtonText.value}
          </Button>
        </div>
      )}
    </>
  );
};

export default CardSection;
