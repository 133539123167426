
import { ContentItem, Elements } from '@kentico/kontent-delivery';
import { AccordionCardItem } from './accordion_card_item';
/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class AccordionCard extends ContentItem {
    public content!: Elements.LinkedItemsElement<AccordionCardItem>;
    public header!: Elements.TextElement;
    public optionsTemplate!: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: (elementName: string) => {
                if (elementName === 'options_template') {
                    return 'optionsTemplate';
                }
                return elementName;
            },
        });
    }
}
