import { useState, useEffect } from 'react';

/**
 *
 * @param breakpoint px size to flag transition to mobile
 * @param showMobile used for unit tests to hard enable mobile view
 * @returns boolean flag indicates if screen size is mobile
 */
const useMobileView = (breakpoint = 1050, showMobile = false) => {
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth <= breakpoint,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= breakpoint);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  return showMobile ? showMobile : isMobileView;
};

export default useMobileView;
