const border = '1px solid #DDDDDD';
export const defaultTheme = {
  maxWidth: '900px',
  border,
  dayHeight: '64px',
  dayBackground: '#fff',
  selectedDayBackground: '#D6E9F5',
  dayNumber: {
    vertical: 'start',
    horizontal: 'center',
    font: 'Open Sans',
    size: '10px',
    color: '#525252',
    weight: '500',
    disabledColor: '#DDDDDD',
    disabledBackground: '#fff',
  },
  dayOfWeek: {
    background: '#127cb5',
    color: '#fff',
    colBorder: 0,
    rowBorder: '1px solid #127cb5',
    alignment: 'center',
    font: 'Open Sans',
    size: '12px',
    weight: '500',
  },
  periodStyles: {
    background: '#F2F8FC',
    startOfPeriodBorder: '2px solid #127cb7',
    endOfPeriodBorder: '2px solid #127cb7',
    dayNumberColor: '#127CB5',
  },
  navigationBar: {
    background: '#fff',
    color: '#127cb5',
    font: 'Ubuntu',
    size: '14px',
    weight: '600',
    border,
    radius: '4px 4px 0 0',
    button: {
      background: '#fff',
      fill: '#127cb5',
      height: '16px',
      width: '9px',
    },
  },
};
