import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import { getHtmlFromMarkdown } from '../../../utils';
import * as dm from '../../../data-models';
import AccordionCard from '../../base/accordion-card';
import BlueCircleCheck from '../../../images/leave-calendar/circle-check-blue.svg';
import dayjs from 'dayjs';

interface DetailCardProps {
  header: string;
  description: string;
  benefits: dm.JobProtectedBenefit[];
  index: number;
  idPrefix?: string;
  onExpandCallback?: () => void;
}

const AccordionCardMod = styled(AccordionCard)`
  background-color: red;
  &.accordion-card-container {
    padding: 16px !important;
  }
  @media (max-width: 577px;) {
  }
`;

const SubHeader = styled.div`
  color: ${colors.sanmarino};
  font-family: ${fonts.ubuntu.bold.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.ubuntu.bold.fontWeight};
  line-height: 24px;
`;

const Text = styled.p`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  line-height: 22px;
  margin: 0;
`;

const Description = styled(Text)`
  width: 100%;
  margin: 13px 0 6px;

  p {
    margin: 0;
  }
`;

const RowItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
`;

const BenefitItem = styled(RowItem)`
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    border-bottom: 1px dashed ${colors.alto2};
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: start;
  }
`;

const BenefitName = styled(Text)`
  color: ${colors.skyD2};
  font-size: 14px;
`;

const BenefitDates = styled(Text)`
  color: ${colors.boulder1};
  font-size: 14px;
`;

const TitleElement = (p: any) => {
  return (
    <Description dangerouslySetInnerHTML={getHtmlFromMarkdown(p.description)} />
  );
};

const getBenefitName = (benefit: any) => {
  if (benefit?.meta?.leavePlanName) {
    return benefit?.meta?.leavePlanName;
  } else if (benefit.source === 'Fineos') {
    return 'Unum Short Term Disability';
  } else {
    return benefit?.meta?.name;
  }
};

const ExpandedElement = (p: any) => {
  return (
    <div id={`${p.idPrefix}-detail-expanded`}>
      <SubHeader>BENEFIT</SubHeader>
      {p.benefits.map((b: any, i: number) => (
        <BenefitItem key={`benefit-${i}`}>
          <BenefitName>{getBenefitName(b)}</BenefitName>
          <BenefitDates>{`${dayjs(b.startDate).format('MM/DD/YYYY')} - ${dayjs(
            b.endDate,
          ).format('MM/DD/YYYY')}`}</BenefitDates>
        </BenefitItem>
      ))}
    </div>
  );
};

const DetailCard: (p: DetailCardProps) => React.ReactElement = (p) => {
  return (
    <AccordionCardMod
      idPrefix={p.idPrefix}
      icon={BlueCircleCheck}
      titleString={p.header}
      titleElement={TitleElement(p)}
      expandedElement={ExpandedElement(p)}
      index={p.index}
      onExpandCallback={p.onExpandCallback}
    />
  );
};

export default DetailCard;
