interface DetailModalProps {
  modalCallback: () => void;
  details?: any;
  customElement?: React.ElementType;
}

const DetailModal: (p: DetailModalProps) => React.ReactElement | null = (p) => {
  if (p.customElement) {
    const Element = p.customElement;
    return <Element details={p.details} modalCallback={p.modalCallback} />;
  } else {
    return null;
  }
};

export default DetailModal;
