
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class EmployerInstructions extends ContentItem {
    public whatProductsDoesThisApplyTo!: Elements.MultipleChoiceElement;
    public priorityLevel!: Elements.MultipleChoiceElement;
    public whatEmployeeGroupsDoesThisApplyTo!: Elements.CustomElement;
    public whoIsThisInstructionFor!: Elements.MultipleChoiceElement;
    public instruction!: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'what_products_does_this_apply_to_') {
                    return 'whatProductsDoesThisApplyTo';
                }
                if (elementName === 'priority_level') {
                    return 'priorityLevel';
                }
                if (elementName === 'what_employee_groups_does_this_apply_to_') {
                    return 'whatEmployeeGroupsDoesThisApplyTo';
                }
                if (elementName === 'who_is_this_instruction_for_') {
                    return 'whoIsThisInstructionFor';
                }
                return elementName;
            })
        });
    }
}
