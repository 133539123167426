import React from 'react';
import * as h from './helpers';
import Handlebars from 'handlebars';
import { KenticoDataContext } from '../../contexts/kentico-data-context';
import { PreferencesContext } from '../../contexts/preferences-context';
import { RefreshContext } from '../../contexts/refresh-context';

/**
 * A hook to assist with Handlebars
 */
const useHandlebars = (
  templateText?: string,
  context?: { [name: string]: any },
  valueGetter?: (v: string) => string,
) => {
  const hbi = Handlebars.create();
  const [output, setOutput] = React.useState('');
  const { assets, config, localizedStrings, dynamicContent, callCenter } =
    React.useContext(KenticoDataContext);
  const { ERContent } = React.useContext(PreferencesContext);
  const { isRefreshing, refreshDetails, status } =
    React.useContext(RefreshContext);

  React.useEffect(() => {
    h.registerSelf(
      hbi,
      valueGetter,
      assets,
      config,
      localizedStrings,
      dynamicContent,
      context,
    );

    if (templateText && context) {
      context.ERContent = ERContent;
      context.refresh = { isRefreshing, refreshDetails, status };
      setOutput(hbi.compile(templateText)({ ...context, callCenter }));
    } else {
      setOutput('');
    }
  }, [
    templateText,
    context,
    hbi,
    valueGetter,
    assets,
    config,
    localizedStrings,
    dynamicContent,
    ERContent,
    callCenter,
    isRefreshing,
    refreshDetails,
    status,
  ]);

  return output;
};

export default useHandlebars;
