
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class DynamicContent extends ContentItem {
    public dcContentShort?: Elements.CustomElement;
    public dcContentMedium?: Elements.CustomElement;
    public linkedBasecampPreference?: Elements.TextElement;
    public dcContentFull?: Elements.CustomElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'dc_content_short') {
                    return 'dcContentShort';
                }
                if (elementName === 'dc_content_medium') {
                    return 'dcContentMedium';
                }
                if (elementName === 'linked_basecamp_preference') {
                    return 'linkedBasecampPreference';
                }
                if (elementName === 'dc_content_full') {
                    return 'dcContentFull';
                }
                return elementName;
            })
        });
    }
}
