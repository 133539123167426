import { ContentItem } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */

export class StatusHelpIcon extends ContentItem {
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'icon') {
          return 'helpIcon';
        }
        return elementName;
      },
    });
  }
}
