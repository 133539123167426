import { useMutation, useQuery } from '@tanstack/react-query';
import * as apis from '../../utils/web-apis-client';
import { getSimpleRemoteConfig } from '../../utils/remote-config-manager';
import { checkCoverage } from '../../utils/supplemental';
import { IAutoSave, ICoverageV1ResponseExt } from '../../data-models';
import { queryClient } from '../..';

export const useAutosaveGet = (formName?: string) =>
  useQuery({
    queryKey: [queryKeys.autosaveGet, formName],
    queryFn: (_context) => apis.getAutoSave(formName!),
    enabled: !!formName,
  });

export const useAutosaveGetFieldValue = (
  formName?: string,
  fieldValue?: string,
) =>
  useQuery({
    queryKey: [queryKeys.autosaveGet, formName],
    queryFn: (_context) => apis.getAutoSave(formName!),
    select: (data) => {
      if (data?.exists) {
        return data.result.data[fieldValue!];
      }
      return undefined;
    },
    enabled: !!formName && !!fieldValue,
  });

export const useAutosavePost = (formName: string) =>
  useMutation({
    mutationKey: [queryKeys.autosavePost, formName],
    mutationFn: (form: IAutoSave) => apis.postAutoSave(form),
    onSuccess: (_data, autosaveData) => {
      if (autosaveData.submitted) {
        queryClient.invalidateQueries([queryKeys.autosaveGet, formName]);
      } else {
        queryClient.setQueryData([queryKeys.autosaveGet, formName], {
          result: autosaveData,
          exists: true,
        });
      }
    },
  });

export const useAutosaveDelete = (formName: string) =>
  useMutation({
    mutationKey: [queryKeys.autosaveDelete, formName],
    mutationFn: () => apis.deleteAutoSave(formName),
  });

export const useAvailableCallbackTimes = (
  companyCode: string,
  queryDate: string,
) =>
  useQuery({
    queryKey: [queryKeys.availableCallbackTimes, companyCode, queryDate],
    queryFn: (_context) =>
      apis.getAvailableCallbackTimes(companyCode, queryDate),
    enabled: !!companyCode && !!queryDate,
  });

export const useVBCoverage = () =>
  useQuery({
    queryKey: [queryKeys.vbCoverage],
    queryFn: apis.getVBCoverage,
    select: (data) =>
      ({ ...data, hasCoverage: checkCoverage(data) } as ICoverageV1ResponseExt),
  });

export const useRemoteConfig = () =>
  useQuery({
    queryKey: [queryKeys.remoteConfig],
    queryFn: getSimpleRemoteConfig,
  });

export const useCases = () =>
  useQuery({
    queryKey: [queryKeys.cases],
    queryFn: apis.getCases,
  });

export const useDraftClaims = () =>
  useQuery({
    queryKey: [queryKeys.draftClaims],
    queryFn: apis.getDraftClaims,
  });

export const usePaymentPreferences = () =>
  useQuery({
    queryKey: [queryKeys.paymentPreferences],
    queryFn: apis.getPaymentPreferences,
  });

export const usePerson = () =>
  useQuery({
    queryKey: [queryKeys.person],
    queryFn: apis.getPerson,
  });

export const usePersonData = () =>
  useQuery({
    queryKey: [queryKeys.personData],
    queryFn: apis.getPersonData,
  });

export const usePreferences = () =>
  useQuery({
    queryKey: [queryKeys.preferences],
    queryFn: apis.getPreferences,
  });

export const useEmployerPreferences = () =>
  useQuery({
    queryKey: [queryKeys.employerPreferences],
    queryFn: apis.getEmployerPreferences,
  });

export const queryKeys = {
  autosaveDelete: 'autosaveDelete',
  autosaveGet: 'autosaveGet',
  autosavePost: 'autosavePost',
  availableCallbackTimes: 'availableCallbackTimes',
  cases: 'cases',
  disability: 'disability',
  draftClaims: 'draftClaims',
  employerPreferences: 'employerPreferences',
  leaveScenarios: 'leaveScenarios',
  paymentPreferences: 'paymentPreferences',
  person: 'person',
  personData: 'personData',
  preferences: 'preferences',
  remoteConfig: 'remoteConfig',
  vbCoverage: 'vbCoverage',
} as const;
