import { useState, useEffect } from 'react';
import { MaskedTextBox } from '@progress/kendo-react-inputs';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import {
  OpenSansSemiBoldLabelMod,
  OpenSansRegularSmallMod,
  SubmitButton,
  CellNumberLabel,
} from '../../index.sc';
import { prefixObjectValues, get, enums } from '../../../../utils';

import { colors } from '../../../../style';
import { getZelleEnrollmentsStatus } from '../../../../utils/web-apis-client';
import { matchesPattern } from '../../../forms/validators';

const templateIds = {
  foundAlias: 'zelle-preference-found-alias',
  loadingIndicator: 'zelle-preference-loading-indicator',
  notFoundText: 'zelle-preference-not-found-text',
  cellPhoneInput: 'cell-input',
};

const ZellePreferenceContainer = styled.div`
  background-color: ${colors.backgroundAlt};
  margin-bottom: 16px;
  padding-bottom: 16px;
  padding-left: 75px;
  padding-top: 16px;
`;

const MaskedTextBoxMod = styled(MaskedTextBox)`
  border: 2px solid #80bedb !important;
  width: 35%;

  input.k-textbox {
    border-color: unset !important;
    box-shadow: unset !important;
  }
`;

const ZelleText = styled(OpenSansRegularSmallMod)`
  width: 100%;
`;

const SubmitButtonMod = styled(SubmitButton)`
  margin-left: unset;
`;

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, templateIds);

export const formatPhoneForSubmission = (number: string) => {
  const bracket1 = number.replace('(', '');
  const bracket2 = bracket1.replace(')', '');
  return bracket2.replace(' ', '-');
};

export const formatPhoneForDisplay = (phoneNumber: string) => {
  const formattedPhone = [];
  if (phoneNumber.indexOf('-') === 3) {
    phoneNumber = phoneNumber.replaceAll('-', '');
  }
  formattedPhone.push(
    '(',
    phoneNumber.substring(0, 3),
    ')',
    ' ',
    phoneNumber.substring(3, 6),
    '-',
    phoneNumber.substring(6, 10),
  );
  return formattedPhone.join('');
};

const doWork = async (
  isEmail: boolean,
  setZelleAccountType: Function,
  resultCallback: Function,
  setMatchingAlias: Function,
  setProcessing: Function,
  phoneNumber: string,
  props: any,
) => {
  let response;
  setProcessing(true);
  try {
    if (isEmail) {
      response = await getZelleEnrollmentsStatus();
      setZelleAccountType('email');
    } else {
      response = await getZelleEnrollmentsStatus(
        formatPhoneForSubmission(phoneNumber),
      );
      setZelleAccountType('phone');
    }
  } catch (e) {
    console.error(e);
  }

  if (response) {
    const enrolled =
      get(response, 'aliasesEnrollmentStatus.0.aliasEnrollmentStatus', '') ===
      'TOKEN_ENROLLED';

    if (!enrolled) {
      resultCallback(false, isEmail);
    } else {
      const firstAlias = get(response, 'aliasesEnrollmentStatus.0.alias', '');
      setMatchingAlias(firstAlias);

      if (
        firstAlias.toLowerCase() === props.currentEmailPref.toLowerCase() &&
        props.currentPaymentMethod === 'Zelle'
      ) {
        resultCallback(true, isEmail);
      } else {
        resultCallback(firstAlias, isEmail);
      }
    }
  }
  setProcessing(false);
};

const ZellePreferenceOptions = (props: any) => {
  const automationIds = domIdsUnique(props.keyId);
  const [matchingAlias, setMatchingAlias] = useState<string>('');
  const [processing, setProcessing] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [zelleAccountType, setZelleAccountType] = useState<string>('email');
  const [phoneError, setPhoneError] = useState<boolean>(true);

  const { resultCallback } = props;

  const addPhoneToState = (telephoneNumber: string) => {
    const valid = matchesPattern(
      telephoneNumber,
      enums.ValidationErrors.matchesPattern,
      ['^(\\([0-9]{3}\\))[ ][0-9]{3}-[0-9]{4}$'],
    );

    if (valid === '') {
      setPhoneNumber(telephoneNumber);
      setPhoneError(false);
    } else {
      setPhoneError(true);
    }
  };

  useEffect(() => {
    handleZelleCheck(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleZelleCheck = (isEmail: boolean) => {
    doWork(
      isEmail,
      setZelleAccountType,
      resultCallback,
      setMatchingAlias,
      setProcessing,
      phoneNumber,
      props,
    );
  };

  return (
    <ZellePreferenceContainer>
      {processing && (
        <div className="text-center">
          <OpenSansSemiBoldLabelMod as="p" className="text-center">
            <em>Searching for Zelle account…</em>
          </OpenSansSemiBoldLabelMod>
          <ClipLoader
            color={colors.primaryBlue}
            loading={processing}
            size={25}
          />
        </div>
      )}
      {!processing && matchingAlias && (
        <>
          <p>
            <ZelleText>
              Your{' '}
              {zelleAccountType === 'email'
                ? 'email address'
                : 'cell phone number'}{' '}
              <strong>
                {zelleAccountType === 'email'
                  ? matchingAlias
                  : formatPhoneForDisplay(matchingAlias)}{' '}
                is enrolled in Zelle.{' '}
              </strong>
              If you would like to receive payments to this Zelle account{' '}
              <strong>click the Submit Button.</strong>
            </ZelleText>
          </p>
        </>
      )}
      {!processing && !matchingAlias && zelleAccountType === 'email' && (
        <>
          <ZelleText
            as="p"
            className="my-1"
            id={automationIds.notFoundText}
            style={{ lineHeight: '22px' }}
          >
            <p>
              Your email address{' '}
              <strong>
                {props.currentEmailPref} is not enrolled in Zelle.{' '}
              </strong>
              We can also verify your enrollment using your cell phone number.
            </p>
          </ZelleText>
          <div>
            <CellNumberLabel>Enter cell phone number:</CellNumberLabel>
            <MaskedTextBoxMod
              mask="(000) 000-0000"
              onChange={(e) => addPhoneToState(e.target.value)}
              id={automationIds.cellPhoneInput}
            />{' '}
            <br />
            <SubmitButtonMod
              id={automationIds.submitButton}
              className="k-button"
              onClick={() => handleZelleCheck(false)}
              disabled={phoneError}
            >
              Check cell phone number
            </SubmitButtonMod>
          </div>
        </>
      )}
      {!processing && !matchingAlias && zelleAccountType === 'phone' && (
        <ZelleText
          as="p"
          className="my-1"
          id={automationIds.notFoundText}
          style={{ lineHeight: '22px' }}
        >
          <p>
            Your phone number{' '}
            <strong>{phoneNumber} is not enrolled in Zelle.</strong> If you
            would like to enroll with Zelle please visit the{' '}
            <a href="https://zellepay.com" target="_blank" rel="noreferrer">
              Zelle Signup Page
            </a>
            . When you have completed the signup process return to this page to
            connect your account.
          </p>
        </ZelleText>
      )}
    </ZellePreferenceContainer>
  );
};

export { ZellePreferenceOptions };
