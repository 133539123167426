import styled from 'styled-components';
import { colors, fonts } from '../../style';
import { Upload as KendoUpload } from '@progress/kendo-react-upload';
import { Dialog as KendoDialog } from '@progress/kendo-react-dialogs';

interface ButtonProps {
  outline?: boolean;
}

const Dialog = styled(KendoDialog)`
  .k-dialog {
    height: 90%;
    max-width: 824px;
    width: 90%;
  }

  .k-window-titlebar {
    padding: 26px 30px 14px 32px;

    @media (max-width: 576px) {
      padding: 26px 16px 14px;
    }
  }

  .k-window-content {
    padding: 32px 30px 0 32px;

    @media (max-width: 576px) {
      padding: 32px 16px 0;
    }
  }

  .k-dialog-buttongroup {
    background-color: ${colors.warmNeutralL1};
    padding: 24px 30px;
  }
`;

const Upload = styled(KendoUpload)`
  align-items: center;
  border: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 14px;
  width: 100%;

  .k-dropzone {
    background-color: ${colors.white};
    padding: 0;

    @media (max-width: 576px) {
      bottom: -3px;
      position: absolute;
    }
  }

  .k-dropzone .k-button {
    background-color: ${colors.white};
    border: 1px solid ${colors.primaryBlue};
    border-radius: 30px;
    color: ${colors.primaryBlue};
    font-family: ${fonts.openSans.semibold.fontFamily};
    font-size: 14px;
    font-weight: ${fonts.openSans.semibold.fontWeight};
    min-width: 140px;
    padding: 3px 20px;
  }

  .k-upload-files {
    border: 0;
    width: 100%;
  }

  .k-file {
    margin: 0;
    padding: 0 !important;
  }

  .k-dropzone-hint {
    display: none;
  }
`;

const Container = styled.div`
  background-color: ${colors.white};

  > div:last-of-type {
    margin-bottom: 0 !important;
  }
`;

const Label = styled.div`
  color: ${colors.mineshaft};
  font-family: ${fonts.openSans.bold.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.bold.fontWeight};
  margin-bottom: 20px;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const TextBox = styled.div`
  background-color: ${colors.warmNeutralL1};
  border: 1px solid ${colors.alto2};
  border-radius: 5px;
  color: ${colors.mineshaft};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin-bottom: 26px;
  min-width: 350px;
  padding: 15px 24px;

  @media (max-width: 576px) {
    font-size: 14px;
    min-width: 0;
  }
`;

const SemiboldText = styled.div`
  color: ${colors.mineshaft};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 18px;
  font-weight: ${fonts.openSans.regular.fontWeight};

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

interface TextProps {
  size?: string;
}

const Text = styled.div<TextProps>`
  color: ${colors.mineshaft};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${(p) => p.size || '16px'};
  font-weight: ${fonts.openSans.regular.fontWeight};

  &.noOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 576px) {
    font-size: ${(p) => p.size || '14px'};
  }
`;

const Button = styled.button<ButtonProps>`
  background-color: ${(p) => (p.outline ? colors.white : colors.primaryBlue)};
  border: 1px solid ${colors.primaryBlue};
  border-radius: 30px;
  color: ${(p) => (p.outline ? colors.primaryBlue : colors.white)};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  margin-right: ${(p) => (p.outline ? '23px' : 0)};
  min-width: 140px;
  padding: 3px 20px;

  :disabled {
    opacity: 35%;
  }

  @media (max-width: 576px) {
    min-width: 120px;
  }
`;

const UploadListItem = styled.div`
  border-bottom: 1px dashed ${colors.alto2};
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;

  :last-child {
    margin-bottom: 0;
  }
`;

const Image = styled.img`
  margin-right: 6px;
  vertical-align: text-top;
`;

const ErrorText = styled.div`
  color: ${colors.errorRed};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 12px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  text-decoration: italic;
`;

export {
  Dialog,
  Upload,
  Container,
  Label,
  TextBox,
  SemiboldText,
  Text,
  Button,
  UploadListItem,
  Image,
  ErrorText,
};
