import styled from 'styled-components';
import { colors, fonts, rem } from '../../../style';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';
import React from 'react';
import { enums, get } from '../../../utils';
import { Form, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import FadeLoader from 'react-spinners/FadeLoader';
import { getLiveChatForm } from '../../../utils/remote-config-manager';
import FormText from '../../forms/form-text';
import FormField from '../../forms/form-field';
import { models } from '../../../utils/cms-client';
import { ChatButton } from '../index.sc';
import { FormContextProvider } from '../../../contexts/form-context';
import { extractConfigUrls } from '../../forms/form/generators';
import './index.css';

interface PreFormProps {
  setPreChatContext: Function;
  preChatContext: any;
}

const Container = styled.div`
  text-align: center;
  pointer-events: all;
`;

const Text = styled.p<{ fontSize?: number }>`
  color: ${colors.blue};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${(p) => rem(p.fontSize || 13)};
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

const BoldText = styled(Text)`
  font-family: ${fonts.openSans.bold.fontFamily};
  font-size: ${rem(16)};
  font-weight: ${fonts.openSans.bold.fontWeight};
  padding-bottom: 20px;
`;

const LoaderContainer = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`;

const handlePreFormInit = async (
  setQuestions: Function,
  setSubmitText: Function,
) => {
  const formItems = await getLiveChatForm();
  setQuestions(get(formItems, '0.questions', []));
  setSubmitText(get(formItems, '0.submitButtonText.value', 'Connect Me'));
};

const generateField = (question: any, i: number, formRenderProps: any) => {
  const questionType = get(question, 'system.type', '');

  if (questionType === 'form_text') {
    return (
      <FormText
        key={`lcf_${i}_`}
        keyId={`lcf_${i}_`}
        text={question}
        formValueGetter={formRenderProps.valueGetter}
      />
    );
  } else if (questionType === 'form_field') {
    return (
      <FieldWrapper key={`lcf_${i}_wrapper`} style={{ margin: '0 0 19px 0' }}>
        <FormField
          key={`lcf_${i}_`}
          keyId={`lcf_${i}_`}
          currentStepIndex={0}
          field={question as models.FormField}
          formValueGetter={formRenderProps.valueGetter}
          formOnChange={formRenderProps.onChange}
          formOptionsTemplate={''}
        />
      </FieldWrapper>
    );
  } else {
    return null;
  }
};

const PreForm: (p: PreFormProps) => React.ReactElement | null = (p) => {
  const { callCenter, localizedStrings, config } =
    React.useContext(KenticoDataContext);
  const [hideOptions, setHideOptions] = React.useState(
    p.preChatContext?.connect || false,
  );
  const [questions, setQuestions] = React.useState([]);
  const [submitText, setSubmitText] = React.useState('');

  React.useEffect(() => {
    handlePreFormInit(setQuestions, setSubmitText);
  }, []);

  const handleSubmit = (e: any) => {
    p.setPreChatContext({ answers: e, connect: true });
    setHideOptions(true);
  };

  if (callCenter.open) {
    return (
      <>
        {!hideOptions ? (
          <Container id="live-chat-preform">
            <FormContextProvider
              formContext={{
                configUrls: extractConfigUrls(config),
              }}
            >
              <Form
                onSubmit={handleSubmit}
                render={(formRenderProps) => (
                  <FormElement>
                    {get(questions, 'value', []).map(
                      (question: any, i: number) =>
                        generateField(question, i, formRenderProps),
                    )}
                    <ChatButton
                      id="live-chat-form-submit_button"
                      type={'submit'}
                      disabled={!formRenderProps.allowSubmit}
                      buttonStyle={enums.ButtonTypes.primary}
                    >
                      {submitText}
                    </ChatButton>
                  </FormElement>
                )}
              />
            </FormContextProvider>
          </Container>
        ) : (
          <div
            id="live-chat-connection-loader"
            style={{ textAlign: 'center', marginLeft: '11px' }}
          >
            <LoaderContainer>
              <FadeLoader
                height={14}
                width={5}
                radius={2}
                margin={0}
                color={colors.blue}
              />
            </LoaderContainer>
            <BoldText>
              {localizedStrings?.live_chat_please_wait?.translatedText ||
                'Please wait while we connect you to a specialist.'}
            </BoldText>
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};

export default PreForm;
