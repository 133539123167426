import React from 'react';
import { prefixObjectValues } from '../../../utils';
import HeaderMessageTemplate from '../header-message-template';
import ChatEventTemplate from '../chat-event-template';
import { useHistory } from 'react-router-dom';
import * as marked from 'marked';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';
import PreForm from '../pre-form';
import ConnectionFailedTemplate from '../connection-failed-template';

const domIdsStatic = {
  rootNode: 'message-bubble',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

function MessageTemplate(props: any) {
  const message = props.item;
  const domIds = domIdsUnique(props.keyId);
  const history = useHistory();
  const { callCenter } = React.useContext(KenticoDataContext);

  React.useEffect(() => {
    const anchorTags = document.querySelectorAll('.live-chat-message a[href]');
    const hostname = window.location.hostname;
    anchorTags.forEach((aTag: any) => {
      let url = aTag.href;
      if (url.includes(hostname)) {
        if (aTag.getAttribute('clicklistener') !== 'true') {
          aTag.addEventListener('click', function (e: any) {
            e.preventDefault();
            url = url.replace('https://' + hostname, '');

            history.push(url);
          });
          aTag.setAttribute('clicklistener', 'true');
        }
      } else {
        aTag.setAttribute('target', '_blank');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (message.type) {
    case 'header':
      return <HeaderMessageTemplate />;
    case 'preForm':
      return (
        <PreForm
          setPreChatContext={message.setPreChatContext}
          preChatContext={message.preChatContext}
        />
      );
    case 'connectionFailed':
      return <ConnectionFailedTemplate message={message} />;
    case 'chatEvent':
      return callCenter.open ? (
        <ChatEventTemplate {...message.chatEventProps} />
      ) : null;
  }

  // parse markdown language
  const parser = marked.setOptions({});
  const parsedMessage = parser.parse(message.text);
  const htmlToinsert = { __html: parsedMessage };

  return (
    <div
      id={domIds.rootNode}
      className="k-bubble live-chat-message"
      style={{ pointerEvents: 'all' }}
    >
      <div dangerouslySetInnerHTML={htmlToinsert} />
    </div>
  );
}
export default MessageTemplate;
