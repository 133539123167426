import { rem } from '../../style';
import {
  defaults,
  getFont,
} from '../../components/forms/simple-styled-components';
import dayjs from 'dayjs';
import { getOptionValue } from '../../components/forms/options';
import { ElementModels } from '@kentico/kontent-delivery';
import { NoticeRequirementsKeys } from '../../utils/enums';
import { enums, get } from '../../utils';

const ERROR_MSG = 'No formValueGetter provided';
const msInHour = 1000 * 60 * 60;

const timeZoneMap: { [name: string]: string } = {
  EDT: 'ET',
  EST: 'ET',
  PDT: 'PT',
  PST: 'PT',
  MDT: 'MT',
  MST: 'MT',
  CST: 'CT',
  CDT: 'CT',
};

export type FormValueGetter = (v: string) => string;

export const argsReduce = function (args: any[], reducer: any) {
  args = Array.from(args);
  args.pop(); // => options
  const first = args.shift();
  return args.reduce(reducer, first);
};

export const getInternalResult = (o: any) => {
  let v = '';

  if (o.fn) {
    v = o.fn() || '';
    if (v.length === 0) {
      v = o.fn(o.data?.root);
    }
  }

  return v;
};

export const getParams = (args: any[]) => {
  const options = args.pop();

  return {
    params: args,
    options,
  };
};

const singularMap: { [key: string]: string } = {
  Calendar: NoticeRequirementsKeys.calendar_singular,
  Working: NoticeRequirementsKeys.working_singular,
};

const pluralMap: { [key: string]: string } = {
  Calendar: NoticeRequirementsKeys.calendar_plural,
  Working: NoticeRequirementsKeys.working_plural,
};

export interface IHelperOptions {
  hb?: any;
  formValueGetter?: (v: string) => any;
  allAssetReferences?: { [name: string]: ElementModels.AssetModel };
  config?: any;
  localizedStrings?: any;
  dynamicContent?: any;
  context?: any;
}

/**
 * Map for Handlebars registerHelper() functions
 *
 * You will always get an IHelperOptions as the first argument and the standard
 * registerHelper arguments after that.
 *
 * https://handlebarsjs.com/guide/expressions.html#helpers
 */
export const helperMap: {
  [name: string]: (ho: IHelperOptions, ...args: any[]) => any;
} = {
  ff: (ho, param) => {
    if (!ho.formValueGetter) {
      throw Error(ERROR_MSG);
    }

    return ho.formValueGetter(param);
  },
  formField: (ho, ...args) => {
    if (!ho.formValueGetter) {
      throw Error(ERROR_MSG);
    }

    const { params, options } = getParams(args);
    const formFieldName = getInternalResult(options);

    return getOptionValue(
      `$formField.${params.length > 0 ? params[0] : formFieldName}`,
      ho.formValueGetter,
      {},
    )?.toString();
  },
  formatArray: (ho, ...args) => {
    if (!ho.formValueGetter) {
      throw Error(ERROR_MSG);
    }

    const { params, options } = getParams(args);
    const formFieldName = getInternalResult(options);

    const fieldValue = getOptionValue(
      `$formField.${params.length > 0 ? params[0] : formFieldName}`,
      ho.formValueGetter,
      {},
    );

    return fieldValue?.join(', ');
  },
  ifDefined: (ho, param, options) => {
    if (!ho.formValueGetter) {
      throw Error(ERROR_MSG);
    }

    const v = ho.formValueGetter(param);
    const isDefined = !(v === undefined || v === null);
    return isDefined ? options.fn(this) : null;
  },
  omitBlankLines: (_ho, options) => options?.fn(this)?.replace(/[\n]+/g, '\n'),
  style: (_ho, o) => {
    const f = getFont(o.hash['fontName'], o.hash['fontType']);

    const finalCss = `
      font-family: ${f.fontFamily};
      font-weight: ${f.fontWeight};
      font-size: ${rem(o.hash['fontSize'] || defaults.fontSize)};
      color: ${o.hash['color'] || defaults.color};
    `;

    return `<span style="${finalCss}">${o.fn(o.data.root)}</span>`;
  },
  dateFormat: (_ho, ...args) => {
    // value
    const { params, options } = getParams(args);
    let v = getInternalResult(options);

    if (v.length === 0) {
      v = params[1];
    }

    return dayjs(params.length > 1 ? params[1] : v).format(params[0]);
  },
  addDays: (_ho, param, date) => {
    if (date?.length > 0) {
      return dayjs(date).add(param, 'day').toISOString();
    }

    return '';
  },
  calcRTWDate: (_ho, ...args) => {
    const { options: o } = getParams(args);
    let returnDate = dayjs().add(6, 'M').add(-1, 'd');

    if (o.hash['leaveAllAtOnce']) {
      returnDate = o.hash['isSpecificDate']
        ? dayjs(o.hash['RTWDate'])
        : dayjs(o.hash['startDate']).add(o.hash['RTWRange'], 'd');
    }

    return returnDate.format('YYYY-MM-DD');
  },
  json: (_ho, p) => JSON.stringify(p).replace(/"/g, '\\"'),
  stringify: (_ho, p) => JSON.stringify(p),
  assetUrlByFilename: (ho, ...args) => {
    if (!ho.allAssetReferences) {
      throw Error('No assetReferences provided');
    }

    const { options } = getParams(args);
    const fileName = getInternalResult(options);
    return ho.allAssetReferences[fileName]?.url;
  },
  assetDescByFilename: (ho, ...args) => {
    if (!ho.allAssetReferences) {
      throw Error('No assetReferences provided');
    }

    const { options } = getParams(args);
    const fileName = getInternalResult(options);
    return ho.allAssetReferences[fileName]?.description || '';
  },
  remoteConfigByName: (ho, ...args) => {
    if (!ho.config) {
      throw Error('No remote config provided');
    }

    const { options } = getParams(args);
    const key = getInternalResult(options);
    return ho.config[key] || '';
  },
  noticeRequirements: (ho, preferenceName) => {
    //Get the notice requirement from the employer preferences
    const preferences = ho.context?.employerPreferences?.preferences || [];
    const noticeRequirement =
      preferenceName in preferences
        ? ho.context.employerPreferences.preferences[preferenceName]
        : '';

    //Split the notice requirement by time and time basis. Return default if preferences is not formatted as expected.
    const noticeRequirementParts = noticeRequirement.split(' ');
    if (noticeRequirementParts.length < 2) {
      return ho.hb.compile(
        ho.localizedStrings[NoticeRequirementsKeys.asap].translatedText,
      )(ho.context);
    }

    //Return the correct localized string based on the time and basis.
    const time = parseInt(noticeRequirementParts[0]);
    const timeBasis = noticeRequirementParts[1];
    let translatedText;
    if (time === 1 && timeBasis in singularMap) {
      //get singular values
      const slsKey = singularMap[timeBasis];
      translatedText = ho.localizedStrings[slsKey].translatedText;
    } else if (time > 1 && timeBasis in pluralMap) {
      //get plural values
      const plsKey = pluralMap[timeBasis];
      translatedText = ho.localizedStrings[plsKey].translatedText;
    } else {
      //Handle unknown and 0 values
      translatedText =
        ho.localizedStrings[NoticeRequirementsKeys.asap].translatedText;
    }

    return ho.hb.compile(translatedText)({
      ...ho.context,
      days: noticeRequirementParts[0],
    });
  },
  dynamicByName: (ho, name, medium) => {
    if (!ho.dynamicContent) {
      throw Error('No dynamic content found');
    }

    return ho.hb.compile(ho.dynamicContent[name][medium])();
  },
  localizedByName: (ho, name) => {
    if (!ho.localizedStrings) {
      throw Error('No localized strings found');
    }

    return ho.hb.compile(ho.localizedStrings[name]?.translatedText || '')(
      ho.context,
    );
  },
  arrayObjectValueIncludes: (ho, arrayContextPath, key, valueIncludes) => {
    const array: Array<{ [name: string]: string | number }> = get(
      ho.context,
      arrayContextPath,
      [],
    );
    return array.some((a) => a[key]?.toString().includes(valueIncludes));
  },
  arrayIncludes: (_ho, arr, valueIncludes) => {
    const array: Array<string | number> = arr || [];
    return array.includes(valueIncludes);
  },
  displayPaymentPreferences: (ho, code) => {
    const erIds = ho.config[enums.RemoteConfigKeys.hidePrefsForId] || '-1';
    const hideForId = erIds.split('|').includes(code);
    const showPaymentPrefs = ho.config[enums.RemoteConfigKeys.showPaymentPrefs];
    return showPaymentPrefs && !hideForId;
  },
  getMilitaryDescription: (ho) => {
    if (!ho.formValueGetter) {
      throw Error(ERROR_MSG);
    }

    const militaryReason = ho.formValueGetter('militaryExigencyReason');

    switch (militaryReason) {
      case 'Short-notice deployment':
        return enums.MilitaryReasonDescriptions.shortNoticeDeployment;

      case 'Military events and related activities':
        return enums.MilitaryReasonDescriptions.militaryEvents;

      case 'Childcare and school activities for the military member’s minor child or adult disabled child':
        return enums.MilitaryReasonDescriptions.childcareActivities;

      case 'Financial and legal arrangements':
        return enums.MilitaryReasonDescriptions.financialArrangements;

      case 'Counseling by someone other than a health care provider':
        return enums.MilitaryReasonDescriptions.counselling;

      case 'Rest and recuperation leave':
        return enums.MilitaryReasonDescriptions.restLeave;

      case 'Post-deployment activities':
        return enums.MilitaryReasonDescriptions.postDeploymentActivities;

      case 'Address issues that arise from the death of the military member while on active duty':
        return enums.MilitaryReasonDescriptions.militaryDeath;

      case 'Parental care for the service member’s parent who is incapable of self-care':
        return enums.MilitaryReasonDescriptions.parentalCare;

      case 'Additional activities':
        return enums.MilitaryReasonDescriptions.additionalActivities;
    }

    return '';
  },
  convertToLocalTimeZone: (ho, formField) => {
    if (!ho.formValueGetter) {
      throw Error(ERROR_MSG);
    }

    const time: any = ho.formValueGetter(formField);
    if (!time) {
      return '';
    }
    const timestamp = time.startTime;
    const startDate = new Date(timestamp);
    const startTime =
      startDate.getHours() > 12
        ? startDate.getHours() - 12
        : startDate.getHours();
    const endTime = startTime === 12 ? 1 : startTime + 1;
    const zoneValue = startDate
      .toLocaleDateString('en-US', {
        day: '2-digit',
        timeZoneName: 'short',
      })
      .slice(4);
    const amPM = startDate.getHours() + 1 >= 12 ? 'PM' : 'AM';
    const zone = timeZoneMap[zoneValue] || 'your time';
    return `${startTime}-${endTime} ${amPM} ${zone} on ${startDate.toLocaleDateString()}`;
  },
  calcTimeDifference: (_ho, o) => {
    const time1 = new Date(o.hash['time1']);
    const time2 = new Date(o.hash['time2']);
    const returnValue = o.hash['returnValue'];
    if (
      !(time1 instanceof Date) ||
      isNaN(time1.getTime()) ||
      !(time2 instanceof Date) ||
      isNaN(time2.getTime())
    ) {
      return 'invalid date';
    }

    let hours = dayjs(time2).diff(dayjs(time1), 'h');
    let mins = dayjs(time2).diff(dayjs(time1), 'minute') - hours * 60;

    if (hours < 0 || mins < 0) {
      time2.setDate(time2.getDate() + 1);
      hours = dayjs(time2).diff(dayjs(time1), 'h');
      mins = dayjs(time2).diff(dayjs(time1), 'minute') - hours * 60;
    }

    if (returnValue === 'hours') {
      return hours;
    } else if (returnValue === 'mins') {
      return mins;
    }
    return '';
  },
  durationLessThanOrEqual: (_ho, o) => {
    const dt1 = new Date(o.hash['time1']);
    const dt2 = new Date(o.hash['time2']);
    const diffValue = o.hash['diffValue'];

    let diff = (dt1.getTime() - dt2.getTime()) / msInHour;
    if (diff < 0) {
      dt1.setDate(dt1.getDate() + 1);
      diff = (dt1.getTime() - dt2.getTime()) / msInHour;
    }

    return diff <= diffValue;
  },
  durationGreaterThan: (_ho, o) => {
    const dt1 = new Date(o.hash['time1']);
    const dt2 = new Date(o.hash['time2']);
    const diffValue = o.hash['diffValue'];

    let diff = (dt1.getTime() - dt2.getTime()) / msInHour;
    if (diff < 0) {
      dt1.setDate(dt1.getDate() + 1);
      diff = (dt1.getTime() - dt2.getTime()) / msInHour;
    }

    return diff > diffValue;
  },
  checkRefreshStatusIs: (ho, caseId, match) => {
    const refreshDetails =
      ho?.context?.refresh?.refreshDetails?.refreshCaseDetail ?? [];

    const statusDetail = refreshDetails.find(
      (details: any) => details?.sourceKey === caseId,
    );

    return statusDetail?.status === match;
  },
  checkRefreshStatusIsNot: (ho, caseId, match) => {
    const refreshDetails =
      ho?.context?.refresh?.refreshDetails?.refreshCaseDetail ?? [];

    const statusDetail = refreshDetails.find(
      (details: any) => details?.sourceKey === caseId,
    );

    return statusDetail?.status !== match;
  },

  // to support #if conditionals
  eq: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a === b),
  ne: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a !== b),
  lt: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a < b),
  gt: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a > b),
  lte: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a <= b),
  gte: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a >= b),
  and: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a && b),
  or: (_ho, ...args) => argsReduce(args, (a: any, b: any) => a || b),
};

export const registerSelf = (
  hb: any,
  formValueGetter?: IHelperOptions['formValueGetter'],
  allAssetReferences?: IHelperOptions['allAssetReferences'],
  config?: IHelperOptions['config'],
  localizedStrings?: IHelperOptions['localizedStrings'],
  dynamicContent?: IHelperOptions['dynamicContent'],
  context?: IHelperOptions['context'],
) => {
  for (const key of Object.keys(helperMap)) {
    hb.registerHelper(key, (...args: any[]) =>
      helperMap[key].apply(this, [
        {
          hb,
          formValueGetter,
          allAssetReferences,
          config,
          localizedStrings,
          dynamicContent,
          context,
        },
        ...args,
      ]),
    );
  }
};
