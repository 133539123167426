
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class LiveChatForm extends ContentItem {
    public submitButtonText!: Elements.TextElement;
    public questions!: Elements.LinkedItemsElement<ContentItem>;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'submit_button_text') {
                    return 'submitButtonText';
                }
                return elementName;
            })
        });
    }
}
