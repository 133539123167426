import styled from 'styled-components';
import { colors, rem } from '../../../../style';
import { FormContext } from '../../../../contexts/form-context';
import React from 'react';
import { Text } from '../../simple-styled-components';
import { enums } from '../../../../utils';
import Button from '../../../base/button';
import { FieldRenderProps } from '@progress/kendo-react-form';
import UploadModal, { IUploadText } from '../../../upload-modal';

export interface IUploadFieldProps extends IUploadText, FieldRenderProps {
  fieldText?: string;
  fieldButtonText?: string;
  fieldCompleteText?: string;
  completed?: boolean;
  caseId?: string;
}

export interface IUploadResult {
  result: enums.UploadStatus;
}

export const DEFAULTS = {
  fieldText: 'Your files',
  fieldButtonText: 'Select & upload file(s)',
  fieldCompleteText: 'UPLOADED',
};

const Container = styled.div`
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CompletedContainer = styled.div`
  border: none;
  background-color: ${colors.iceberg};
  border-radius: ${rem(24)};
  padding: ${rem(5)} ${rem(15)};
  color: ${colors.greenHaze};

  .k-icon {
    transform: scale(1.25);
  }
`;

/**
 * Handle returns from the upload modal
 * @param setModalOpen Shows or hides the upload modal
 * @param setCompleted True hides the button and shows the completed text
 * @param onChange Passed in from the Kendo form field, call to update the form's field value
 * @param result Passed in from the modal, currently a string corresponding to enums.UploadStatus
 */
export const handleCallback = (
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setCompleted: React.Dispatch<React.SetStateAction<boolean>>,
  onChange: (event: { target?: any; value?: any }) => void,
  result?: enums.UploadStatus,
) => {
  // only when all uploads are successful do we hide the upload button and consider the field answered
  if (result === enums.UploadStatus.allSuccessful) {
    setCompleted(true);
    onChange({
      value: {
        result,
      },
    });
  }

  // in any case, close the modal
  setModalOpen(false);
};

const Upload: (p: IUploadFieldProps) => React.ReactElement = ({
  onChange,
  fieldText,
  fieldButtonText,
  fieldCompleteText,
  modalTitle,
  modalInitialDesc,
  modalSuccessDesc,
  modalSomeErrorDesc,
  modalAllErrorDesc,
  modalCloseButton,
  ...others
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [completed, setCompleted] = React.useState(
    others.completed ? true : false,
  );
  const { formContext } = React.useContext(FormContext);

  return (
    <Container id={others.id}>
      <Text>{fieldText || DEFAULTS.fieldText}</Text>
      {completed ? (
        <CompletedContainer>
          <span className="mr-2">
            {fieldCompleteText || DEFAULTS.fieldCompleteText}
          </span>
          <span className="k-icon k-i-check" />
        </CompletedContainer>
      ) : (
        <Button
          id="form-upload-button"
          type="button"
          buttonStyle={enums.ButtonTypes.primary}
          onClick={() => setModalOpen(true)}
        >
          {fieldButtonText || DEFAULTS.fieldButtonText}
        </Button>
      )}
      {modalOpen && (
        <UploadModal
          planId={formContext?.currentLeavePlan?.planId}
          caseId={others?.caseId}
          modalCallback={(result) =>
            handleCallback(setModalOpen, setCompleted, onChange, result)
          }
          text={{
            modalTitle,
            modalInitialDesc,
            modalSuccessDesc,
            modalSomeErrorDesc,
            modalAllErrorDesc,
            modalCloseButton,
          }}
        />
      )}
    </Container>
  );
};

export default Upload;
