import React from 'react';
import ReactDOM from 'react-dom';
import './style/root.scss';
import './tw-output.css';
import AppStartupHandler from './components/base/app-startup-handler';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Landing from './components/base/landing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

if (process.env.NODE_ENV !== 'production') {
  window.LOG_LEVEL = 'DEBUG';
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 7200000, // 2 hours
      staleTime: Infinity,
    },
  },
});

Object.defineProperty(global, 'connectSession', {
  value: null,
  writable: true,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route path="/app">
          <QueryClientProvider client={queryClient}>
            <AppStartupHandler />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
