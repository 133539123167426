import React from 'react';
import styled from 'styled-components';
import { colors, fonts, rem } from '../../style';
import { PersonDataContext } from '../../contexts/person-data-context';
import { PreferencesContext } from '../../contexts/preferences-context';
import { getStartAClaimContent } from '../../utils/remote-config-manager';
import { models } from '../../utils/cms-client';
import { supplemental, enums } from '../../utils';
import * as dm from '../../data-models';
import { FullScreenLoadingIndicator } from '../base/full-screen-loading-indicator';
import {
  IKenticoData,
  KenticoDataContext,
} from '../../contexts/kentico-data-context';
import CardSection from './card-section';
import Button from '../base/button';
import { useHistory } from 'react-router-dom';
import { Form, FormElement } from '@progress/kendo-react-form';
import { useVBCoverage } from '../../hooks/use-data';

const INTAKE_TYPE = 'intake-type';

const Container = styled.div`
  background-color: ${colors.coolNeutralL1};
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1007px;

  p {
    margin: 0;
  }
`;

const Header = styled.h1`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: ${rem(36)};
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  line-height: 48px;
  margin: 0 0 18px;
  text-align: left;
  width: 100%;
`;

const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 96px;
`;

const handleUseEffect = async (p: {
  setContent: React.Dispatch<
    React.SetStateAction<models.IntakeSelection | undefined>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  employerPreferences: dm.EmployerPreferences | undefined;
  config: IKenticoData[enums.KenticoDataKeys.config];
  setSteps: React.Dispatch<React.SetStateAction<models.IntakeSection[]>>;
  ERContent: any;
  hasAnyCoverage: () => boolean | undefined;
  companyCode?: string;
}) => {
  const pageContent = await getStartAClaimContent();
  const steps = pageContent.item.sections.value;
  p.setContent(pageContent.item);

  const excludedPlans = (p.ERContent?.excludedLeavePlans || []).map(
    (excludedPlan: any) => excludedPlan.codename,
  );

  let filteredSteps = steps;
  if (excludedPlans.length > 0) {
    filteredSteps = steps.map((step) => {
      step.contentItems.value = step.contentItems.value.filter(
        (item) => !excludedPlans.includes(item.intake.value[0].codename),
      );
      return step;
    });
  }

  const hideADAByEmployerList =
    p.config['hide_ada_by_employer']?.split('|') || [];
  const hideADA = hideADAByEmployerList.includes(p.companyCode);
  const showADA =
    !hideADA && p.employerPreferences?.preferences?.ADA === 'Carrier';

  const hasCoverage = p.hasAnyCoverage();
  const employerEnabled = await supplemental.isEmployerEnabled(p.companyCode);
  const showSupplemental = hasCoverage && employerEnabled;

  if (!showADA) {
    filteredSteps[0].contentItems.value =
      filteredSteps[0].contentItems.value.filter(
        (item) => item.system.codename !== 'start__ada_card',
      );
  }
  if (!showSupplemental) {
    filteredSteps[0].contentItems.value =
      filteredSteps[0].contentItems.value.filter(
        (item) => item.system.codename !== 'supplementary_benefits',
      );
  }

  //Remove first page if not an ADA Carrier
  p.setSteps(
    showADA || showSupplemental
      ? filteredSteps
      : filteredSteps.slice(1, filteredSteps.length),
  );
  p.setLoading(false);
};

const StartAClaim: () => React.ReactElement = () => {
  const { personData } = React.useContext(PersonDataContext);
  const { employerPreferences, ERContent } =
    React.useContext(PreferencesContext);
  const { config } = React.useContext(KenticoDataContext);
  const [loading, setLoading] = React.useState(true);
  const [formState, setFormState] = React.useState<any>({});
  const [content, setContent] = React.useState<
    models.IntakeSelection | undefined
  >(undefined);
  const [step, setStep] = React.useState(0);
  const [steps, setSteps] = React.useState<models.IntakeSection[]>([]);
  const history = useHistory();

  const { data: vbCoverageData, isFetching: vbCovFetching } = useVBCoverage();
  const hasAnyCoverage = React.useCallback(
    () => vbCoverageData?.hasCoverage || false,
    [vbCoverageData],
  );

  React.useEffect(() => {
    handleUseEffect({
      setContent,
      setLoading,
      employerPreferences,
      config,
      setSteps,
      ERContent,
      hasAnyCoverage,
      companyCode: personData?.person?.companyCode,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personData, employerPreferences, vbCoverageData]);

  const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const isPreviousStepsValid =
    steps
      .slice(0, step)
      .findIndex((currentStep) => currentStep.isValid === false) === -1;

  const onStepSubmit = React.useCallback(
    (event) => {
      const { isValid, values } = event;

      // if intake-type is "supplemental", go to /app/supplemental
      if (isValid && values[INTAKE_TYPE] === 'supplemental') {
        history.push(enums.AppPaths.supplemental);
        return;
      }

      if (isValid) {
        const currentSteps = steps.map((currentStep: any, index: number) => ({
          ...currentStep,
          isValid: index === step ? isValid : currentStep.isValid,
        }));

        setSteps(currentSteps);
        setStep(() => Math.min(step + 1, lastStepIndex));
        setFormState(values);
      }

      if (!isLastStep && isValid && values[INTAKE_TYPE] !== 'none') {
        history.push(`${enums.AppPaths.form}/${values[INTAKE_TYPE]}`);
      }

      if (isLastStep && isPreviousStepsValid && isValid) {
        history.push(`${enums.AppPaths.form}/${values['leave-type']}`);
      }
    },
    [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex, history],
  );

  const onPrevClick = React.useCallback(
    (event) => {
      event.preventDefault();
      setStep(() => Math.max(step - 1, 0));
    },
    [step, setStep],
  );

  if (loading || vbCovFetching) {
    return <FullScreenLoadingIndicator />;
  }

  return (
    <Container id="start-a-claim-container">
      <Header>{steps[step].header.value}</Header>
      <Form
        initialValues={formState}
        onSubmitClick={onStepSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <CardSection content={steps[step]} name={steps[step].name.value} />
            <ButtonGroupContainer>
              {step !== 0 && (
                <Button
                  id="intake-back-button"
                  buttonStyle={enums.ButtonTypes.secondary}
                  style={{ marginRight: 'auto' }}
                  onClick={onPrevClick}
                >
                  {content?.backButtonText?.value}
                </Button>
              )}
              <Button
                id="intake-next-button"
                buttonStyle={enums.ButtonTypes.primary}
                disabled={
                  !isPreviousStepsValid ||
                  !formRenderProps.allowSubmit ||
                  !formRenderProps.modified
                }
                onClick={formRenderProps.onSubmit}
              >
                {content?.nextButtonText?.value}
              </Button>
            </ButtonGroupContainer>
          </FormElement>
        )}
      />
    </Container>
  );
};

export default StartAClaim;
