import styled from 'styled-components';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { colors, fonts, rem } from '../../../style';
import CloseX from '../../../images/modal-x-lg.svg';
import { useState, cloneElement } from 'react';
import { TextArea } from '../../forms/simple-styled-components';
import {
  DropDownList as KendoDropDownList,
  DropDownListChangeEvent,
  ListItemProps,
} from '@progress/kendo-react-dropdowns';
import {
  ItemRenderWrapper,
  CancelButton as SharedCancelButton,
  DialogActionsStyle,
  SubmitButton as SharedSubmitButton,
  RegularCustom as RegularText,
} from '../simple-styled-components';

const CancelButton = styled(SharedCancelButton)`
  width: fit-content;
`;

const SubmitButton = styled(SharedSubmitButton)`
  width: fit-content;
`;

const DropDownList = styled(KendoDropDownList)<any>`
  max-width: ${(p) => p.maxwidth};
  width: 100%;

  .k-dropdown-wrap {
    background-color: ${colors.white};
    border-color: ${colors.alto};
    height: 56px;
  }
  .k-input,
  option {
    align-self: center;
    color: ${colors.warmNeutralL3};
    font-family: ${fonts.openSans.regular.fontFamily};
    font-weight: ${fonts.openSans.regular.fontWeight};
  }
`;

const ItemRenderWrapperMod = styled(ItemRenderWrapper)`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: space-around;
  padding: 25px 15px;
`;

const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RegularCustom = styled(RegularText)<{ bold?: boolean }>``;

const DropDownItemRender = (
  li: React.ReactElement,
  itemProps: ListItemProps,
) => {
  const newLiProps = {
    ...li.props,
    style: {
      padding: 0,
      margin: 0,
      borderBottom: `1px solid ${colors.geyser}`,
    },
  };

  const data = itemProps.dataItem;

  const itemChildren = (
    <Container>
      <ItemRenderWrapperMod>
        <div>{data}</div>
      </ItemRenderWrapperMod>
    </Container>
  );

  return cloneElement(li, newLiProps, itemChildren);
};

const DialogMod = styled(Dialog)`
  .k-window {
    max-height: 675px;
    max-width: 557px;
    width: 90%;
  }
  .k-dialog-title {
    color: ${colors.warmNeutralL3};
    font-family: ${fonts.openSans.semibold.fontFamily};
    font-size: ${rem(18)};
    font-weight: ${fonts.openSans.semibold.fontWeight};
  }
  .k-window-titlebar {
    border-bottom-width: 0;
    padding: 20px 20px 0;
  }
  .k-dialog-close .k-icon.k-i-x::before {
    content: url(${CloseX});
  }
  .k-window-content {
    padding: 20px;
  }
  .k-dialog-buttongroup {
    border-top-width: 0;
  }
`;

/**
 * A ConfirmDialogModal component
 * @param {object} props
 */
const OptionsModal = (props: OptionsModalProps) => {
  const [option, setOption] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [mandatoryError, setMandatoryError] = useState<boolean>(true);

  const handleClick = (confirm = true) => {
    if (props.handleConfirm && confirm) {
      props.handleConfirm(option, text);
    }
    props.modalCallback(confirm);
  };

  const handleOptionChange = (e: DropDownListChangeEvent) => {
    setOption(e.target.value);
    setMandatoryError(false);
  };

  return (
    <DialogMod
      id="confirmation-dialog"
      onClose={() => props.modalCallback(false)}
      title={props.title}
    >
      <RegularCustom bold>{props.description}</RegularCustom>
      <div className="mt-4 mb-4">
        <DropDownList
          data={props.options}
          itemRender={(li: any, items: any) => DropDownItemRender(li, items)}
          id="options-dropdown"
          defaultValue="No option selected"
          onChange={(e: DropDownListChangeEvent) => handleOptionChange(e)}
        ></DropDownList>
      </div>
      {props.useTextBox && option === props.useTextBox && (
        <TextArea id="text-box" onChange={(e) => setText(e.value)}></TextArea>
      )}
      <DialogActionsBar>
        <DialogActionsStyle>
          <CancelButton
            id="confirm-dialog-modal-cancel-button"
            onClick={() => handleClick(false)}
            {...props.dataAttributes}
          >
            {props.cancelButtonText ? props.cancelButtonText : 'Cancel'}
          </CancelButton>
          <SubmitButton
            disabled={mandatoryError}
            id={
              props.confirmButtonCustomId ||
              'confirm-dialog-modal-confirm-button'
            }
            onClick={() => handleClick(true)}
            {...props.dataAttributes}
          >
            {props.confirmButtonText}
          </SubmitButton>
        </DialogActionsStyle>
      </DialogActionsBar>
    </DialogMod>
  );
};

interface OptionsModalProps {
  title: string;
  description: string;
  markdown?: boolean;
  confirmButtonText: string;
  cancelButtonText?: string;
  modalCallback: any;
  handleConfirm?: any;
  dataAttributes?: any;
  confirmButtonCustomId?: string;
  cancelButtonCustomId?: string;
  options?: string[];
  useTextBox?: string; //should be one of the strings from options
  mandatory?: boolean;
}

export default OptionsModal;
