
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class AccordionCardItem extends ContentItem {
    public summary!: Elements.TextElement;
    public article!: Elements.AssetsElement;
    public thumbnail!: Elements.AssetsElement;
    public content!: Elements.CustomElement;
    public headline!: Elements.TextElement;
    public optionsTemplate!: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: (elementName: string) => {
                if (elementName === 'options_template') {
                    return 'optionsTemplate';
                }
                return elementName;
            },
        });
    }
}
