import { log } from '../../../utils/log';
import React from 'react';
import { Error } from '../simple-styled-components';

// const log = new Logger\('form-error-boundary');

export default class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    log.error(error);
    return { hasError: true };
  }

  render() {
    if ((this.state as any).hasError) {
      return <Error>Unable to render this field</Error>;
    }

    return this.props.children;
  }
}
