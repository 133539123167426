import React from 'react';
import styled from 'styled-components';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { colors, fonts, rem } from '../../../../style';
import CloseX from '../../../../images/modal-x-lg.svg';
import {
  CancelButton,
  DialogActionsStyle,
  SubmitButton,
  RegularCustom as StyledText,
  SectionSeparator,
} from '../../../base/simple-styled-components';
import { MaskedTextBox } from '../../../forms/simple-styled-components';
import { formatPhoneNumber } from '..';

const RegularCustom = styled(StyledText)<{ bold?: boolean }>``;

const SubmitButtonMod = styled(SubmitButton)`
  width: auto;
`;

const ActionBar = styled(DialogActionsStyle)`
  padding: 20px;
  text-align: right;

  > button {
    border-radius: 40px !important;
  }
`;

const DialogMod = styled(Dialog)`
  z-index: 10009;
  .k-window {
    max-height: 675px;
    max-width: 557px;
    width: 90%;
    z-index: 10009;
  }
  .k-overlay {
    z-index: 10009;
  }
  .k-dialog-title {
    color: ${colors.warmNeutralL3};
    font-family: ${fonts.openSans.semibold.fontFamily};
    font-size: ${rem(18)};
    font-weight: ${fonts.openSans.semibold.fontWeight};
  }
  .k-window-titlebar {
    border-bottom-width: 0;
    padding: 0 20px;
    padding-top: 20px;
  }
  .k-dialog-close .k-icon.k-i-x::before {
    content: url(${CloseX});
  }
  .k-window-content {
    padding: 0 20px;
  }
  .k-dialog-buttongroup {
    border-top-width: 0;
    padding: 0;
  }
`;

/**
 * A ConfirmDialogModal component
 * @param {object} props
 */
const PhoneNumberModal = (props: PhoneNumberModalProps) => {
  const [validNumber, setValidNumber] = React.useState<boolean>(false);

  const handleClick = (confirm = true) => {
    if (props.handleConfirm && confirm) {
      props.handleConfirm();
    }
    props.modalCallback(confirm);
  };

  const validateNumber = (number: string) => {
    const formatted = formatPhoneNumber(number);
    if (formatted.length === 10) {
      setValidNumber(true);
      props.setNumber(formatted);
    }
  };

  return (
    <DialogMod
      id="confirmation-dialog"
      onClose={() => props.modalCallback(false)}
      title={props.title}
    >
      <SectionSeparator />
      <RegularCustom bold>{props.description}</RegularCustom>
      <br />
      <div>New Mobile Number</div>
      <MaskedTextBox
        mask="(000) 000-0000"
        id="phone-number-entry"
        valid={validNumber}
        onChange={(e) => validateNumber(e.target.value)}
      />

      <DialogActionsBar>
        <ActionBar>
          <CancelButton
            id="confirm-dialog-modal-cancel-button"
            onClick={() => handleClick(false)}
            {...props.dataAttributes}
          >
            {props.cancelButtonText ? props.cancelButtonText : 'Cancel'}
          </CancelButton>
          <SubmitButtonMod
            id={
              props.confirmButtonCustomId ||
              'confirm-dialog-modal-confirm-button'
            }
            onClick={() => handleClick(true)}
            {...props.dataAttributes}
            disabled={!validNumber}
          >
            {props.confirmButtonText}
          </SubmitButtonMod>
        </ActionBar>
      </DialogActionsBar>
    </DialogMod>
  );
};

interface PhoneNumberModalProps {
  title: string;
  description: string;
  markdown?: boolean;
  confirmButtonText: string;
  cancelButtonText?: string;
  modalCallback: any;
  handleConfirm?: any;
  dataAttributes?: any;
  confirmButtonCustomId?: string;
  cancelButtonCustomId?: string;
  setNumber: Function;
}

export default PhoneNumberModal;
