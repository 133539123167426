import {
  Calendar as KendoCalendar,
  CalendarCell,
  CalendarCellProps,
  CalendarProps,
  DateInput,
  DatePickerChangeEvent,
  DatePickerProps,
} from '@progress/kendo-react-dateinputs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DatePicker as StyledDatePicker } from '../../simple-styled-components';
import React from 'react';
import { enums } from '../../../../utils';
import {
  IKenticoData,
  KenticoDataContext,
} from '../../../../contexts/kentico-data-context';

dayjs.extend(utc);
export interface CustomDatePickerProps extends DatePickerProps {
  disabledDates?: string[];
}

export const isDateDisabled = (v: Date, disabledDates: string[]) =>
  disabledDates.some((d) => dayjs(d).isSame(dayjs(v), 'd'));

export const Cell = (p: CalendarCellProps) => {
  const { disableWeekends, disabledDates, ...others } = p;

  return (
    <CalendarCell
      {...others}
      isDisabled={
        p.isDisabled ||
        (disableWeekends && p.isWeekend) ||
        (disabledDates?.length > 0 && isDateDisabled(p.value, disabledDates))
      }
    />
  );
};

export interface DisabledCalendarProps extends CalendarProps {
  disabledDates?: string[];
  disableWeekends?: boolean;
}

export const Calendar = (p: DisabledCalendarProps) => {
  const { disableWeekends, disabledDates, ...others } = p;

  const injectProps = (ip: any) => {
    const newProps = {
      ...ip,
      disableWeekends,
      disabledDates,
    };
    return <Cell {...newProps} />;
  };

  return <KendoCalendar cell={injectProps} {...others} />;
};

export const getDisabledDates = async (
  setDisabledDates: React.Dispatch<React.SetStateAction<string[]>>,
  callCenter: IKenticoData['callCenter'],
  setMinDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
) => {
  callCenter.closures?.forEach((i) => {
    if (!i.allDay) {
      return;
    }

    const s = dayjs(i.start).utc().startOf('d');
    const e = dayjs(i.end).utc().startOf('d');
    const daysBetween = e.diff(s, 'd') + 1;
    const disabledDates: string[] = [];

    // from start to end, add to disabledDates
    for (let j = 0; j < daysBetween; j++) {
      const d = s.add(j, 'd');
      disabledDates.push(d.format('YYYY-MM-DD'));
    }
    DisableTodayButtonForDisabledDates(disabledDates, setMinDate);
    setDisabledDates((d) => [...d, ...disabledDates]);
  });
};

const DisableTodayButtonForDisabledDates = (
  disabledDates: string[],
  setMinDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
) => {
  disabledDates.forEach((d: string) => {
    const date = new Date(d);
    const day = new Date().getDate();
    const month = new Date().getMonth();
    const year = new Date().getFullYear();

    if (
      date.getDate() === day &&
      date.getMonth() === month &&
      date.getFullYear() === year
    ) {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      setMinDate(today);
    }
  });
};

//if today is a weekend, increase the minimum date by 1 or 2 to shift it to Monday
const DisableTodayButtonForWeekends = (
  setMinDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
) => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  if (dayOfWeek === 0) {
    today.setDate(today.getDate() + 1);
    setMinDate(today);
  } else if (dayOfWeek === 6) {
    today.setDate(today.getDate() + 2);
    setMinDate(today);
  } else {
    return;
  }
};

export const handleStartEffect = (
  p: CustomDatePickerProps,
  setDisableWeekends: React.Dispatch<React.SetStateAction<boolean>>,
  setDisabledDates: React.Dispatch<React.SetStateAction<string[]>>,
  callCenter: IKenticoData['callCenter'],
  setMinDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
) => {
  if ((p.disabledDates?.length || 0) > 0) {
    p.disabledDates?.forEach((v) => {
      if (v === enums.DataSources.weekends) {
        setDisableWeekends(true);
        DisableTodayButtonForWeekends(setMinDate);
      } else if (v === enums.DataSources.callCenterClosures) {
        getDisabledDates(setDisabledDates, callCenter, setMinDate);
      } else {
        setMinDate(p.min);
      }
    });
  } else {
    setMinDate(p.min);
  }
};

const styles = `
  .k-calendar-navigation {
    z-index: 1;
  }
`;

const DatePicker: (p: CustomDatePickerProps) => React.ReactElement = (p) => {
  const { min, onChange, value, ...others } = p;

  const [disableWeekends, setDisableWeekends] = React.useState(false);
  const [disabledDates, setDisabledDates] = React.useState<string[]>([]);
  const { callCenter } = React.useContext(KenticoDataContext);
  const [minDate, setMinDate] = React.useState<Date | undefined>(min);

  React.useEffect(() => {
    if (p) {
      handleStartEffect(
        p,
        setDisableWeekends,
        setDisabledDates,
        callCenter,
        setMinDate,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p]);

  const disabledCalendar = (ip: any) => {
    const newProps = {
      ...ip,
      disableWeekends,
      disabledDates,
    };

    return React.cloneElement(<Calendar />, newProps, ip.children);
  };

  const disabledDateInput = (ip: any) => {
    const { _disabled, ...ddOthers } = ip;
    const newProps = {
      ...ddOthers,
      disabled: true,
    };

    return <DateInput {...newProps}>{ip.children}</DateInput>;
  };

  const onChangeNoScroll = (e: DatePickerChangeEvent) => {
    if (e.syntheticEvent.type === 'wheel') {
      return;
    }

    if (onChange) {
      onChange(e);
    }
  };

  React.useEffect(() => {
    if (others.onBlur && !!value) {
      others.onBlur({ target: { value } } as any);
    }
  }, [others, others.onBlur, value]);

  return disabledDates?.length > 0 || disableWeekends ? (
    <>
      <style>{styles}</style>
      <StyledDatePicker
        calendar={disabledCalendar}
        dateInput={disabledDateInput}
        min={minDate}
        onChange={onChangeNoScroll}
        value={value ? dayjs(value).toDate() : value}
        format="MM/dd/yyyy"
        {...others}
      />
    </>
  ) : (
    <>
      <style>{styles}</style>
      <StyledDatePicker
        value={value ? dayjs(value).toDate() : value}
        onChange={onChangeNoScroll}
        min={minDate}
        format="MM/dd/yyyy"
        {...others}
      />
    </>
  );
};

export default DatePicker;
