/* eslint-disable import/first */
import { log } from '../log';
import { getSimpleRemoteConfig } from '../remote-config-manager';
import { enums } from '../';
import 'amazon-connect-chatjs';
import { getSessionInfo, postLogout } from '../web-apis-client';

declare global {
  interface Window {
    Cobrowse: any;
    dataLayer: any;
  }
}
declare global {
  namespace NodeJS {
    interface Global {
      document: Document;
      window: Window;
      navigator: Navigator;
      connectSession?: any;
      displayLiveChat: any;
    }
  }
}

/**
 * Clears stored credentials
 */
export const clearAWSCredentials = () => {
  sessionStorage.removeItem(enums.CacheKeys.sessionCredentials);
  sessionStorage.removeItem(enums.CacheKeys.userInfo);
};

let awaitingLogout = false;

export const logout = async (redirect = true) => {
  const remoteConfig = await getSimpleRemoteConfig();
  sessionStorage.removeItem(enums.CacheKeys.ssoCookieKey);
  sessionStorage.removeItem(enums.CacheKeys.ssoCookieValue);

  try {
    if (awaitingLogout) {
      return;
    }
    awaitingLogout = true;
    await postLogout();
    awaitingLogout = false;
    await global?.connectSession?.disconnectParticipant();
  } catch (e) {
    log.error(e);
  }

  if (window.Cobrowse?.modal) {
    window.Cobrowse.modal.endSession(); //Close a CoBrowse session if one is open
  }

  // TODO: remove this after same origin changes
  clearAWSCredentials();

  if (redirect) {
    localStorage.removeItem(enums.CacheKeys.lastLoginTime);
    const redirectUrl = remoteConfig['login_redirect_url'];
    localStorage.clear();
    sessionStorage.clear();
    window.location.assign(redirectUrl);
  }
};

/**
 * Check if the user appears to be logged in
 * @returns {boolean}
 */
export const isAuthenticated = async () => {
  try {
    // TODO: helpful data is in this response
    // for example, we can show the user the time their session will expire if we want
    const userInfo = await getSessionInfo();
    sessionStorage.setItem(
      enums.CacheKeys.userInfo,
      JSON.stringify({
        email: userInfo.username,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        eid: userInfo.eid,
      }),
    );
  } catch (e) {
    console.error(e);
    await logout();
    return false;
  }

  return true;
};
