import { colors } from '../../style';

export const DEFAULT_STATUSES = {
  Approved: {
    background: colors.iceberg,
    color: colors.observatory1,
    text: 'All absences are approved',
    calendar: colors.greenHaze1,
    showDecisions: true,
  },
  Pending: {
    background: colors.bridesmaid,
    color: colors.christine,
    text: 'Some absences are pending or denied',
    calendar: colors.christine,
    showDecisions: false,
  },
  Partial: {
    background: colors.bridesmaid,
    color: colors.christine,
    text: 'Some absences are pending or denied',
    calendar: colors.christine,
    showDecisions: true,
  },
  Denied: {
    background: colors.wepeep,
    color: colors.cardinal,
    text: 'All absences are denied',
    calendar: colors.cardinal,
    showDecisions: true,
  },
};

export const DEFAULT_LEAVE_OPTION = 'All leaves';

export const DEFAULT_FILTER_OPTION = 'View All';
