import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { colors } from '../../style';
import { prefixObjectValues } from '../../utils';
import CommunicationPreferences from './communication-preferences';
import PaymentPreferences from './payment-preferences';
import { PageTitle } from './index.sc';
import { PersonDataContext } from '../../contexts/person-data-context';

interface ProfileProps {
  keyId?: string;
  userEmail?: string;
}

const domIdsStatic = {
  rootNode: 'profile-page',
  componentKey: 'profile-',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  background-color: ${colors.coolNeutralL1};
`;

const PrefsContainer = styled.div`
  margin-bottom: 15px;
  margin-top: 15px;
`;

const Profile: (p: ProfileProps) => React.ReactElement = (p) => {
  const domIds = domIdsUnique(p.keyId);
  const [showPaymentPrefs, setShowPaymentPrefs] = useState<boolean>(true);
  const { personData } = useContext(PersonDataContext);

  useEffect(() => {
    setShowPaymentPrefs(personData?.eeCentric?.checkCutting ?? false);
  }, [personData]);

  return (
    <Container id={domIds.rootNode}>
      <PageTitle>My Preferences</PageTitle>
      <PrefsContainer>
        <CommunicationPreferences keyId={domIdsStatic.componentKey} />
      </PrefsContainer>
      {showPaymentPrefs ? (
        <PrefsContainer>
          <PaymentPreferences
            userEmail={p.userEmail}
            keyId={domIdsStatic.componentKey}
          />
        </PrefsContainer>
      ) : null}
    </Container>
  );
};

export default Profile;
