import dayjs from 'dayjs';
import {
  getMergeUploadedFilesBucketUrl,
  postMergeUploadedFiles,
  postConvertFile,
} from '../../utils/web-apis-client';
import axios from 'axios';

export const uploadAction2 = async (
  caseId: string,
  docType: string,
  sortableFiles: any[],
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>,
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>,
) => {
  const timeStamp = dayjs().unix();
  const fileOrder: string[] = [];

  for await (const file of sortableFiles) {
    setCurrentStep(`Processing ${file.name}`);
    setCurrentStepIndex((prev) => prev + 1);
    const response = await getMergeUploadedFilesBucketUrl(
      caseId,
      file.name,
      docType,
      timeStamp.toString(),
    );

    await axios.put(response.URL, file.getRawFile?.(), {
      headers: {
        'Content-Type': file.contentType,
      },
    });

    const convert = await postConvertFile(response.KEY);
    fileOrder.push(convert.file);
  }
  setCurrentStep('Nearly done!');
  return await postMergeUploadedFiles(fileOrder, docType).then(
    (response: any) => response.data,
  );
};
