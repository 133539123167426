import { get } from '../../utils';
import {
  CustomEntitlement,
  CustomSharedEntitlement,
  Leave,
  PersonData,
  SelectedLeavePlan,
} from '../../data-models';
import { NY_PFL_PLANS, TRACKING_PLANS } from './util';
import dayjs from 'dayjs';
import { getLeaveAvailibility } from '../../utils/web-apis-client';

const dateFormat = 'MMM DD, YYYY';
const caclFormat = 'YYYY-MM-DD';

const selectedLeavePlans = (leaves: Leave[]) => {
  const output: SelectedLeavePlan[] = [];
  leaves.forEach((leave) => {
    if (leave.selectedLeavePlans) {
      leave.selectedLeavePlans.forEach((plan) => {
        if (
          !TRACKING_PLANS.find((ele) => ele === plan.longname) &&
          plan.entitlementCalculationPeriod &&
          plan.timeWithinPeriodBasis &&
          plan.timeBasis &&
          plan.timeEntitlement &&
          plan.availabilityPeriodStartDate &&
          plan.availabilityPeriodEndDate
        ) {
          output.push({ ...plan });
        }
      });
    }
  });
  console.log('selectedLeavePlans', output);
  return output;
};

const groupSharedSelectedLeavePlans = (selectedLeaves: SelectedLeavePlan[]) => {
  const map = new Map<string, SelectedLeavePlan[]>();
  selectedLeaves.forEach((leave) => {
    // leave has child node, check common parents that have same child (sharedPlan)
    if (
      leave.sharedEntitlementPlans &&
      leave.sharedEntitlementPlans.length &&
      leave.sharedEntitlementPlans.length > 0
    ) {
      const sharedPlan = leave.sharedEntitlementPlans[0];
      const value = map.get(sharedPlan.id);
      if (value) {
        const checkDupe = value.find(
          (v) => v.leavePlanId === leave.leavePlanId,
        );
        if (!checkDupe) {
          value.push({ ...leave });
          map.set(sharedPlan.id, value);
        }
      } else {
        map.set(sharedPlan.id, [{ ...leave }]);
      }
    } else {
      // some tracking plans have empty sharedEntitlement nodes
      if (leave.leavePlanId) {
        map.set(leave.leavePlanId, [{ ...leave }]);
      }
    }
  });
  return map;
};

const shapeData = (map: Map<string, SelectedLeavePlan[]>) => {
  if (map.size === 0) {
    return [];
  }
  const customData: CustomSharedEntitlement[] = [];
  map.forEach((value) => {
    // dont nest NY plans
    if (
      NY_PFL_PLANS.find((e) => e === value[0].longname) ||
      value.length <= 1
    ) {
      customData.push({
        mainEntitlement: createEntitlement(value[0]),
        entitlements: [],
      });
    }
    // create nested data if leaves have shared plans
    else if (value.length > 1) {
      const temp: CustomEntitlement[] = [];
      value.forEach((plan) => {
        temp.push(createEntitlement(plan));
      });
      const shared = value[0].sharedEntitlementPlans[0];
      customData.push({
        mainEntitlement: {
          id: shared.id,
          name: shared.name,
          availableTime: formatNumber(
            shared.timeEntitlement - (shared.approvedTime + shared.pendingTime),
          ),
          periodEndDate: dayjs(value[0].availabilityPeriodEndDate).format(
            dateFormat,
          ),
          calculationPeriod: value[0].entitlementCalculationPeriod,
          timeBasis: shared.timeBasis,
          timeEntitlement: shared.timeEntitlement,
          timeWithinPeriod: shared.timeWithinPeriod,
          timeWithinPeriodBasis: shared.timeWithinPeriodBasis,
          approvedTime: shared.approvedTime,
          pendingTime: shared.pendingTime,
          availabilityPeriodStartDate: shared.availabilityPeriodStartDate,
          availabilityPeriodEndDate: shared.availabilityPeriodEndDate,
        },
        entitlements: temp,
      });
    }
  });
  return customData.sort(
    (a, b) => b.entitlements.length - a.entitlements.length,
  );
};

const createEntitlement = (
  selectedLeave: SelectedLeavePlan,
): CustomEntitlement => {
  return {
    id: selectedLeave.leavePlanId ?? '',
    name: selectedLeave.longname ?? '',
    availableTime: formatNumber(
      selectedLeave.timeEntitlement -
        (selectedLeave.approvedTime + selectedLeave.pendingTime),
    ),
    periodEndDate: dayjs(selectedLeave.availabilityPeriodEndDate).format(
      dateFormat,
    ),
    calculationPeriod: selectedLeave.entitlementCalculationPeriod,
    timeBasis: selectedLeave.timeBasis ?? '',
    timeEntitlement: selectedLeave.timeEntitlement,
    timeWithinPeriod: selectedLeave.timeWithinPeriod,
    timeWithinPeriodBasis: selectedLeave.timeWithinPeriodBasis,
    approvedTime: selectedLeave.approvedTime,
    pendingTime: selectedLeave.pendingTime,
    availabilityPeriodStartDate: selectedLeave.availabilityPeriodStartDate,
    availabilityPeriodEndDate: selectedLeave.availabilityPeriodEndDate,
  };
};

const formatNumber = (num: number) => {
  const fixedNum = num.toFixed(4);
  return parseFloat(fixedNum).toString();
};

export const getAvailableTime = async (personData: PersonData | undefined) => {
  const leaves: Leave[] = get(personData, 'cases.leaves', []);

  if (!leaves) {
    return [];
  }

  const selectedLeaves = selectedLeavePlans(leaves);
  const grouped = groupSharedSelectedLeavePlans(selectedLeaves);
  const shapedData = shapeData(grouped);

  for await (const item of shapedData) {
    if (item.entitlements.length > 0) {
      const isRollingBack = item.mainEntitlement.calculationPeriod
        .toLocaleLowerCase()
        .includes('rolling back');
      const upToDate = await getLeaveAvailibility(
        item.mainEntitlement.id,
        isRollingBack
          ? dayjs(item.entitlements[0].availabilityPeriodEndDate).format(
              caclFormat,
            )
          : dayjs(item.entitlements[0].availabilityPeriodStartDate).format(
              caclFormat,
            ),
      );

      item.mainEntitlement = {
        ...item.mainEntitlement,
        pendingTime: upToDate.pendingTime,
        approvedTime: upToDate.approvedTime,
        availableTime: formatNumber(
          upToDate.timeEntitlement -
            (upToDate.approvedTime + upToDate.pendingTime),
        ),
      };
    }
  }

  return shapedData;
};
