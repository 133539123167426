import React from 'react';
import AbsenceCard from '../absence-card';
import { PageChangeEvent } from '@progress/kendo-react-data-tools';
import PagerSection from '../../base/pager-section';
import { BlueText, NoAbsencesContainer } from '../index.sc';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';
import NoAbsencesWarning from '../../../images/absence-warning.svg';
import { getHtmlFromMarkdown, getLocalizedOrDefault } from '../../../utils';
import { convertAnchorTagsToRouterLinks } from '../../../utils/html-parsing-helpers';
import { ProcessedCalendarItem } from '../../../utils/calendars';
import dayjs from 'dayjs';

type AbsenceCardSectionType = (p: {
  legend: { [name: string]: any };
  elements: any;
  selectedDate: dayjs.Dayjs;
  listView: boolean;
}) => React.ReactElement;

const sortAbsenceListDesc = (a: any, b: any) =>
  dayjs(a.startDate).isBefore(dayjs(b.startDate)) ? 1 : -1;

const AbsenceCardSection: AbsenceCardSectionType = (p) => {
  const { elements, legend, selectedDate, listView } = p;
  const { assets, localizedStrings } = React.useContext(KenticoDataContext);
  const [filteredElements, setFilteredElements] = React.useState<any>([]);
  const [page, setPage] = React.useState({
    skip: 0,
    take: 5,
  });

  React.useEffect(() => {
    const filtered = listView
      ? elements
      : elements.filter(
          (elem: any) =>
            elem.startDate && dayjs(elem.startDate).isSame(selectedDate, 'd'),
        );
    const allItems = filtered.flatMap((elem: any) => elem.data);
    allItems.sort(sortAbsenceListDesc);
    setFilteredElements(allItems);
    setPage({
      skip: 0,
      take: 5,
    });
  }, [selectedDate, listView, elements]);

  const handlePageChange = (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
  };

  const { skip, take } = page; //Used for Pagination

  if (filteredElements.length > 0) {
    return (
      <>
        {filteredElements
          .slice(skip, skip + take)
          .map((actualAbsencePeriod: ProcessedCalendarItem, i: number) => (
            <AbsenceCard
              key={i}
              {...actualAbsencePeriod}
              legend={legend}
              index={i}
            />
          ))}
        <PagerSection
          skip={skip}
          take={take}
          handlePageChange={handlePageChange}
          elements={filteredElements}
        />
      </>
    );
  } else {
    return (
      <NoAbsencesContainer id="no-absences-view">
        <img
          src={assets['absence-warning.svg']?.url || NoAbsencesWarning}
          alt=""
          width="156"
          height="114"
        />
        <BlueText style={{ maxWidth: 532 }}>
          {convertAnchorTagsToRouterLinks(
            getHtmlFromMarkdown(
              getLocalizedOrDefault(
                localizedStrings,
                'absence_history___no_absences',
                "There are no reported absences to display. If you've been absent from work, please report your absence as soon as possible.",
              ),
            ).__html,
          )}
        </BlueText>
      </NoAbsencesContainer>
    );
  }
};

export default AbsenceCardSection;
