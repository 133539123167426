import orangeWarning from '../../../images/orange-warning.svg';

type Props = {
  show: boolean;
};
export const EmployerTaskInfo = ({ show }: Props) => {
  return (
    <>
      {show && (
        <div className="tw-w-full tw-mt-2 tw-p-2 tw-border tw-rounded-md tw-bg-[#fef3ea] tw-flex tw-text-sm">
          <img alt="info" src={orangeWarning} />
          <div className="tw-pl-[15px]">
            Please note, we are actively working with your employer to gather
            outstanding information
          </div>
        </div>
      )}
    </>
  );
};
