import { isTrackingDeclined } from '..';
import { log } from '../log';
import { postTrack } from '../web-apis-client';

declare global {
  interface Window {
    FS?: {
      setUserVars: (traits?: { [key: string]: any }) => void;
    };
  }
}

const IDENTIFY_EVENT_TYPE = 'Identify';
const TRACK_EVENT_TYPE = 'Track';
const PAGEVIEW_EVENT_TYPE = 'PageView';
const EXCEPTION_EVENT_TYPE = 'ExceptionEvent';

window.dataLayer = window.dataLayer || [];

export const trackGA4Event = (e: any) => {
  const dataObject = e.currentTarget.getAttribute('data-dl');
  const parsed = JSON.parse(dataObject);
  window.dataLayer.push({ event: 'custom_event', ...parsed });
};

export const trackGA4PageView = (data: { [name: string]: any }) => {
  window.dataLayer.push(data);
};

const identifyByCustomerNumber = async (traits?: { [key: string]: any }) => {
  try {
    if (isTrackingDeclined()) {
      return;
    }

    await postTrack({
      type: IDENTIFY_EVENT_TYPE,
      name: IDENTIFY_EVENT_TYPE,
      properties: {},
      traits,
    });
  } catch (error) {
    console.log('Failed to identify traits.');
  }
};

const trackByCustomerNumber = async (
  type: string,
  eventName: string,
  props?: { [key: string]: any },
) => {
  try {
    if (isTrackingDeclined()) {
      return;
    }

    await postTrack({
      type,
      name: eventName,
      properties: props,
    });
  } catch (error) {
    console.log('Failed to track event.');
  }
};

export const trackEvent = async (
  eventName: string,
  props?: { [key: string]: any },
) => {
  return trackByCustomerNumber(TRACK_EVENT_TYPE, eventName, props);
};

export const pageViewEvent = async (
  pageName: string,
  props?: { [key: string]: any },
) => {
  return trackByCustomerNumber(PAGEVIEW_EVENT_TYPE, pageName, props);
};

export const exceptionEvent = async (
  eventName: string,
  props?: { [key: string]: any },
) => {
  return trackByCustomerNumber(EXCEPTION_EVENT_TYPE, eventName, props);
};

export const identifyEvent = async (traits?: { [key: string]: any }) => {
  return identifyByCustomerNumber(traits);
};

export const trackFSVars = (userVars?: { [key: string]: any }) => {
  if (window.FS && typeof window.FS.setUserVars === 'function' && userVars) {
    window.FS.setUserVars(userVars);
  } else {
    log.error(`Failed to setUserVars.`, { traits: userVars });
  }
};

(window as any).caas = (window as any).caas || {};
(window as any).caas.internal = {
  trackEvent,
  pageViewEvent,
  identifyEvent,
};
