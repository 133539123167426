
import { ContentItem, Elements } from '@kentico/kontent-delivery';
import { Descriptionbybusinessrole } from './descriptionbybusinessrole';
import { Faq } from './faq';
import { FormConfig } from './form_config';
/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Task extends ContentItem {
    public taskTitle!: Elements.LinkedItemsElement<Descriptionbybusinessrole>;
    public name!: Elements.TextElement;
    public shortDescription!: Elements.LinkedItemsElement<Descriptionbybusinessrole>;
    public description!: Elements.LinkedItemsElement<Descriptionbybusinessrole>;
    public tasker!: Elements.LinkedItemsElement<ContentItem>;
    public form!: Elements.LinkedItemsElement<FormConfig>;
    public category!: Elements.MultipleChoiceElement;
    public importantTask!: Elements.MultipleChoiceElement;
    public duedatesettings!: Elements.LinkedItemsElement<ContentItem>;
    public followuptask!: Elements.LinkedItemsElement<ContentItem>;
    public tags!: Elements.TaxonomyElement;
    public frequentlyAskedQuestions!: Elements.LinkedItemsElement<Faq>;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'task_title') {
                    return 'taskTitle';
                }
                if (elementName === 'short_description') {
                    return 'shortDescription';
                }
                if (elementName === 'important_task') {
                    return 'importantTask';
                }
                if (elementName === 'frequently_asked_questions') {
                    return 'frequentlyAskedQuestions';
                }
                return elementName;
            })
        });
    }
}
