import React from 'react';

import {
  CircularGauge,
  CircularGaugeProps,
} from '@progress/kendo-react-gauges';
import { CustomEntitlement } from '../../../data-models';

type Props = {
  item: CustomEntitlement;
};

export const CircularGuage: React.FC<Props> = ({ item }) => {
  const gaugeStyle = {
    width: '160px',
    height: '160px',
  };
  const arcOptions: CircularGaugeProps = {
    value: item.approvedTime + item.pendingTime,
    color: '#015294',
    scale: {
      rangeSize: 15,
      max: item.timeEntitlement,
    },
  };
  const centerRenderer = () => {
    return (
      <span className="tw-text-xs tw-font-bold">
        {`${(
          item.timeEntitlement -
          (item.approvedTime + item.pendingTime)
        ).toFixed(1)}`}
        <br />
        {`${item.timeBasis} Available`}
      </span>
    );
  };

  return (
    <CircularGauge
      {...arcOptions}
      style={gaugeStyle}
      centerRender={centerRenderer}
    />
  );
};
