import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../style';
import {
  prefixObjectValues,
  getHtmlFromMarkdown,
  analyticsTrackEvent,
  enums,
} from '../../utils';
import useHandlebars from '../../hooks/use-handlebars';
import { FormContext } from '../../contexts/form-context';

interface NextStepProps {
  keyId?: string;
  title?: string;
  description?: string;
  thumbnail?: any;
  link?: any;
  type?: string;
}

const domIdsStatic = {
  rootNode: 'next-step',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  background-color: ${colors.coolNeutralL1};
`;

const prefixMap: { [key: string]: string } = {
  Fig: 'Fig',
  Behavioral: 'Behavioral Health',
};

const NextStep: (p: NextStepProps) => React.ReactElement = (p) => {
  const [shown, setshown] = useState(false);
  const domIds = domIdsUnique(p.keyId);
  const { formContext } = React.useContext(FormContext);
  const markdown = useHandlebars(p.description, formContext);
  let externalLink;
  let linkUrl;
  if (p.link && p.link.value && p.link.value.length > 0) {
    externalLink = p.link.value[0].is_client_route_.value[0].codename === 'no';
    linkUrl = p.link.value[0].url.value;
  }
  const compiledLink = useHandlebars(linkUrl, formContext);

  const handleNextStepClick = (_e: any) => {
    const eventProps = {
      Eid: formContext.person?.eid,
      Form: formContext.formName,
      FIGCard: p.title,
    };

    const eventsToTrack = Object.keys(prefixMap);
    eventsToTrack.forEach((ett) => {
      if (p?.title?.split?.(' ')?.includes?.(ett)) {
        analyticsTrackEvent(
          `${prefixMap[ett]} ${enums.AnalyticsEvents.genericNextStepClicked}`,
          eventProps,
        );
      }
    });
  };

  React.useEffect(() => {
    const eventProps = {
      Eid: formContext.person?.eid,
      Form: formContext.formName,
      CardTitle: p.title,
    };
    if (
      p.title !== undefined &&
      p.title.split(' ').includes('Fig') &&
      shown === false
    ) {
      analyticsTrackEvent(enums.AnalyticsEvents.figNextStepShown, eventProps);
      setshown(true);
    }
  }, [p, shown, formContext]);

  return (
    <Container id={domIds.rootNode}>
      {!externalLink ? (
        <Link to={compiledLink} className="next-step-box">
          <img alt="" src={p.thumbnail} />
          <span className="next-content">
            <span className="next-title">{p.title}</span>
            <span
              dangerouslySetInnerHTML={
                p.description ? getHtmlFromMarkdown(markdown) : undefined
              }
            ></span>
          </span>
        </Link>
      ) : (
        <a
          href={compiledLink}
          target={p.link.value[0].target.value}
          className="next-step-box"
          rel="noreferrer"
          onClick={(e) => handleNextStepClick(e)}
        >
          <img alt="" src={p.thumbnail} />
          <span className="next-content">
            <span className="next-title">{p.title}</span>
            <span
              className="next-copy"
              dangerouslySetInnerHTML={
                p.description ? getHtmlFromMarkdown(markdown) : undefined
              }
            ></span>
          </span>
        </a>
      )}
    </Container>
  );
};

export default NextStep;
